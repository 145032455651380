import * as TYPES from 'common/Types';

export const CONSTS = {
  /** PC表示のコンテンツエリアの幅(px) */
  CONTENTS_AREA_WIDTH: 975,
  /** 日付 フォーマット */
  DATE_FORMAT: 'YYYY/MM/DD',
  /** セレクトボックス 空項目値 */
  SELECT_EMPTY_VALUE: '',
  /** テキストフィールドのデフォルト幅 */
  TEXT_NUMBER_FIELD_WIDTH: 100,
  TEXT_STRING_FIELD_WIDTH: 250,
  /** ボタン 背景色パターン */
  BUTTON_BACKGROUND_COLOR: {
    [TYPES.COLOR_TYPES.MAIN]: {
      backgroundColor: TYPES.COLOR_CODE_MAIN_BASIC,
      hoverBackgroundColor: TYPES.COLOR_CODE_MAIN_DARK,
      focusBackgroundColor: TYPES.COLOR_CODE_MAIN_LIGHT,
    },
    [TYPES.COLOR_TYPES.ACCENT]: {
      backgroundColor: TYPES.COLOR_CODE_ACCENT_BASIC,
      hoverBackgroundColor: TYPES.COLOR_CODE_ACCENT_DARK,
      focusBackgroundColor: TYPES.COLOR_CODE_ACCENT_LIGHT,
    },
    [TYPES.COLOR_TYPES.SUCCESS]: {
      backgroundColor: TYPES.COLOR_CODE_SUCCESS_BASIC,
      hoverBackgroundColor: TYPES.COLOR_CODE_SUCCESS_DARK,
      focusBackgroundColor: TYPES.COLOR_CODE_SUCCESS_LIGHT,
    },
    [TYPES.COLOR_TYPES.ERROR]: {
      backgroundColor: TYPES.COLOR_CODE_ERROR_BASIC,
      hoverBackgroundColor: TYPES.COLOR_CODE_ERROR_DARK,
      focusBackgroundColor: TYPES.COLOR_CODE_ERROR_LIGHT,
    },
  },

  /** サービス名 Symgram  */
  SERVICE_NAME_SYMGRAM: 'SYMGRAM®️',
  /** サービス名 Para Check BIO  */
  SERVICE_NAME_PARA_CHECK_BIO: 'PARA Check BIO',

  /** GroupID バージョン１ */
  GROUP_ID_VERSION_ONE: 'G0000000000000000000000000000001',
  /** GroupID バージョン３ */
  GROUP_ID_VERSION_THREE: 'G0000000000000000000000000000002',
  /** GroupID バージョン4 */
  GROUP_ID_VERSION_FOUR: 'G0000000000000000000000000000003',

  /** Symgram バージョン３ */
  SYMGRAM_VERSION_THREE: 3,
  /** Symgram バージョン4 */
  SYMGRAM_VERSION_FOUR: 4,

  /** パス GC004_トップページ */
  PATH_TOP: '/top',
  /** パス GC005_ログイン */
  PATH_LOGIN: '/login',
  /** パス GC006_被検者参照 */
  PATH_VIEW_SUBJECT: '/subject/view',
  /** パス GC008_パスワード再設定メール送信 */
  PATH_SEND_RESET_PASSWORD_EMAIL: '/password/sendResetEmail',
  /** パス GC009_パスワード再設定メール送信結果参照 */
  PATH_VIEW_PASSWORD_RESET_EMAIL_SEND_RESULT:
    '/password/viewSendResetEmailResult',
  /** パス GC010_パスワード再設定 */
  PATH_RESET_PASSWORD: '/password/reset',
  /** パス GC011_利用者参照 */
  PATH_UPDATE_USER: '/user/update',
  /** パス GC012_被検者登録確認 */
  PATH_REGISTRATION_CHECK_SUBJECT: '/subject/registrationCheck',
  /** パス GC013_被検者入力 */
  PATH_INPUT_SUBJECT: '/subject/input',
  /** パス 被検者更新 */
  PATH_UPDATE_SUBJECT: '/subject/update',
  /** パス GC014_認証コード参照 */
  PATH_VIEW_CERTIFICATION_CODE: '/certificationCode/view',
  PATH_VIEW_CERTIFICATION_CODE_WITH_ID:
    '/certificationCode/view/:kitId/:sampleId',
  /** パス GC015_認証コード入力 */
  PATH_INPUT_CERTIFICATION_CODE: '/certificationCode/input',
  PATH_INPUT_CERTIFICATION_CODE_SHORT: '/in',
  /** パス GC016_利用者入力 */
  PATH_INPUT_USER: '/user/input',
  /** パス GC018_利用者登録確認 */
  PATH_REGISTRATION_CHECK_USER: '/user/registrationCheck',
  /** パス GC019_パスワード更新 */
  PATH_UPDATE_PASSWORD: '/password/update',
  /** パス GC020_メールアドレス更新 */
  PATH_UPDATE_EMAIL: '/email/update',
  /** パス GC022_メールアドレス更新確認コード入力 */
  PATH_INPUT_UPDATE_EMAIL_CONFIRMATION_CODE:
    '/emailConfirmationCode/inputUpdate',
  /** パス GC023_メールアドレス登録確認コード入力 */
  PATH_INPUT_REGIST_EMAIL_CONFIRMATION_CODE:
    '/emailConfirmationCode/inputRegist',
  /** パス GC024_研究参加同意入力 */
  PATH_INPUT_STUDY_PARTICIPATION_CONSENT: '/studyParticipationConsent/input',
  /** パス GC025_研究参加同意参照 */
  PATH_VIEW_STUDY_PARTICIPATION_CONSENT: '/studyParticipationConsent/view',
  /** パス GC026_被検者設定 */
  PATH_SET_SUBJECT: '/subject/set',
  /** パス GC027_アンケート入力 */
  PATH_INPUT_ENQUETE: '/enquete/input/:groupId/:templateId',
  /** パス GC028_アンケート参照 */
  PATH_VIEW_ENQUETE: '/enquete/view/:groupId/:templateId',
  /** パス GC029_利用者登録履歴確認 */
  PATH_REGISTRATION_CHECK_HISTORY: '/user/registration/history',
  /** サンプルIDとキットIDの確認 */
  PATH_CHECK_SAMPLEID_KITID: '/check/sampleid/:sampleId/kitid/:kitId',
  /** 管理者ログイン画面 */
  PATH_MANAGER_LOGIN: '/manager/login',
  /** 管理者画面 */
  PATH_MANAGER: '/manager',
  /** 基本設定変更 */
  PATH_MANAGER_SETTING: '/manager/setting',
  /** メール詳細設定 */
  PATH_MANAGER_SETTING_MAIL: '/manager/setting/mail',
  /** 回答状態を確認 */
  PATH_MANAGER_MEMBER_PROGRESS: '/manager/members/progresses',
};

export const MEMBER_ID_MAP: { [key: string]: string } = {
  identification: '利用者との関係',
  relation: '被検者からみた、あなた（利用者）との関係',
  relation_detail: '続柄（父、母、子、法定後見人　など）',
  email: 'メールアドレス',
  password: 'パスワード',
  first_name: '名',
  last_name: '姓',
  first_name_kana: '名（フリガナ）',
  last_name_kana: '姓（フリガナ）',
  birthdate: '生年月日',
  zip: '郵便番号',
  address: '住所１（都道府県・市区町村）',
  building: '住所２（番地・建物名・部屋番号）',
  phone_number: '電話番号',
};

export default CONSTS;
