import React, { Fragment } from 'react';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import BackPageTitle from 'presentational/general/molecules/other/BackPageTitle';
import Form from 'presentational/general/molecules/form/Form';
import Button from 'presentational/general/atoms/button/Button';
import TemplatePanel from 'presentational/general/organisms/area/TemplatePanel';
import useReactRouter from 'use-react-router';
import { connect } from 'react-redux';
import {
  AnswerProgress,
  Template,
  Panel,
  Form as APIForm,
  FormOnlySlider,
} from 'oapi/api';
import { AppState } from 'duck/Reducer';
import TemplateState from 'duck/client/template/State';
import { isSameMembers, inSameMembers } from 'common/Utility';
import { generateTsxToReplacedBr } from 'commontsx/Utility';

export type ViewTemplateProps = {
  device: TYPES.DEVICE_TYPES;
  backIconTo: string;
  fixButtonTo: string;
  template: TemplateState;
};

const WrapAnswer = styled.span`
  padding-left: 8px;
`;

const PanelArea = styled.div`
  & div:first-child {
    margin-top: 0;
  }
`;

const ButtonArea = styled.div`
  margin: 50px auto 85px auto;
  width: 100px;
`;

/**
 * パネルまたはフォームの表示条件を満たしているか確認
 * @param {any} visibleConditions 質問の表示条件
 * @param {Panel[]} panels 全パネル情報
 * @returns {boolean} - 表示条件の充足状況
 */
const checkVisibleConditions = (visibleConditions: any, panels: Panel[]) => {
  let isVisible = true;
  if (visibleConditions) {
    visibleConditions.forEach((condition: any) => {
      const targetValue = ((): string[] => {
        const answers: string[] = [];
        for (let i = 0; i < panels.length; i += 1) {
          panels[i].forms.forEach((form) => {
            if (form.id === condition.value1.form_id && form.answers) {
              form.answers.forEach((answer) => {
                answers.push(answer.value);
              });
            }
          });
        }
        return answers;
      })();

      switch (`${condition.and_or}_${condition.type}`) {
        case '_=':
          isVisible = isSameMembers(condition.value2, targetValue);
          break;
        case '_!=':
          isVisible = !isSameMembers(condition.value2, targetValue);
          break;
        case '_IN':
          isVisible = inSameMembers(condition.value2, targetValue);
          break;
        case '_NOT_IN':
          isVisible = !inSameMembers(condition.value2, targetValue);
          break;
        case 'AND_=':
          isVisible = isVisible && isSameMembers(condition.value2, targetValue);
          break;
        case 'AND_!=':
          isVisible =
            isVisible && !isSameMembers(condition.value2, targetValue);
          break;
        case 'AND_IN':
          isVisible = isVisible && inSameMembers(condition.value2, targetValue);
          break;
        case 'AND_NOT_IN':
          isVisible =
            isVisible && !inSameMembers(condition.value2, targetValue);
          break;
        case 'OR_=':
          isVisible = isVisible || isSameMembers(condition.value2, targetValue);
          break;
        case 'OR_!=':
          isVisible =
            isVisible || !isSameMembers(condition.value2, targetValue);
          break;
        case 'OR_IN':
          isVisible = isVisible || inSameMembers(condition.value2, targetValue);
          break;
        case 'OR_NOT_IN':
          isVisible =
            isVisible || !inSameMembers(condition.value2, targetValue);
          break;
        default:
      }
    });
  }
  return isVisible;
};

/**
 * 各フォームの表示状態取得
 * @param {Template} template - テンプレート情報
 * @returns {boolean[]} - 各フォームの表示状態情報
 */
const getIsVisibleForms = (template: Template): boolean[][] => {
  return template.panels.map((panel: any): boolean[] => {
    return panel.forms.map((form: any): boolean => {
      if (
        form.type === 'none' &&
        form.options?.additional?.flag_display_in_confirmation !== '1'
      ) {
        return false;
      }
      if (form.options) {
        return checkVisibleConditions(
          form.options.visible_conditions,
          template.panels,
        );
      }
      return true;
    });
  });
};

type MixedFormsProps = {
  forms: APIForm[];
  device: TYPES.DEVICE_TYPES;
};

type SliderListFormsProps = {
  forms: FormOnlySlider[];
  device: TYPES.DEVICE_TYPES;
};

const MixedForms = ({ device, forms }: MixedFormsProps) => {
  const answers: React.ReactNode[] = [];
  forms.forEach((form, i) => {
    if (form.answers) {
      form.answers.forEach((answer, j) => {
        const key = `${answer.title}_${i}_${j}`;
        answers.push(<span key={key}>{answer.title}</span>);
      });
    }
    return null;
  });
  return (
    <Form
      device={device}
      question=""
      templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
    >
      {answers}
    </Form>
  );
};

const MixedPostalForms = ({ forms }: MixedFormsProps) => {
  const answers: React.ReactNode[] = [];
  forms.forEach((form) => {
    if (form.answers) {
      if (form?.options?.column_name === 'postal_code') {
        // eslint-disable-next-line react/jsx-one-expression-per-line
        answers.push(<div>&#12306;&nbsp;{form.answers[0].title}</div>);
      }
      if (form?.options?.column_name === 'life_place') {
        answers.push(<div>{form.answers[0].title}</div>);
      }
    }
    return null;
  });
  return <span>{answers}</span>;
};

const SliderListForms = ({ device, forms }: SliderListFormsProps) => {
  const answers: React.ReactNode[] = [];
  forms.forEach((form, i) => {
    if (form.answers) {
      form.answers.forEach((answer, j) => {
        const key = `${answer.title}_${i}_${j}`;
        answers.push(
          <div key={key}>
            <span>{`${form.subscribe}：`}</span>
            <WrapAnswer>{`${answer.title}`}</WrapAnswer>
          </div>,
        );
      });
    }
    return null;
  });
  return (
    <Form
      device={device}
      question=""
      templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
    >
      {answers}
    </Form>
  );
};

/**
 * 各パネルの表示状態取得
 * @param {Template} template - テンプレート情報
 * @param {boolean[][]} isVisibleForms - 各フォームの表示状態
 * @returns {boolean[]} - 各パネルの表示状態情報
 */
const getIsVisiblePanels = (
  template: Template,
  isVisibleForms: boolean[][],
): boolean[] => {
  return template.panels.map((panel: Panel, panelIndex: number): boolean => {
    let isVisiblePanel = false;
    isVisibleForms[panelIndex].forEach((isVisibleForm: boolean): void => {
      // パネル内部のフォームが全て非表示か判定
      isVisiblePanel = isVisiblePanel || isVisibleForm;
    });
    if (panel.options) {
      return checkVisibleConditions(
        panel.options.visible_conditions,
        template.panels,
      );
    }
    return isVisiblePanel;
  });
};

/**
 * 【画面】GB004_テンプレート参照
 * @param {DEVICE_TYPES} device - 表示デバイス区分
 * @param {string} backIconTo - 戻る付きタイトルアイコン遷移先
 * @param {string} fixButtonTo - 修正ボタン遷移先
 * @param {TemplateState} template - テンプレート(Redux connect)
 */
const ViewTemplate = ({
  device,
  backIconTo,
  fixButtonTo,
  template,
}: ViewTemplateProps) => {
  const { history } = useReactRouter();

  const handleBackIconClick = (): void => {
    history.push(backIconTo);
  };
  const handleButtonClick = (): void => {
    // TODO テンプレートAPI取得
    history.push(fixButtonTo);
  };

  /**
   * 修正ボタン出力判定
   * @param {AnswerProgress} progress 進捗区分
   */
  const isShowButton = (progress: AnswerProgress): boolean => {
    if (progress === AnswerProgress.TMPSAVED) {
      return true;
    }
    if (progress === AnswerProgress.ANSWERED) {
      return true;
    }
    return false;
  };

  // 各フォームの表示状態管理
  const isVisibleForms = getIsVisibleForms(template.template);
  // 各パネルの表示状態管理
  const isVisiblePanels = getIsVisiblePanels(template.template, isVisibleForms);

  return (
    <div>
      <BackPageTitle device={device} onClick={handleBackIconClick}>
        {template.template && template.template.name}
      </BackPageTitle>
      <PanelArea>
        {template.answers.panels.map((panel, panelIndex) => {
          return (
            <TemplatePanel
              device={device}
              key={`${panel.id}`}
              templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
              isDisplayed={isVisiblePanels[panelIndex]}
            >
              {panel.forms.map((form, formIndex) => {
                return (
                  <Fragment key={form.id}>
                    {isVisibleForms[panelIndex][formIndex] && (
                      <Form
                        device={device}
                        required={form.is_required}
                        question={generateTsxToReplacedBr(form.subscribe)}
                        templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
                        visible={isVisibleForms[panelIndex][formIndex]}
                      >
                        {form.answers &&
                          form.options?.column_name !== 'country_status' &&
                          form.answers.map((answer, i) => {
                            return (
                              <div
                                key={`${answer.title}_${String(
                                  formIndex,
                                )}_${String(i)}`}
                              >
                                {i > 0 && <br />}
                                {generateTsxToReplacedBr(answer.title)}
                              </div>
                            );
                          })}
                        {form.mixed_form &&
                          form.text_form?.type !== 'postal_with_address' && (
                            <MixedForms
                              device={device}
                              forms={form.mixed_form.forms}
                            />
                          )}
                        {form.mixed_form &&
                          form.text_form?.type === 'postal_with_address' && (
                            <MixedPostalForms
                              device={device}
                              forms={form.mixed_form?.forms}
                            />
                          )}
                        {form.slider_list_form && (
                          <SliderListForms
                            device={device}
                            forms={form.slider_list_form.forms}
                          />
                        )}
                      </Form>
                    )}
                  </Fragment>
                );
              })}
            </TemplatePanel>
          );
        })}
      </PanelArea>
      <ButtonArea>
        {/* TODO 進捗区分取得パターンによる表示テスト */}
        {isShowButton(template.answers.progress) && (
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            onClick={handleButtonClick}
          >
            修正
          </Button>
        )}
      </ButtonArea>
    </div>
  );
};

export default connect(({ template }: AppState) => ({ template }))(
  ViewTemplate,
);
