import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import Question from 'presentational/general/atoms/text/Question';
import RequiredLabel from 'presentational/general/atoms/label/RequiredLabel';
import Complement from 'presentational/general/atoms/text/Complement';

const PARTS = {
  MARGIN_PC: 20,
};

export type FormProps = PropsWithChildren<{
  width?: number;
  required?: boolean;
  question: React.ReactNode;
  complement?: React.ReactNode;
  device: TYPES.DEVICE_TYPES;
  templateInOut: TYPES.TEMPLATE_IN_OUT_TYPES;
  displayLeftRequiredLabel?: boolean;
  visible?: boolean;
}>;

type WrapProps = PropsWithChildren<{
  width?: number;
  device: TYPES.DEVICE_TYPES;
  templateInOut: TYPES.TEMPLATE_IN_OUT_TYPES;
  visible: boolean;
}>;

const Wrap = styled.div<WrapProps>`
  display: ${({ visible }: WrapProps) => {
    return visible ? 'block' : 'none';
  }};

  width: ${({ width, device }: WrapProps) => {
    if (device === TYPES.DEVICE_TYPES.PC) {
      return width
        ? `calc(${width}px - ${PARTS.MARGIN_PC}px * 2)`
        : `calc(100% - ${PARTS.MARGIN_PC}px * 2)`;
    }
    return width ? `${width}px` : '100%';
  }};

  margin: ${({ device, templateInOut }: WrapProps) => {
    if (device === TYPES.DEVICE_TYPES.PC) {
      return templateInOut === TYPES.TEMPLATE_IN_OUT_TYPES.INPUT
        ? `5px ${PARTS.MARGIN_PC}px`
        : `15px ${PARTS.MARGIN_PC}px`;
    }
    return templateInOut === TYPES.TEMPLATE_IN_OUT_TYPES.INPUT
      ? '5px 0'
      : '15px 0';
  }};
`;

type QuestionWrapProps = PropsWithChildren<{
  displayLeftRequiredLabel: boolean;
}>;

const QuestionWrap = styled.div<QuestionWrapProps>`
  display: flex;
  margin-bottom: 5px;
  ${({ displayLeftRequiredLabel }: QuestionWrapProps) =>
    !displayLeftRequiredLabel && 'justify-content: space-between;'};
  div {
    margin-left: 5px;
  }
`;

const ComplementWrap = styled.div`
  margin-left: 3px;
  margin-bottom: 5px;
`;

const AnswerWrap = styled.div`
  margin-left: 5px;
  word-wrap: break-word;
`;

/**
 * 【部品】【分子】【フォーム】フォーム
 * @param {number} width - 幅(px)
 * @param {boolean} required - 必須フラグ
 * @param {React.ReactNode} question - 設問文
 * @param {React.ReactNode} complement - 補足文
 * @param {React.ReactNode} children - フォーム内容
 * @param {DEVICE_TYPES} device - 表示デバイスの種類
 * @param {TEMPLATE_IN_OUT_TYPES} templateInOut - テンプレート入出力区分
 * @param {boolean} displayLeftRequiredLabel - 必須ラベル左寄せフラグ
 */
const Form = ({
  children,
  width,
  required,
  question,
  complement,
  device,
  templateInOut,
  displayLeftRequiredLabel = false,
  visible = true,
}: FormProps) => {
  return (
    <Wrap
      width={width}
      device={device}
      templateInOut={templateInOut}
      visible={visible}
    >
      <QuestionWrap displayLeftRequiredLabel={displayLeftRequiredLabel}>
        <Question>{question}</Question>
        {required && <RequiredLabel />}
      </QuestionWrap>
      <ComplementWrap>
        <Complement>{complement}</Complement>
      </ComplementWrap>
      <AnswerWrap>{children}</AnswerWrap>
    </Wrap>
  );
};

export default Form;
