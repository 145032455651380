import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Error from 'presentational/general/atoms/text/Error';

export type InputAreaProps = PropsWithChildren<{
  error?: boolean;
  errorMessage?: string;
}>;

const InputWrap = styled.div`
  margin-left: 3px;
`;

const ErrorArea = styled.div`
  min-height: 20px;
`;

/**
 * 【部品】【分子】【フォーム】入力エリア
 * @param {boolean} error - エラーフラグ
 * @param {string} errorMessage - エラー文
 */
const InputArea = ({ error, errorMessage, children }: InputAreaProps) => {
  return (
    <InputWrap>
      {children}
      <ErrorArea>{error && <Error>{errorMessage}</Error>}</ErrorArea>
    </InputWrap>
  );
};

export default InputArea;
