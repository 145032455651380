import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import { Button as MuiButton } from '@material-ui/core';
import Icon from 'presentational/general/atoms/text/Icon';
import Text from 'presentational/general/atoms/text/Text';

const FONT_SIZE = 16;

type MenuItemButtonProps = {
  backgroundColor: string;
  backgroundColorFocus: string;
  backgroundColorHover: string;
  backgroundColorDisabled: string;
};

/* eslint-disable react/jsx-props-no-spreading */
const MenuItemButton = styled(
  ({
    backgroundColor,
    backgroundColorFocus,
    backgroundColorHover,
    backgroundColorDisabled,
    ...props
  }) => <MuiButton {...props} />,
)<MenuItemButtonProps>`
  /* base */
  &.MuiButtonBase-root {
    background-color: ${({ backgroundColor }: MenuItemButtonProps) =>
      backgroundColor};
    border-radius: unset;
    justify-content: flex-start;
    width: 100%;
  }

  /* focus */
  & .MuiTouchRipple-root {
    color: ${({ backgroundColorFocus }: MenuItemButtonProps) =>
      backgroundColorFocus};
  }

  /* hover */
  &.MuiButton-root:hover {
    background-color: ${({ backgroundColorHover }: MenuItemButtonProps) =>
      backgroundColorHover};
  }

  /* disabled */
  &.MuiButton-root.Mui-disabled {
    background-color: ${({ backgroundColorDisabled }: MenuItemButtonProps) =>
      backgroundColorDisabled};
  }
`;

const MenuItemText = styled(Text)`
  margin: 0 8px;
`;

export type MenuItemProps = PropsWithChildren<{
  iconName?: string;
  color?: string;
  backgroundColor?: string;
  backgroundColorFocus?: string;
  backgroundColorHover?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => void;
  children?: string;
  disabledBasic: string;
  disabledLight: string;
  textDark: string;
  surfaceBasic: string;
  mainDark: string;
  mainLight: string;
}>;

/**
 * 【部品】【原子】【ボタン】メニュー項目 <MenuItem>タグ
 * @param {string} iconName - アイコン名
 * @param {string} color - 文字色
 * @param {string} backgroundColor - 背景色
 * @param {string} backgroundColorFocus - 背景色(クリック時)
 * @param {string} backgroundColorHover - 背景色(カーソルが当たった時)
 * @param {boolean} disabled - 非活性フラグ
 * @param {(
 *  e: React.MouseEvent<HTMLButtonElement | MouseEvent>
 *  ) => void} onClick - クリックイベント
 * @param {React.ReactNode} children - MenuItemタグの中身
 * @param {string} disabledBasic - 非活性色
 * @param {string} disabledLight - 非活性色
 * @param {string} textDark - テキスト色
 * @param {string} surfaceBasic - サーフェス色
 * @param {string} mainDark - メイン色
 * @param {string} mainLight - メイン色
 */
const MenuItem = ({
  iconName,
  disabledBasic,
  disabledLight,
  textDark,
  surfaceBasic,
  mainDark,
  mainLight,
  color = textDark,
  backgroundColor = surfaceBasic,
  backgroundColorFocus = mainDark,
  backgroundColorHover = mainLight,
  disabled = false,
  onClick,
  children,
}: MenuItemProps) => {
  return (
    <MenuItemButton
      backgroundColor={backgroundColor}
      backgroundColorFocus={backgroundColorFocus}
      backgroundColorHover={backgroundColorHover}
      backgroundColorDisabled={disabledLight}
      disabled={disabled}
      onClick={onClick}
    >
      {iconName && (
        <Icon iconColor={disabled ? disabledBasic : color} iconSize={FONT_SIZE}>
          {iconName}
        </Icon>
      )}
      <MenuItemText
        color={disabled ? disabledBasic : color}
        fontSize={FONT_SIZE}
      >
        {children}
      </MenuItemText>
    </MenuItemButton>
  );
};

export default connect(({ color }: AppState) => ({
  disabledBasic: color.disabledBasic,
  disabledLight: color.disabledLight,
  textDark: color.textDark,
  surfaceBasic: color.surfaceBasic,
  mainDark: color.mainDark,
  mainLight: color.mainLight,
}))(MenuItem);
