import React, { useLayoutEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from 'duck/Reducer';
import { Setting } from 'duck/manager/setting/State';
import templateActions, {
  FetchTemplateParams,
} from 'duck/client/template/Actions';
import { connect } from 'react-redux';
import CommonTemplate from 'presentational/custom/templates/CommonTemplate';
import ViewTemplate from 'presentational/general/templates/template/ViewTemplate';
import CONSTS from 'common/Consts';
import * as TYPES from 'common/Types';
import { CustomMemberDetail } from 'oapi/api';

export type ViewEnqueteProps = {
  deviceType: TYPES.DEVICE_TYPES;
  fetchAll: (params: FetchTemplateParams) => Promise<boolean>;
  setting: Setting;
  selectedMember?: CustomMemberDetail;
} & RouteComponentProps<{ groupId: string; templateId: string }>;

/**
 * 【画面】GC028_アンケート参照
 * @param {CommonState} common 共通(Redux)
 */
const ViewEnquete = ({
  match,
  deviceType,
  fetchAll,
  selectedMember,
  setting,
}: ViewEnqueteProps) => {
  const sampleId = selectedMember ? selectedMember.sample_id : '';
  useLayoutEffect(() => {
    if (sampleId !== '') {
      fetchAll({
        groupId: match.params.groupId,
        templateId: match.params.templateId,
        customId: sampleId,
      });
    }
  }, [fetchAll, match.params.groupId, match.params.templateId, sampleId]);
  return (
    <CommonTemplate
      device={deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <ViewTemplate
        device={deviceType}
        backIconTo={CONSTS.PATH_TOP}
        fixButtonTo={CONSTS.PATH_INPUT_ENQUETE.replace(
          ':groupId',
          match.params.groupId,
        ).replace(':templateId', match.params.templateId)}
      />
    </CommonTemplate>
  );
};

export default connect(
  ({ common, members, managerSetting }: AppState) => ({
    deviceType: common.deviceType,
    selectedMember: members.selectedMember,
    setting: managerSetting.setting,
  }),
  {
    fetchAll: templateActions.fetchAll,
  },
)(ViewEnquete);
