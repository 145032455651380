import React from 'react';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import Text from 'presentational/general/atoms/text/Text';

export type ApiErrorDialogProps = {
  open: boolean;
  onClose: () => void;
  okOnClick: () => void;
  apiLabel: string;
};

/**
 * 【部品】【テンプレート】【ダイアログ】APIエラーダイアログ
 * @param {boolean} open - 表示フラグ
 * @param {() => void} onClose - クローズイベント
 * @param {() => void} okOnClick - OKボタンクリックイベント
 * @param {string} apiLabel - 処理名
 */
const ApiErrorDialog = ({
  open,
  onClose,
  okOnClick,
  apiLabel,
}: ApiErrorDialogProps) => {
  const errorDialogButtons = [
    {
      text: 'OK',
      onClick: okOnClick,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="エラー"
      buttons={errorDialogButtons}
    >
      <Text>
        {apiLabel}
        に失敗しました。
        <br />
        入力内容をご確認ください。
      </Text>
    </Dialog>
  );
};

export default ApiErrorDialog;
