import { useState } from 'react';
import { ErrorProps, validateSliderValue } from 'hooks/Validator';
import {
  Slider,
  Shortage,
} from 'presentational/general/atoms/compositeInput/SliderList';
import {
  getSliderListSumValue,
  getSliderListShortageValue,
  replaceMessage,
} from 'common/Utility';
import { VALIDATE_MESSAGES } from 'common/Messages';

export type SliderListUpdateProps = {
  dataList: {
    id: string;
    value: string;
  }[];
  error: ErrorProps;
  hasInput: boolean;
};

export type SliderListHooksProps = {
  sliders: Slider[];
  shortage: Shortage;
  error: ErrorProps;
  hasInput: boolean;
  setHasInputTrue: () => void;
  onChange: (changeValue: number, key: number) => SliderListUpdateProps;
  onChangeCommitted: (
    changeValue: number,
    key: number,
  ) => SliderListUpdateProps;
  clear: () => SliderListUpdateProps;
};

/**
 * answersに送る結果データ配列作成
 * @param {Slider[]} sliders - スライダーリスト
 * @param {Shortage} shortage - 不足表示スライダー
 */
export const generateDataList = (
  sliders: Slider[],
  shortage: Shortage,
): { id: string; value: string }[] => {
  const result: { id: string; value: string }[] = [];
  sliders.forEach((slider: Slider): void => {
    result.push({
      id: slider.id,
      value: String(slider.value),
    });
  });
  result.push({
    id: shortage.id,
    value: String(shortage.value),
  });
  return result;
};

/**
 * スライダーリスト<SliderList>を使う場合の部品生成
 * @param {Slider[]} initialSliders - 初期値
 * @param {Shortage} initialShortage - 不足値表示スライダー初期値
 * @param {boolean} hasInitialInput - 入力済フラグ初期値
 * @param {string[]} validatorRules - バリデーション内容
 * @param {Object} validatorMessage - バリデーションメッセージ上書き用
 * @returns {SliderHooksProps} - 生成された部品
 */
const useSliderList = (
  initialSliders: Slider[],
  initialShortage: Shortage,
  hasInitialInput: boolean,
  validatorRules?: string[],
  validatorMessage?: { [key: string]: string },
): SliderListHooksProps => {
  const [sliders, setSliders] = useState<Slider[]>(initialSliders);
  const [shortage, setShortage] = useState<Shortage>(initialShortage);
  const [error, setError] = useState<ErrorProps>(
    validateSliderValue({
      value: shortage.value,
      validatorRules,
      validatorMessage,
    }),
  );
  const [hasInput, setHasInput] = useState<boolean>(hasInitialInput);

  /**
   * バリデーション
   * @param {number} shortageValue 不足値
   */
  const validation = (shortageValue: number): ErrorProps => {
    const VALIDATOR_CUSTOM_RULES = [`max:${shortage.total}`];
    const VALIDATOR_CUSTOM_MESSAGE = {
      max: replaceMessage(VALIDATE_MESSAGES.ERROR_SLIDER_LIST_OVER, [
        String(shortage.total),
        String(shortageValue * -1),
      ]),
    };

    const customValidatorRules = validatorRules
      ? [...VALIDATOR_CUSTOM_RULES, ...validatorRules]
      : VALIDATOR_CUSTOM_RULES;
    const customvalidatorMessage = validatorMessage
      ? {
          ...VALIDATOR_CUSTOM_MESSAGE,
          ...validatorMessage,
        }
      : VALIDATOR_CUSTOM_MESSAGE;

    return validateSliderValue({
      value: getSliderListSumValue(sliders),
      validatorRules: customValidatorRules,
      validatorMessage: customvalidatorMessage,
    });
  };

  const setHasInputTrue = (): void => {
    setHasInput(true);
  };

  const onChange = (
    changeValue: number,
    key: number,
  ): SliderListUpdateProps => {
    const updateSliders = [...sliders];
    updateSliders[key].value = changeValue;
    const updateShortage = { ...shortage };
    updateShortage.value = getSliderListShortageValue(
      updateSliders,
      shortage.total,
    );

    const validateResult = validation(updateShortage.value);
    const update: SliderListUpdateProps = {
      dataList: generateDataList(updateSliders, updateShortage),
      error,
      hasInput: true,
    };

    setSliders(updateSliders);
    setShortage(updateShortage);
    setError(validateResult);
    setHasInput(update.hasInput);
    return update;
  };

  const onChangeCommitted = (
    changeValue: number,
    key: number,
  ): SliderListUpdateProps => {
    const updateSliders = [...sliders];
    updateSliders[key].value = changeValue;
    const updateShortage = { ...shortage };
    updateShortage.value = getSliderListShortageValue(
      updateSliders,
      shortage.total,
    );

    const validateResult = validation(updateShortage.value);
    const update: SliderListUpdateProps = {
      dataList: generateDataList(updateSliders, updateShortage),
      error,
      hasInput,
    };

    setSliders(updateSliders);
    setShortage(updateShortage);
    setError(validateResult);
    return update;
  };

  const clear = (): SliderListUpdateProps => {
    const updateSliders = [...sliders];
    for (let i = 0; i < updateSliders.length; i += 1) {
      updateSliders[i].value = updateSliders[i].defaultValue;
    }
    const updateShortage = { ...shortage };
    updateShortage.value = getSliderListShortageValue(
      updateSliders,
      shortage.total,
    );

    const validateResult: ErrorProps = validateSliderValue({
      value: getSliderListSumValue(updateSliders),
      validatorRules,
      validatorMessage,
    });
    const update: SliderListUpdateProps = {
      dataList: generateDataList(updateSliders, updateShortage),
      error: validateResult,
      hasInput: false,
    };

    setSliders(updateSliders);
    setShortage(updateShortage);
    setError(validateResult);
    setHasInput(update.hasInput);
    return update;
  };

  return {
    sliders,
    shortage,
    error,
    hasInput,
    setHasInputTrue,
    onChange,
    onChangeCommitted,
    clear,
  };
};

export default useSliderList;
