import { Dispatch } from 'redux';
import actionCreatorFactory from 'typescript-fsa';
import APIWrapper, { DefaultApiFp, APIError } from 'common/APIWrapper';
import {
  ResultsContentsSettingGet,
  RequestsManagerContentsSettingPut,
  RequestsManagerContentsSettingMailPut,
  ResultsManagerContentsSettingMailGet,
  FormAnswer,
  ResultSetting,
} from 'oapi';
import { AxiosResponse } from 'axios';
import { Setting } from 'duck/manager/setting/State';
import { actions as commonActions } from 'duck/common/Actions';

const {
  apiContentsSettingGet,
  apiManagerContentsSettingPut,
  apiManagerContentsSettingMailGet,
  apiManagerContentsSettingMailPut,
} = DefaultApiFp();
const actionCreator = actionCreatorFactory();

export const actions = {
  load: actionCreator.async<{}, void, APIError>('LOAD_MANAGER_SETTING'),
  setSetting: actionCreator<Setting>('SET_SETTING_MANAGER_SETTING'),
};

export const getSetting = () => {
  const params = {};
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started(params));
      const res: AxiosResponse<ResultsContentsSettingGet> = await APIWrapper(
        await apiContentsSettingGet(),
      );
      dispatch(actions.load.done({ params }));
      const answersMap: { [key: string]: FormAnswer } = {};
      if (res.data.data.answers.answers) {
        res.data.data.answers.answers.forEach((answer) => {
          answersMap[answer.id] = answer;
        });
      }
      res.data.data.template.panels.forEach((panel, i) => {
        panel.forms.forEach((form, j) => {
          if (answersMap[form.id]) {
            res.data.data.template.panels[i].forms[j].answers =
              answersMap[form.id].answers;
          }
        });
      });
      return res.data.data;
    } catch (e) {
      dispatch(actions.load.failed({ params, error: e }));
      return null;
    }
  };
};

export const putSetting = (answers: Array<FormAnswer>) => {
  const params = {};
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started(params));
      const req: RequestsManagerContentsSettingPut = {
        answers,
      };
      await APIWrapper(await apiManagerContentsSettingPut(req));
      dispatch(actions.load.done({ params }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
      } else {
        dispatch(actions.load.failed({ params, error: e }));
      }
      return false;
    }
  };
};

export const setSetting = (setting: ResultSetting) => {
  return (dispatch: Dispatch) => {
    const set: Setting = {
      serviceName: '',
      headerColor: '',
      headerImagePath: '',
      footerColor: '',
      footerText: '',
    };
    if (setting.answers.answers) {
      setting.answers.answers.forEach((ans) => {
        if (ans.answers.length === 0) {
          return;
        }
        switch (ans.name) {
          case 'service_name':
            set.serviceName = ans.answers[0].value;
            break;
          case 'header_color':
            set.headerColor = ans.answers[0].value;
            break;
          case 'header_image_path':
            set.headerImagePath = ans.answers[0].value;
            break;
          case 'footer_color':
            set.footerColor = ans.answers[0].value;
            break;
          case 'footer_text':
            set.footerText = ans.answers[0].value;
            break;
          default:
            break;
        }
      });
    }
    dispatch(actions.setSetting(set));
  };
};

export const getMailSetting = () => {
  const params = {};
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started(params));
      const res: AxiosResponse<ResultsManagerContentsSettingMailGet> = await APIWrapper(
        await apiManagerContentsSettingMailGet(),
      );
      dispatch(actions.load.done({ params }));
      const answersMap: { [key: string]: FormAnswer } = {};
      if (res.data.data.answers.answers) {
        res.data.data.answers.answers.forEach((answer) => {
          answersMap[answer.id] = answer;
        });
      }
      res.data.data.template.panels.forEach((panel, i) => {
        panel.forms.forEach((form, j) => {
          if (answersMap[form.id]) {
            res.data.data.template.panels[i].forms[j].answers =
              answersMap[form.id].answers;
          }
        });
      });
      return res.data.data;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
      } else {
        dispatch(actions.load.failed({ params, error: e }));
      }
      return null;
    }
  };
};

export const putMailSetting = (answers: Array<FormAnswer>) => {
  const params = {};
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started(params));
      const req: RequestsManagerContentsSettingMailPut = {
        answers,
      };
      await APIWrapper(await apiManagerContentsSettingMailPut(req));
      dispatch(actions.load.done({ params }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
      } else {
        dispatch(actions.load.failed({ params, error: e }));
      }
      return false;
    }
  };
};

export default {
  getSetting,
  putSetting,
  setSetting,
  getMailSetting,
  putMailSetting,
};
