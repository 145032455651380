import React, { useState, useMemo, useLayoutEffect } from 'react';
import { useCookies } from 'react-cookie';
import useReactRouter from 'use-react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ButtonColorType } from 'duck/color/State';
import { CommonState } from 'duck/common/State';
import { AppState } from 'duck/Reducer';
import { Member } from 'duck/custom/members/State';
import sampleidInfoActions from 'duck/custom/sampleidInfo/Actions';
import membersActions from 'duck/custom/members/Actions';
import { Setting } from 'duck/manager/setting/State';
import { useSelect } from 'hooks';
import CONSTS from 'common/Consts';
import Panel from 'presentational/general/organisms/area/Panel';
import InputArea from 'presentational/general/molecules/form/InputArea';
import Button from 'presentational/general/atoms/button/Button';
import Select from 'presentational/general/atoms/basicInput/Select';
import Text from 'presentational/general/atoms/text/Text';
import Loading from 'presentational/general/atoms/other/Loading';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import * as TYPES from 'common/Types';
import { isDisabledInputList, isDisplayError } from 'common/Utility';
import { APIError } from 'common/APIWrapper';
import { CustomMemberDetail } from 'oapi/api';

export type SetSubjectProps = {
  common: CommonState;
  setting: Setting;
  errorBasic: string;
  buttonBackgroundColorError: ButtonColorType;
  buttonBackgroundColorMain: ButtonColorType;
  buttonBackgroundColorSuccess: ButtonColorType;
  sampleId: string;
  kitId: string;
  sampleIdVersion: string;
  members: Member[];
  fetchAll: () => Promise<boolean>;
  selectMember: (id: string) => Promise<boolean>;
  error?: APIError;
  clearError: () => void;
  mustAddMember: () => void;
  addSampleId: (
    sampleId: string,
    kitId: string,
    member?: CustomMemberDetail,
  ) => Promise<boolean>;
  selectedMember?: CustomMemberDetail;
  setSampleIdAndKitId: (param: { sampleId: string; kitId: string }) => void;
  setSampleIdVersion: (param: { sampleIdVersion: string }) => void;
  clearMustAddMember: () => void;
  isLoading: boolean;
};

type SelectAreaProps = {
  device: TYPES.DEVICE_TYPES;
};

const NotesArea = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const SelectArea = styled.div<SelectAreaProps>`
  margin: ${({ device }: SelectAreaProps) =>
    device === TYPES.DEVICE_TYPES.PC ? `15px 20px` : '15px 0'};

  ${({ device }: SelectAreaProps) =>
    device === TYPES.DEVICE_TYPES.MOBILE &&
    `display: flex; justify-content: center;`}
`;

const ButtonArea = styled.div`
  padding: 20px 0;
  text-align: center;
`;

/**
 * 【画面】GC026_被検者設定
 * @param {CommonState} common - 共通Redux
 * @param {string} errorBasic - エラーカラーコード (Reduxから取得)
 * @param {ButtonColorType} buttonBackgroundColorMain - ボタンカラーコード (Reduxから取得)
 */
const SetSubject = ({
  common,
  setting,
  errorBasic,
  buttonBackgroundColorError,
  buttonBackgroundColorMain,
  buttonBackgroundColorSuccess,
  sampleId,
  kitId,
  sampleIdVersion,
  members,
  fetchAll,
  selectMember,
  error,
  clearError,
  mustAddMember,
  clearMustAddMember,
  addSampleId,
  setSampleIdAndKitId,
  setSampleIdVersion,
  selectedMember,
  isLoading,
}: SetSubjectProps) => {
  const useCookie = useCookies(['web-form']);
  const setCookies = useCookie[1];
  const removeCookies = useCookie[2];
  const select = useSelect(CONSTS.SELECT_EMPTY_VALUE, false, ['required']);
  const { history } = useReactRouter();
  const buttonDisabledInputList = [
    {
      hasInput: select.hasInput,
      isError: select.error.isError,
    },
  ];
  const [isError, setIsError] = useState(false);
  const [isSampleIdError, setIsSampleIdError] = useState(false);
  const [isOpenAddSampleId, setIsOpenAddSampleId] = useState(false);

  const onNextClick = async () => {
    if (select.value === '__NEW__') {
      mustAddMember();
      history.push(CONSTS.PATH_INPUT_SUBJECT);
      setCookies('mustAddMember', true, { path: '/', maxAge: 3600 });
    } else if (await selectMember(select.value)) {
      setCookies('selectedMemberId', select.value, { path: '/', maxAge: 3600 });
      clearMustAddMember();
      removeCookies('mustAddMember', { path: '/' });
      if (sampleId !== '' && kitId !== '' && sampleIdVersion !== '') {
        setIsOpenAddSampleId(true);
      } else {
        history.push(CONSTS.PATH_TOP);
      }
    }
  };

  const onCloseError = () => {
    clearError();
  };

  const onCloseSampleIdError = () => {
    clearError();
    history.push(CONSTS.PATH_INPUT_CERTIFICATION_CODE);
  };

  useLayoutEffect(() => {
    fetchAll().then((result) => {
      if (!result) {
        setIsError(true);
      }
    });
  }, [fetchAll, setIsError]);

  const selectItems = useMemo(() => {
    const items = members.map((member) => {
      return { value: member.id, label: member.name };
    });
    if (sampleId !== '' && kitId !== '' && members.length < 3) {
      return [...items, { value: '__NEW__', label: '新しく被検者を登録' }];
    }
    return items;
  }, [members, kitId, sampleId]);

  if (isOpenAddSampleId && selectedMember) {
    if (selectedMember.sample_id === sampleId) {
      // すでに同じサンプルIDがセットされている
      setSampleIdAndKitId({ sampleId: '', kitId: '' });
      setSampleIdVersion({ sampleIdVersion: '' });
      removeCookies('sampleId', { path: '/' });
      removeCookies('kitId', { path: '/' });
      removeCookies('mustAddMember', { path: '/' });
      setIsOpenAddSampleId(false);
      history.push(CONSTS.PATH_TOP);
    } else {
      return (
        <Dialog
          open
          onClose={() => setIsOpenAddSampleId(false)}
          title="サンプルID設定"
          buttons={[
            {
              text: 'キャンセル',
              onClick: () => setIsOpenAddSampleId(false),
              ...buttonBackgroundColorError,
            },
            {
              text: 'OK',
              onClick: () => {
                setIsOpenAddSampleId(false);
                (async () => {
                  const isSucceed = await addSampleId(
                    sampleId,
                    kitId,
                    selectedMember,
                  );
                  if (isSucceed) {
                    setSampleIdAndKitId({ sampleId: '', kitId: '' });
                    setSampleIdVersion({ sampleIdVersion: '' });
                    removeCookies('sampleId', { path: '/' });
                    removeCookies('kitId', { path: '/' });
                    removeCookies('mustAddMember', { path: '/' });
                    await selectMember(select.value);
                    history.push(CONSTS.PATH_UPDATE_SUBJECT);
                  }
                })();
              },
              ...buttonBackgroundColorMain,
            },
          ]}
        >
          選択した被検者様は過去に別のサンプルIDの検査キットを使用しました。
          <br />
          <>(</>
          {selectedMember.sample_id}
          <>)</>
          <br />
          <br />
          こちらの被検者様にて新たな検査キットのサンプルID(
          {sampleId}
          )を使用しますか？
          <br />
          <br />
          <Text fontSize={12}>
            ※選択する被検者様に誤りがあった場合は、「キャンセル」で前の画面に戻ってください。
          </Text>
        </Dialog>
      );
    }
  }

  if (isSampleIdError) {
    return (
      <Dialog
        open
        onClose={() => {
          setIsSampleIdError(false);
          history.push(CONSTS.PATH_INPUT_CERTIFICATION_CODE);
        }}
        title="サンプルID設定"
        buttons={[
          {
            text: 'OK',
            onClick: async () => {
              setIsSampleIdError(false);
              history.push(CONSTS.PATH_INPUT_CERTIFICATION_CODE);
            },
            ...buttonBackgroundColorSuccess,
          },
        ]}
      >
        サンプルIDがすでに使用されています
        <br />
        サンプルIDを確認してください。
      </Dialog>
    );
  }

  return (
    <BeforeLoginTemplate
      device={common.deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <Panel device={common.deviceType}>
        <NotesArea>
          <Text>今回、検査を受ける被検者様を選択してください</Text>
          <Text color={errorBasic}>
            ※ 選択する被検者様を間違えないようにご注意ください
          </Text>
        </NotesArea>
        <SelectArea device={common.deviceType}>
          <InputArea
            error={isDisplayError(select.hasInput, select.error.isError)}
            errorMessage={select.error.errorMessage}
          >
            <Select
              id="subject"
              items={selectItems}
              value={select.value}
              error={isDisplayError(select.hasInput, select.error.isError)}
              width={150}
              onChange={select.onChange}
            />
          </InputArea>
        </SelectArea>
        <ButtonArea>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            disabled={isDisabledInputList(buttonDisabledInputList)}
            onClick={onNextClick}
            {...buttonBackgroundColorMain}
          >
            次へ
          </Button>
        </ButtonArea>
      </Panel>
      <Dialog
        open={
          isError &&
          error !== undefined &&
          error.status !== 404 &&
          error.status !== 401
        }
        onClose={onCloseError}
        title="被検者リスト取得エラー"
        buttons={[
          {
            text: 'OK',
            onClick: onCloseError,
            ...buttonBackgroundColorSuccess,
          },
        ]}
      >
        <br />
        {error && error.detail}
      </Dialog>
      <Dialog
        open={
          isError &&
          error !== undefined &&
          error.status === 404 &&
          (kitId === '' || sampleId === '')
        }
        onClose={onCloseSampleIdError}
        title="被検者リスト取得エラー"
        buttons={[
          {
            text: 'OK',
            onClick: onCloseSampleIdError,
            ...buttonBackgroundColorSuccess,
          },
        ]}
      >
        認証コードおよびサンプルIDを設定してください。
      </Dialog>
      <Loading display={isLoading} />
    </BeforeLoginTemplate>
  );
};

export default connect(
  ({ common, color, sampleidInfo, members, managerSetting }: AppState) => ({
    common,
    setting: managerSetting.setting,
    errorBasic: color.errorBasic,
    buttonBackgroundColorError: color.buttonBackgroundColorError,
    buttonBackgroundColorMain: color.buttonBackgroundColorMain,
    buttonBackgroundColorSuccess: color.buttonBackgroundColorSuccess,
    sampleId: sampleidInfo.sampleId,
    kitId: sampleidInfo.kitId,
    sampleIdVersion: sampleidInfo.sampleIdVersion,
    members: members.members,
    error: members.error,
    selectedMember: members.selectedMember,
    isLoading: members.isLoading,
  }),
  {
    fetchAll: membersActions.fetchAll,
    clearError: membersActions.clearError,
    selectMember: membersActions.selectMember,
    addSampleId: membersActions.addSampleId,
    mustAddMember: membersActions.mustAddMember,
    clearMustAddMember: membersActions.clearMustAddMember,
    setSampleIdAndKitId: sampleidInfoActions.setSampleIdAndKitId,
    setSampleIdVersion: sampleidInfoActions.setSampleIdVersion,
  },
)(SetSubject);
