import styled from 'styled-components';
import { hex2rgba } from 'common/Utility';

export type LockedFooterProps = {
  height?: number;
  backgroundColor: string;
};

/**
 * 【部品】【有機体】【エリア】固定フッター <LockedFooter>タグ
 */
const LockedFooter = styled.div<LockedFooterProps>`
  overflow: auto;
  background-color: ${({ backgroundColor }) => hex2rgba(backgroundColor, 0.5)};
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  ${({ height }: LockedFooterProps) => height && `height: ${height}px;`}
`;

export default LockedFooter;
