import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import Text from 'presentational/general/atoms/text/Text';

const StyledDiv = connect(({ color }: AppState) => ({
  errorBasic: color.errorBasic,
}))(styled.div<{ errorBasic: string }>`
  min-width: 40px;
  width: 40px;
  height: 22px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ errorBasic }) => errorBasic};
`);

/**
 * 【部品】【原子】【ラベル】必須ラベル <RequiredLabel>タグ
 * @param {string} textLight - テキスト色
 */
const RequiredLabel = ({ textLight }: { textLight: string }) => {
  return (
    <StyledDiv>
      <Text color={textLight} fontSize={12} fontWeight="bold">
        必須
      </Text>
    </StyledDiv>
  );
};
export default connect(({ color }: AppState) => ({
  textLight: color.textLight,
}))(RequiredLabel);
