import React, { useLayoutEffect } from 'react';
import * as TYPES from 'common/Types';
import CONSTS from 'common/Consts';
import useTextNumberField from 'hooks/useTextNumberField';
import { HandleSetAnswerProps } from 'presentational/general/templates/template/InputTemplate';
import MixedInputArea from 'presentational/general/molecules/form/MixedInputArea';
import TextField from 'presentational/general/atoms/basicInput/TextField';
import * as api from 'oapi/api';

export type TextNumberFieldFormProps = {
  device: TYPES.DEVICE_TYPES;
  form: api.Form;
  disabled: boolean;
  visible: boolean;
  handleSetAnswer: (answer: HandleSetAnswerProps) => void;
};

/**
 * 【部品】【有機体】【テンプレート入力フォーム】複数テキストフィールド(数値)
 * @param {TYPES.DEVICE_TYPES} device - 表示デバイスの種類
 * @param {api.Form} form - フォーム情報
 * @param {boolean} disabled - 非活性フラグ
 * @param {boolean} visible - 表示フラグ
 * @param {(answer: HandleSetAnswerProps) => void} handleSetAnswer - InputTemplateに入力状態を渡す関数
 */
const MixedTextNumberFieldForm = ({
  device,
  form,
  disabled,
  handleSetAnswer,
}: TextNumberFieldFormProps) => {
  const textForm = form.text_form as api.TextForm;
  const initialValue: string =
    form.answers && form.answers.length > 0 ? form.answers[0].value : '';
  const hasInitialInput: boolean =
    form.answers && form.answers.length > 0 ? true : initialValue !== '';
  const hooks = useTextNumberField(
    initialValue,
    hasInitialInput,
    textForm.validations.values,
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const { value, error, hasInput } = hooks.onChange(e);
    handleSetAnswer({
      formId: form.id,
      values: [value],
      hasInput,
      isError: error.isError,
      isInitial: false,
      setHasInputTrue: hooks.setHasInputTrue,
    });
  };

  const handleBlur = (): void => {
    const { value, error, hasInput } = hooks.onBlur();
    handleSetAnswer({
      formId: form.id,
      values: [value],
      hasInput,
      isError: error.isError,
      isInitial: false,
      setHasInputTrue: hooks.setHasInputTrue,
    });
  };

  useLayoutEffect((): void => {
    if (disabled && hooks.hasInput) {
      const { value, error, hasInput } = hooks.clear();
      handleSetAnswer({
        formId: form.id,
        values: [value],
        hasInput,
        isError: error.isError,
        isInitial: true,
        setHasInputTrue: hooks.setHasInputTrue,
      });
    }
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useLayoutEffect((): void => {
    handleSetAnswer({
      formId: form.id,
      values: [hooks.value],
      hasInput: hooks.hasInput,
      isError: hooks.error.isError,
      isInitial: true,
      setHasInputTrue: hooks.setHasInputTrue,
    });
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MixedInputArea
      error={hooks.hasInput && hooks.error.isError}
      errorMessage={hooks.hasInput ? hooks.error.errorMessage : ''}
    >
      <TextField
        id={form.id}
        prefix={textForm.prefix}
        suffix={textForm.suffix}
        value={hooks.value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={hooks.hasInput && hooks.error.isError}
        disabled={disabled}
        textAlignLight
        width={CONSTS.TEXT_NUMBER_FIELD_WIDTH}
        inputProps={{
          type: device === TYPES.DEVICE_TYPES.MOBILE ? 'number' : 'text',
          maxLength: textForm.max_length || 50,
        }}
      />
    </MixedInputArea>
  );
};

export default MixedTextNumberFieldForm;
