import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actions } from 'duck/custom/manager/Actions';
import { initialState } from 'duck/custom/manager/State';

const customManagerReducer = reducerWithInitialState(initialState)
  .case(actions.load.started, (state) => {
    return {
      ...state,
      isLoading: true,
    };
  })
  .case(actions.load.done, (state) => {
    return {
      ...state,
      isLoading: false,
    };
  })
  .case(actions.load.failed, (state, payload) => {
    return {
      ...state,
      isLoading: false,
      error: payload.error,
    };
  });

export default customManagerReducer;
