import React, { useLayoutEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { ActionCreator } from 'typescript-fsa';
import useReactRouter from 'use-react-router';
import styled from 'styled-components';
import { useTextStringField } from 'hooks';
import { isDisabledInputList, isDisplayError } from 'common/Utility';
import * as TYPES from 'common/Types';
import { AppState } from 'duck/Reducer';
import { ButtonColorType } from 'duck/color/State';
import { Setting } from 'duck/manager/setting/State';
import { connect } from 'react-redux';
import { CommonState } from 'duck/common/State';
import Form from 'presentational/general/molecules/form/Form';
import InputArea from 'presentational/general/molecules/form/InputArea';
import TextField from 'presentational/general/atoms/basicInput/TextField';
import Panel from 'presentational/general/organisms/area/Panel';
import Text from 'presentational/general/atoms/text/Text';
import Button from 'presentational/general/atoms/button/Button';
import Loading from 'presentational/general/atoms/other/Loading';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import { VALIDATE_MESSAGES } from 'common/Messages';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import actions from 'duck/custom/sampleidInfo/Actions';
import { APIError } from 'common/APIWrapper';
import CONSTS from 'common/Consts';

export type InputCertificationCodeProps = {
  common: CommonState;
  buttonBackgroundColorMain: ButtonColorType;
  setting: Setting;
  setSampleIdAndKitId: ({
    sampleId,
    kitId,
  }: {
    sampleId: string;
    kitId: string;
  }) => void;
  clearError: ActionCreator<void>;
  checkSampleId: () => Promise<boolean>;
  isLoading: boolean;
  error?: APIError;
} & RouteComponentProps<{ kitId?: string; sampleId?: string }>;
const ButtonArea = styled.div`
  padding: 20px 0;
  text-align: center;
`;

const validatorRules = {
  certificationCode: ['required', 'regex:/^[0-9A-Z]{8}$/'],
  sampleId: ['required', 'regex:/^[0-9]{2}[A-Z][0-9]{6}$/'],
};
const validatorMessages = {
  sampleId: { regex: '00A000000の形式で入力してください。' },
};

/**
 * 【画面】GC015_認証コード入力
 * @param {CommonState} common 共通Redux
 * @param {ButtonColorType} buttonBackgroundColorMain ボタン用のカラー
 */
const InputCertificationCode = ({
  match,
  common,
  setting,
  buttonBackgroundColorMain,
  setSampleIdAndKitId,
  clearError,
  checkSampleId,
  isLoading,
  error,
}: InputCertificationCodeProps) => {
  const useCookie = useCookies(['web_form']);
  const setCookie = useCookie[1];
  const removeCookie = useCookie[2];
  const { history } = useReactRouter();
  const { sampleId, kitId } = match.params;
  // 認証コード
  const certificationCode = useTextStringField(
    kitId || '',
    false,
    validatorRules.certificationCode,
  );
  // サンプルID
  const sampleIdField = useTextStringField(
    sampleId || '',
    false,
    validatorRules.sampleId,
    validatorMessages.sampleId,
  );
  // 完了ボタン
  const buttonDisabledInputList = [
    {
      hasInput: certificationCode.hasInput,
      isError: certificationCode.error.isError,
    },
    {
      hasInput: sampleIdField.hasInput,
      isError: sampleIdField.error.isError,
    },
  ];
  const buttonOnClick = async () => {
    history.push(
      `/check/sampleid/${encodeURIComponent(
        sampleIdField.value,
      )}/kitid/${encodeURIComponent(certificationCode.value)}`,
    );
  };
  useLayoutEffect(() => {
    if (!kitId || !sampleId) {
      return;
    }
    setSampleIdAndKitId({ kitId, sampleId });
    checkSampleId().then((result) => {
      if (result) {
        setCookie('sampleId', sampleId, { path: '/', maxAge: 36000 });
        setCookie('kitId', kitId, { path: '/', maxAge: 36000 });
        const url = CONSTS.PATH_VIEW_CERTIFICATION_CODE_WITH_ID.replace(
          /:kitId/,
          kitId,
        ).replace(/:sampleId/, sampleId);
        history.push(url);
      } else {
        removeCookie('sampleId', { path: '/' });
        removeCookie('kitId', { path: '/' });
      }
    });
  }, [
    kitId,
    sampleId,
    history,
    checkSampleId,
    setSampleIdAndKitId,
    setCookie,
    removeCookie,
  ]);

  return (
    <BeforeLoginTemplate
      device={common.deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <Panel device={common.deviceType}>
        <Text>認証コードとサンプルIDを入力してください。</Text>
        <Form
          device={common.deviceType}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question="認証コード"
        >
          <InputArea
            error={isDisplayError(
              certificationCode.hasInput,
              certificationCode.error.isError,
            )}
            errorMessage={VALIDATE_MESSAGES.ERROR_REQUIRED}
          >
            <TextField
              id="certificationCode"
              value={certificationCode.value}
              onChange={certificationCode.onChange}
              onBlur={certificationCode.onBlur}
              error={isDisplayError(
                certificationCode.hasInput,
                certificationCode.error.isError,
              )}
            />
          </InputArea>
        </Form>
        <Form
          device={common.deviceType}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question="サンプルID"
        >
          <InputArea
            error={isDisplayError(
              sampleIdField.hasInput,
              sampleIdField.error.isError,
            )}
            errorMessage={VALIDATE_MESSAGES.ERROR_REQUIRED}
          >
            <TextField
              id="sampleId"
              value={sampleIdField.value}
              onChange={sampleIdField.onChange}
              onBlur={sampleIdField.onBlur}
              error={isDisplayError(
                sampleIdField.hasInput,
                sampleIdField.error.isError,
              )}
            />
          </InputArea>
        </Form>
        <ButtonArea>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            disabled={isDisabledInputList(buttonDisabledInputList)}
            onClick={buttonOnClick}
            {...buttonBackgroundColorMain}
          >
            完了
          </Button>
        </ButtonArea>
        <Dialog
          open={error !== undefined}
          onClose={() => {
            clearError();
          }}
          title={error ? error.message : 'エラー'}
          showCloseButton
          buttons={[
            {
              text: 'OK',
              onClick: () => {
                clearError();
              },
            },
          ]}
        >
          <Text>{error && error.detail}</Text>
        </Dialog>
      </Panel>

      <Loading display={isLoading} />
    </BeforeLoginTemplate>
  );
};

export default connect(
  ({ common, color, sampleidInfo, managerSetting }: AppState) => ({
    common,
    buttonBackgroundColorMain: color.buttonBackgroundColorMain,
    setting: managerSetting.setting,
    isLoading: sampleidInfo.isLoading,
    error: sampleidInfo.error,
  }),
  {
    setSampleIdAndKitId: actions.setSampleIdAndKitId,
    clearError: actions.clearError,
    checkSampleId: actions.checkSampleId,
  },
)(InputCertificationCode);
