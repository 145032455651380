import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from 'presentational/general/atoms/basicInput/Checkbox';

export type CheckboxDataProps = {
  value: string;
  label: string;
  checked: boolean;
};

export type CheckboxDataListProps = CheckboxDataProps[];

export type CheckboxGroupProps = {
  checkboxDataList: CheckboxDataListProps;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, key: number) => void;
};

export const StyledFormControlLabel = connect(({ color }: AppState) => ({
  textDark: color.textDark,
  disabledBasic: color.disabledBasic,
}))(styled(({ textDark, disabledBasic, dispatch, ...props }) => (
  <FormControlLabel {...props} />
))<{ disabledBasic: string }>`
  color: ${({ textDark }) => textDark};
  .MuiTypography-body1 {
    font-size: 15px;
  }
  .MuiFormControlLabel-label.Mui-disabled {
    color: ${({ disabledBasic }) => disabledBasic};
  }
`);

/**
 * 【部品】【原子】【基本入力】チェックボックスマーク <Checkbox>タグ
 * @param {CheckboxDataProps} checkboxDataList - チェックボックス配列
 * @param {boolean} disabled - 非活性フラグ
 * @param {(
 *  event: React.ChangeEvent<HTMLInputElement>,
 *  checked: boolean,
 *  key: number,
 * ) => void} onChange - 値変更イベント
 */
const CheckboxGroup = ({
  checkboxDataList,
  disabled,
  onChange,
}: CheckboxGroupProps) => {
  return (
    <FormGroup>
      {checkboxDataList.map(
        (checkboxData: CheckboxDataProps, i: number): React.ReactNode => {
          const control = (
            <Checkbox
              checked={checkboxData.checked}
              onChange={(e) => onChange(e, i)}
              disabled={disabled}
              name={checkboxData.label}
              value={checkboxData.value}
            />
          );
          return (
            <StyledFormControlLabel
              key={checkboxData.value}
              control={control}
              label={checkboxData.label}
            />
          );
        },
      )}
    </FormGroup>
  );
};

export default CheckboxGroup;
