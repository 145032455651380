import React, { useState } from 'react';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import { AppState } from 'duck/Reducer';
import { connect } from 'react-redux';
import { CommonState } from 'duck/common/State';
import { SubjectState } from 'duck/custom/subject/State';
import { Setting } from 'duck/manager/setting/State';
import Form from 'presentational/general/molecules/form/Form';
import BackPageTitle from 'presentational/general/molecules/other/BackPageTitle';
import Button from 'presentational/general/atoms/button/Button';
import TemplatePanel from 'presentational/general/organisms/area/TemplatePanel';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import ApiErrorDialog from 'presentational/general/templates/dialog/ApiErrorDialog';
import NetworkErrorDialog from 'presentational/general/templates/dialog/NetworkErrorDialog';
import { ButtonColorType } from 'duck/color/State';

export type RegistrationCheckSubjectProps = {
  common: CommonState;
  setting: Setting;
  subject: SubjectState;
  buttonBackgroundColorSuccess: ButtonColorType;
  buttonBackgroundColorError: ButtonColorType;
};

const PanelArea = styled.div`
  & div:first-child {
    margin-top: 0;
  }
`;

const ButtonArea = styled.div`
  padding: 20px 0 40px 0;
  text-align: center;
  button {
    margin: 0 5px;
  }
`;

/**
 * 【画面】GC012_被検者登録確認
 * @param {CommonState} common 共通(Redux)
 * @param {SubjectState} subject 被検者(Redux)
 * @param {ButtonColorType} buttonBackgroundColorSuccess ボタン背景色：成功(Redux)
 * @param {ButtonColorType} buttonBackgroundColorError ボタン背景色：エラー(Redux)
 */
const RegistrationCheckSubject = ({
  common,
  setting,
  subject,
  buttonBackgroundColorSuccess,
  buttonBackgroundColorError,
}: RegistrationCheckSubjectProps) => {
  // エラーダイアログ
  // TODO Reduxのエラー取得に変更
  const [isApiErrorDialogOpen, setIsApiErrorDialogOpen] = useState(false);
  const apiErrorDialogOnClose = () => {
    // TODO エラークリア
    setIsApiErrorDialogOpen(false);
  };
  const apiErrorDialogOkOnClick = () => {
    // TODO エラークリア
    setIsApiErrorDialogOpen(false);
  };
  // TODO Reduxのエラー取得に変更
  const [isNetworkErrorDialogOpen, setIsNetworkErrorDialogOpen] = useState(
    false,
  );
  const networkErrorDialogOnClose = () => {
    // TODO エラークリア
    setIsNetworkErrorDialogOpen(false);
  };
  const networkErrorDialogOkOnClick = () => {
    // TODO エラークリア
    setIsNetworkErrorDialogOpen(false);
  };

  // 戻るボタン
  const handleBackIconClick = () => {
    // TODO 被検者入力 遷移処理
  };
  // 修正ボタン
  const handleFixButtonClick = () => {
    // TODO 被検者入力 遷移処理
  };
  // 確定ボタン
  const handleConfirmButtonClick = () => {
    // TODO 被検者情報登録
    // TODO トップページ 遷移処理
  };

  return (
    <BeforeLoginTemplate
      device={common.deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <BackPageTitle device={common.deviceType} onClick={handleBackIconClick}>
        被検者情報登録確認
      </BackPageTitle>
      <PanelArea>
        <TemplatePanel
          device={common.deviceType}
          templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="あなた（利用者。いま情報を入力していただいている方）は、被検者（検査を受ける方）とどのような関係ですか。"
          >
            {subject.identification}
          </Form>
          {subject.relation && (
            <Form
              device={common.deviceType}
              templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
              required
              question="被検者からみた、あなた（利用者）との関係を選択、記入してください）。"
            >
              {subject.relation}
            </Form>
          )}
          {subject.relation_detail && (
            <Form
              device={common.deviceType}
              templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
              required
              question="続柄名称"
            >
              {subject.relation_detail}
            </Form>
          )}
        </TemplatePanel>
        <TemplatePanel
          device={common.deviceType}
          templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="姓"
          >
            {subject.familyName}
          </Form>
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="名"
          >
            {subject.firstName}
          </Form>
        </TemplatePanel>
        <TemplatePanel
          device={common.deviceType}
          templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="姓（フリガナ）"
          >
            {subject.familyNameKana}
          </Form>
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="名（フリガナ）"
          >
            {subject.firstNameKana}
          </Form>
        </TemplatePanel>
        <TemplatePanel
          device={common.deviceType}
          templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="生年月日"
          >
            {subject.birthday}
          </Form>
        </TemplatePanel>
        <TemplatePanel
          device={common.deviceType}
          templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="郵便番号"
          >
            {subject.postalCode}
          </Form>
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="住所１（都道府県・市区町村）"
          >
            {subject.address}
          </Form>
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="住所２（番地・建物名・部屋番号）"
          >
            {subject.building}
          </Form>
        </TemplatePanel>
        <TemplatePanel
          device={common.deviceType}
          templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="電話番号"
          >
            {subject.telephoneCode}
          </Form>
        </TemplatePanel>
      </PanelArea>
      <ButtonArea>
        <Button
          buttonType={TYPES.BUTTON_TYPES.ROUND}
          onClick={handleFixButtonClick}
          {...buttonBackgroundColorError}
        >
          修正
        </Button>
        <Button
          buttonType={TYPES.BUTTON_TYPES.ROUND}
          onClick={handleConfirmButtonClick}
          {...buttonBackgroundColorSuccess}
        >
          確定
        </Button>
      </ButtonArea>
      <ApiErrorDialog
        open={isApiErrorDialogOpen}
        onClose={apiErrorDialogOnClose}
        okOnClick={apiErrorDialogOkOnClick}
        apiLabel="被検者登録"
      />
      <NetworkErrorDialog
        open={isNetworkErrorDialogOpen}
        onClose={networkErrorDialogOnClose}
        okOnClick={networkErrorDialogOkOnClick}
      />
    </BeforeLoginTemplate>
  );
};

export default connect(
  ({ common, subject, color, managerSetting }: AppState) => ({
    common,
    subject,
    setting: managerSetting.setting,
    buttonBackgroundColorSuccess: color.buttonBackgroundColorSuccess,
    buttonBackgroundColorError: color.buttonBackgroundColorError,
  }),
)(RegistrationCheckSubject);
