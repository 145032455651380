import React, { PropsWithChildren } from 'react';
import Text from 'presentational/general/atoms/text/Text';

export type ComplementProps = PropsWithChildren<{}>;

/**
 * 【部品】【原子】【文章】補足文 <Complement>タグ
 * @param {React.ReactNode} children - Complementタグの中身
 */
const Complement = ({ children }: ComplementProps) => {
  return <Text fontSize={14}>{children}</Text>;
};

export default Complement;
