import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import Link from 'presentational/general/atoms/button/Link';
import Text from 'presentational/general/atoms/text/Text';

export type ProgresRoundProps = {
  panelType: TYPES.PANEL_TYPES;
  isDisplaying?: boolean;
  to: string;
  idx: number;
  onClick?: (e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => void;
  errorMiddleLight: string;
  textLight: string;
  successMiddleLight: string;
  surfaceBasic: string;
  textDark: string;
  disabledBasic: string;
};

type RoundProps = {
  bgColor: string;
  isDisplaying: boolean;
  height: number;
  width: number;
  disabled: boolean;
};

const ROUND_STYLE_LIST: {
  isDisplaying: boolean;
  fontSize: number;
  height: number;
  width: number;
}[] = [
  {
    isDisplaying: true,
    fontSize: 18,
    height: 30,
    width: 30,
  },
  {
    isDisplaying: false,
    fontSize: 13,
    height: 24,
    width: 24,
  },
];

/**
 * 円スタイル取得
 * @param isDisplaying 表示中フラグ
 */
const getRoundStyle = (isDisplaying: boolean) => {
  const roundStyle = ROUND_STYLE_LIST.find(
    (e) => e.isDisplaying === isDisplaying,
  );
  if (roundStyle) {
    return roundStyle;
  }
  return ROUND_STYLE_LIST[1];
};

const StyledLink = styled(Link)`
  div {
    text-decoration: none;
  }
`;

const Round = styled.div<RoundProps>`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }: RoundProps) => height}px;
  width: ${({ width }: RoundProps) => width}px;
  background-color: ${({ bgColor }: RoundProps) => bgColor};

  &:hover {
    opacity: ${({ disabled }: RoundProps) => (disabled ? '1' : '0.5')};
  }
`;

/**
 * 【部品】【原子】【その他】進捗円 <ProgresRound>タグ
 * @param {TYPES.PANEL_TYPES} panelType - パネル区分
 * @param {boolean} isDisplaying - 表示中フラグ
 * @param {string} to - ハッシュリンク先
 * @param {number} idx - パネル位置(表示数字)
 * @param {(
 *  e: React.MouseEvent<HTMLButtonElement | MouseEvent>
 *  ) => void} onClick - クリックイベント
 */
const ProgresRound = ({
  panelType,
  isDisplaying = false,
  to,
  idx,
  onClick,
  errorMiddleLight,
  textLight,
  successMiddleLight,
  surfaceBasic,
  textDark,
  disabledBasic,
}: ProgresRoundProps) => {
  const COLOR_LIST: {
    [key: number]: {
      bgColor: string;
      color: string;
    };
  } = {
    [TYPES.PANEL_TYPES.ERROR]: {
      bgColor: errorMiddleLight,
      color: textLight,
    },
    [TYPES.PANEL_TYPES.SUCCESS]: {
      bgColor: successMiddleLight,
      color: textLight,
    },
    [TYPES.PANEL_TYPES.UNSEEN]: {
      bgColor: surfaceBasic,
      color: textDark,
    },
    [TYPES.PANEL_TYPES.DISABLED]: {
      bgColor: disabledBasic,
      color: textLight,
    },
  };
  const roundStyle = getRoundStyle(isDisplaying);
  return (
    <StyledLink
      hash
      to={to}
      hiddenUnderline
      onClick={onClick}
      disabled={panelType === TYPES.PANEL_TYPES.DISABLED}
    >
      <Round
        bgColor={COLOR_LIST[panelType].bgColor}
        isDisplaying={isDisplaying}
        width={roundStyle.width}
        height={roundStyle.height}
        disabled={panelType === TYPES.PANEL_TYPES.DISABLED}
      >
        <Text
          color={COLOR_LIST[panelType].color}
          fontWeight="bold"
          fontSize={roundStyle.fontSize}
        >
          {idx}
        </Text>
      </Round>
    </StyledLink>
  );
};

export default connect(({ color }: AppState) => ({
  errorMiddleLight: color.errorMiddleLight,
  textLight: color.textLight,
  successMiddleLight: color.successMiddleLight,
  surfaceBasic: color.surfaceBasic,
  textDark: color.textDark,
  disabledBasic: color.disabledBasic,
}))(ProgresRound);
