import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import { hex2rgba } from 'common/Utility';

export type CheckboxProps = {
  name: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
};

type StyledCheckboxProps = {
  frameBasic: string;
  accentBasic: string;
  disabledLight: string;
};

const StyledCheckbox = connect(({ color }: AppState) => ({
  frameBasic: color.frameBasic,
  accentBasic: color.accentBasic,
  disabledLight: color.disabledLight,
}))(styled(({ frameBasic, accentBasic, disabledLight, dispatch, ...props }) => (
  <MuiCheckbox {...props} />
))<StyledCheckboxProps>`
  /* base */
  &.MuiCheckbox-root {
    color: ${({ frameBasic }) => frameBasic};
  }
  &.MuiIconButton-root.Mui-checked {
    color: ${({ accentBasic }) => accentBasic};
  }
  /* hover */
  &.MuiIconButton-root:hover,
  &.MuiIconButton-root.Mui-checked:hover {
    background-color: ${({ accentBasic }) => hex2rgba(accentBasic, 0.1)};
  }
  /* disabled */
  &.MuiIconButton-root.Mui-disabled {
    color: ${({ disabledLight }) => disabledLight};
  }
`);

/**
 * 【部品】【原子】【基本入力】チェックボックスマーク <Checkbox>タグ
 * @param {string} name - チェックボックス名
 * @param {string} value - チェックボックスの値
 * @param {boolean} checked - チェックフラグ
 * @param {boolean} disabled - 非活性フラグ
 * @param {(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void} onChange - 値変更イベント
 */
const Checkbox = ({
  name,
  value,
  checked,
  disabled,
  onChange,
}: CheckboxProps) => {
  return (
    <StyledCheckbox
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

export default Checkbox;
