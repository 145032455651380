import React, { PropsWithChildren } from 'react';
import { RadioGroup as MuiRadioGroup } from '@material-ui/core';

export type RadioGroupProps = PropsWithChildren<{
  id: string;
  value: string;
  inputRef?: React.RefObject<any>;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}>;

/**
 * ラジオボタングループ
 * @param {string} id - ID
 * @param {string} value - 設定値(初期値)
 * @param {(
 *  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 * ) => void} onChange - onChangeイベント
 * @param {ReactNode} children - Radio, DescriptionRadioのリスト
 */
const RadioGroup = ({
  id,
  value,
  onChange,
  inputRef,
  children,
}: RadioGroupProps) => {
  return (
    <MuiRadioGroup id={id} value={value} onChange={onChange} ref={inputRef}>
      {children}
    </MuiRadioGroup>
  );
};

export default RadioGroup;
