import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actions } from 'duck/custom/sampleidInfo/Actions';
import SampleidInfoState from 'duck/custom/sampleidInfo/State';

const initialSampleidInfoState: SampleidInfoState = {
  sampleId: '',
  kitId: '',
  sampleIdVersion: '',
  isLoading: false,
};
export const sampleidInfoReducer = reducerWithInitialState(
  initialSampleidInfoState,
)
  .case(actions.setSampleIdAndKitId, (state, payload) => {
    return {
      ...state,
      sampleId: payload.sampleId,
      kitId: payload.kitId,
    };
  })
  .case(actions.setSampleId, (state, payload) => {
    return {
      ...state,
      sampleId: payload.sampleId,
    };
  })
  .case(actions.checkSampleId.started, (state) => {
    return {
      ...state,
      isLoading: true,
    };
  })
  .case(actions.checkSampleId.done, (state, payload) => {
    return {
      ...state,
      isLoading: false,
      sampleIdVersion: payload.result.version,
    };
  })
  .case(actions.checkSampleId.failed, (state, payload) => {
    return {
      ...state,
      isLoading: false,
      error: payload.error,
    };
  })
  .case(actions.clearError, (state) => {
    return {
      ...state,
      error: undefined,
    };
  });

export default sampleidInfoReducer;
