import React, { useState, useLayoutEffect } from 'react';
import { useCookies } from 'react-cookie';
import useReactRouter from 'use-react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AppState } from 'duck/Reducer';
import { CommonState } from 'duck/common/State';
import commonActions from 'duck/common/Actions';
import { Setting } from 'duck/manager/setting/State';
import { useTextStringField, usePasswordField } from 'hooks';
import { isDisplayError } from 'common/Utility';
import CONSTS from 'common/Consts';
import Panel from 'presentational/general/organisms/area/Panel';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import Form from 'presentational/general/molecules/form/Form';
import InputArea from 'presentational/general/molecules/form/InputArea';
import TextField from 'presentational/general/atoms/basicInput/TextField';
import PasswordField from 'presentational/general/atoms/basicInput/PasswordField';
import Button from 'presentational/general/atoms/button/Button';
import Text from 'presentational/general/atoms/text/Text';
import Link from 'presentational/general/atoms/button/Link';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import * as TYPES from 'common/Types';
import authActions, {
  dispatchLogin,
  dispatchLoginMfa,
} from 'duck/client/auth/Actions';
import { ButtonColorType } from 'duck/color/State';

// TODO バリデーション指定を修正する
const validatorRules = {
  loginId: ['required'],
  loginPassword: ['required'],
  mfa: ['required'],
};

const LoginArea = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
`;

const ButtonArea = styled.div`
  padding-top: 40px;
  text-align: center;
`;

const LinkArea = styled.div`
  padding: 60px 100px 0 0;
  text-align: right;
`;

const MaintenanceText = styled.div`
  font-size: 12px;
  margin-top: 40px;
  color: #cc0000;
`;

type dispatchByProps = {
  login: dispatchLogin;
  loginMfa: dispatchLoginMfa;
};

export type LoginProps = {
  common: CommonState;
  buttonBackgroundColorMain: ButtonColorType;
  willApplyPermissionAuth: () => void;
  setting: Setting;
} & dispatchByProps;

/**
 * 【画面】GC005_ログイン
 * @param {CommonState} common - Storeのcommon
 * @param {ButtonColorType} buttonBackgroundColorMain - ボタン色
 */
const Login = ({
  common,
  login,
  loginMfa,
  buttonBackgroundColorMain,
  willApplyPermissionAuth,
  setting,
}: LoginProps) => {
  const useCookie = useCookies(['web_form']);
  const removeCookie = useCookie[2];
  const { history } = useReactRouter();
  const loginId = useTextStringField('', false, validatorRules.loginId);
  const loginPassword = usePasswordField(
    '',
    false,
    validatorRules.loginPassword,
  );
  const mfa = useTextStringField('', false, validatorRules.mfa);
  const [mustMfa, setMustMfa] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onClickLogin = async () => {
    removeCookie('selectedMemberId', { path: '/' });
    removeCookie('mustAddMember', { path: '/' });
    removeCookie('session-key', { path: '/' });
    const res = await login(loginId.value, loginPassword.value);
    if (!res) {
      setErrorMessage('ログインに失敗しました。');
      setIsError(true);
    } else if (res.logined) {
      history.push(CONSTS.PATH_SET_SUBJECT);
    } else {
      setMustMfa(true);
    }
  };

  const onClickLoginMfa = async () => {
    if (await loginMfa(mfa.value)) {
      history.push(CONSTS.PATH_SET_SUBJECT);
    } else {
      setIsError(true);
    }
  };

  const onClickDialogClose = () => {
    setIsError(false);
  };

  useLayoutEffect(() => {
    willApplyPermissionAuth();
    removeCookie('selectedMemberId', { path: '/' });
    removeCookie('mustAddMember', { path: '/' });
    removeCookie('session-key', { path: '/' });
  }, [willApplyPermissionAuth, removeCookie]);

  type StateType = {
    from?: string;
  };

  const state = history.location.state as StateType;
  const hasCheckedId = state && state.from === 'RegistrationCheckHistory';
  const dynamicUrl = hasCheckedId
    ? CONSTS.PATH_INPUT_USER
    : CONSTS.PATH_INPUT_CERTIFICATION_CODE;

  return (
    <BeforeLoginTemplate
      device={common.deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <Panel device={common.deviceType}>
        <LoginArea>
          <div>
            <Form
              device={common.deviceType}
              templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
              question="メールアドレス"
            >
              <InputArea
                error={isDisplayError(loginId.hasInput, loginId.error.isError)}
                errorMessage={loginId.error.errorMessage}
              >
                <TextField
                  id="loginId"
                  width={250}
                  value={loginId.value}
                  onChange={loginId.onChange}
                  onBlur={loginId.onBlur}
                  error={isDisplayError(
                    loginId.hasInput,
                    loginId.error.isError,
                  )}
                />
              </InputArea>
            </Form>
            <Form
              device={common.deviceType}
              templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
              question="パスワード"
            >
              <InputArea
                error={isDisplayError(
                  loginPassword.hasInput,
                  loginPassword.error.isError,
                )}
                errorMessage={loginPassword.error.errorMessage}
              >
                <PasswordField
                  id="loginPassword"
                  width={250}
                  value={loginPassword.value}
                  onChange={loginPassword.onChange}
                  onBlur={loginPassword.onBlur}
                  error={isDisplayError(
                    loginPassword.hasInput,
                    loginPassword.error.isError,
                  )}
                />
              </InputArea>
            </Form>

            <ButtonArea>
              <Button
                buttonType={TYPES.BUTTON_TYPES.ROUND}
                onClick={onClickLogin}
                {...buttonBackgroundColorMain}
              >
                ログイン
              </Button>
            </ButtonArea>
            <Dialog
              open={isError}
              onClose={onClickDialogClose}
              title="エラー"
              showCloseButton
              buttons={[{ text: 'OK', onClick: onClickDialogClose }]}
            >
              <Text>{errorMessage}</Text>
            </Dialog>
            <Dialog
              open={mustMfa}
              onClose={() => setMustMfa(false)}
              title="MFAコード"
              buttons={[]}
            >
              <TextField
                id="mfa"
                width={250}
                value={mfa.value}
                onChange={mfa.onChange}
                onBlur={mfa.onBlur}
                error={isDisplayError(mfa.hasInput, mfa.error.isError)}
              />
              <ButtonArea>
                <Button
                  width={250}
                  buttonType={TYPES.BUTTON_TYPES.ROUND}
                  onClick={onClickLoginMfa}
                  {...buttonBackgroundColorMain}
                >
                  コードを送信
                </Button>
              </ButtonArea>
            </Dialog>
          </div>
        </LoginArea>
        <MaintenanceText>
          【重要なお知らせ】
          <br />
          システムメンテナンス実施のため、下記の期間はこちらのサービスをご利用いただくことができません。
          <br />
          メンテナンス期間：2024年9月25日（水）～9月30日（月）
        </MaintenanceText>
        <LinkArea>
          <Link to={CONSTS.PATH_SEND_RESET_PASSWORD_EMAIL}>
            パスワードを忘れた場合
          </Link>
          <br />
          <Link to={dynamicUrl}>利用者登録がお済みでない場合</Link>
        </LinkArea>
      </Panel>
    </BeforeLoginTemplate>
  );
};

export default connect(
  ({ common, color, managerSetting }: AppState) => ({
    common,
    buttonBackgroundColorMain: color.buttonBackgroundColorMain,
    setting: managerSetting.setting,
  }),
  {
    login: authActions.login,
    loginMfa: authActions.loginMfa,
    willApplyPermissionAuth: commonActions.willApplyPermissionAuth,
  },
)(Login);
