import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import { Button as MuiButton } from '@material-ui/core';
import * as TYPES from 'common/Types';
import Icon from 'presentational/general/atoms/text/Icon';

export type ButtonProps = PropsWithChildren<{
  buttonType: TYPES.BUTTON_TYPES;
  iconName?: string;
  fontSize?: number;
  width?: number;
  height?: number;
  disabled?: boolean;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  focusBackgroundColor?: string;
  color?: string;
  disabledBasic: string;
  accentBasic: string;
  accentDark: string;
  accentLight: string;
  textLight: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => void;
  children?: React.ReactNode;
}>;

const ButtonText = styled.span`
  margin: 0 8px;
  font-weight: bold;
`;

type StyledButtonProps = {
  buttonType: TYPES.BUTTON_TYPES;
  fontSize: number;
  backgroundColor: string;
  hoverBackgroundColor: string;
  focusBackgroundColor: string;
  color: string;
  width: number;
  height?: number;
  disabledLight: string;
  disabledBasic: string;
};

const StyledButton = connect(({ color }: AppState) => ({
  disabledLight: color.disabledLight,
  disabledBasic: color.disabledBasic,
}))(styled(
  ({
    buttonType,
    backgroundColor,
    hoverBackgroundColor,
    focusBackgroundColor,
    color,
    width,
    height,
    disabledLight,
    disabledBasic,
    dispatch,
    ...props
  }) => <MuiButton {...props} />,
)<StyledButtonProps>`
  /* base */
  &.MuiButtonBase-root {
    background-color: ${({ backgroundColor }: StyledButtonProps) =>
      backgroundColor};
    color: ${({ color }: StyledButtonProps) => color};
    font-size: ${({ fontSize }: StyledButtonProps) => fontSize}px;
    width: ${({ width }: StyledButtonProps) => width}px;
    min-width: ${({ width }: StyledButtonProps) => width}px;
    ${({ height }: StyledButtonProps) => height && `height: ${height}px;`}
    ${({ buttonType }: StyledButtonProps) =>
      buttonType === TYPES.BUTTON_TYPES.ROUND && `border-radius: 16px;`}
  }

  /* focus */
  & .MuiTouchRipple-root {
    color: ${({ focusBackgroundColor }: StyledButtonProps) =>
      focusBackgroundColor};
  }

  /* hover */
  &.MuiButton-root:hover {
    background-color: ${({ hoverBackgroundColor }: StyledButtonProps) =>
      hoverBackgroundColor};
  }

  /* disabled */
  &.MuiButton-root.Mui-disabled {
    background-color: ${({ disabledLight }) => disabledLight};
    color: ${({ disabledBasic }) => disabledBasic};
  }
`);

/**
 * 【部品】【原子】【ボタン】ボタン <Button>タグ
 * @param {TYPES.BUTTON_TYPES} buttonType - ボタン種別
 * @param {string} iconName - アイコン名(未指定で無し)
 * @param {number} fontSize - 文字サイズ(px)
 * @param {number} width - ボタン横幅(px)
 * @param {number} height - ボタン縦幅(px)
 * @param {boolean} disabled - 非活性フラグ
 * @param {(
 *  e: React.MouseEvent<HTMLButtonElement | MouseEvent>
 *  ) => void} onClick - クリックイベント
 * @param {string} backgroundColor - 背景色
 * @param {string} hoverBackgroundColor - 背景色:hover時
 * @param {string} focusBackgroundColor - 背景色:focus時
 * @param {string} color - 文字色
 * @param {React.ReactNode} children - Buttonタグの中身
 * @param {string} disabledBasic - 使用不可色
 * @param {string} textLight - テキスト色
 * @param {string} accentBasic - アクセント色
 * @param {string} accentDark - アクセン色
 * @param {string} accentLight - アクセント色
 */
const Button = ({
  buttonType,
  iconName,
  fontSize = 16,
  width = 100,
  height,
  disabled = false,
  onClick,
  disabledBasic,
  textLight,
  accentBasic,
  accentDark,
  accentLight,
  backgroundColor = accentBasic,
  hoverBackgroundColor = accentDark,
  focusBackgroundColor = accentLight,
  color = textLight,
  children,
}: ButtonProps) => {
  return (
    <StyledButton
      buttonType={buttonType}
      fontSize={fontSize}
      width={width}
      height={height}
      disabled={disabled}
      onClick={onClick}
      backgroundColor={backgroundColor}
      hoverBackgroundColor={hoverBackgroundColor}
      focusBackgroundColor={focusBackgroundColor}
      color={color}
    >
      {iconName && (
        <Icon
          iconColor={disabled ? disabledBasic : textLight}
          iconSize={fontSize}
        >
          {iconName}
        </Icon>
      )}
      <ButtonText>{children}</ButtonText>
    </StyledButton>
  );
};

export default connect(({ color }: AppState) => ({
  disabledBasic: color.disabledBasic,
  textLight: color.textLight,
  accentBasic: color.accentBasic,
  accentDark: color.accentDark,
  accentLight: color.accentLight,
}))(Button);
