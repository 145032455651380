import { Dispatch } from 'redux';
import actionCreatorFactory from 'typescript-fsa';
import {
  Form,
  FormOnlySlider,
  Answer,
  FormAnswer,
  Template,
  AnswerProgress,
  RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId,
  ResultAnswers,
} from 'oapi';
import { Answers } from 'duck/client/template/State';
import APIWrapper, { APIError, DefaultApiFp } from 'common/APIWrapper';
import store from 'Store';
import { actions as commonActions } from 'duck/common/Actions';

const {
  apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdGet,
  apiClientGroupsGroupIdTemplatesTemplateIdGet,
  apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPut,
} = DefaultApiFp();

const generateTitle = (val: string, form?: Form): string => {
  if (!form) {
    return val;
  }
  if (form.radio_form) {
    for (let i = 0; i < form.radio_form.list.length; i += 1) {
      if (form.radio_form.list[i].value === val) {
        return form.radio_form.list[i].label;
      }
    }
  }
  if (form.checkbox_form) {
    for (let i = 0; i < form.checkbox_form.list.length; i += 1) {
      if (form.checkbox_form.list[i].value === val) {
        return form.checkbox_form.list[i].label;
      }
    }
  }
  if (form.select_form) {
    for (let i = 0; i < form.select_form.list.length; i += 1) {
      if (
        form.select_form.list[i].type !== 'header' &&
        form.select_form.list[i].value === val
      ) {
        const prefix = form.select_form.prefix ? form.select_form.prefix : '';
        const suffix = form.select_form.suffix ? form.select_form.suffix : '';
        return `${prefix}${form.select_form.list[i].label}${suffix}`;
      }
    }
  }
  if (form.text_form) {
    const prefix = form.text_form.prefix ? form.text_form.prefix : '';
    const suffix = form.text_form.suffix ? form.text_form.suffix : '';
    return `${prefix}${val}${suffix}`;
  }
  if (form.date_form) {
    const prefix = form.date_form.prefix ? form.date_form.prefix : '';
    const suffix = form.date_form.suffix ? form.date_form.suffix : '';
    return `${prefix}${val}${suffix}`;
  }
  return val;
};

const generateFormsMap = (
  forms: Array<Form>,
): { [key: string]: Form | FormOnlySlider } => {
  let formsMap: { [key: string]: Form | FormOnlySlider } = {};
  forms.forEach((form) => {
    formsMap[form.id] = form;
    if (form.mixed_form) {
      formsMap = Object.assign(
        formsMap,
        generateFormsMap(form.mixed_form.forms),
      );
    }
    if (form.slider_list_form) {
      form.slider_list_form.forms.forEach((f) => {
        formsMap[f.id] = f;
      });
    }
  });
  return formsMap;
};

export const generateRequestAnswer = (
  template: Template,
  answers: Answers,
  progress: AnswerProgress,
): RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId => {
  const saveAnswers: RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId = {
    progress,
    answers: [],
  };
  let formsMap: { [key: string]: Form | FormOnlySlider } = {};
  template.panels.forEach((panel) => {
    formsMap = Object.assign(formsMap, generateFormsMap(panel.forms));
  });
  saveAnswers.answers = answers.map(
    (answer): FormAnswer => {
      const form = formsMap[answer.id];
      return {
        id: answer.id,
        answers: answer.values.map(
          (val): Answer => {
            return {
              title: generateTitle(val, form as Form),
              value: val,
            };
          },
        ),
      };
    },
  );
  return saveAnswers;
};

/**
 * テンプレート情報取得のため
 */
export type FetchTemplateParams = {
  // グループID
  groupId: string;
  // テンプレートID
  templateId: string;
  // カスタムID
  customId: string;
};

/**
 * フォームに対する回答
 */
export type answerFromParams = {
  // フォームID
  formId: string;
  // タイトル(表示値)
  answers: Array<Answer>;
};

const actionCreator = actionCreatorFactory();
export const actions = {
  load: actionCreator.async<{}, void, APIError>('LOAD_TEMPLATE'),
  fetchCurrentAnswers: actionCreator<ResultAnswers>('CURRENT_ANSER_TEMPLATE'),
  fetchTemplate: actionCreator.async<{}, Template, APIError>('FETCH_TEMPLATE'),
  fetchAll: actionCreator.async<FetchTemplateParams, void, APIError>(
    'FETCH_ALL',
  ),
  saveAnswers: actionCreator<void>('SAVE_ANSWERS'),
  setError: actionCreator<APIError>('SET_ERROR_TEMPLATE'),
  clearError: actionCreator('CLEAR_ERROR'),
};

/**
 * テンプレートに対する最新の回答結果を取得
 */
export const fetchCurrentAnswers = () => {
  return async (dispatch: Dispatch) => {
    const { groupId, templateId, customId } = store.getState().template;
    try {
      const res = await APIWrapper(
        await apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdGet(
          groupId,
          templateId,
          customId,
        ),
      );
      dispatch(actions.fetchCurrentAnswers(res.data.data));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 404) {
        /* dispatch(
          actions.fetchCurrentAnswers(temporaryAnswers)
        ); */
      } else if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
      } else {
        dispatch(actions.setError(e));
      }
      return false;
    }
  };
};

/**
 * テンプレート情報を取得する
 * @param {void} params - 不要
 */
export const fetchTemplate = (params: {} = {}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchTemplate.started(params));
      const { groupId, templateId } = store.getState().template;
      const res = await APIWrapper(
        await apiClientGroupsGroupIdTemplatesTemplateIdGet(groupId, templateId),
      );
      dispatch(actions.fetchTemplate.done({ params, result: res.data.data }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
      } else {
        dispatch(actions.fetchTemplate.failed({ params, error: e }));
      }
      return false;
    }
  };
};
/**
 * サーバーのテンプレートに関する情報を全て取得する
 * @param {FetchTemplateParams} params - テンプレートを取得するための情報
 */
export const fetchAll = (params: FetchTemplateParams) => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    dispatch(actions.fetchAll.started(params));
    const isSucceed = await store.dispatch(fetchTemplate());
    await store.dispatch(fetchCurrentAnswers());
    dispatch(actions.fetchAll.done({ params }));
    return isSucceed;
  };
};

/**
 * 回答を保存する
 * @param {AnswerProgress} progressType - 回答状態
 */
export const saveAnswers = (
  progressType: AnswerProgress = AnswerProgress.ANSWERED,
  answers: Answers,
  customId: string,
) => {
  const params = {};
  return async (dispatch: Dispatch) => {
    dispatch(actions.load.started(params));
    const state = store.getState().template;
    if (!state.template) {
      dispatch(
        actions.load.failed({
          params,
          error: new APIError(
            '回答が存在しません。',
            999,
            '回答が存在しません。',
          ),
        }),
      );
      return false;
    }
    const sAnswers = generateRequestAnswer(
      state.template,
      answers,
      progressType,
    );
    try {
      await APIWrapper(
        await apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPut(
          state.groupId,
          state.templateId,
          customId,
          sAnswers,
        ),
      );
      const isSucceed = await store.dispatch(fetchCurrentAnswers());
      dispatch(actions.load.done({ params }));
      return isSucceed;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params }));
      } else {
        dispatch(actions.load.failed({ params, error: e }));
      }
      return false;
    }
  };
};

/**
 *
 * @param { Template } template テンプレート情報
 */
export const setTemplate = (template: Template) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.fetchTemplate.done({ params: {}, result: template }));
  };
};

/**
 * clearError - エラーをクリアする
 */
export const clearError = () => {
  return (dispatch: Dispatch) => dispatch(actions.clearError());
};

export default { fetchAll, saveAnswers, clearError, setTemplate };
