import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import Button from 'presentational/general/atoms/button/Button';
import Bar from 'presentational/general/atoms/other/Bar';
import { AppState } from 'duck/Reducer';

export type TemplatePanelProps = PropsWithChildren<{
  id?: string;
  device: TYPES.DEVICE_TYPES;
  showNextButton?: boolean;
  onClickNextButton?: (
    e: React.MouseEvent<HTMLButtonElement | MouseEvent>,
  ) => void;
  templateType: TYPES.TEMPLATE_IN_OUT_TYPES;
  mainLight: string;
  children: React.ReactNode;
  isDisplayed?: boolean;
}>;

type PanelAreaProps = {
  device: TYPES.DEVICE_TYPES;
  templateType: TYPES.TEMPLATE_IN_OUT_TYPES;
  isDisplayed: boolean;
  surfaceBasic: string;
};

const PanelArea = connect(({ color }: AppState) => ({
  surfaceBasic: color.surfaceBasic,
}))(styled.div<PanelAreaProps>`
  display: ${({ isDisplayed }) => (isDisplayed ? 'block' : 'none')};
  background-color: ${({ surfaceBasic }) => surfaceBasic};
  width: ${({ device }: PanelAreaProps) =>
    device === TYPES.DEVICE_TYPES.PC
      ? 'calc(100% - 35px * 2)'
      : 'calc(100% - 15px * 2)'};
  margin: ${({ device, templateType }: PanelAreaProps) => {
    if (device === TYPES.DEVICE_TYPES.PC) {
      return '0 auto';
    }
    if (templateType === TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT) {
      return '50px auto';
    }
    return '40px auto';
  }};
  padding: ${({ device }: PanelAreaProps) =>
    device === TYPES.DEVICE_TYPES.PC ? '0 35px' : '15px 15px'};
`);

const FormArea = styled.div`
  & div:last-child {
    margin-bottom: 0;
  }
`;
const StyledBar = styled(Bar)`
  margin: 30px 0;
`;

const ButtonArea = styled.div`
  padding: 20px 0;
  text-align: center;
`;

/**
 * 【部品】【有機体】【エリア】テンプレートパネル <TemplatePanel>タグ
 * @param {stirng} id - HTML ID属性
 * @param {DEVICE_TYPES} device - 表示デバイスの種類
 * @param {boolean} showNextButton - 次へボタンの表示フラグ
 * @param {(
 *  e: React.MouseEvent<HTMLButtonElement | MouseEvent>
 *  ) => void} onClickNextButton - クリックイベント
 * @param {React.ReactNode} children - PageTitleタグの中身
 * @param {TEMPLATE_TYPE} templateType - テンプレート入出力区分
 * @param {boolean} isDisplayed - 表示フラグ
 */
const TemplatePanel = ({
  id,
  device,
  showNextButton,
  onClickNextButton,
  children,
  templateType,
  mainLight,
  isDisplayed = true,
}: TemplatePanelProps) => {
  if (device === TYPES.DEVICE_TYPES.MOBILE) {
    return (
      <PanelArea
        id={id}
        device={device}
        templateType={templateType}
        isDisplayed={isDisplayed}
      >
        <FormArea>{children}</FormArea>
        {showNextButton && (
          <ButtonArea>
            <Button
              buttonType={TYPES.BUTTON_TYPES.ROUND}
              width={100}
              onClick={onClickNextButton}
            >
              次へ
            </Button>
          </ButtonArea>
        )}
      </PanelArea>
    );
  }
  return (
    <PanelArea
      id={id}
      device={device}
      templateType={templateType}
      isDisplayed={isDisplayed}
    >
      <FormArea>{children}</FormArea>
      {showNextButton && (
        <ButtonArea>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            width={100}
            onClick={onClickNextButton}
          >
            次へ
          </Button>
        </ButtonArea>
      )}
      <StyledBar height={3} color={mainLight} />
    </PanelArea>
  );
};

export default connect(({ color }: AppState) => ({
  mainLight: color.mainLight,
}))(TemplatePanel);
