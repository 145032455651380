import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import Text from 'presentational/general/atoms/text/Text';

export type TitleProps = PropsWithChildren<{
  color?: string;
  textDark: string;
  children?: React.ReactNode;
}>;

/**
 * 【部品】【原子】【文章】タイトル <Title>タグ
 * @param {string} color - フォントカラー
 * @param {React.ReactNode} children - Titleタグの中身
 */
const Title = ({ textDark, color = textDark, children }: TitleProps) => {
  return (
    <Text color={color} fontSize={20} fontWeight="bold">
      {children}
    </Text>
  );
};

export default connect(({ color }: AppState) => ({
  textDark: color.textDark,
}))(Title);
