import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Utility from 'common/Utility';
import * as TYPES from 'common/Types';
import IconButton from 'presentational/general/atoms/button/IconButton';
import PageTitle from 'presentational/general/atoms/text/PageTitle';
import { AppState } from 'duck/Reducer';

const ICON_SIZE_MB = 40; // 戻るボタンのサイズ(モバイル)
const ICON_SIZE_PC = 36; // 戻るボタンのサイズ(パソコン)

export type BackPageTitleProps = PropsWithChildren<{
  device: TYPES.DEVICE_TYPES;
  onClick?: (e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => void;
  mainBasic: string;
  children: React.ReactNode;
}>;

type TitleAreaProps = {
  iconSize: number;
};

const Wrap = styled.div`
  display: flex;
  margin-bottom: 10px;
  button {
    height: fit-content;
  }
`;

const TitleArea = styled.div<TitleAreaProps>`
  width: calc(100% - ${({ iconSize }: TitleAreaProps) => iconSize}px);
`;

/**
 * 【部品】【分子】【その他】戻る付きタイトル <BackPageTitle>タグ
 * @param {DEVICE_TYPES} device - 表示デバイスの種類
 * @param {(
 *  e: React.MouseEvent<HTMLButtonElement | MouseEvent>
 *  ) => void} onClick - クリックイベント
 * @param {React.ReactNode} children - BackPageTitleタグの中身
 * @param {string} mainBasic - メイン色
 */
const BackPageTitle = ({
  device,
  onClick,
  mainBasic,
  children,
}: BackPageTitleProps) => {
  const iconSize =
    device === TYPES.DEVICE_TYPES.MOBILE ? ICON_SIZE_MB : ICON_SIZE_PC;
  return (
    <Wrap>
      <IconButton
        iconName="navigate_before"
        iconSize={iconSize}
        iconColor={mainBasic}
        backgroundColor={Utility.hex2rgba('FFF', 0)}
        onClick={onClick}
      />
      <TitleArea iconSize={iconSize}>
        <PageTitle device={device}>{children}</PageTitle>
      </TitleArea>
    </Wrap>
  );
};

export default connect(({ color }: AppState) => ({
  mainBasic: color.mainBasic,
}))(BackPageTitle);
