import { useState, ChangeEvent } from 'react';
import { ErrorProps, validateChecks } from 'hooks/Validator';
import {
  CheckboxDataProps,
  CheckboxDataListProps,
} from 'presentational/general/atoms/basicInput/CheckboxGroup';

export type TermsHooksProps = {
  checkboxData: CheckboxDataListProps;
  value: string[];
  error: ErrorProps;
  hasInput: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  clear: () => void;
};

/**
 * <Terms> 専用カスタム hooks
 * @param {CheckboxDataProps} initialValue - 初期値
 * @param {boolean} hasInitialInput - 入力済みフラグ初期値
 * @param {string[]} validatorRules - バリデーションルール
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ
 * @returns {TermsHooksProps} - 生成された部品
 */
const useTerms = (
  initialValue: CheckboxDataProps,
  hasInitialInput: boolean,
  validatorRules?: string[],
  validatorMessage?: { [key: string]: string },
): TermsHooksProps => {
  const [checkboxData, setCheckboxData] = useState([initialValue]);
  const [value, setValue] = useState<string[]>(
    initialValue.checked ? [initialValue.value] : [],
  );
  const [error, setError] = useState<ErrorProps>(
    validateChecks({
      checks: [initialValue.checked],
      validatorRules,
      validatorMessage,
    }),
  );
  const [hasInput, setHasInput] = useState(hasInitialInput);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isTargetChecked = e.target.checked;
    const validateResult = validateChecks({
      checks: [isTargetChecked],
      validatorRules,
      validatorMessage,
    });
    setError(validateResult);

    const data = { ...checkboxData[0] };
    data.checked = isTargetChecked;
    setCheckboxData([data]);
    setValue(isTargetChecked ? [data.value] : []);

    if (!hasInput) {
      setHasInput(true);
    }
  };

  const clear = () => {
    const data = { ...checkboxData[0] };
    data.checked = false;

    setCheckboxData([data]);
    setValue([]);
    setHasInput(false);
  };

  return { checkboxData, value, error, hasInput, onChange, clear };
};

export default useTerms;
