import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export type LinkProps = PropsWithChildren<{
  to: string;
  fontSize?: number;
  disabled?: boolean;
  hash?: boolean;
  hiddenUnderline?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => void;
  external?: boolean;
}>;

type StyledReactRouterDomLinkProps = {
  fontSize: number;
  hiddenUnderline: boolean;
};

const StyledReactRouterDomLink = connect(({ color }: AppState) => ({
  accentBasic: color.accentBasic,
  accentDark: color.accentDark,
}))(styled(
  ({
    fontSize,
    hiddenUnderline,
    accentBasic,
    accentDark,
    dispatch,
    ...props
  }) => <ReactRouterDomLink {...props} />,
)<StyledReactRouterDomLinkProps>`
  & {
    font-size: ${({ fontSize }) => fontSize}px;
    color: ${({ accentBasic }) => accentBasic};
    text-decoration: ${({ hiddenUnderline }) =>
      hiddenUnderline ? 'none' : 'underline'};
  }

  & img {
    border: 1px solid ${({ accentBasic }) => accentBasic};
  }

  /* hover */
  &:hover {
    color: ${({ accentDark }) => accentDark};
    text-decoration: none;
  }

  & img:hover {
    border: 1px solid ${({ accentDark }) => accentDark};
  }
`);

type DisabledLinkProps = {
  fontSize: number;
  hiddenUnderline: boolean;
};

const DisabledLink = connect(({ color }: AppState) => ({
  disabledBasic: color.disabledBasic,
}))(styled(
  ({ hiddenUnderline, fontSize, disabledBasic, dispatch, ...props }) => (
    <span {...props} />
  ),
)<DisabledLinkProps>`
  font-size: ${({ fontSize }: DisabledLinkProps) => fontSize}px;
  color: ${({ disabledBasic }) => disabledBasic};
  text-decoration: ${({ hiddenUnderline }) =>
    hiddenUnderline ? 'none' : 'underline'};
  cursor: default;
`);

type StyledHashLinkProps = {
  fontSize: number;
  hiddenUnderline: boolean;
  accentBasic: string;
  accentDark: string;
};

const StyledHashLink = connect(({ color }: AppState) => ({
  accentBasic: color.accentBasic,
  accentDark: color.accentDark,
}))(styled(
  ({
    hiddenUnderline,
    fontSize,
    accentBasic,
    accentDark,
    dispatch,
    ...props
  }) => <HashLink {...props} />,
)<StyledHashLinkProps>`
  font-size: ${({ fontSize }: StyledHashLinkProps) => fontSize}px;
  color: ${({ accentBasic }) => accentBasic};
  text-decoration: ${({ hiddenUnderline }: StyledHashLinkProps) =>
    hiddenUnderline ? 'none' : 'underline'};
  cursor: default;

  & img {
    border: 1px solid ${({ accentBasic }) => accentBasic};
  }

  /* hover */
  &:hover {
    color: ${({ accentDark }) => accentDark};
    text-decoration: none;
    cursor: pointer;
  }

  & img:hover {
    border: 1px solid ${({ accentDark }) => accentDark};
  }
`);

const StyledAnker = connect(({ color }: AppState) => ({
  accentBasic: color.accentBasic,
  accentDark: color.accentDark,
}))(styled.a<StyledHashLinkProps>`
  font-size: ${({ fontSize }: StyledHashLinkProps) => fontSize}px;
  color: ${({ accentBasic }) => accentBasic};
  text-decoration: ${({ hiddenUnderline }: StyledHashLinkProps) =>
    hiddenUnderline ? 'none' : 'underline'};
  cursor: default;

  & img {
    border: 1px solid ${({ accentBasic }) => accentBasic};
  }

  /* hover */
  &:hover {
    color: ${({ accentDark }) => accentDark};
    text-decoration: none;
    cursor: pointer;
  }

  & img:hover {
    border: 1px solid ${({ accentDark }) => accentDark};
  }
`);

/**
 * 【部品】【原子】【ボタン】リンク <Link>タグ
 * @param {string} to - リンク先
 * @param {number} fontSize - フォントサイズ
 * @param {boolean} disabled - 非活性フラグ
 * @param {boolean} hash - ハッシュリンクフラグ
 * @param {boolean} hiddenUnderline - 下線非表示フラグ
 * @param {React.ReactNode} children - Linkタグの中身
 * @param {(
 *  e: React.MouseEvent<HTMLButtonElement | MouseEvent>
 *  ) => void} onClick - クリックイベント
 */
const Link = ({
  to,
  fontSize = 13,
  disabled = false,
  hash = false,
  children,
  hiddenUnderline = false,
  onClick,
  external = false,
}: LinkProps) => {
  if (disabled) {
    return (
      <DisabledLink fontSize={fontSize} hiddenUnderline={hiddenUnderline}>
        {children}
      </DisabledLink>
    );
  }
  if (hash) {
    return (
      <StyledHashLink
        smooth
        to={to}
        fontSize={fontSize}
        hiddenUnderline={hiddenUnderline}
        onClick={onClick}
      >
        {children}
      </StyledHashLink>
    );
  }
  if (external) {
    return (
      <StyledAnker
        href={to}
        fontSize={fontSize}
        hiddenUnderline={hiddenUnderline}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </StyledAnker>
    );
  }
  return (
    <StyledReactRouterDomLink
      to={to}
      fontSize={fontSize}
      hiddenUnderline={hiddenUnderline}
      onClick={onClick}
    >
      {children}
    </StyledReactRouterDomLink>
  );
};

export default Link;
