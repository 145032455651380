import React from 'react';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import CONSTS from 'common/Consts';
import Image from 'presentational/general/atoms/other/Image';
import logo from 'images/custom/symgram_logo.svg';

const PARTS = {
  HEIGHT_MOBILE: 50,
  HEIGHT_PC: 40,
  PADDING: 10,
};

type WrapProps = {
  device: TYPES.DEVICE_TYPES;
  backgroundColor: string;
};

const Wrap = styled.div<WrapProps>`
  background-color: ${({ backgroundColor }: WrapProps) => backgroundColor};
  ${({ device }: WrapProps) =>
    device === TYPES.DEVICE_TYPES.PC &&
    `min-width: calc(${CONSTS.CONTENTS_AREA_WIDTH}px - ${PARTS.PADDING}px * 2);`}
  width: calc(100% - ${PARTS.PADDING}px * 2);
  padding: ${PARTS.PADDING}px;

  img {
    display: block;
    ${({ device }: WrapProps) =>
      device === TYPES.DEVICE_TYPES.MOBILE && `margin: 0 auto;`}
  }
`;

export const height = {
  mobile: PARTS.HEIGHT_MOBILE + PARTS.PADDING * 2,
  pc: PARTS.HEIGHT_PC + PARTS.PADDING * 2,
};

export type LoginHeaderProps = {
  device: TYPES.DEVICE_TYPES;
  src?: string;
  alt?: string;
  backgroundColor?: string;
};

/**
 * 【部品】【有機体】【エリア】【SSI個別】ログインヘッダー <LoginHeader>タグ
 * @param {DEVICE_TYPES} device - 表示デバイス区分
 */
const LoginHeader = ({
  device,
  src = logo,
  alt = 'SYMGRAM',
  backgroundColor = '#9fc2e3',
}: LoginHeaderProps) => {
  return (
    <Wrap device={device} backgroundColor={backgroundColor}>
      <Image
        src={src}
        height={
          device === TYPES.DEVICE_TYPES.MOBILE
            ? PARTS.HEIGHT_MOBILE
            : PARTS.HEIGHT_PC
        }
        alt={alt}
      />
    </Wrap>
  );
};

export default LoginHeader;
