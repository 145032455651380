import React from 'react';
import styled from 'styled-components';
import Text from 'presentational/general/atoms/text/Text';
import Slider from 'presentational/general/atoms/basicInput/Slider';
import Description from 'presentational/general/atoms/basicInput/Description';

export type Slider = {
  id: string;
  label: string;
  value: number;
  defaultValue: number;
  description?: string;
};

export type Shortage = {
  insertSlidersIndex: number;
  id: string;
  total: number;
  label: string;
  value: number;
  description?: string;
};

export type SliderListProps = {
  sliders: Slider[];
  shortage: Shortage;
  valueLimit: {
    sliders: { [key: string]: number }[];
    shortage: { [key: string]: number };
  };
  max?: number;
  min?: number;
  step?: number;
  onChange: (changeValue: number, key: number) => void;
  onChangeCommitted: (changeValue: number, key: number) => void;
  disabled?: boolean;
};

const DescriptionArea = styled.div`
  display: inline;
  margin-left: 9px;
`;

/**
 * 【部品】【原子】【複合入力】郵便番号 <SliderList>タグ
 * @param {Slider[]} sliders - スライダー配列
 * @param {Shortage} shortage - 不足値表示用スライダー
 * @param {{
    sliders: { [key: string]: number }[];
    shortage: { [key: string]: number };
   }} valueLimit - 最大値/最小値/目盛り単位
 * @param {(
 *  changeValue: number, key: number
 * ) => SliderListUpdateProps} onChange - 値変更イベント
 * @param {(
 *  changeValue: number, key: number
 * ) => SliderListUpdateProps} onChangeCommitted - 値変更完了イベント
 * @param {boolean} disabled - 非活性フラグ
 */
const SliderList = ({
  sliders,
  shortage,
  valueLimit,
  onChange,
  onChangeCommitted,
  disabled = false,
}: SliderListProps) => {
  return (
    <>
      {sliders.map((slider, i) => {
        return (
          <div key={slider.id}>
            {shortage && shortage.insertSlidersIndex === i && (
              <div key={shortage.label}>
                <Text>{shortage.label}</Text>
                {shortage.description && (
                  <DescriptionArea>
                    <Description>{shortage.description}</Description>
                  </DescriptionArea>
                )}
                <Slider
                  value={shortage.value}
                  max={valueLimit.shortage.max}
                  min={valueLimit.shortage.min}
                  step={valueLimit.shortage.step}
                  disabled
                />
              </div>
            )}
            <div>
              <Text>{slider.label}</Text>
              {slider.description && (
                <DescriptionArea>
                  <Description>{slider.description}</Description>
                </DescriptionArea>
              )}
              <Slider
                value={slider.value}
                max={valueLimit.sliders[i].max}
                min={valueLimit.sliders[i].min}
                step={valueLimit.sliders[i].step}
                disabled={disabled}
                onChange={(value: number | number[]) => {
                  if (typeof value === 'number') {
                    onChange(value, i);
                  }
                }}
                onChangeCommitted={(value: number | number[]) => {
                  if (typeof value === 'number') {
                    onChangeCommitted(value, i);
                  }
                }}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SliderList;
