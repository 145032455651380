import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';

export type InlineAreaProps = PropsWithChildren<{
  width?: number;
  height?: number;
}>;

type StyledDivProps = {
  width?: number;
  height?: number;
  frameLight: string;
  frameBasic: string;
};

const PADDING_RIGHT = 15;
const PADDING_LEFT = 5;

const StyledDiv = connect(({ color }: AppState) => ({
  frameLight: color.frameLight,
  frameBasic: color.frameBasic,
}))(styled.div<StyledDivProps>`
  width: ${({ width }: StyledDivProps) =>
    width ? `${width}px;` : `100% - ${PADDING_RIGHT} - ${PADDING_LEFT};`};
  height: ${({ height }: StyledDivProps) => height}px;
  background-color: ${({ frameLight }) => frameLight};
  padding: 0px ${PADDING_RIGHT}px 0px ${PADDING_LEFT}px;
  border: 1px solid ${({ frameBasic }) => frameBasic};
  overflow: auto;
`);

/**
 * UKIYOE-120 【部品】【原子】【その他】インラインエリア
 * @param {number} width - 幅
 * @param {number} height - 高さ（デフォルト200）
 * @param {ReactChild} children - 子要素
 */
const InlineArea = ({ width, height = 200, children }: InlineAreaProps) => {
  return (
    <StyledDiv width={width} height={height}>
      {children}
    </StyledDiv>
  );
};

export default InlineArea;
