import React, { useLayoutEffect, ChangeEvent } from 'react';
import * as api from 'oapi/api';
import * as TYPES from 'common/Types';
import { generateTsxToReplacedBr } from 'commontsx/Utility';
import useCheckboxGroup, {
  generateCheckboxDataList,
  generateCheckboxValues,
} from 'hooks/useCheckboxGroup';
import { HandleSetAnswerProps } from 'presentational/general/templates/template/InputTemplate';
import Form from 'presentational/general/molecules/form/Form';
import InputArea from 'presentational/general/molecules/form/InputArea';
import CheckboxGroup from 'presentational/general/atoms/basicInput/CheckboxGroup';
import FormDescription from 'presentational/general/molecules/other/FormDescription';

export type CheckboxFormProps = {
  device: TYPES.DEVICE_TYPES;
  form: api.Form;
  disabled: boolean;
  visible: boolean;
  handleSetAnswer: (answer: HandleSetAnswerProps) => void;
};

/**
 * 【部品】【有機体】【テンプレート入力フォーム】チェックボックスグループ
 * @param {TYPES.DEVICE_TYPES} device - 表示デバイスの種類
 * @param {api.Form} form - フォーム情報
 * @param {boolean} disabled - 非活性フラグ
 * @param {boolean} visible - 表示フラグ
 * @param {(answer: HandleSetAnswerProps) => void} handleSetAnswer - InputTemplateに入力状態を渡す関数
 */
const CheckboxForm = ({
  device,
  form,
  disabled,
  visible,
  handleSetAnswer,
}: CheckboxFormProps) => {
  const checkboxForm = form.checkbox_form as api.CheckboxForm;
  const initialValue: string[] = form.answers
    ? form.answers.map((answer) => answer.value)
    : [];
  const hasInitialInput: boolean = form.answers
    ? true
    : initialValue.length !== 0;
  const hooks = useCheckboxGroup(
    generateCheckboxDataList(checkboxForm.list, initialValue),
    hasInitialInput,
    checkboxForm.validations.values,
  );

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: number,
  ): void => {
    const { values, error, hasInput } = hooks.onChange(e, key);
    handleSetAnswer({
      formId: form.id,
      values,
      hasInput,
      isError: error.isError,
      isInitial: false,
      setHasInputTrue: hooks.setHasInputTrue,
    });
  };

  useLayoutEffect((): void => {
    if (disabled && hooks.hasInput) {
      const { values, error, hasInput } = hooks.clear();
      handleSetAnswer({
        formId: form.id,
        values,
        hasInput,
        isError: error.isError,
        isInitial: true,
        setHasInputTrue: hooks.setHasInputTrue,
      });
    }
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useLayoutEffect((): void => {
    handleSetAnswer({
      formId: form.id,
      values: generateCheckboxValues(hooks.checkboxDataList),
      hasInput: hooks.hasInput,
      isError: hooks.error.isError,
      isInitial: true,
      setHasInputTrue: hooks.setHasInputTrue,
    });
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {visible && (
        <Form
          device={device}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question={generateTsxToReplacedBr(form.subscribe)}
          complement={<FormDescription form={form} />}
          required={disabled ? false : form.is_required}
          visible={visible}
        >
          <InputArea
            error={hooks.hasInput && hooks.error.isError}
            errorMessage={hooks.hasInput ? hooks.error.errorMessage : ''}
          >
            <CheckboxGroup
              checkboxDataList={hooks.checkboxDataList}
              disabled={disabled}
              onChange={handleChange}
            />
          </InputArea>
        </Form>
      )}
    </>
  );
};

export default CheckboxForm;
