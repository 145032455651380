import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import { IconButton as MuiIconButton } from '@material-ui/core';
import { hex2rgba } from 'common/Utility';
import Icon from 'presentational/general/atoms/text/Icon';

export type IconButtonProps = {
  iconName: string;
  iconSize?: number;
  disabled?: boolean;
  backgroundColor?: string;
  iconColor?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => void;
  accentBasic: string;
  accentDark: string;
  textDark: string;
  disabledBasic: string;
};

type StyledIconButtonProps = {
  backgroundcolor: string;
  iconcolor: string;
  disabledLight: string;
};

const StyledIconButton = connect(({ color }: AppState) => ({
  disabledLight: color.disabledLight,
}))(styled(
  ({ backgroundcolor, iconcolor, disabledLight, dispatch, ...props }) => (
    <MuiIconButton {...props} />
  ),
)<StyledIconButtonProps>`
  /* base */
  &.MuiIconButton-root {
    background-color: ${({ backgroundcolor }: StyledIconButtonProps) =>
      backgroundcolor};
    padding: 0;
  }

  /* action */
  & .MuiTouchRipple-root {
    color: ${({ iconcolor }: StyledIconButtonProps) => iconcolor};
  }

  /* hover */
  &.MuiIconButton-root:hover {
    background-color: ${({ backgroundcolor }: StyledIconButtonProps) =>
      hex2rgba(backgroundcolor, 0.5)};
  }

  /* disabled */
  &.MuiIconButton-root.Mui-disabled {
    background-color: ${({ disabledLight }) => disabledLight};
  }
`);

/**
 * 【部品】【原子】【ボタン】アイコンボタン <IconButton>タグ
 * @param {string} iconName - アイコン名
 * @param {number} iconSize - アイコンのサイズ(px) ボタンはこれより大きくなります
 * @param {boolean} disabled - 非活性フラグ
 * @param {(
 *  e: React.MouseEvent<HTMLButtonElement | MouseEvent>
 *  ) => void} onClick - クリックイベント
 * @param {string} backgroundColor - 背景色
 * @param {string} iconColor - アイコン色
 * @param {string} accentBasic - アクセント色
 * @param {string} accentDark - アクセント色
 * @param {string} textDark - テキスト色
 * @param {string} disabledBasic - 非活性色
 */
const IconButton = ({
  iconName,
  iconSize = 16,
  disabled = false,
  onClick,
  accentBasic,
  accentDark,
  textDark,
  disabledBasic,
  backgroundColor = accentBasic,
  iconColor = accentDark,
}: IconButtonProps) => {
  let hoverColor = hex2rgba(textDark, 0.5);
  if (disabled) {
    hoverColor = disabledBasic;
  } else if (iconColor) {
    hoverColor = hex2rgba(iconColor, 0.5);
  }
  return (
    <StyledIconButton
      disabled={disabled}
      onClick={onClick}
      backgroundcolor={backgroundColor}
      iconcolor={iconColor}
    >
      <Icon
        iconColor={disabled ? disabledBasic : iconColor || textDark}
        hoverColor={hoverColor}
        iconSize={iconSize}
      >
        {iconName}
      </Icon>
    </StyledIconButton>
  );
};

export default connect(({ color }: AppState) => ({
  accentBasic: color.accentBasic,
  accentDark: color.accentDark,
  textDark: color.textDark,
  disabledBasic: color.disabledBasic,
}))(IconButton);
