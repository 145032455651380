import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from 'presentational/general/atoms/button/Button';
import * as TYPES from 'common/Types';
import IconButton from 'presentational/general/atoms/button/IconButton';
import { ButtonColorType } from 'duck/color/State';
import { AppState } from 'duck/Reducer';

export type ButtonsProps = {
  text: string;
  iconName?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => void;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  focusBackgroundColor?: string;
};

export type DialogProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
  title: string;
  buttons?: ButtonsProps[];
  buttonBackgroundColorMain: ButtonColorType;
  children: React.ReactNode;
}>;

const StyledDialogTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    padding: 10px 15px;
  }
  .MuiTypography-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .MuiButtonBase-root {
    height: fit-content;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 10px 20px;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }
  button {
    margin: 0 45px;
  }
`;

/**
 * 【部品】【原子】【その他】画像 <Dialog>タグ
 * @param {boolean} open - 表示フラグ
 * @param {boolean} showCloseButton - 閉じるボタン表示
 * @param {string} title - タイトル
 * @param {(e: object, reason: string) => void} onClose - クローズイベント
 * @param {React.ReactNode} children - 表示内容
 * @param {{
    text: string;
    iconName?: string;
    disabled?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => void;
  }[]} buttons - ボタン配列
 */
const Dialog = ({
  open,
  title,
  onClose,
  children,
  buttons,
  showCloseButton = false,
  buttonBackgroundColorMain,
}: DialogProps) => {
  return (
    <MuiDialog open={open} onClose={onClose} fullWidth>
      <StyledDialogTitle>
        {title}
        {showCloseButton && (
          <IconButton
            iconName="close"
            backgroundColor="transparent"
            onClick={onClose}
          />
        )}
      </StyledDialogTitle>
      <StyledDialogContent>{children}</StyledDialogContent>
      {buttons && (
        <StyledDialogActions>
          {buttons.map((button) => {
            return (
              <Button
                key={button.text}
                buttonType={TYPES.BUTTON_TYPES.ROUND}
                iconName={button.iconName}
                disabled={button.disabled}
                onClick={button.onClick}
                backgroundColor={
                  button.backgroundColor
                    ? button.backgroundColor
                    : buttonBackgroundColorMain.backgroundColor
                }
                hoverBackgroundColor={
                  button.hoverBackgroundColor
                    ? button.hoverBackgroundColor
                    : buttonBackgroundColorMain.hoverBackgroundColor
                }
                focusBackgroundColor={
                  button.focusBackgroundColor
                    ? button.focusBackgroundColor
                    : buttonBackgroundColorMain.focusBackgroundColor
                }
                width={120}
              >
                {button.text}
              </Button>
            );
          })}
        </StyledDialogActions>
      )}
    </MuiDialog>
  );
};

export default connect(({ color }: AppState) => ({
  buttonBackgroundColorMain: color.buttonBackgroundColorMain,
}))(Dialog);
