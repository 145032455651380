import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import Header, {
  height as headerHeight,
} from 'presentational/custom/organisms/area/Header';
import ContentsArea from 'presentational/general/organisms/area/ContentsArea';
import Footer, {
  height as footerHeight,
} from 'presentational/custom/organisms/area/Footer';

const Wrap = styled.div`
  padding-top: ${headerHeight}px;
`;

export type CommonTemplateProps = PropsWithChildren<{
  device: TYPES.DEVICE_TYPES;
  headerColor?: string;
  headerText?: string;
  footerColor?: string;
  footerText?: string;
}>;

const margin = headerHeight + footerHeight;

/**
 * 【画面】GC000_共通レイアウト
 * @param {DEVICE_TYPES} device - 表示デバイスの種類
 * @param {React.ReactNode} children - 画面内容
 */
const CommonTemplate = ({
  device,
  headerColor,
  footerColor,
  footerText,
  children,
}: CommonTemplateProps) => {
  return (
    <>
      <Header
        device={device}
        backgroundColor={headerColor}
        src="/api/contents/image/logo.svg"
      />
      <Wrap>
        <ContentsArea device={device} heightMargin={margin}>
          {children}
        </ContentsArea>
      </Wrap>
      <Footer device={device} text={footerText} backgroundColor={footerColor} />
    </>
  );
};

export default CommonTemplate;
