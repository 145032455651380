import React, { useState } from 'react';
import { connect } from 'react-redux';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import Loading from 'presentational/general/atoms/other/Loading';
import { APIError } from 'common/APIWrapper';
import { AppState } from 'duck/Reducer';
import { ButtonColorType } from 'duck/color/State';
import customManagerActions, {
  dispatchGetMembersAnswersProgress,
  dispatchGetMember,
} from 'duck/custom/manager/Actions';
import {
  CustomMembersAnswers,
  CustomMemberAnswers,
  MemberAnswersProgress,
  AnswerProgress,
  FormTypeEnum,
  TextFormTypeEnum,
  CustomMemberDetail,
} from 'oapi/api';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Form as APIForm } from 'duck/Types';
// eslint-disable-next-line max-len
import TextStringFieldForm from 'presentational/general/organisms/inputTemplateForm/TextStringFieldForm';
import Button from 'presentational/general/atoms/button/Button';
import { HandleSetAnswerProps } from 'presentational/general/templates/template/InputTemplate';
import * as TYPES from 'common/Types';
import { Link } from 'react-router-dom';

type ManagerMembersProgressesProps = {
  getMembersAnswersProgress: dispatchGetMembersAnswersProgress;
  getMember: dispatchGetMember;
  buttonBackgroundColorError: ButtonColorType;
  deviceType: TYPES.DEVICE_TYPES;
  isLoading: boolean;
  error?: APIError;
};

type clickMemberIdType = (sampleId: string) => Promise<boolean>;

const StyledTableHead = styled(TableHead)`
  background-color: #341f97;
`;

const TableHeadCell = styled(TableCell)`
  &.MuiTableCell-root {
    color: #fff;
  }
`;

const NotAnsweredTableRow = styled(TableRow)`
  background-color: #ff6b6b;
`;

const ConfirmedTableRow = styled(TableRow)`
  background-color: #8395a7;
  & .MuiTableCell-root {
    color: #c8d6e5;
  }
`;

const getProgress = (answers: MemberAnswersProgress[], id: string) => {
  if (answers === null) {
    return '未回答';
  }
  const ans = answers.find((answer) => answer.template_id === id);
  if (!ans) {
    return '未回答';
  }
  switch (ans.progress) {
    case AnswerProgress.ANSWERED:
      return '回答済';
    case AnswerProgress.CONFIRMED:
      return '確定';
    case AnswerProgress.TMPSAVED:
      return '一時保存';
    case AnswerProgress.PREVANSWERD:
      return '前回回答';
    default:
      return '未回答';
  }
};

const getLastAnsweredDate = (answers: MemberAnswersProgress[]) => {
  if (answers === null) {
    return '';
  }
  let lastDate = '';
  answers.forEach((ans) => {
    if (ans.answered_date > lastDate) {
      lastDate = ans.answered_date;
    }
  });
  return lastDate.slice(0, 16);
};

const generateTableCells = (
  member: CustomMemberAnswers,
  clickMemberId: clickMemberIdType,
) => {
  return (
    <>
      <TableCell component="th" scope="row">
        <Link
          to={`#${member.member_id}`}
          onClick={() => clickMemberId(member.member_id)}
        >
          {member.member_id}
        </Link>
      </TableCell>
      <TableCell>{member.sample_id}</TableCell>
      <TableCell>{getLastAnsweredDate(member.answers)}</TableCell>
      <TableCell>
        {getProgress(member.answers, '10000000000000000000000000000001')}
      </TableCell>
      <TableCell>
        {getProgress(member.answers, '10000000000000000000000000000002')}
      </TableCell>
      <TableCell>
        {getProgress(member.answers, '10000000000000000000000000000003')}
      </TableCell>
      <TableCell>
        {getProgress(member.answers, '10000000000000000000000000000004')}
      </TableCell>
      <TableCell>
        {getProgress(member.answers, '10000000000000000000000000000005')}
      </TableCell>
    </>
  );
};

const generateTableRow = (
  member: CustomMemberAnswers,
  clickMemberId: clickMemberIdType,
) => {
  if (!member.answers || member.answers.length === 0) {
    return (
      <NotAnsweredTableRow key={member.member_id + member.sample_id}>
        {generateTableCells(member, clickMemberId)}
      </NotAnsweredTableRow>
    );
  }
  if (member.answers[0].progress === AnswerProgress.CONFIRMED) {
    return (
      <ConfirmedTableRow key={member.member_id + member.sample_id}>
        {generateTableCells(member, clickMemberId)}
      </ConfirmedTableRow>
    );
  }
  return (
    <TableRow key={member.member_id + member.sample_id}>
      {generateTableCells(member, clickMemberId)}
    </TableRow>
  );
};

const sampleIdForm: APIForm = {
  id: 'sample_id',
  subscribe: 'SAMPLE ID',
  description: '',
  type: FormTypeEnum.Text,
  text_form: {
    type: TextFormTypeEnum.Text,
    validations: {
      values: [] as string[],
      messages: {},
    },
  },
  created_at: '',
  updated_at: '',
  is_required: false,
};

const ManagerMembersProgresses = ({
  getMember,
  getMembersAnswersProgress,
  buttonBackgroundColorError,
  deviceType,
  isLoading,
  error,
}: ManagerMembersProgressesProps) => {
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<CustomMembersAnswers | null>(null);
  const [sampleId, setSampleId] = useState('');
  const [member, setMember] = useState<CustomMemberDetail | null>(null);

  const onClickSearch = async () => {
    const sId = sampleId === '' ? undefined : sampleId;
    const res = await getMembersAnswersProgress(sId);
    if (res === null) {
      setIsError(true);
    } else {
      setData(res);
    }
  };

  const onClickMemberId = async (memberId: string) => {
    const m = await getMember(memberId);
    if (!m) {
      setIsError(true);
      setMember(null);
      return false;
    }
    setMember(m);
    return true;
  };

  return (
    <div>
      <TextStringFieldForm
        device={deviceType}
        form={sampleIdForm}
        disabled={false}
        handleSetAnswer={(answer: HandleSetAnswerProps) => {
          setSampleId(answer.values[0]);
        }}
        visible
      />
      <Button buttonType={TYPES.BUTTON_TYPES.SQUARE} onClick={onClickSearch}>
        検索
      </Button>
      {data && (
        <div>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <StyledTableHead>
                <TableRow>
                  <TableHeadCell>メンバーID</TableHeadCell>
                  <TableHeadCell>サンプルID</TableHeadCell>
                  <TableHeadCell>最終回答日</TableHeadCell>
                  <TableHeadCell>基本</TableHeadCell>
                  <TableHeadCell>細菌叢調査</TableHeadCell>
                  <TableHeadCell>うつ</TableHeadCell>
                  <TableHeadCell>睡眠</TableHeadCell>
                  <TableHeadCell>食事・運動</TableHeadCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {data.members.map((mem) =>
                  generateTableRow(mem, onClickMemberId),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      <Loading display={isLoading} />
      {error && (
        <Dialog
          open={isError}
          onClose={() => setIsError(false)}
          title="エラー"
          buttons={[
            {
              text: 'OK',
              onClick: () => setIsError(false),
              ...buttonBackgroundColorError,
            },
          ]}
        >
          {error.detail}
        </Dialog>
      )}
      <Dialog
        open={member !== null}
        onClose={() => setMember(null)}
        title="被検者情報"
        buttons={[
          {
            text: 'OK',
            onClick: () => setMember(null),
            ...buttonBackgroundColorError,
          },
        ]}
      >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <StyledTableHead>
              <TableRow>
                <TableHeadCell>項目</TableHeadCell>
                <TableHeadCell>内容</TableHeadCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {member && member.parent_id && member.id !== member.parent_id && (
                <TableRow>
                  <TableCell>PARENT ID</TableCell>
                  <TableCell component="th" scope="row">
                    <Link
                      to={`#${member.parent_id}`}
                      onClick={() => {
                        const id = member.parent_id ? member.parent_id : '';
                        onClickMemberId(id);
                      }}
                    >
                      {member.parent_id}
                    </Link>
                  </TableCell>
                  <TableCell>{}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>{member && member.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>EMAIL</TableCell>
                <TableCell>{member && member.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>関係</TableCell>
                <TableCell>{member && member.identification}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>名前</TableCell>
                <TableCell>
                  {member && `${member.last_name} ${member.first_name}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>名前(読み)</TableCell>
                <TableCell>
                  {member &&
                    `${member.last_name_kana} ${member.first_name_kana}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
    </div>
  );
};

export default connect(
  ({ customManager, color, common }: AppState) => ({
    isLoading: customManager.isLoading,
    error: customManager.error,
    buttonBackgroundColorError: color.buttonBackgroundColorError,
    deviceType: common.deviceType,
  }),
  {
    getMembersAnswersProgress: customManagerActions.getMembersAnswersProgress,
    getMember: customManagerActions.getMember,
  },
)(ManagerMembersProgresses);
