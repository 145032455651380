import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import { getDeviceOs } from 'common/Utility';
import ProgresBar, {
  ProgresBarProps,
} from 'presentational/general/molecules/form/ProgresBar';
import LockedFooter from 'presentational/general/organisms/area/LockedFooter';
import Button from 'presentational/general/atoms/button/Button';
import { AppState } from 'duck/Reducer';

const GridArea = styled(Grid)`
  padding: 10px 0;
`;

const ProgresBarGrid = styled(Grid)`
  display: flex;
  margin-left: auto;
  padding: 12px;
`;

const ButtonGridMb = styled(Grid)`
  text-align: center;
  padding: 12px 0;
`;

const ButtonGridPc = styled(Grid)`
  text-align: right;
  padding: 12px;
`;

export type InputFooterProps = {
  device: TYPES.DEVICE_TYPES;
  enableTemporarySave?: boolean;
  onClickTemporarySave?: (
    e: React.MouseEvent<HTMLButtonElement | MouseEvent>,
  ) => void;
  warningBasic: string;
  warningDark: string;
  warningLight: string;
  surfaceDark: string;
} & ProgresBarProps;

const focuscheckTypes = [
  'text',
  'search',
  'tel',
  'url',
  'email',
  'password',
  'number',
];

const focusInput = (event: Event): boolean => {
  const osType = getDeviceOs();
  if (
    osType !== TYPES.DEVICE_OS_TYPES.IPHONE &&
    osType !== TYPES.DEVICE_OS_TYPES.ANDROID
  ) {
    return false;
  }
  if (event.target === null) {
    return false;
  }
  const element = event.target as Element;
  if (element.tagName !== 'INPUT' && element.tagName !== 'TEXTAREA') {
    return false;
  }
  if (element.tagName === 'INPUT') {
    const eleType = element.getAttribute('type');
    if (eleType === null || focuscheckTypes.indexOf(eleType) === -1) {
      return false;
    }
  }
  return true;
};

/**
 * 【部品】【有機体】【エリア】入力フッター <InputFooter>タグ
 * @param {DEVICE_TYPES} device - 表示デバイスの種類
 * @param {boolean} enableTemporarySave - 一時保存ボタンの表示フラグ
 * @param {(
 *  e: React.MouseEvent<HTMLButtonElement | MouseEvent>
 *  ) => void} onClickTemporarySave - 一時保存ボタンイベント
 * @param {{
 *  panelType: TYPES.PANEL_TYPES;
 *  to: string;
 *  }[]} panels - パネル配列
 * @param {number} displayingIdx - 表示中パネルのインデックス
 * @param {(idx: number) => void} setIdx - クリックされたidxを取得する
 */
const InputFooter = ({
  device,
  enableTemporarySave = false,
  onClickTemporarySave,
  panels,
  setIdx,
  displayingIdx,
  warningBasic,
  warningDark,
  warningLight,
  surfaceDark,
}: InputFooterProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const focusInEventListener = (e: Event) => {
    if (!focusInput(e)) {
      return;
    }
    setIsVisible(false);
  };
  const focusOutEventListener = () => {
    setIsVisible(true);
  };
  useEffect(() => {
    window.document.addEventListener('DOMFocusIn', focusInEventListener);
    window.document.addEventListener('DOMFocusOut', focusOutEventListener);
    return () => {
      window.document.removeEventListener('DOMFocusIn', focusInEventListener);
      window.document.removeEventListener('DOMFocusOut', focusOutEventListener);
    };
  }, []);

  if (!isVisible) {
    return null;
  }
  if (device === TYPES.DEVICE_TYPES.MOBILE) {
    return (
      <LockedFooter backgroundColor={surfaceDark}>
        <GridArea container>
          <Grid item xs={enableTemporarySave ? 8 : 12}>
            <div>
              <ProgresBar
                panels={panels}
                displayingIdx={displayingIdx}
                setIdx={setIdx}
              />
            </div>
          </Grid>
          {enableTemporarySave && (
            <ButtonGridMb item xs={4}>
              <Button
                buttonType={TYPES.BUTTON_TYPES.ROUND}
                backgroundColor={warningBasic}
                hoverBackgroundColor={warningDark}
                focusBackgroundColor={warningLight}
                onClick={onClickTemporarySave}
              >
                一時保存
              </Button>
            </ButtonGridMb>
          )}
        </GridArea>
      </LockedFooter>
    );
  }
  return (
    <LockedFooter backgroundColor={surfaceDark}>
      <Grid container>
        <ProgresBarGrid item xs={enableTemporarySave ? 6 : 12}>
          <ProgresBar
            panels={panels}
            displayingIdx={displayingIdx}
            setIdx={setIdx}
          />
        </ProgresBarGrid>
        {enableTemporarySave && (
          <ButtonGridPc item xs={6}>
            <Button
              buttonType={TYPES.BUTTON_TYPES.ROUND}
              backgroundColor={warningBasic}
              hoverBackgroundColor={warningDark}
              focusBackgroundColor={warningLight}
              onClick={onClickTemporarySave}
            >
              一時保存
            </Button>
          </ButtonGridPc>
        )}
      </Grid>
    </LockedFooter>
  );
};

export default connect(({ color }: AppState) => ({
  warningBasic: color.warningBasic,
  warningDark: color.warningDark,
  warningLight: color.warningLight,
  surfaceDark: color.surfaceDark,
}))(InputFooter);
