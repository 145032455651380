import React, { useState } from 'react';
import styled from 'styled-components';
import { useTextStringField } from 'hooks';
import { isDisabledInputList, isDisplayError } from 'common/Utility';
import * as TYPES from 'common/Types';
import { AppState } from 'duck/Reducer';
import { ButtonColorType } from 'duck/color/State';
import { connect } from 'react-redux';
import { CommonState } from 'duck/common/State';
import { Setting } from 'duck/manager/setting/State';
import Form from 'presentational/general/molecules/form/Form';
import InputArea from 'presentational/general/molecules/form/InputArea';
import TextField from 'presentational/general/atoms/basicInput/TextField';
import Panel from 'presentational/general/organisms/area/Panel';
import Text from 'presentational/general/atoms/text/Text';
import Button from 'presentational/general/atoms/button/Button';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import useReactRouter from 'use-react-router';
import CONSTS from 'common/Consts';
import authActions from 'duck/client/auth/Actions';
import { APIError } from 'common/APIWrapper';
import Loading from 'presentational/general/atoms/other/Loading';

export type InputRegistEmailConfirmationCodeProps = {
  error?: APIError;
  common: CommonState;
  setting: Setting;
  buttonBackgroundColorMain: ButtonColorType;
  buttonBackgroundColorError: ButtonColorType;
  confirm: (code: string) => Promise<boolean>;
  isLoading: boolean;
};

const ButtonArea = styled.div`
  padding: 20px 0 40px 0;
  text-align: center;
  button {
    margin: 0 5px;
  }
`;

const validatorRules = {
  emailConfirmationCode: ['required', 'regex:/^\\S+$/'],
  emailConfirmationMessage: {
    regex: '確認コードに不要なスペースが含まれています',
  },
};

/**
 * 【画面】GC023_メールアドレス登録確認コード入力
 * @param {CommonState} common 共通Redux
 * @param {ButtonColorType} buttonBackgroundColorMain ボタン用のカラー
 */
const InputRegistEmailConfirmationCode = ({
  common,
  setting,
  buttonBackgroundColorMain,
  buttonBackgroundColorError,
  confirm,
  isLoading,
  error,
}: InputRegistEmailConfirmationCodeProps) => {
  const { history } = useReactRouter();
  const [hasError, setHasError] = useState(false);
  const [hasSucceed, setHasSucceed] = useState(false);

  // メールアドレス確認コード
  const emailConfirmationCode = useTextStringField(
    '',
    false,
    validatorRules.emailConfirmationCode,
    validatorRules.emailConfirmationMessage,
  );
  // 送信ボタン
  const buttonDisabledInputList = [
    {
      hasInput: emailConfirmationCode.hasInput,
      isError: emailConfirmationCode.error.isError,
    },
  ];
  const buttonOnClick = async () => {
    const isSucceed = await confirm(emailConfirmationCode.value);
    if (isSucceed) {
      setHasSucceed(true);
    } else {
      setHasError(true);
    }
  };

  return (
    <BeforeLoginTemplate
      device={common.deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <Panel device={common.deviceType}>
        <Text>メールアドレス確認コードを入力してください。</Text>
        <Form
          device={common.deviceType}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question="メールアドレス確認コード"
        >
          <InputArea
            error={isDisplayError(
              emailConfirmationCode.hasInput,
              emailConfirmationCode.error.isError,
            )}
            errorMessage={emailConfirmationCode.error.errorMessage}
          >
            <TextField
              id="emailConfirmationCode"
              value={emailConfirmationCode.value}
              onChange={emailConfirmationCode.onChange}
              onBlur={emailConfirmationCode.onBlur}
              error={isDisplayError(
                emailConfirmationCode.hasInput,
                emailConfirmationCode.error.isError,
              )}
            />
          </InputArea>
        </Form>
        <ButtonArea>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            onClick={() => {
              history.goBack();
            }}
            {...buttonBackgroundColorError}
          >
            戻る
          </Button>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            disabled={isDisabledInputList(buttonDisabledInputList)}
            onClick={buttonOnClick}
            {...buttonBackgroundColorMain}
          >
            送信
          </Button>
        </ButtonArea>
      </Panel>
      <Dialog
        open={hasSucceed}
        onClose={() => {
          setHasSucceed(false);
          history.push(CONSTS.PATH_INPUT_SUBJECT);
        }}
        title="利用者情報登録"
        buttons={[
          {
            text: 'OK',
            onClick: () => {
              setHasSucceed(false);
              history.push(CONSTS.PATH_INPUT_SUBJECT);
            },
            ...buttonBackgroundColorMain,
          },
        ]}
      >
        利用者情報が登録されました。
      </Dialog>
      <Dialog
        open={hasError}
        onClose={() => {
          setHasError(false);
        }}
        title="確認コードエラー"
        buttons={[
          {
            text: 'OK',
            onClick: () => {
              setHasError(false);
            },
            backgroundColor: buttonBackgroundColorMain.backgroundColor,
            hoverBackgroundColor:
              buttonBackgroundColorMain.hoverBackgroundColor,
            focusBackgroundColor:
              buttonBackgroundColorMain.focusBackgroundColor,
          },
        ]}
      >
        {error && error.detail}
      </Dialog>
      <Loading display={isLoading} />
    </BeforeLoginTemplate>
  );
};

export default connect(
  ({ common, color, auth, managerSetting }: AppState) => ({
    common,
    setting: managerSetting.setting,
    buttonBackgroundColorMain: color.buttonBackgroundColorMain,
    buttonBackgroundColorError: color.buttonBackgroundColorError,
    isLoading: auth.isLoading,
    error: auth.error,
  }),
  {
    confirm: authActions.confirm,
  },
)(InputRegistEmailConfirmationCode);
