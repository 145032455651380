import React, { PropsWithChildren, ChangeEvent } from 'react';
import styled from 'styled-components';
import InlineArea from 'presentational/general/atoms/other/InlineArea';
import CheckboxGroup, {
  CheckboxDataListProps,
} from 'presentational/general/atoms/basicInput/CheckboxGroup';

type TermsProps = PropsWithChildren<{
  checkboxDataList: CheckboxDataListProps;
  inlineWidth?: number;
  inlineHeight?: number;
  required?: boolean;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}>;

const StyledDiv = styled.div`
  width: 100%;
`;

const CheckBoxArea = styled.div`
  margin-top: 3px;
`;

/**
 * 【部品】【原子】【複合入力】規約
 * @param {ReactNode} children    - インラインエリア内のコンテンツ
 * @param {boolean} checked       - チェクボックスのチェック状態
 * @param {string} checkboxLabel  - チェックボックスに設定するラベル
 * @param {number} inlineWidth    - インラインエリアの幅
 * @param {number} inlineHeight   - インラインエリアの高さ
 * @param {boolean} required      - 入力必須か否か
 * @param {boolean} disabled      - チェック可能か否か
 * @param {(checked: boolean, errorMessage: string) => void} onChange - 値変更時のイベントハンドラ
 */
const Terms = ({
  children,
  checkboxDataList,
  inlineWidth,
  inlineHeight,
  disabled = false,
  onChange,
}: TermsProps) => {
  return (
    <StyledDiv>
      <InlineArea width={inlineWidth} height={inlineHeight}>
        {children}
      </InlineArea>
      <CheckBoxArea>
        <CheckboxGroup
          checkboxDataList={checkboxDataList}
          onChange={onChange}
          disabled={disabled}
        />
      </CheckBoxArea>
    </StyledDiv>
  );
};

export default Terms;
