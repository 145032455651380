import React from 'react';
import useReactRouter from 'use-react-router';
import styled from 'styled-components';
import { AppState } from 'duck/Reducer';
import { Setting } from 'duck/manager/setting/State';
import { connect } from 'react-redux';
import Button from 'presentational/general/atoms/button/Button';
import BackPageTitle from 'presentational/general/molecules/other/BackPageTitle';
import Form from 'presentational/general/molecules/form/Form';
import TemplatePanel from 'presentational/general/organisms/area/TemplatePanel';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import Question from 'presentational/general/atoms/text/Question';
import templateActions from 'duck/client/template/Actions';
import { ButtonColorType } from 'duck/color/State';
import CONSTS from 'common/Consts';
import * as TYPES from 'common/Types';
import { CustomMemberDetail } from 'oapi';

const PanelArea = styled.div`
  & div:first-child {
    margin-top: 0;
  }
`;

const ButtonArea = styled.div`
  margin: 50px auto 85px auto;
  display: flex;
`;

const Flex1 = styled.div`
  flex: 1;
`;

const ButtonSplit = styled.div`
  width: 20px;
`;

const InputHeadline = styled.div`
  margin-top: 25px;
`;

export type ViewSubjectProps = {
  sampleId: string;
  deviceType: TYPES.DEVICE_TYPES;
  self?: CustomMemberDetail;
  newMember?: CustomMemberDetail;
  isLoading: boolean;
  buttonBackgroundColorSuccess: ButtonColorType;
  buttonBackgroundColorError: ButtonColorType;
  setting: Setting;
};

/**
 * 【画面】GC028_アンケート参照
 * @param {CommonState} common 共通(Redux)
 */
const ViewSubject = ({
  deviceType,
  newMember,
  sampleId,
  buttonBackgroundColorSuccess,
  buttonBackgroundColorError,
  setting,
}: ViewSubjectProps) => {
  const { history } = useReactRouter();
  if (!newMember) {
    history.push(CONSTS.PATH_INPUT_SUBJECT);
    return null;
  }
  const handleBackIconClick = () => {
    history.push(CONSTS.PATH_INPUT_SUBJECT);
  };

  const handleCancelClick = () => {
    history.push(CONSTS.PATH_INPUT_SUBJECT);
  };
  const handleConfirmClick = async () => {
    if (!newMember) {
      return;
    }
    const member = { ...newMember };
    member.sample_id = sampleId;
    member.sample_ids = [sampleId];
    history.push(CONSTS.PATH_INPUT_STUDY_PARTICIPATION_CONSENT);
  };
  return (
    <BeforeLoginTemplate
      device={deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <BackPageTitle device={deviceType} onClick={handleBackIconClick}>
        被検者情報登録確認
      </BackPageTitle>
      <PanelArea>
        <TemplatePanel
          device={deviceType}
          templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <Form
            device={deviceType}
            required
            question="あなた（利用者。いま情報を入力していただいている方）は、被検者（検査を受ける方）とどのような関係ですか"
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
          >
            <div>{newMember.identification}</div>
          </Form>
          {newMember.identification === '代諾者' && (
            <>
              <Form
                device={deviceType}
                required
                question="被検者からみた、あなた（利用者）との関係を選択、記入してください"
                templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
              >
                <div>{newMember.relation}</div>
              </Form>
              <Form
                device={deviceType}
                question="続柄"
                templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
              >
                <div>{newMember.relation_detail}</div>
              </Form>
            </>
          )}
          <InputHeadline>
            <Question>[ 被検者情報 ]</Question>
          </InputHeadline>
          <Form
            device={deviceType}
            required
            question="姓"
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
          >
            <div>{newMember.last_name}</div>
          </Form>
          <Form
            device={deviceType}
            required
            question="名"
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
          >
            <div>{newMember.first_name}</div>
          </Form>
          <Form
            device={deviceType}
            required
            question="姓（フリガナ）"
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
          >
            <div>{newMember.last_name_kana}</div>
          </Form>
          <Form
            device={deviceType}
            required
            question="名（フリガナ）"
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
          >
            <div>{newMember.first_name_kana}</div>
          </Form>
          <Form
            device={deviceType}
            required
            question="生年月日"
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
          >
            <div>{newMember.birthdate}</div>
          </Form>
          <Form
            device={deviceType}
            required
            question="郵便番号"
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
          >
            <div>{newMember.zip}</div>
          </Form>
          <Form
            device={deviceType}
            required
            question="住所１（都道府県・市区町村）"
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
          >
            <div>{newMember.address}</div>
          </Form>
          <Form
            device={deviceType}
            required
            question="住所２（番地・建物名・部屋番号）"
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
          >
            <div>{newMember.building}</div>
          </Form>
          <Form
            device={deviceType}
            required
            question="電話番号"
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
          >
            <div>{newMember.phone_number}</div>
          </Form>
        </TemplatePanel>
      </PanelArea>
      <ButtonArea>
        <Flex1 />
        <Button
          buttonType={TYPES.BUTTON_TYPES.ROUND}
          onClick={handleCancelClick}
          {...buttonBackgroundColorError}
        >
          修正
        </Button>
        <ButtonSplit />
        <Button
          buttonType={TYPES.BUTTON_TYPES.ROUND}
          onClick={handleConfirmClick}
          {...buttonBackgroundColorSuccess}
        >
          確定
        </Button>
        <Flex1 />
      </ButtonArea>
    </BeforeLoginTemplate>
  );
};

export default connect(
  ({ common, sampleidInfo, members, color, managerSetting }: AppState) => ({
    deviceType: common.deviceType,
    sampleId: sampleidInfo.sampleId,
    self: members.self,
    newMember: members.newMember,
    isLoading: members.isLoading,
    buttonBackgroundColorSuccess: color.buttonBackgroundColorSuccess,
    buttonBackgroundColorError: color.buttonBackgroundColorError,
    setting: managerSetting.setting,
  }),
  {
    setTemplate: templateActions.setTemplate,
  },
)(ViewSubject);
