import { Dispatch } from 'redux';
import actionCreatorFactory from 'typescript-fsa';
import APIWrapper, { APIError, DefaultApiFp } from 'common/APIWrapper';
import store from 'Store';

const actionCreator = actionCreatorFactory();

const { customApiClientCheckSampleIdKitIdGet } = DefaultApiFp();

export const actions = {
  checkSampleId: actionCreator.async<
    {},
    { success: boolean; version: string },
    APIError
  >('CHECK_SAMPLE_ID'),
  setSampleId: actionCreator<{ sampleId: string }>('SET_SAMPLE_ID'),
  setSampleIdAndKitId: actionCreator<{ sampleId: string; kitId: string }>(
    'SET_SAMPLE_ID_KIT_ID',
  ),
  setSampleIdVersion: actionCreator<{ sampleIdVersion: string }>(
    'SET_SAMPLE_ID_VERSION',
  ),
  clearError: actionCreator<void>('CLEAR_ERROR_SAMPELID_INFO'),
};

/**
 * サンプルIDとキットIDの検証を行う
 * 成功した場合はtrue, 失敗はfalseを返す
 */
export const checkSampleId = () => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    const params = {};
    try {
      dispatch(actions.checkSampleId.started(params));
      const { sampleId, kitId } = store.getState().sampleidInfo;
      const res = await APIWrapper(
        await customApiClientCheckSampleIdKitIdGet(sampleId, kitId),
      );
      dispatch(actions.checkSampleId.done({ params, result: res.data.data }));
      return true;
    } catch (e) {
      dispatch(actions.checkSampleId.failed({ params, error: e }));
      return false;
    }
  };
};

export const {
  setSampleId,
  setSampleIdAndKitId,
  setSampleIdVersion,
  clearError,
} = actions;
export default {
  setSampleId,
  setSampleIdAndKitId,
  setSampleIdVersion,
  checkSampleId,
  clearError,
};
