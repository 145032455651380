import React, { useState } from 'react';
import styled from 'styled-components';
import { useTextStringField } from 'hooks';
import { isDisabledInputList, isDisplayError } from 'common/Utility';
import * as TYPES from 'common/Types';
import CONSTS from 'common/Consts';
import { AppState } from 'duck/Reducer';
import { connect } from 'react-redux';
import AuthState from 'duck/client/auth/State';
import { Setting } from 'duck/manager/setting/State';
import Form from 'presentational/general/molecules/form/Form';
import InputArea from 'presentational/general/molecules/form/InputArea';
import TextField from 'presentational/general/atoms/basicInput/TextField';
import Panel from 'presentational/general/organisms/area/Panel';
import Text from 'presentational/general/atoms/text/Text';
import Button from 'presentational/general/atoms/button/Button';
import CommonTemplate from 'presentational/custom/templates/CommonTemplate';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import useReactRouter from 'use-react-router';
import BackPageTitle from 'presentational/general/molecules/other/BackPageTitle';
import Loading from 'presentational/general/atoms/other/Loading';
import authActions from 'duck/client/auth/Actions';
import { CustomMemberDetail } from 'oapi/api';

export type UpdateEmailProps = {
  deviceType: TYPES.DEVICE_TYPES;
  auth: AuthState;
  changeEmail: (email: string) => Promise<boolean>;
  setting: Setting;
  self?: CustomMemberDetail;
};

const ButtonArea = styled.div`
  padding: 20px 0;
  text-align: center;
`;

/**
 * 【画面】GC020_メールアドレス更新
 * @param {CommonState} common 共通Redux
 * @param {AuthState} auth 認証(Redux)
 */
const UpdateEmail = ({
  deviceType,
  auth,
  changeEmail,
  setting,
  self,
}: UpdateEmailProps) => {
  const { history } = useReactRouter();

  // メールアドレス確認コード送信完了ダイアログ
  const [isUpdateEmailDialogOpen, setIsUpdateEmailDialogOpen] = useState(false);
  const [isNotUpdateEmailDialogOpen, setIsNotUpdateEmailDialogOpen] = useState(
    false,
  );
  const [isError, setIsError] = useState(false);
  const updateEmailDialogOnClose = () => {
    setIsUpdateEmailDialogOpen(false);
    history.push(CONSTS.PATH_INPUT_UPDATE_EMAIL_CONFIRMATION_CODE);
  };
  const updateEmailDialogOkOnClick = () => {
    setIsUpdateEmailDialogOpen(false);
    history.push(CONSTS.PATH_INPUT_UPDATE_EMAIL_CONFIRMATION_CODE);
  };
  const updateEmailDialogButtons = [
    {
      text: 'OK',
      onClick: updateEmailDialogOkOnClick,
    },
  ];

  const notUpdateEmailDialogOnClose = () => {
    setIsNotUpdateEmailDialogOpen(false);
    history.push(CONSTS.PATH_UPDATE_USER);
  };
  const notUpdateEmailDialogButtons = [
    {
      text: 'OK',
      onClick: notUpdateEmailDialogOnClose,
    },
  ];

  // エラーダイアログ
  const errorDialogOnClose = () => {
    setIsError(false);
  };
  const errorDialogOkOnClick = () => {
    setIsError(false);
  };
  const errorDialogButtons = [
    {
      text: 'OK',
      onClick: errorDialogOkOnClick,
    },
  ];

  // メールアドレス
  const email = useTextStringField(self && self.email ? self.email : '', true, [
    'required',
    'email',
  ]);

  // 戻る付きタイトルアイコンボタン
  const backIconOnClick = () => {
    history.push(CONSTS.PATH_UPDATE_USER);
  };

  // 設定ボタン
  const buttonOnClick = async () => {
    if (self && self.email === email.value && self.email_verified) {
      setIsNotUpdateEmailDialogOpen(true);
    } else if (await changeEmail(email.value)) {
      setIsUpdateEmailDialogOpen(true);
    } else {
      setIsError(true);
    }
  };

  const disabledInputList = [
    {
      hasInput: email.hasInput,
      isError: email.error.isError,
    },
  ];

  if (!self) {
    history.push(CONSTS.PATH_UPDATE_USER);
    return null;
  }

  return (
    <CommonTemplate
      device={deviceType}
      headerColor={setting.headerColor}
      footerColor={setting.footerColor}
      footerText={setting.footerText}
    >
      <BackPageTitle device={deviceType} onClick={backIconOnClick}>
        メールアドレス更新
      </BackPageTitle>
      <Panel device={deviceType}>
        <Text>新しいメールアドレスを設定してください。</Text>
        <Form
          device={deviceType}
          question="新メールアドレス"
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
        >
          <InputArea
            error={isDisplayError(email.hasInput, email.error.isError)}
            errorMessage={email.error.errorMessage}
          >
            <TextField
              id="email"
              width={250}
              value={email.value}
              onChange={email.onChange}
              onBlur={email.onBlur}
              error={isDisplayError(email.hasInput, email.error.isError)}
              inputProps={{ maxLength: 255 }}
            />
          </InputArea>
        </Form>
        <ButtonArea>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            disabled={isDisabledInputList(disabledInputList)}
            onClick={buttonOnClick}
          >
            設定
          </Button>
        </ButtonArea>
      </Panel>
      <Dialog
        open={isUpdateEmailDialogOpen}
        onClose={updateEmailDialogOnClose}
        title="メールアドレス確認コード送信完了"
        buttons={updateEmailDialogButtons}
      >
        <Text>
          メールアドレスを変更しました。
          <br />
          登録メールアドレス宛にメールアドレス確認コードが送信されました。
          <br />
          <br />
          ※５分以内にメールが届かない場合は、メールアドレスが間違っている可能性があるので、
          戻るボタンを押して再度メールアドレスの入力をお願いします。
        </Text>
      </Dialog>
      <Dialog
        open={isNotUpdateEmailDialogOpen}
        onClose={notUpdateEmailDialogOnClose}
        title="メールアドレス変更"
        buttons={notUpdateEmailDialogButtons}
      >
        <Text>
          現在設定されているメールアドレスと同じため
          <br />
          メールアドレスの変更を行いませんでした。
        </Text>
      </Dialog>

      <Dialog
        open={isError}
        onClose={errorDialogOnClose}
        title="メールアドレス変更失敗"
        buttons={errorDialogButtons}
      >
        <Text>メールアドレスの変更に失敗しました。</Text>
      </Dialog>
      <Loading display={auth.isLoading} />
    </CommonTemplate>
  );
};

export default connect(
  ({ common, color, auth, members, managerSetting }: AppState) => ({
    deviceType: common.deviceType,
    buttonBackgroundColorMain: color.buttonBackgroundColorMain,
    auth,
    self: members.self,
    setting: managerSetting.setting,
  }),
  {
    changeEmail: authActions.changeEmail,
  },
)(UpdateEmail);
