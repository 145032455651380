import React, { useState } from 'react';
import useReactRouter from 'use-react-router';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import templateActions, {
  FetchTemplateParams,
} from 'duck/client/template/Actions';
import groupsActions from 'duck/client/groups/Actions';
import customGroupsActions from 'duck/custom/groups/Actions';
import GroupState, { Group } from 'duck/client/groups/State';
// import TemplateState from 'duck/client/template/State';
import CONSTS from 'common/Consts';
import { APIError } from 'common/APIWrapper';
import TemplateRow from 'presentational/general/molecules/other/TemplateRow';
import Category from 'presentational/general/molecules/other/Category';
import LockedFooter from 'presentational/general/organisms/area/LockedFooter';
import Button from 'presentational/general/atoms/button/Button';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import Loading from 'presentational/general/atoms/other/Loading';
import ProgressLabel from 'presentational/general/atoms/label/ProgressLabel';
import Text from 'presentational/general/atoms/text/Text';
import Checkbox from 'presentational/general/atoms/basicInput/Checkbox';
import { StyledFormControlLabel } from 'presentational/general/atoms/basicInput/CheckboxGroup';
import { CustomMemberDetail } from 'oapi/api';

export type ViewGroupProps = {
  device: TYPES.DEVICE_TYPES;
  groups: GroupState;
  groupsError?: APIError;
  templateError?: APIError;
  successBasic: string;
  successDark: string;
  successLight: string;
  surfaceDark: string;
  errorBasic: string;
  errorDark: string;
  errorLight: string;
  mainBasic: string;
  mainDark: string;
  mainLight: string;
  customId: string;
  confirmAnswers: (groupId: string, customId: string) => Promise<boolean>;
  fetchAll: (params: FetchTemplateParams) => Promise<boolean>;
  clearTemplateError: () => void;
  clearGroupsError: () => void;
  isLoading: boolean;
  version?: string;
  selectedMember?: CustomMemberDetail;
};

type StyledLockedFooterProps = {
  device: TYPES.DEVICE_TYPES;
};

const StyledLockedFooter = styled(LockedFooter)<StyledLockedFooterProps>`
  width: calc(100% - 20px * 2);
  padding: 10px 20px;
  display: flex;
  justify-content: ${({ device }: StyledLockedFooterProps) =>
    device === TYPES.DEVICE_TYPES.PC ? 'center' : 'flex-end'};
`;

type FooterAreaProps = {
  device: TYPES.DEVICE_TYPES;
};

const FooterArea = styled.div<FooterAreaProps>`
  display: flex;
  justify-content: flex-end;
  ${({ device }: FooterAreaProps) =>
    device === TYPES.DEVICE_TYPES.PC && 'width: 1000px;'};
`;

const ConfirmCheckArea = styled.div`
  text-align: center;
  padding-top: 15px;
`;

const CustomArea = styled.div`
  margin-top: 7px;
  margin-left: 10px;
`;

/**
 * 【画面】GB001_テンプレート一覧
 * @param {Array<Groups>} groups - グループ(Redux connect)
 * @param {DEVICE_TYPES} device - 表示デバイス区分
 */
const ViewGroup = ({
  groups,
  customId,
  device,
  successBasic,
  successDark,
  successLight,
  errorBasic,
  errorDark,
  errorLight,
  mainBasic,
  mainDark,
  mainLight,
  surfaceDark,
  confirmAnswers,
  fetchAll,
  isLoading,
  groupsError,
  version,
  selectedMember,
}: //  templateError,
ViewGroupProps) => {
  // テンプレート取得エラーダイアログ
  const { history } = useReactRouter();
  // 確定ダイアログ
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setSuccessDialogOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  /**
   * 確定ダイアログクローズイベント
   */
  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };
  /**
   * 成功ダイアログクローズイベント
   */
  const handleSeccessDialog = () => {
    setSuccessDialogOpen(false);
  };
  /**
   * 確定ダイアログ内キャンセルボタン押下イベント
   */
  const handleConfirmDialogCancelClick = () => {
    setConfirmDialogOpen(false);
  };

  const groupsData = groups.groups.filter((group) => {
    const versionNumber = Number(version?.charAt(0));
    if (versionNumber === CONSTS.SYMGRAM_VERSION_THREE) {
      return group.id === CONSTS.GROUP_ID_VERSION_THREE;
    }
    if (versionNumber === CONSTS.SYMGRAM_VERSION_FOUR) {
      return group.id === CONSTS.GROUP_ID_VERSION_FOUR;
    }
    return group.id === CONSTS.GROUP_ID_VERSION_ONE;
  });

  /**
   * 確定ダイアログ内確定ボタン押下イベント
   */
  const handleConfirmDialogConfirmClick = async () => {
    const results: Array<Promise<boolean>> = [];
    setConfirmDialogOpen(false);
    groupsData.forEach((group) => {
      results.push(confirmAnswers(group.id, customId));
    });
    const res = await Promise.all(results);
    const isFailed = res.find((isSucceed) => !isSucceed);
    if (isFailed === undefined) {
      setSuccessDialogOpen(true);
    } else {
      setIsError(true);
    }
  };
  const confirmDialogButtons = [
    {
      text: '戻る',
      backgroundColor: errorBasic,
      hoverBackgroundColor: errorDark,
      focusBackgroundColor: errorLight,
      onClick: handleConfirmDialogCancelClick,
    },
    {
      text: '回答送信',
      backgroundColor: successBasic,
      hoverBackgroundColor: successDark,
      focusBackgroundColor: successLight,
      disabled: !isConfirmed,
      onClick: handleConfirmDialogConfirmClick,
    },
  ];

  const successDialogButtons = [
    {
      text: 'OK',
      onClick: () => {
        history.go(0);
      },
    },
  ];

  const errorDialogButtons = [
    {
      text: 'OK',
      backgroundColor: mainBasic,
      hoverBackgroundColor: mainDark,
      focusBackgroundColor: mainLight,
      onClick: () => {
        setIsError(false);
      },
    },
  ];

  /**
   * テンプレート行クリックイベント
   * @param {string} groupId グループID
   * @param {string} templateId テンプレートID
   */
  const handleTemplateRowClick = async (
    groupId: string,
    templateId: string,
  ) => {
    const isSucceed = await fetchAll({
      groupId,
      templateId,
      customId,
    });
    return !isSucceed;
  };

  /**
   * フッター内確定ボタン押下イベント
   */
  const handleConfirmButtonClick = () => {
    setConfirmDialogOpen(true);
  };

  /**
   * 必須テンプレートが全件回答済か判定
   * @param {Group[]} checkGroups グループ
   */
  const answerdAllRequired = (checkGroups: Group[]): boolean => {
    let isAllAnswerd = true;
    checkGroups.forEach((group) => {
      group.categories.forEach((category) => {
        category.templates.forEach((t) => {
          if (t.is_required && t.progress !== TYPES.PROGRESS_TYPES.ANSWERED) {
            isAllAnswerd = false;
          }
        });
      });
    });
    return isAllAnswerd;
  };
  const control = (
    <Checkbox
      checked={isConfirmed}
      name="confirmed-check"
      value="confirmed"
      onChange={() => setIsConfirmed(!isConfirmed)}
    />
  );
  const isVersionFourGeneral =
    Number(version?.charAt(0)) === 4 &&
    !(
      selectedMember?.sample_id?.includes('U') ||
      selectedMember?.sample_id?.includes('V')
    );
  return (
    <>
      {groupsData.map((group) => {
        return group.categories.map((category) => {
          return (
            <Category key={category.name} title={category.name}>
              {category.templates.map((t, i) => {
                return (
                  <TemplateRow
                    key={t.id}
                    title={t.name}
                    to={
                      t.progress === TYPES.PROGRESS_TYPES.ANSWERED ||
                      t.progress === TYPES.PROGRESS_TYPES.CONFIRMED
                        ? CONSTS.PATH_VIEW_ENQUETE.replace(
                            ':groupId',
                            group.id,
                          ).replace(':templateId', t.id)
                        : CONSTS.PATH_INPUT_ENQUETE.replace(
                            ':groupId',
                            group.id,
                          ).replace(':templateId', t.id)
                    }
                    progressType={t.progress}
                    required={t.is_required}
                    checkError={() => handleTemplateRowClick(group.id, t.id)}
                    hiddenBorderTop={i !== 0}
                  />
                );
              })}
            </Category>
          );
        });
      })}
      {isVersionFourGeneral && (
        <CustomArea>
          <Text fontWeight="bold" color="#CB4B4B">
            任意アンケートにもできるだけご回答いただけますようお願いいたします
          </Text>
        </CustomArea>
      )}
      <StyledLockedFooter device={device} backgroundColor={surfaceDark}>
        <FooterArea device={device}>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            onClick={handleConfirmButtonClick}
            disabled={!answerdAllRequired(groupsData)}
            backgroundColor={successBasic}
            hoverBackgroundColor={successDark}
            focusBackgroundColor={successLight}
          >
            回答送信
          </Button>
        </FooterArea>
      </StyledLockedFooter>
      <Dialog
        open={isError}
        onClose={() => {
          setIsError(false);
        }}
        title="全体確定失敗"
        buttons={errorDialogButtons}
      >
        <Text>全体確定に失敗しました。</Text>
        <Text>{groupsError && groupsError.detail}</Text>
      </Dialog>
      <Dialog
        open={isConfirmDialogOpen}
        onClose={handleConfirmDialogClose}
        title="回答送信確認"
        buttons={confirmDialogButtons}
      >
        <Text>
          すべてのアンケート回答を確定し、回答送信しますか？
          <br />
        </Text>
        <ProgressLabel
          progressType={TYPES.PROGRESS_TYPES.ANSWERED}
          displayInline
        />
        <Text>&nbsp;以外は未回答扱いとなります。</Text>
        <br />
        <br />
        <Text fontWeight="bold" fontSize={16}>
          回答送信すると、回答内容は修正できません。
        </Text>
        <ConfirmCheckArea>
          <StyledFormControlLabel
            key="confirmed-check"
            control={control}
            label="上記について理解しました。"
          />
        </ConfirmCheckArea>
      </Dialog>
      <Dialog
        open={isSuccessDialogOpen}
        onClose={handleSeccessDialog}
        title="アンケート提出"
        buttons={successDialogButtons}
      >
        アンケートが提出されました。
      </Dialog>
      <Loading display={isLoading} />
    </>
  );
};

export default connect(
  ({ groups, template, color, customGroups, members }: AppState) => ({
    groups,
    isLoading:
      template.isLoading ||
      template.isUploading ||
      groups.isLoading ||
      customGroups.isLoading,
    groupsError: groups.error,
    templateError: template.error,
    successBasic: color.successBasic,
    successDark: color.successDark,
    successLight: color.successLight,
    surfaceDark: color.surfaceDark,
    errorBasic: color.errorBasic,
    errorDark: color.errorDark,
    errorLight: color.errorLight,
    mainBasic: color.mainBasic,
    mainDark: color.mainDark,
    mainLight: color.mainLight,
    selectedMember: members.selectedMember,
  }),
  {
    confirmAnswers: customGroupsActions.confirmAnswers,
    fetchAll: templateActions.fetchAll,
    clearTemplateError: templateActions.clearError,
    clearGroupsError: groupsActions.clearError,
  },
)(ViewGroup);
