import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actions } from 'duck/common/Actions';
import * as TYPES from 'common/Types';
import { getDeviceType } from 'common/Utility';
import CommonState from 'duck/common/State';

const initialCommonState: CommonState = {
  deviceType: getDeviceType(),
  isVirtualKeyboardDisplayed: false,
  colorCodeList: [
    {
      colorType: TYPES.COLOR_TYPES.MAIN,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_MAIN_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.MAIN,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_MAIN_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.MAIN,
      swatchType: TYPES.SWATCH_TYPES.DARK,
      colorCode: TYPES.COLOR_CODE_MAIN_DARK,
    },
    {
      colorType: TYPES.COLOR_TYPES.ACCENT,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_ACCENT_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.ACCENT,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_ACCENT_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.ACCENT,
      swatchType: TYPES.SWATCH_TYPES.DARK,
      colorCode: TYPES.COLOR_CODE_ACCENT_DARK,
    },
    {
      colorType: TYPES.COLOR_TYPES.ERROR,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_ERROR_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.ERROR,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_ERROR_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.ERROR,
      swatchType: TYPES.SWATCH_TYPES.MIDDLE_LIGHT,
      colorCode: TYPES.COLOR_CODE_ERROR_MIDDLE_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.ERROR,
      swatchType: TYPES.SWATCH_TYPES.DARK,
      colorCode: TYPES.COLOR_CODE_ERROR_DARK,
    },
    {
      colorType: TYPES.COLOR_TYPES.WARNING,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_WARNING_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.WARNING,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_WARNING_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.WARNING,
      swatchType: TYPES.SWATCH_TYPES.MIDDLE_LIGHT,
      colorCode: TYPES.COLOR_CODE_WARNING_MIDDLE_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.WARNING,
      swatchType: TYPES.SWATCH_TYPES.DARK,
      colorCode: TYPES.COLOR_CODE_WARNING_DARK,
    },
    {
      colorType: TYPES.COLOR_TYPES.SUCCESS,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_SUCCESS_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.SUCCESS,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_SUCCESS_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.SUCCESS,
      swatchType: TYPES.SWATCH_TYPES.MIDDLE_LIGHT,
      colorCode: TYPES.COLOR_CODE_SUCCESS_MIDDLE_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.SUCCESS,
      swatchType: TYPES.SWATCH_TYPES.DARK,
      colorCode: TYPES.COLOR_CODE_SUCCESS_DARK,
    },
    {
      colorType: TYPES.COLOR_TYPES.DISABLED,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_DISABLED_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.DISABLED,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_DISABLED_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.SURFACE,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_SURFACE_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.BACKGROUND,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_BACKGROUND_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.TEXT,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_TEXT_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.TEXT,
      swatchType: TYPES.SWATCH_TYPES.DARK,
      colorCode: TYPES.COLOR_CODE_TEXT_DARK,
    },
    {
      colorType: TYPES.COLOR_TYPES.FRAME,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_FRAME_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.FRAME,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_FRAME_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.CONCEPT,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_CONCEPT_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.CONCEPT,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_CONCEPT_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.CONCEPT,
      swatchType: TYPES.SWATCH_TYPES.DARK,
      colorCode: TYPES.COLOR_CODE_CONCEPT_DARK,
    },
  ],
  has401Error: false,
};

/**
 * Reducer
 */
export const commonReducer = reducerWithInitialState(initialCommonState)
  .case(actions.changeDeviceType, (state, deviceType) => {
    if (state.deviceType === deviceType) {
      return state;
    }
    return { ...state, deviceType };
  })
  .case(actions.revokePermission, (state) => {
    return {
      ...state,
      has401Error: true,
    };
  })
  .case(actions.willApplyPermissionAuth, (state) => {
    return {
      ...state,
      has401Error: false,
    };
  })
  .case(actions.setIsVirtualKeyboardActive, (state, payload) => {
    return {
      ...state,
      isVirtualKeyboardDisplayed: payload,
    };
  });

export default commonReducer;
