import { createStore, applyMiddleware, AnyAction, compose } from 'redux';
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import TemplateState from 'duck/client/template/State';
import Reducer from 'duck/Reducer';

interface ExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}
declare let window: ExtendedWindow;

const thunk: ThunkMiddleware<TemplateState, AnyAction> = thunkMiddleware;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(Reducer, composeEnhancers(applyMiddleware(thunk)));
