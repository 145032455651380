import React from 'react';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import Text from 'presentational/general/atoms/text/Text';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import { ButtonColorType } from 'duck/color/State';

type StudyParticipationConcentWarningDialogProps = {
  open: boolean;
  onClickCancel: () => void | Promise<void>;
  onClickOK: () => void | Promise<void>;
  buttonBackgroundColorError: ButtonColorType;
  buttonBackgroundColorSuccess: ButtonColorType;
  sampleId: string;
  sampleIdVersion: string;
};

const StudyParticipationConcentWarningDialog = ({
  open,
  onClickCancel,
  onClickOK,
  buttonBackgroundColorError,
  buttonBackgroundColorSuccess,
  sampleId,
  sampleIdVersion,
}: StudyParticipationConcentWarningDialogProps) => {
  const buttons = [
    {
      text: '修正',
      onClick: onClickCancel,
      ...buttonBackgroundColorError,
    },
    {
      text: '確定',
      onClick: onClickOK,
      ...buttonBackgroundColorSuccess,
    },
  ];
  const isVersionFourGeneral =
    sampleIdVersion.charAt(0) === '4' &&
    !(sampleId.includes('U') || sampleId.includes('V'));
  return (
    <Dialog
      open={open}
      onClose={onClickCancel}
      title="研究参加同意確認"
      buttons={buttons}
    >
      <Text>
        研究参加に同意していませんが、よろしいですか？
        <br />
        研究参加に同意してアンケートにご回答いただくと、下記レポートをご提供いたします。
        {!isVersionFourGeneral && (
          <>
            <br />
            ・食事・運動の評価結果
          </>
        )}
        <br />
        ・うつ傾向の評価結果
        <br />
        ・睡眠の評価結果
        <br />
        研究参加に同意する場合は、「修正」ボタンを押してください。
      </Text>
    </Dialog>
  );
};

export default connect(({ color, sampleidInfo }: AppState) => ({
  buttonBackgroundColorSuccess: color.buttonBackgroundColorSuccess,
  buttonBackgroundColorError: color.buttonBackgroundColorError,
  sampleId: sampleidInfo.sampleId,
  sampleIdVersion: sampleidInfo.sampleIdVersion,
}))(StudyParticipationConcentWarningDialog);
