import { useState, useRef } from 'react';
import * as api from 'oapi/api';
import * as TYPES from 'common/Types';
import {
  isEqualMembers,
  isSameMembers,
  isEqualObjectMembers,
  inSameMembers,
} from 'common/Utility';
import { PanelProps } from 'presentational/general/molecules/form/ProgresBar';

export type InputTemplateProps = {
  device: TYPES.DEVICE_TYPES;
  allDecision: TYPES.TEMPLATE_INPUT_ALL_DECISION_TYPES;
  query: api.Template;
};

type AnswerProps = {
  panelIndex: number;
  formIndex: number;
  formId: string;
  values: string[];
  hasInput: boolean;
  isError: boolean;
  setHasInputTrue: () => void;
};

export type HandleSetAnswerProps = Pick<
  AnswerProps,
  'formId' | 'values' | 'hasInput' | 'isError' | 'setHasInputTrue'
> & {
  isInitial: boolean;
};

export type useInputTemplateHooksProps = {
  answers: React.MutableRefObject<AnswerProps[]>;
  isVisiblePanels: boolean[];
  isVisibleForms: boolean[][];
  isDisabledFinishButton: boolean;
  inputFooterIndex: number;
  setInputFooterIndex: React.Dispatch<React.SetStateAction<number>>;
  inputFooterPanels: PanelProps[];
  handleSetAnswer: ({
    formId,
    values,
    hasInput,
    isError,
    setHasInputTrue,
  }: HandleSetAnswerProps) => void;
};

/**
 * パネルまたはフォームの表示条件を満たしているか確認
 * @param {Array<api.VisibleCondition> | undefined} visibleConditions 質問の表示条件
 * @param {React.MutableRefObject<AnswerProps[]>} answers 全質問の回答情報
 * @returns {boolean} - 表示条件の充足状況
 */
const checkVisibleConditions = (
  visibleConditions: Array<api.VisibleCondition> | undefined,
  answers: React.MutableRefObject<AnswerProps[]>,
) => {
  let isVisible = true;
  if (visibleConditions) {
    visibleConditions.forEach((condition: api.VisibleCondition) => {
      const targetValue = ((): string[] => {
        for (let i = 0; i < answers.current.length; i += 1) {
          if (answers.current[i].formId === condition.value1.form_id) {
            return answers.current[i].values;
          }
        }
        return [];
      })();
      switch (`${condition.and_or}_${condition.type}`) {
        case '_=':
          isVisible = isSameMembers(condition.value2, targetValue);
          break;
        case '_!=':
          isVisible = !isSameMembers(condition.value2, targetValue);
          break;
        case '_IN':
          isVisible = inSameMembers(condition.value2, targetValue);
          break;
        case '_NOT_IN':
          isVisible = !inSameMembers(condition.value2, targetValue);
          break;
        case 'AND_=':
          isVisible = isVisible && isSameMembers(condition.value2, targetValue);
          break;
        case 'AND_!=':
          isVisible =
            isVisible && !isSameMembers(condition.value2, targetValue);
          break;
        case 'AND_IN':
          isVisible = isVisible && inSameMembers(condition.value2, targetValue);
          break;
        case 'AND_NOT_IN':
          isVisible =
            isVisible && !inSameMembers(condition.value2, targetValue);
          break;
        case 'OR_=':
          isVisible = isVisible || isSameMembers(condition.value2, targetValue);
          break;
        case 'OR_!=':
          isVisible =
            isVisible || !isSameMembers(condition.value2, targetValue);
          break;
        case 'OR_IN':
          isVisible = isVisible || inSameMembers(condition.value2, targetValue);
          break;
        case 'OR_NOT_IN':
          isVisible =
            isVisible || !inSameMembers(condition.value2, targetValue);
          break;
        default:
      }
    });
  }
  return isVisible;
};

/**
 * 各フォームの表示状態取得
 * @param {api.Template} query - 質問JSONデータ
 * @param {React.MutableRefObject<AnswerProps[]>} answers - 各質問の回答情報
 * @returns {boolean[]} - 各フォームの表示状態情報
 */
const getIsVisibleForms = (
  query: api.Template,
  answers: React.MutableRefObject<AnswerProps[]>,
): boolean[][] => {
  return query.panels.map((panel: api.Panel): boolean[] => {
    return panel.forms.map((form: api.Form): boolean => {
      if (form.options) {
        return checkVisibleConditions(form.options.visible_conditions, answers);
      }
      return true;
    });
  });
};

/**
 * 各パネルの表示状態取得
 * @param {api.Template} query - 質問JSONデータ
 * @param {React.MutableRefObject<AnswerProps[]>} answers - 各質問の回答情報
 * @param {boolean[][]} isVisibleForms - 各フォームの表示状態
 * @returns {boolean[]} - 各パネルの表示状態情報
 */
const getIsVisiblePanels = (
  query: api.Template,
  answers: React.MutableRefObject<AnswerProps[]>,
  isVisibleForms: boolean[][],
): boolean[] => {
  return query.panels.map((panel: api.Panel, panelIndex: number): boolean => {
    let isVisiblePanel = false;
    isVisibleForms[panelIndex].forEach((isVisibleForm: boolean): void => {
      // パネル内部のフォームが全て非表示か判定
      isVisiblePanel = isVisiblePanel || isVisibleForm;
    });
    if (isVisiblePanel && panel.options) {
      return checkVisibleConditions(panel.options.visible_conditions, answers);
    }
    return isVisiblePanel;
  });
};

/**
 * 保存(確認)ボタンの活性状態取得
 * @param {React.MutableRefObject<AnswerProps[]>} answers - 各質問の回答情報
 * @param {boolean[]} isVisiblePanels - 各パネルの表示状態情報
 * @param {boolean[][]} isVisibleForms - 各フォームの表示状態情報
 * @returns {boolean} - 保存(確認)ボタンの活性状態
 */
const getIsDisabledFinishButton = (
  answers: React.MutableRefObject<AnswerProps[]>,
  isVisiblePanels: boolean[],
  isVisibleForms: boolean[][],
): boolean => {
  for (let i = 0; i < answers.current.length; i += 1) {
    if (
      answers.current[i].isError &&
      isVisiblePanels[answers.current[i].panelIndex] &&
      isVisibleForms[answers.current[i].panelIndex][
        answers.current[i].formIndex
      ]
    ) {
      return true;
    }
  }
  return false;
};

/**
 * フッター内パネルの状態取得
 * @param {api.Template} query - 質問JSONデータ
 * @param {React.MutableRefObject<AnswerProps[]>} answers - 各質問の回答情報
 * @param {boolean[]} isVisiblePanels - 各パネルの表示状態情報
 * @param {boolean[]} isVisibleForms - 各フォームの表示状態情報
 * @param {PanelProps[]} - フッター内パネルの状態
 */
const getPanels = (
  query: api.Template,
  answers: React.MutableRefObject<AnswerProps[]>,
  isVisiblePanels: boolean[],
  isVisibleForms: boolean[][],
): PanelProps[] => {
  return query.panels.map(
    (panel: api.Panel, panelIndex: number): PanelProps => {
      return {
        panelType: ((): TYPES.PANEL_TYPES => {
          if (!isVisiblePanels[panelIndex]) {
            return TYPES.PANEL_TYPES.DISABLED;
          }
          let isSuccess: boolean = false;
          let hasInputCache: boolean = true;
          for (let i = 0; i < answers.current.length; i += 1) {
            if (answers.current[i].panelIndex === panelIndex) {
              if (answers.current[i].hasInput && answers.current[i].isError) {
                return TYPES.PANEL_TYPES.ERROR;
              }
              if (
                isVisibleForms[answers.current[i].panelIndex][
                  answers.current[i].formIndex
                ]
              ) {
                hasInputCache = hasInputCache && answers.current[i].hasInput;
              }
              isSuccess = hasInputCache;
            }
          }
          if (isSuccess) {
            return TYPES.PANEL_TYPES.SUCCESS;
          }
          return TYPES.PANEL_TYPES.UNSEEN;
        })(),
        to: `#${panel.id}`,
      };
    },
  );
};

/**
 * テンプレート入力のカスタムフックス
 * @param {api.Template} query - 質問JSONデータ
 */
const useInputTemplate = (query: api.Template): useInputTemplateHooksProps => {
  // 各質問の回答管理
  const answers = useRef<AnswerProps[]>(
    ((): Array<AnswerProps> => {
      const initialAnswer: Array<AnswerProps> = [];
      query.panels.forEach((panel: api.Panel, panelIndex: number) => {
        panel.forms.forEach((form: api.Form, formIndex: number) => {
          if (form.type === 'mixed_form') {
            form.mixed_form?.forms.forEach((parts_form: api.Form) => {
              const initialValue = parts_form.answers
                ? parts_form.answers.map(
                    (answer: api.Answer): string => answer.value,
                  )
                : [];
              const hasInitialInput = initialValue.length !== 0;
              initialAnswer.push({
                panelIndex,
                formIndex,
                formId: parts_form.id,
                values: initialValue,
                hasInput: hasInitialInput,
                isError: false,
                setHasInputTrue: () => {},
              });
            });
          } else if (form.type === 'slider_list') {
            form.slider_list_form?.forms.forEach(
              (slider_form: api.FormOnlySlider) => {
                const initialValue = slider_form.answers
                  ? slider_form.answers.map(
                      (answer: api.Answer): string => answer.value,
                    )
                  : [];
                const hasInitialInput = initialValue.length !== 0;
                initialAnswer.push({
                  panelIndex,
                  formIndex,
                  formId: slider_form.id,
                  values: initialValue,
                  hasInput: hasInitialInput,
                  isError: false,
                  setHasInputTrue: () => {},
                });
              },
            );
          } else if (form.type === 'none') {
            initialAnswer.push({
              panelIndex,
              formIndex,
              formId: form.id,
              values: [''],
              hasInput: true,
              isError: false,
              setHasInputTrue: () => {},
            });
          } else {
            const initialValue = form.answers
              ? form.answers.map((answer: api.Answer): string => answer.value)
              : [];
            const hasInitialInput = initialValue.length !== 0;
            initialAnswer.push({
              panelIndex,
              formIndex,
              formId: form.id,
              values: initialValue,
              hasInput: hasInitialInput,
              isError: false,
              setHasInputTrue: () => {},
            });
          }
        });
      });
      return initialAnswer;
    })(),
  );

  // 各フォームの表示状態管理
  const [isVisibleForms, setIsVisibleForms] = useState<boolean[][]>(
    getIsVisibleForms(query, answers),
  );

  // 各パネルの表示状態管理
  const [isVisiblePanels, setIsVisiblePanels] = useState<boolean[]>(
    getIsVisiblePanels(query, answers, isVisibleForms),
  );

  // 保存(確認)ボタンの活性状態管理
  const [isDisabledFinishButton, setIsDisabledFinishButton] = useState<boolean>(
    getIsDisabledFinishButton(answers, isVisiblePanels, isVisibleForms),
  );

  // フッター内パネルの状態管理
  const [inputFooterIndex, setInputFooterIndex] = useState<number>(0);
  const [inputFooterPanels, setInputFooterPanels] = useState<PanelProps[]>(
    getPanels(query, answers, isVisiblePanels, isVisibleForms),
  );

  /**
   * 一括管理された回答の更新
   * @param {string} formId - 更新対象のフォームID
   * @param {string} value - 更新値
   * @param {boolean} hasInput - 入力済みフラグ
   * @param {boolean} isError - バリデーションエラーフラグ
   * @param {boolean} isInitial - 初期化時(useLayoutEffect時)
   * @param {() => void} setHasInputTrue - 入力済みにする関数
   */
  const handleSetAnswer = ({
    formId,
    values,
    hasInput,
    isError,
    setHasInputTrue,
    isInitial,
  }: HandleSetAnswerProps): void => {
    // 各質問の新しい回答結果を生成
    const temporaryAnswers: AnswerProps[] = [...answers.current];
    for (let i = 0; i < temporaryAnswers.length; i += 1) {
      if (temporaryAnswers[i].formId === formId) {
        temporaryAnswers[i] = {
          ...temporaryAnswers[i],
          values,
          hasInput,
          isError,
          setHasInputTrue,
        };
        if (!isInitial) {
          setInputFooterIndex(temporaryAnswers[i].panelIndex);
          // 入力を飛ばした項目の入力済フラグを有効化
          for (let j = 0; j < i; j += 1) {
            temporaryAnswers[j].hasInput = true;
            temporaryAnswers[j].setHasInputTrue();
          }
        }
        break;
      }
    }
    // 各質問の回答更新
    answers.current = temporaryAnswers;
    // 各フォームの表示状態更新
    const isVisibleFormsCache = getIsVisibleForms(query, answers);
    for (let i = 0; i < isVisibleFormsCache.length; i += 1) {
      if (!isEqualMembers(isVisibleFormsCache[i], isVisibleForms[i])) {
        setIsVisibleForms(isVisibleFormsCache);
        break;
      }
    }
    // 各パネルの表示状態更新
    const isVisiblePanelsCache = getIsVisiblePanels(
      query,
      answers,
      isVisibleFormsCache,
    );
    if (!isEqualMembers(isVisiblePanelsCache, isVisiblePanels)) {
      setIsVisiblePanels(isVisiblePanelsCache);
    }
    // 保存(確認)ボタンの活性状態更新
    const isDisbledFinishButtonCache = getIsDisabledFinishButton(
      answers,
      isVisiblePanelsCache,
      isVisibleFormsCache,
    );
    setIsDisabledFinishButton(isDisbledFinishButtonCache);
    // フッター内パネルの状態更新
    const inputFooterPanelsCache = getPanels(
      query,
      answers,
      isVisiblePanelsCache,
      isVisibleFormsCache,
    );
    if (!isEqualObjectMembers(inputFooterPanelsCache, inputFooterPanels)) {
      setInputFooterPanels(inputFooterPanelsCache);
    }
  };

  return {
    answers,
    isVisiblePanels,
    isVisibleForms,
    isDisabledFinishButton,
    inputFooterIndex,
    setInputFooterIndex,
    inputFooterPanels,
    handleSetAnswer,
  };
};

export default useInputTemplate;
