import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import IconButton from 'presentational/general/atoms/button/IconButton';
import * as TYPES from 'common/Types';
import { fetchImageSize, getAspectRatio, hex2rgba } from 'common/Utility';
import Modal from 'presentational/general/organisms/modal/Modal';
import ImageComponent from 'presentational/general/atoms/other/Image';

export type SupplementalImageProps = {
  src: string;
  alt: string;
  width: number;
  height?: number;
  modalTitle: string;
  device: TYPES.DEVICE_TYPES;
  accentBasic: string;
  textLight: string;
};

type WrapperProps = {
  width: number;
  height: number;
  accentBasic: string;
  accentLight: string;
  device: TYPES.DEVICE_TYPES;
};

const Wrapper = connect(({ color }: AppState) => ({
  accentBasic: color.accentBasic,
  accentLight: color.accentLight,
}))(styled.div<WrapperProps>`
  border: 3px solid ${({ accentBasic }) => accentBasic};
  width: ${({ width }: WrapperProps) => width}px;
  height: ${({ height }: WrapperProps) => height}px;
  position: relative;
  transition: all 0.125s linear;
  ${({ device }: WrapperProps) =>
    device === TYPES.DEVICE_TYPES.PC && `cursor: pointer;`}

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${({ accentLight }) => accentLight};
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 10;
    transition: all 0.125s linear;
  }

  ${({ device, accentBasic }: WrapperProps) =>
    device === TYPES.DEVICE_TYPES.PC &&
    `
    &:hover {
      border: 3px solid ${hex2rgba(accentBasic, 0.3)};
    }

    &:hover::before {
      opacity: 0.75;
    }
  `}
`);

const StyledIconWrapper = connect(({ color }: AppState) => ({
  accentBasic: color.accentBasic,
}))(styled.div<{ accentBasic: string }>`
  position: absolute;
  bottom: 3px;
  right: 3px;
  border-radius: 50%;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ accentBasic }) => accentBasic};
`);

const ModalChildren = styled.div`
  overflow: auto;
`;

const MAX_ICON_SIZE = 16;

/**
 * 【部品】【分子】【フォーム】補足画像
 * @param {string} src                - 画像URL
 * @param {string} alt                - img タグのalt属性
 * @param {number} width              - 画像幅
 * @param {number} height             - 画像高さ
 * @param {string} modalTitle         - モーダルに表示するタイトル
 * @param {TYPES.DEVICE_TYPES} device - デバイスタイプの指定
 */
const SupplementalImage = ({
  src,
  alt,
  width,
  height = 150,
  modalTitle,
  device,
  accentBasic,
  textLight,
}: SupplementalImageProps) => {
  // ダイアログ表示ステータス
  const [isOpen, setOpen] = useState(false);
  const [imgSize, setImgSize] = useState({ width, height });

  // ダイアログ表示関数（onClick）
  const openDialog = (): void => {
    setOpen(true);
  };

  // ダイアログ非表示関数
  const closeDialog = (): void => {
    setOpen(false);
  };

  // props の幅高さの値を参照し、画像のアスペクト比を維持したまま表示画像の大きさを変更
  useEffect(() => {
    fetchImageSize(src).then((imgBaseSize) => {
      let updateWidth: number;
      let updateHeight: number;

      // 画像のアスペクト比を取得
      const aspectRatio = getAspectRatio(imgBaseSize.width, imgBaseSize.height);

      // props に指定した値に合わせてアスペクト比を維持したまま縦横の長さを調節
      // ※ アスペクト比を維持するため、props に指定した幅高さは値の大きい方のみ適用され、一方はアスペクト比に合わせた値となる
      if (width > height) {
        updateHeight = (width * aspectRatio.height) / aspectRatio.width;
        updateWidth = width;
      } else {
        updateWidth = (height * aspectRatio.width) / aspectRatio.height;
        updateHeight = height;
      }

      // 再描画
      setImgSize({ width: updateWidth, height: updateHeight });
    });
  }, [src, width, height]);

  // 画像サイズによってアイコンサイズを調整
  const iconSize = Math.min(imgSize.width, imgSize.height, MAX_ICON_SIZE);

  return (
    <>
      <Wrapper
        onClick={openDialog}
        device={device}
        width={imgSize.width}
        height={imgSize.height}
      >
        <ImageComponent
          src={src}
          alt={alt}
          width={imgSize.width}
          height={imgSize.height}
          verticalAlign="top"
        />
        <StyledIconWrapper>
          <IconButton
            iconName="search"
            iconSize={iconSize}
            backgroundColor={accentBasic}
            iconColor={textLight}
          />
        </StyledIconWrapper>
      </Wrapper>
      <Modal
        open={isOpen}
        title={modalTitle}
        onClose={closeDialog}
        showCloseButton
      >
        <ModalChildren>
          <ImageComponent src={src} alt={alt} />
        </ModalChildren>
      </Modal>
    </>
  );
};

export default connect(({ color }: AppState) => ({
  accentBasic: color.accentBasic,
  textLight: color.textLight,
}))(SupplementalImage);
