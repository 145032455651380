/**
 * 表示デバイス区分
 */
export enum DEVICE_TYPES {
  /** スマートフォン */
  MOBILE,
  /** パソコン */
  PC,
}

/**
 * OSのタイプ
 */
export enum DEVICE_OS_TYPES {
  IPHONE,
  ANDROID,
  IPAD,
  PC,
}

/**
 * テンプレート入出力区分
 */
export enum TEMPLATE_IN_OUT_TYPES {
  /** 入力 */
  INPUT,
  /** 出力 */
  OUTPUT,
}

/**
 * テンプレート入力全体確定区分
 */
export enum TEMPLATE_INPUT_ALL_DECISION_TYPES {
  /** 全体確定有効 */
  ON,
  /** 全体確定無効 */
  OFF,
}

/**
 * パスワード強度区分
 */
export enum PASSWORD_STRENGTH_TYPES {
  /** 高 */
  HIGH,
  /** 中 */
  MIDDLE,
  /** 低 */
  LOW,
}

/**
 * パネル区分
 */
export enum PANEL_TYPES {
  /** エラー */
  ERROR,
  /** エラーなし */
  SUCCESS,
  /** 未表示 */
  UNSEEN,
  /** 無効 */
  DISABLED,
}

/**
 * 進捗区分
 */
export { AnswerProgress as PROGRESS_TYPES } from 'oapi';

/**
 * ボタン縁取り区分
 */
export enum BUTTON_TYPES {
  /** 主に一般ユーザーページ用 */
  ROUND,
  /** 主に企業ユーザーページ用 */
  SQUARE,
}

/**
 *  色区分
 */
export enum COLOR_TYPES {
  /** メイン */
  MAIN,
  /** アクセント */
  ACCENT,
  /** エラー */
  ERROR,
  /** 警告 */
  WARNING,
  /** 成功 */
  SUCCESS,
  /** 非活性 */
  DISABLED,
  /** サーフェイス */
  SURFACE,
  /** 背景 */
  BACKGROUND,
  /** 文字 */
  TEXT,
  /** 枠 */
  FRAME,
  /** コンセプト */
  CONCEPT,
}

/** スウォッチ区分 */
export enum SWATCH_TYPES {
  /** ベーシック */
  BASIC,
  /** ライト */
  LIGHT,
  /** ミドルライト */
  MIDDLE_LIGHT,
  /** ダーク */
  DARK,
}

// TODO 仮カラーコード API疎通後削除
/**
 * 色区分：メイン・ベーシック
 * @const {string}
 */
export const COLOR_CODE_MAIN_BASIC: string = '#CB6C3A';
/**
 * 色区分：メイン・ライト
 * @const {string}
 */
export const COLOR_CODE_MAIN_LIGHT: string = '#E8BDA7';
/**
 * 色区分：メイン・ダーク
 * @const {string}
 */
export const COLOR_CODE_MAIN_DARK: string = '#865034';
/**
 * 色区分：アクセント・ベーシック
 * @const {string}
 */
export const COLOR_CODE_ACCENT_BASIC: string = '#4AA077';
/**
 * 色区分：アクセント・ライト
 * @const {string}
 */
export const COLOR_CODE_ACCENT_LIGHT: string = '#E7F5EE';
/**
 * 色区分：アクセント・ダーク
 * @const {string}
 */
export const COLOR_CODE_ACCENT_DARK: string = '#365A49';
/**
 * 色区分：コンセプト・ベーシック
 * @const {string}
 */
export const COLOR_CODE_CONCEPT_BASIC: string = '#00489D';
/**
 * 色区分：コンセプト・ライト
 * @const {string}
 */
export const COLOR_CODE_CONCEPT_LIGHT: string = '#C2D8F2';
/**
 * 色区分：コンセプト・ダーク
 * @const {string}
 */
export const COLOR_CODE_CONCEPT_DARK: string = '#0F335D';
/**
 * 色区分：エラー・ベーシック
 * @const {string}
 */
export const COLOR_CODE_ERROR_BASIC: string = '#CB4B4B';
/**
 * 色区分：エラー・ライト
 * @const {string}
 */
export const COLOR_CODE_ERROR_LIGHT: string = '#F2BDBD';
/**
 * 色区分：エラー・ミドルライト
 * @const {string}
 */
export const COLOR_CODE_ERROR_MIDDLE_LIGHT: string = '#E76769';
/**
 * 色区分：エラー・ダーク
 * @const {string}
 */
export const COLOR_CODE_ERROR_DARK: string = '#AC3F3D';
/**
 * 色区分：警告・ベーシック
 * @const {string}
 */
export const COLOR_CODE_WARNING_BASIC: string = '#B5AF64';
/**
 * 色区分：警告・ライト
 * @const {string}
 */
export const COLOR_CODE_WARNING_LIGHT: string = '#F0EFC7';
/**
 * 色区分：警告・ミドルライト
 * @const {string}
 */
export const COLOR_CODE_WARNING_MIDDLE_LIGHT: string = '#DCDC83';
/**
 * 色区分：警告・ダーク
 * @const {string}
 */
export const COLOR_CODE_WARNING_DARK: string = '#7C7943';
/**
 * 色区分：成功・ベーシック
 * @const {string}
 */
export const COLOR_CODE_SUCCESS_BASIC: string = '#51C783';
/**
 * 色区分：成功・ライト
 * @const {string}
 */
export const COLOR_CODE_SUCCESS_LIGHT: string = '#B9E6C9';
/**
 * 色区分：成功・ミドルライト
 * @const {string}
 */
export const COLOR_CODE_SUCCESS_MIDDLE_LIGHT: string = '#77C799';
/**
 * 色区分：成功・ダーク
 * @const {string}
 */
export const COLOR_CODE_SUCCESS_DARK: string = '#008D39';
/**
 * 色区分：非活性・ベーシック
 * @const {string}
 */
export const COLOR_CODE_DISABLED_BASIC: string = '#ADADAD';
/**
 * 色区分：非活性・ライト
 * @const {string}
 */
export const COLOR_CODE_DISABLED_LIGHT: string = '#F0F0F0';
/**
 * 色区分：サーフェス・ベーシック
 * @const {string}
 */
export const COLOR_CODE_SURFACE_BASIC: string = '#FCFCFC';
/**
 * 色区分：サーフェス・ダーク
 * @const {string}
 */
export const COLOR_CODE_SURFACE_DARK: string = '#393939';
/**
 * 色区分：背景・ベーシック
 * @const {string}
 */
export const COLOR_CODE_BACKGROUND_BASIC: string = '#F2F2F2';
/**
 * 色区分：枠・ベーシック
 * @const {string}
 */
export const COLOR_CODE_FRAME_BASIC: string = '#989898';
/**
 * 色区分：枠・ライト
 * @const {string}
 */
export const COLOR_CODE_FRAME_LIGHT: string = '#FFFFFF';
/**
 * 色区分：枠・ダーク
 * @const {string}
 */
export const COLOR_CODE_FRAME_DARK: string = '#393939';
/**
 * 色区分：文字・ライト
 * @const {string}
 */
export const COLOR_CODE_TEXT_LIGHT: string = '#FFFFFF';
/**
 * 色区分：文字・ダーク
 * @const {string}
 */
export const COLOR_CODE_TEXT_DARK: string = '#000000';
