import styled from 'styled-components';

export type BarProps = {
  color: string;
  height?: number;
};

/**
 * 【部品】【原子】【その他】バー <Bar>タグ
 * @param {string} color - 表示色
 * @param {number} height - 高さ(px)
 */
const Bar = styled.hr<BarProps>`
  background-color: ${({ color }: BarProps) => color};
  height: ${({ height = 5 }: BarProps) => height}px;
  padding: 0;
`;

export default Bar;
