import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ProgressLabel from 'presentational/general/atoms/label/ProgressLabel';
import RequiredLabel from 'presentational/general/atoms/label/RequiredLabel';
import * as TYPES from 'common/Types';
import Title from 'presentational/general/atoms/text/Title';
import ButtonArea from 'presentational/general/atoms/button/ButtonArea';
import Icon from 'presentational/general/atoms/text/Icon';
import useReactRouter from 'use-react-router';
import { AppState } from 'duck/Reducer';

export type TemplateRowProps = PropsWithChildren<{
  title: string;
  progressType: TYPES.PROGRESS_TYPES;
  to: string;
  required?: boolean;
  hiddenBorderTop?: boolean;
  checkError?: () => Promise<boolean>;
  disabledLight: string;
  disabledBasic: string;
  surfaceBasic: string;
  frameBasic: string;
  mainLight: string;
  textDark: string;
}>;

/**
 * 進捗区分が非活性か判定
 * @param progressType 進捗区分
 */
const isDisabled = (progressType: TYPES.PROGRESS_TYPES) => {
  return progressType === TYPES.PROGRESS_TYPES.DISABLED;
};

type WrapProps = PropsWithChildren<{
  progressType: TYPES.PROGRESS_TYPES;
  hiddenBorderTop: boolean;
  disabledLight: string;
  surfaceBasic: string;
  frameBasic: string;
  mainLight: string;
}>;

const Wrap = styled.div<WrapProps>`
  /* base */
  width: 100%;
  background-color: ${({
    progressType,
    disabledLight,
    surfaceBasic,
  }: WrapProps) => (isDisabled(progressType) ? disabledLight : surfaceBasic)};
  border: 1px solid ${({ frameBasic }) => frameBasic};
  ${({ hiddenBorderTop }: WrapProps) =>
    hiddenBorderTop && 'border-top: hidden;'}
  display: flex;
  align-items: center;
  padding: 5px 15px;

  & > span {
    margin-right: 7px;
  }

  /* hover */
  &:hover {
    ${({ progressType, mainLight }: WrapProps) =>
      !isDisabled(progressType) &&
      `background-color: ${mainLight};
	`}
`;

const RightWrap = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const defaultCheckError = async () => {
  return false;
};

/**
 * 【部品】【分子】【その他】テンプレート行 <TemplateRow>タグ
 * @param {number} title - テンプレートタイトル
 * @param {TYPES.PROGRESS_TYPES} progressType - 進捗区分
 * @param {string} to - リンク先
 * @param {boolean} required - 必須フラグ
 * @param {boolean} unsetBorderTop - 上枠線なしフラグ
 * @param {(
 *  e: React.MouseEvent<HTMLButtonElement | MouseEvent>
 *  ) => void} onClick - クリックイベント
 */
const TemplateRow = ({
  title,
  progressType,
  to,
  required = false,
  hiddenBorderTop = false,
  checkError = defaultCheckError,
  disabledLight,
  disabledBasic,
  surfaceBasic,
  frameBasic,
  mainLight,
  textDark,
}: TemplateRowProps) => {
  const { history } = useReactRouter();
  /**
   * テンプレート行クリックイベント
   */
  const handleClick = () => {
    const promise = checkError();
    promise.then((isError) => {
      if (!isError) {
        history.push(to);
      }
    });
  };

  return (
    <ButtonArea onClick={handleClick} disabled={isDisabled(progressType)}>
      <Wrap
        progressType={progressType}
        hiddenBorderTop={hiddenBorderTop}
        mainLight={mainLight}
        disabledLight={disabledLight}
        surfaceBasic={surfaceBasic}
        frameBasic={frameBasic}
      >
        <Title color={isDisabled(progressType) ? disabledBasic : textDark}>
          {title}
        </Title>
        {required && <RequiredLabel />}
        <RightWrap>
          <ProgressLabel progressType={progressType} />
          <Icon
            iconColor={isDisabled(progressType) ? disabledBasic : textDark}
            iconSize={30}
          >
            chevron_right
          </Icon>
        </RightWrap>
      </Wrap>
    </ButtonArea>
  );
};

export default connect(({ color }: AppState) => ({
  disabledLight: color.disabledLight,
  disabledBasic: color.disabledBasic,
  surfaceBasic: color.surfaceBasic,
  frameBasic: color.frameBasic,
  mainLight: color.mainLight,
  textDark: color.textDark,
}))(TemplateRow);
