import { useState } from 'react';
import { Moment } from 'moment';
import { DateValidatorRules, ErrorProps, validateValue } from 'hooks/Validator';
import CONSTS from 'common/Consts';

export type DateUpdateProps = {
  value: string;
  error: ErrorProps;
  hasInput: boolean;
};

export type DateFieldHooksType = {
  date: Moment | null;
  value: string | null | undefined;
  error: ErrorProps;
  hasInput: boolean;
  setHasInputTrue: () => void;
  onChange: (
    date: Moment | null,
    value: string | null | undefined,
  ) => DateUpdateProps;
  onBlur: (required?: boolean) => DateUpdateProps;
  onAccept: (date: Moment | null) => DateUpdateProps;
  clear: () => DateUpdateProps;
};

const TEXT_DATE_FIELD_DEFAULT_RULES = ['valid_date'];

/**
 * <DateField> 専用hooks
 * 不正日付バリデーションはデフォルトで設定
 *
 * @param {Moment | null} initialValue - 初期値
 * @param {boolean} hasInitialInput - 初期入力済みステータス
 * @param {DateValidatorRules} validatorRule - バリデーション条件指定
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ
 * @returns {DateFieldHooksType} - 生成された部品
 */
const useDateField = (
  initialValue: Moment | null,
  hasInitialInput: boolean,
  validatorRules?: DateValidatorRules,
  validatorMessage?: { [key: string]: string },
): DateFieldHooksType => {
  const customValidatorRules = validatorRules
    ? [...TEXT_DATE_FIELD_DEFAULT_RULES, ...validatorRules]
    : TEXT_DATE_FIELD_DEFAULT_RULES;
  const [value, setValue] = useState<string>(
    initialValue?.format(CONSTS.DATE_FORMAT) || '',
  );
  const [date, setDate] = useState<Moment | null>(initialValue);
  const [error, setError] = useState<ErrorProps>(
    validateValue({
      value: initialValue ? initialValue.format(CONSTS.DATE_FORMAT) : '',
      validatorRules: customValidatorRules,
      validatorMessage,
    }),
  );
  const [hasInput, setHasInput] = useState(hasInitialInput);

  const setHasInputTrue = (): void => {
    setHasInput(true);
  };

  const onChange = (
    inputDate: Moment | null,
    dateValue: string | null | undefined,
  ): DateUpdateProps => {
    const valueCache = dateValue || '';
    const validateResult = validateValue({
      value: valueCache,
      validatorRules: customValidatorRules,
      validatorMessage,
    });
    const update: DateUpdateProps = {
      value: valueCache,
      error: validateResult,
      hasInput: true,
    };
    setValue(valueCache);
    setDate(inputDate);
    setError(validateResult);
    setHasInput(update.hasInput);
    return update;
  };

  const onBlur = (): DateUpdateProps => {
    const valueCache = value;
    const validateResult = validateValue({
      value: valueCache,
      validatorRules: customValidatorRules,
      validatorMessage,
    });
    const update: DateUpdateProps = {
      value: valueCache,
      error,
      hasInput: true,
    };
    setError(validateResult);
    setHasInput(hasInput);
    return update;
  };

  const onAccept = (inputDate: Moment | null): DateUpdateProps => {
    const valueCache = inputDate ? inputDate.format(CONSTS.DATE_FORMAT) : '';
    const validateResult = validateValue({
      value: valueCache,
      validatorRules: customValidatorRules,
      validatorMessage,
    });
    const update: DateUpdateProps = {
      value: valueCache,
      error: validateResult,
      hasInput: true,
    };
    setValue(valueCache);
    setDate(inputDate);
    setError(validateResult);
    setHasInput(hasInput);
    return update;
  };

  const clear = (): DateUpdateProps => {
    const validateResult = validateValue({
      value: '',
      validatorRules: customValidatorRules,
      validatorMessage,
    });
    const update: DateUpdateProps = {
      value: '',
      error: validateResult,
      hasInput: false,
    };
    setValue('');
    setDate(null);
    setError(validateResult);
    setHasInput(update.hasInput);
    return update;
  };

  return {
    date,
    value,
    error,
    hasInput,
    setHasInputTrue,
    onChange,
    onBlur,
    onAccept,
    clear,
  };
};

export default useDateField;
