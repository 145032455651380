import { APIError } from 'common/APIWrapper';
import { CustomMemberDetail, RequestsCustomApiClientRegister } from 'oapi/api';
/**
 * メンバー情報
 */
export type Member = {
  id: string;
  name: string;
  identification: string;
  relation: string;
};

/**
 * 被検者メンバーリスト
 */
export type MembersState = {
  self?: CustomMemberDetail;
  newUser?: RequestsCustomApiClientRegister;
  newMember?: CustomMemberDetail;
  members: Member[];
  selectedMember?: CustomMemberDetail;
  mustAddMember: boolean;
  isLoading: boolean;
  // サンプルIDに対して回答が存在するかどうか
  isSampleIdConfirmed: boolean;
  // サンプルIDが自身のものかどうか
  isSampleIdMine: boolean;
  error?: APIError;
};

export const initialState: MembersState = {
  members: [],
  isLoading: false,
  mustAddMember: false,
  isSampleIdConfirmed: false,
  isSampleIdMine: true,
};

export default MembersState;
