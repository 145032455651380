import { actions } from 'duck/manager/auth/Actions';
import AuthState from 'duck/manager/auth/State';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

const initialState: AuthState = {
  isLoading: false,
};

const authReducer = reducerWithInitialState<AuthState>(initialState)
  .case(actions.load.started, (state) => {
    return {
      ...state,
      isLoading: true,
    };
  })
  .case(actions.load.done, (state) => {
    return {
      ...state,
      isLoading: false,
    };
  })
  .case(actions.load.failed, (state, payload) => {
    return {
      ...state,
      error: payload.error,
      isLoading: false,
    };
  });

export default authReducer;
