import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actions } from 'duck/manager/setting/Actions';
import SettingState from 'duck/manager/setting/State';

const initialState: SettingState = {
  isLoading: false,
  setting: {
    serviceName: '',
    headerColor: '',
    headerImagePath: '',
    footerColor: '',
    footerText: '',
  },
};

export const settingReducer = reducerWithInitialState(initialState)
  .case(actions.load.started, (state) => {
    return {
      ...state,
      isLoading: true,
    };
  })
  .case(actions.load.done, (state) => {
    return {
      ...state,
      isLoading: false,
    };
  })
  .case(actions.load.failed, (state, payload) => {
    return {
      ...state,
      isLoading: false,
      error: payload.error,
    };
  })
  .case(actions.setSetting, (state, payload) => {
    return {
      ...state,
      setting: payload,
    };
  });

export default settingReducer;
