import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import Text from 'presentational/general/atoms/text/Text';
import Bar from 'presentational/general/atoms/other/Bar';

export type PasswordStrengthProps = {
  passwordStrengthType: TYPES.PASSWORD_STRENGTH_TYPES;
  width: number;
  successBasic: string;
  warningBasic: string;
  errorBasic: string;
};

type StyledBarProps = {
  width: number;
};

const StyledBar = styled(Bar)<StyledBarProps>`
  margin: 3px 0;
  width: ${({ width }: StyledBarProps) => width}px;
  text-align: left;
`;

/**
 * 【部品】【原子】【基本入力】パスワード強度
 * @param {TYPES.PASSWORD_STRENGTH_TYPES} passwordStrengthType - パスワード強度区分
 * @param {number} width - バー幅(px)
 */
const PasswordStrength = ({
  passwordStrengthType,
  width,
  successBasic,
  warningBasic,
  errorBasic,
}: PasswordStrengthProps) => {
  const PASSWORD_STRENGTH_LIST: {
    [key: number]: { color: string; text: string };
  } = {
    [TYPES.PASSWORD_STRENGTH_TYPES.HIGH]: {
      color: successBasic,
      text: '高',
    },
    [TYPES.PASSWORD_STRENGTH_TYPES.MIDDLE]: {
      color: warningBasic,
      text: '中',
    },
    [TYPES.PASSWORD_STRENGTH_TYPES.LOW]: {
      color: errorBasic,
      text: '低',
    },
  };
  return (
    <>
      <StyledBar
        color={PASSWORD_STRENGTH_LIST[passwordStrengthType].color}
        width={width}
      />
      <Text color={PASSWORD_STRENGTH_LIST[passwordStrengthType].color}>
        パスワード強度：
        {PASSWORD_STRENGTH_LIST[passwordStrengthType].text}
      </Text>
    </>
  );
};

export default connect(({ color }: AppState) => ({
  successBasic: color.successBasic,
  warningBasic: color.warningBasic,
  errorBasic: color.errorBasic,
}))(PasswordStrength);
