import React, { useState } from 'react';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import { AppState } from 'duck/Reducer';
import { connect } from 'react-redux';
import { CommonState } from 'duck/common/State';
import { Setting } from 'duck/manager/setting/State';
import Panel from 'presentational/general/organisms/area/Panel';
import Text from 'presentational/general/atoms/text/Text';
import Button from 'presentational/general/atoms/button/Button';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import CONSTS from 'common/Consts';
import Link from 'presentational/general/atoms/button/Link';
import ApiErrorDialog from 'presentational/general/templates/dialog/ApiErrorDialog';
import NetworkErrorDialog from 'presentational/general/templates/dialog/NetworkErrorDialog';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import { ButtonColorType } from 'duck/color/State';

export type ViewPasswordResetEmailSendResultProps = {
  common: CommonState;
  setting: Setting;
  buttonBackgroundColorMain: ButtonColorType;
};

const ButtonArea = styled.div`
  padding: 20px 0;
  text-align: center;
`;

const LinkArea = styled.div`
  padding-right: 10px;
  text-align: right;
`;

/**
 * 【画面】GC009_パスワード再設定メール送信結果参照
 * @param {CommonState} common 共通Redux
 * @param {ButtonColorType} buttonBackgroundColorMain ボタン色
 */
const ViewPasswordResetEmailSendResult = ({
  common,
  setting,
  buttonBackgroundColorMain,
}: ViewPasswordResetEmailSendResultProps) => {
  // パスワード再設定メール送信完了ダイアログ
  const [isSendedEmailDialogOpen, setIsSendedEmailDialogOpen] = useState(false);
  const sendedEmailDialogOnClose = () => {
    setIsSendedEmailDialogOpen(false);
  };
  const sendedEmailDialogOkOnClick = () => {
    setIsSendedEmailDialogOpen(false);
  };
  const sendedEmailDialogButtons = [
    {
      text: 'OK',
      onClick: sendedEmailDialogOkOnClick,
    },
  ];

  // エラーダイアログ
  // TODO Reduxのエラー取得に変更
  const [isApiErrorDialogOpen, setIsApiErrorDialogOpen] = useState(false);
  const apiErrorDialogOnClose = () => {
    // TODO エラークリア
    setIsApiErrorDialogOpen(false);
  };
  const apiErrorDialogOkOnClick = () => {
    // TODO エラークリア
    setIsApiErrorDialogOpen(false);
  };
  // TODO Reduxのエラー取得に変更
  const [isNetworkErrorDialogOpen, setIsNetworkErrorDialogOpen] = useState(
    false,
  );
  const networkErrorDialogOnClose = () => {
    // TODO エラークリア
    setIsNetworkErrorDialogOpen(false);
  };
  const networkErrorDialogOkOnClick = () => {
    // TODO エラークリア
    setIsNetworkErrorDialogOpen(false);
  };

  // 再送信ボタン
  const resendButtonOnClick = () => {
    // TODO 再送信
    // TODO エラーダイアログとの表示切り替え
    setIsSendedEmailDialogOpen(true);
  };

  return (
    <BeforeLoginTemplate
      device={common.deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <Panel device={common.deviceType}>
        {/* TODO メールアドレス可変化 */}
        <Text>
          パスワード再設定メールを送信しました。
          <br />
          メールアドレス：sample@sample.com
          <br />
          しばらく経ってもメールが届かない場合は、再送信をお試しください。
        </Text>
        <ButtonArea>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            onClick={resendButtonOnClick}
            {...buttonBackgroundColorMain}
          >
            再送信
          </Button>
        </ButtonArea>
        <LinkArea>
          <Link to={CONSTS.PATH_LOGIN}>ログイン</Link>
        </LinkArea>
      </Panel>
      <Dialog
        open={isSendedEmailDialogOpen}
        onClose={sendedEmailDialogOnClose}
        title="パスワード再設定メール送信完了"
        buttons={sendedEmailDialogButtons}
      >
        <Text>パスワード再設定メールを送信完了しました。</Text>
      </Dialog>
      <ApiErrorDialog
        open={isApiErrorDialogOpen}
        onClose={apiErrorDialogOnClose}
        okOnClick={apiErrorDialogOkOnClick}
        apiLabel="パスワード再設定メール送信"
      />
      <NetworkErrorDialog
        open={isNetworkErrorDialogOpen}
        onClose={networkErrorDialogOnClose}
        okOnClick={networkErrorDialogOkOnClick}
      />
    </BeforeLoginTemplate>
  );
};

export default connect(({ common, color, managerSetting }: AppState) => ({
  common,
  setting: managerSetting.setting,
  buttonBackgroundColorMain: color.buttonBackgroundColorMain,
}))(ViewPasswordResetEmailSendResult);
