import React from 'react';
import styled from 'styled-components';
import { AppState } from 'duck/Reducer';
import { connect } from 'react-redux';
import { CommonState } from 'duck/common/State';
import { Setting } from 'duck/manager/setting/State';
import CommonTemplate from 'presentational/custom/templates/CommonTemplate';
import Text from 'presentational/general/atoms/text/Text';
import PageTitle from 'presentational/general/atoms/text/PageTitle';
import Panel from 'presentational/general/organisms/area/Panel';

export type ViewStudyParticipationConsentProps = {
  common: CommonState;
  setting: Setting;
};

const TextHeadWrap = styled.div`
  margin: 30px 0 10px 0;
`;

const TextWrap = styled.div`
  margin: 10px 0;
`;

const BlockText = styled(Text)`
  display: block;
`;

/**
 * 【画面】GC025_研究参加同意参照
 * @param {CommonState} common 共通(Redux)
 */
const ViewStudyParticipationConsent = ({
  common,
  setting,
}: ViewStudyParticipationConsentProps) => {
  return (
    <CommonTemplate
      device={common.deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <PageTitle device={common.deviceType}>研究参加について</PageTitle>
      <Panel device={common.deviceType}>
        <TextWrap>
          <BlockText fontWeight="bold">
            「ヒトの腸内細菌叢、生活習慣及び健康状態の関連性に関する疫学研究
            Ⅱ」の研究参加について
          </BlockText>
        </TextWrap>
        <TextWrap>
          <BlockText fontWeight="bold">１．はじめに</BlockText>
        </TextWrap>
        <TextWrap>
          <BlockText>
            この文章はシンバイオシス・ソリューションズ株式会社が実施する研究にご協力いただけるかどうかを決めていただくための説明文です。
            <br />
            説明の内容を十分にご理解いただいた上で、この調査に協力するかどうかをご自身の意思によってお決めください。たとえ参加を辞退しても、不利益を被ることは一切ありません。
            <br />
            この研究への参加に同意されても、その同意はいつでも自由に撤回できます。研究を開始した後でも構いません。
          </BlockText>
        </TextWrap>
        <TextHeadWrap>
          <BlockText fontWeight="bold">２．研究の目的</BlockText>
        </TextHeadWrap>
        <TextWrap>
          <BlockText>
            本研究は、腸内細菌叢と年齢・性別等の属性、生活習慣及び健康状態の関連性を収めたデータベースの構築を目的としています。
            このデータベースを利用して、腸内細菌叢と関連がある疾病の予防や、健康状態を良くするための方法を研究します。
          </BlockText>
        </TextWrap>
        <TextHeadWrap>
          <BlockText fontWeight="bold">３．研究の方法および期間</BlockText>
        </TextHeadWrap>
        <TextWrap>
          <BlockText>
            研究に参加できる方は、大便試料などの生体試料や生活習慣などの情報をご提供いただいた方のうち、ご本人又は代諾者が本研究の参加に同意された方です。
            <br />
            ご提供いただいた生体試料やアンケートによる調査結果等を利用し、統計解析手法を使って健康の増進方法や疾病の予防方法を研究します。
            ご提供いただいた情報は個人を容易に特定できないようにした上でデータベースに保管します。
            <br />
            本研究の実施期間は、芝パレスクリニック倫理審査委員会による承認による許可を得た日を研究開始日とし、2030年3月31日までの実施を予定しております。
            なお、必要に応じて、倫理審査委員会の承認を得た上で実施期間を延長する場合があります。
          </BlockText>
        </TextWrap>
        <TextHeadWrap>
          <BlockText fontWeight="bold">４．同意撤回について</BlockText>
        </TextHeadWrap>
        <TextWrap>
          <BlockText>
            この研究への参加に同意していただいた後でも、参加をやめたいと思ったときは、いつでも同意を撤回することができます。
            同意の撤回を希望する場合は、「問い合わせ窓口」に連絡してください。
          </BlockText>
        </TextWrap>
        <TextHeadWrap>
          <BlockText fontWeight="bold">５．予測される利益及び不利益</BlockText>
        </TextHeadWrap>
        <TextWrap>
          <BlockText>
            本研究では、疾病や健康状態に関連する腸内細菌叢の解析を行うため、本研究の成果によって
            健康の増進方法や疾病の予防方法を構築できれば、ご自身の将来の健康管理に役立つ可能性があります。
            <br />
            なお、本研究の解析費用は当社の研究費で実施するため、費用負担等の不利益は生じません。
          </BlockText>
        </TextWrap>
        <TextHeadWrap>
          <BlockText fontWeight="bold">６．プライバシーの保護</BlockText>
        </TextHeadWrap>
        <TextWrap>
          <BlockText>
            プライバシーの保護には十分配慮いたします。
            <br />
            また、この研究を通じて得られた結果は共同研究機関に報告されたり、調査の結果が学会や医学雑誌などに公表されたりすることがありますが、
            氏名などの個人を容易に特定できる情報が公開されることはありません。なお、ご提供いただいた情報や、これらから作成されたデータを、
            学術研究目的のために第三者に提供する場合がありますが、提供する情報には、氏名や住所など個人を容易に特定できるような情報は含まれません。
            また、調査の関係者（研究を依頼した会社の担当者、研究の共同研究機関が指定した者等）が、情報を閲覧することがありますが、
            これらの関係者には秘密を守る義務が課せられておりますのでプライバシーは保護されます。
            <br />
            なお、この同意文書に署名することにより、上記のことに承諾していただいたこととさせていただきます。
            また、調査の途中で調査参加の撤回を申し出られ、同意を撤回した場合でも、生命や身体、財産等の保護のために必要な場合は、
            調査の関係者が調査中の背景調査などを閲覧することがありますのでご了承ください。
          </BlockText>
        </TextWrap>

        <TextHeadWrap>
          <BlockText fontWeight="bold">７．知的財産権について</BlockText>
        </TextHeadWrap>
        <TextWrap>
          <BlockText>
            本研究の成果として特許権等の知的財産権が生じる可能性がありますが、その権利は当社に帰属します。
          </BlockText>
        </TextWrap>

        <TextHeadWrap>
          <BlockText fontWeight="bold">８．問い合わせ窓口</BlockText>
        </TextHeadWrap>
        <TextWrap>
          <BlockText>
            この研究に関して何かわからないことや相談したいことがある場合は、以下の問い合わせ窓口に連絡してください。
            <br />
            実施機関名：シンバイオシス・ソリューションズ株式会社
            <br />
            電話番号：03-6275-0878（代表）
            <br />
            受付時間・曜日： 月〜金：10:00～17:00
            <br />
            メールアドレス：research@symbiosis-solutions.co.jp
          </BlockText>
        </TextWrap>
      </Panel>
    </CommonTemplate>
  );
};

export default connect(({ common, managerSetting }: AppState) => ({
  common,
  setting: managerSetting.setting,
}))(ViewStudyParticipationConsent);
