import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import Text from 'presentational/general/atoms/text/Text';

export type ErrorProps = PropsWithChildren<{
  errorBasic: string;
}>;

/**
 * 【部品】【原子】【文章】エラー文 <Error>タグ
 * @param {React.ReactNode} children - Errorタグの中身
 */
const Error = ({ children, errorBasic }: ErrorProps) => {
  return (
    <Text color={errorBasic} fontSize={12}>
      {children}
    </Text>
  );
};

export default connect(({ color }: AppState) => ({
  errorBasic: color.errorBasic,
}))(Error);
