/* eslint-disable max-len */
import React, { Fragment, useLayoutEffect, useState } from 'react';
import * as api from 'oapi/api';
import * as TYPES from 'common/Types';
import CONSTS from 'common/Consts';
import { generateTsxToReplacedBr } from 'commontsx/Utility';
import useTextStringField from 'hooks/useTextStringField';
import { HandleSetAnswerProps } from 'presentational/general/templates/template/InputTemplate';
import Form from 'presentational/general/molecules/form/Form';
import styled from 'styled-components';
import TextField from 'presentational/general/atoms/basicInput/TextField';
import FormDescription from 'presentational/general/molecules/other/FormDescription';
import InputArea from 'presentational/general/molecules/form/InputArea';
import { getAddressByZipCode } from 'duck/custom/common/Actions';
import LoadingIcon from 'presentational/general/atoms/other/LoadingIcon';

export type MixedPostalWithAddressFormProps = {
  device: TYPES.DEVICE_TYPES;
  form: api.Form;
  disabled: boolean;
  visible: boolean;
  handleSetAnswer: (answer: HandleSetAnswerProps) => void;
};

const AddressField = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * 【部品】【有機体】【テンプレート入力フォーム】郵便番号・住所
 * @param {TYPES.DEVICE_TYPES} device - 表示デバイスの種類
 * @param {api.Form} form - フォーム情報
 * @param {boolean} disabled - 非活性フラグ
 * @param {boolean} visible - 表示フラグ
 * @param {(answer: HandleSetAnswerProps) => void} handleSetAnswer - InputTemplateに入力状態を渡す関数
 */
const MixedPostalWithAddressForm = ({
  device,
  form,
  disabled,
  visible,
  handleSetAnswer,
}: MixedPostalWithAddressFormProps) => {
  const [address, setAddress] = useState<string>('');
  const [isLoadingIcon, setIsLoadingIcon] = useState<boolean>(false);
  const postalForm = (form.mixed_form?.forms as api.Form[])[0];
  const addressForm = (form.mixed_form?.forms as api.Form[])[1];

  // 郵便番号
  const postalTextForm = postalForm.text_form as api.TextForm;
  const postalInitialValue: string =
    postalForm.answers && postalForm.answers.length > 0
      ? postalForm.answers[0].value
      : '';
  const hasInitialPostalInput: boolean =
    postalForm.answers && postalForm.answers.length > 0
      ? true
      : postalInitialValue !== '';
  const postalHooks = useTextStringField(
    postalInitialValue,
    hasInitialPostalInput,
    postalForm.text_form?.validations.values,
  );

  // 住所
  const addressTextForm = addressForm.text_form as api.TextForm;
  const addressInitialValue: string =
    addressForm.answers && addressForm.answers.length > 0
      ? addressForm.answers[0].value
      : '';
  const hasInitialAddressInput: boolean =
    addressForm.answers && addressForm.answers.length > 0
      ? true
      : addressInitialValue !== '';
  const addressHooks = useTextStringField(
    addressInitialValue,
    hasInitialAddressInput,
    addressTextForm.validations.values,
  );

  const postalHandleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const { value, error, hasInput } = postalHooks.onChange(e);

    if (value.length === 7) {
      setIsLoadingIcon(true);
      // 郵便番号検索API
      getAddressByZipCode(value).then((res) => {
        if (res && res.results) {
          const addresses = res.results[0];
          let addressValue: string;

          // 町域名(address3)は空文字で来る可能性がある
          if (addresses.address3 === '') {
            addressValue = addresses.address1 + addresses.address2;
            setAddress(addressValue);
          } else {
            addressValue =
              addresses.address1 + addresses.address2 + addresses.address3;
            setAddress(addressValue);
          }

          handleSetAnswer({
            formId: addressForm.id,
            values: [addressValue],
            hasInput: true,
            isError: false,
            isInitial: false,
            setHasInputTrue: addressHooks.setHasInputTrue,
          });
        }
        setIsLoadingIcon(false);
      });
    }

    handleSetAnswer({
      formId: postalForm.id,
      values: [value],
      hasInput,
      isError: error.isError,
      isInitial: false,
      setHasInputTrue: postalHooks.setHasInputTrue,
    });
  };

  const postalHandleBlur = (): void => {
    const { value, error, hasInput } = postalHooks.onBlur();
    handleSetAnswer({
      formId: postalForm.id,
      values: [value],
      hasInput,
      isError: error.isError,
      isInitial: false,
      setHasInputTrue: postalHooks.setHasInputTrue,
    });
  };

  const addressHandleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const { value, error, hasInput } = addressHooks.onChange(e);
    setAddress(value);
    handleSetAnswer({
      formId: addressForm.id,
      values: [value],
      hasInput,
      isError: error.isError,
      isInitial: false,
      setHasInputTrue: addressHooks.setHasInputTrue,
    });
  };

  useLayoutEffect((): void => {
    if (disabled && postalHooks.hasInput) {
      const { value, error, hasInput } = postalHooks.clear();
      handleSetAnswer({
        formId: postalForm.id,
        values: [value],
        hasInput,
        isError: error.isError,
        isInitial: true,
        setHasInputTrue: postalHooks.setHasInputTrue,
      });
    }

    if (disabled && addressHooks.hasInput) {
      const { value, error, hasInput } = addressHooks.clear();
      handleSetAnswer({
        formId: addressForm.id,
        values: [value],
        hasInput,
        isError: error.isError,
        isInitial: true,
        setHasInputTrue: addressHooks.setHasInputTrue,
      });
    }
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useLayoutEffect((): void => {
    setAddress(addressHooks.value);

    handleSetAnswer({
      formId: postalForm.id,
      values: [postalHooks.value],
      hasInput: postalHooks.hasInput,
      isError: postalHooks.error.isError,
      isInitial: true,
      setHasInputTrue: postalHooks.setHasInputTrue,
    });
    handleSetAnswer({
      formId: addressForm.id,
      values: [addressHooks.value],
      hasInput: addressHooks.hasInput,
      isError: addressHooks.error.isError,
      isInitial: true,
      setHasInputTrue: addressHooks.setHasInputTrue,
    });
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {visible &&
        form.mixed_form?.forms.map((mixedForm: any) => {
          switch (mixedForm.text_form.type) {
            case 'postal_text':
              return (
                <Form
                  device={device}
                  templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
                  question={generateTsxToReplacedBr(postalForm.subscribe)}
                  complement={<FormDescription form={postalForm} />}
                  required={disabled ? false : postalForm.is_required}
                  visible={visible}
                >
                  <InputArea
                    error={postalHooks.hasInput && postalHooks.error.isError}
                    errorMessage={
                      postalHooks.hasInput ? postalHooks.error.errorMessage : ''
                    }
                  >
                    <TextField
                      id={postalForm.id}
                      prefix={postalTextForm.prefix}
                      suffix={postalTextForm.suffix}
                      value={postalHooks.value}
                      onChange={postalHandleChange}
                      onBlur={postalHandleBlur}
                      error={postalHooks.hasInput && postalHooks.error.isError}
                      disabled={disabled}
                      width={CONSTS.TEXT_STRING_FIELD_WIDTH}
                      inputProps={{
                        maxLength: postalTextForm.max_length || 50,
                      }}
                    />
                  </InputArea>
                </Form>
              );
            case 'address_text':
              return (
                <Form
                  device={device}
                  templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
                  question={generateTsxToReplacedBr(addressForm.subscribe)}
                  complement={<FormDescription form={addressForm} />}
                  required={disabled ? false : addressForm.is_required}
                  visible={visible}
                >
                  <InputArea
                    error={addressHooks.hasInput && addressHooks.error.isError}
                    errorMessage={
                      addressHooks.hasInput && address.length === 0
                        ? addressHooks.error.errorMessage
                        : ''
                    }
                  >
                    <AddressField>
                      <TextField
                        id={addressForm.id}
                        prefix={addressTextForm.prefix}
                        suffix={addressTextForm.suffix}
                        value={address}
                        onChange={addressHandleChange}
                        error={
                          addressHooks.hasInput &&
                          addressHooks.error.isError &&
                          address.length === 0
                        }
                        disabled={disabled}
                        width={CONSTS.TEXT_STRING_FIELD_WIDTH}
                        inputProps={{
                          maxLength: addressTextForm.max_length || 50,
                        }}
                      />
                      {isLoadingIcon && (
                        <LoadingIcon size={20} marginLeft={10} />
                      )}
                    </AddressField>
                  </InputArea>
                </Form>
              );
            default:
              return <Fragment key={form.id} />;
          }
        })}
    </>
  );
};

export default MixedPostalWithAddressForm;
