export const VALIDATE_ERROR_MESSAGE = {
  /* 共通 */
  REQUIRED: '必須項目です',
  REGEX: '不正な入力値です',
  MAX: ':max桁以下を入力してください',
  MIN: ':min桁以上を入力してください',
  NUMERIC: '半角数字で入力してください',
  INTEGER: '半角数字で入力してください',
  EMAIL: 'メールアドレスの形式で入力してください',
  RANGE: ':min〜:maxを入力してください',
  NOT_IN: '必須項目です',
  /* テキストフィールド(数値) */
  TEXT_NUMBER_FIELD_MAX: ':max以下を入力してください',
  TEXT_NUMBER_FIELD_MIN: ':min以上を入力してください',
  /* テキストフィールド(文字) */
  TEXT_STRING_FIELD_MAX: ':max文字以下を入力してください',
  TEXT_STRING_FIELD_MIN: ':min文字以上を入力してください',
  /* パスワードフィールド */
  PASSWORD_FIELD_MAX: ':max文字以下を入力してください',
  PASSWORD_FIELD_MIN: ':min文字以上を入力してください',
  /* テキストエリア */
  TEXT_AREA_MAX: ':max文字以下を入力してください',
  TEXT_AREA_MIN: ':min文字以上を入力してください',
  /* ラジオボタングループ */
  RADIO_GROUP_MIN: '必須項目です',
  /* チェックボックスグループ */
  CHECKBOX_GROUP_MAX: ':max個以下を選択してください',
  CHECKBOX_GROUP_MIN: ':min個以上を選択してください',
  /* 年月日 */
  DATE_INVALID: '正しい日付ではありません',
  DATE_AFTER: '本日以降の日付は入力できません',
  DATE_BEFORE: '本日より以前の日付は入力できません',
  DATE_TWENTY: '20歳未満の入力はできません',
  DATE_YEARS_AND_OVER: ':years_and_over歳未満の入力はできません',
  /* 電話番号 */
  PHONE: '電話番号を入力してください',
  /* 郵便番号 */
  ZIP: '郵便番号を入力してください',
  /* カタカナ */
  KATAKANA: 'カタカナで入力してください',
  /* カタカナ(英数字) */
  KATAKANA_WITH_ALPHANUMERIC: 'カタカナおよび英数字のみで入力してください',
  /* 整数部の最大桁数 */
  MAX_INTEGER_DIGIT: '半角数字で:max_integer_digit桁以下にしてください',
  /* 小数部の最大桁数 */
  MAX_DECIMAL_DIGIT: '小数桁は:max_decimal_digit桁以下にしてください',
};

// TODO VALIDATE_ERROR_MESSAGEに統一する
export const VALIDATE_MESSAGES = {
  /* エラー：必須 */
  ERROR_REQUIRED: '必須項目です',

  /* エラー: 正規表現アンマッチ */
  ERROR_REGEX: '不正な入力値です',
  /* エラー: メールアドレス */
  ERROR_EMAIL: 'メールアドレスの形式で入力してください',

  /* エラー: 最大桁数 */
  ERROR_MAX_DIGIT: '{0}桁以下を入力してください',
  /* エラー: 最小桁数 */
  ERROR_MIN_DIGIT: '{0}桁以上を入力してください',

  /* エラー：複合部品 必須 */
  ERROR_REQUIRED_COMPOSITE: '「{0}」は必須項目です',

  /* エラー：数値 */
  ERROR_NUMERIC: '半角数字で入力してください',
  /* エラー：複合部品 数値 */
  ERROR_NUMERIC_COMPOSITE: '「{0}」は半角数字で入力してください',
  /* エラー：整数 */
  ERROR_INTEGER: '半角数字で入力してください',
  /* エラー：複合部品 整数 */
  ERROR_INTEGER_COMPOSITE: '「{0}」は半角数字で入力してください',

  /* エラー：範囲 */
  ERROR_RANGE: '「{0}〜{1}を入力してください',
  /* エラー：複合部品 範囲 */
  ERROR_RANGE_COMPOSITE: '「{0}」は{1}〜{2}を入力してください',
  /* エラー：最大値 */
  ERROR_MAX: '{0}以下を入力してください',
  /* エラー：複合部品 最大値 */
  ERROR_MAX_COMPOSITE: '「{0}」は{1}以下を入力してください',
  /* エラー：最小値 */
  ERROR_MIN: '{0}以上を入力してください',
  /* エラー：複合部品 最小値 */
  ERROR_MIN_COMPOSITE: '「{0}」は{1}以上を入力してください',
  /* エラー：テキストエリア 最大文字数 */
  ERROR_MAX_TEXT_AREA: '{0}文字以下を入力してください',
  /* エラー：テキストエリア 最小文字数 */
  ERROR_MIN_TEXT_AREA: '{0}文字以上を入力してください',

  /* エラー：郵便番号 */
  ERROR_POSTAL_CODE: '000-0000の形式で入力してください',
  /* エラー：電話番号 */
  ERROR_TELEPHONE_NUMBER: '0000-0000-0000の形式で入力してください',
  /* エラー：スライダーリスト 合計超過 */
  ERROR_SLIDER_LIST_OVER: '全項目の合計を{0}にしてください（{1}超過）',

  /* エラー：最大選択数 */
  ERROR_SELECT_MAX: '{0}個以下を選択してください',
  /* エラー：最小選択数 */
  ERROR_SELECT_MIN: '{0}個以上を選択してください',

  /* エラー：不正な日付 */
  ERROR_DATE_INVALID: '正しい日付ではありません',
  /* エラー：本日以前の日付 */
  ERROR_DATE_BEFORE: '本日より以前の日付は入力できません',
  /* エラー：本日以降の日付 */
  ERROR_DATE_AFTER: '本日以降の日付は入力できません',
  /* エラー：20歳未満の生年月日 */
  ERROR_DATE_TWENTY: '20歳未満の入力はできません',
  /* エラー：パスワード */
  ERROR_REGEX_PASSWORD:
    '半角英字小文字・大文字・数字を、各1字以上含めてください',
  /* エラー：パスワード確認 */
  ERROR_REGEX_CONFIRM: '新パスワードと一致しません',
};

export default { VALIDATE_MESSAGES };
