import { Dispatch } from 'redux';
import actionCreatorFactory from 'typescript-fsa';
import APIWrapper, { APIError, DefaultApiFp } from 'common/APIWrapper';
import { actions as commonActions } from 'duck/common/Actions';
import { ResultsClientLogin, ResultLogin } from 'oapi';

const {
  apiClientRegisterPost,
  apiClientRegisterConfirmPost,
  apiClientLoginPost,
  apiClientLoginMfaPost,
  apiClientLogoutGet,
  apiClientPasswordPut,
  apiClientEmailPut,
  apiClientEmailConfirmPost,
  apiClientRecoveryPasswordPost,
  apiClientRecoveryPasswordConfirmPost,
} = DefaultApiFp();
const actionCreator = actionCreatorFactory();

export const actions = {
  load: actionCreator.async<{}, void, APIError>('CLIENT_AUTH_LOAD'),
  clearError: actionCreator<void>('CLIENT_AUTH_CLEAR_ERROR'),
  setPasswordResetEmail: actionCreator<string>(
    'CLIENT_AUTH_SET_PASSWORD_RESET_EMAIL',
  ),
  clearPasswordResetEmail: actionCreator<void>(
    'CLIENT_AUTH_CLEAR_PASSWORD_RESET_EMAIL',
  ),
};

/**
 * ユーザー登録
 * @param {string} email - メールアドレス
 * @param {string} password - パスワード
 */
export const regist = (email: string, password: string) => {
  return async (dispatch: Dispatch) => {
    const params = { email, password };
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(await apiClientRegisterPost(params));
      dispatch(actions.load.done({ params }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params }));
      } else {
        dispatch(actions.load.failed({ params, error: e }));
      }
      return false;
    }
  };
};

/**
 * ユーザー登録認証
 * @param {string} code - 認証コード
 */
export const confirm = (code: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(await apiClientRegisterConfirmPost({ code }));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return false;
    }
  };
};

export type dispatchLogin = (
  loginId: string,
  password: string,
) => Promise<ResultLogin | undefined>;
/**
 * ログイン
 * @param loginId - ユーザーID
 * @param password - パスワード
 */
export const login = (loginId: string, password: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      const res = await APIWrapper(
        await apiClientLoginPost({ login_id: loginId, password }),
      );
      dispatch(actions.load.done({ params: {} }));
      return (res.data as ResultsClientLogin).data;
    } catch (e) {
      dispatch(actions.load.failed({ params: {}, error: e }));
      return undefined;
    }
  };
};

export type dispatchLoginMfa = (code: string) => Promise<boolean>;
/**
 * MFAコードを送信
 * @param code - MFAコード
 */
export const loginMfa = (code: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(await apiClientLoginMfaPost({ code }));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      dispatch(actions.load.failed({ params: {}, error: e }));
      return false;
    }
  };
};

/**
 * ログアウト
 */
export const logout = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(await apiClientLogoutGet({}));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      dispatch(actions.load.failed({ params: {}, error: e }));
      return false;
    }
  };
};

/**
 * パスワードを変更する
 * @param {string} oldPassword - 古いパスワード
 * @param {string} newPassword - 新しいパスワード
 */
export const changePassword = (oldPassword: string, newPassword: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(
        await apiClientPasswordPut({
          old_password: oldPassword,
          new_password: newPassword,
        }),
      );
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return false;
    }
  };
};

/**
 * メールアドレスを変更する
 * @param {string} email - 新しいメールアドレス
 */
export const changeEmail = (email: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(await apiClientEmailPut({ email }));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return false;
    }
  };
};

/**
 * メールアドレスの確認コード
 * @param {string} code  - メールアドレスの確認コード
 */
export const confirmEmailCode = (code: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(await apiClientEmailConfirmPost({ code }));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return false;
    }
  };
};

/**
 * パスワード忘れ用
 * @param {string} email - メールアドレス
 */
export const forgotPassword = (email: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(await apiClientRecoveryPasswordPost({ email }));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return false;
    }
  };
};

/**
 * パスワード忘れの確認コードと新しいパスワード
 * @param {string} code
 */
export const confirmForgotPassword = (code: string, password: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(
        await apiClientRecoveryPasswordConfirmPost({ code, password }),
      );
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return false;
    }
  };
};

/**
 * エラーをクリアする
 */
export const clearError = () => {
  return (dispatch: Dispatch) => dispatch(actions.clearError());
};

/**
 * パスワードリセット用のメールアドレス設定
 * @param {string} email メールアドレス
 */
export const setPasswordResetEmail = (email: string) => {
  return (dispatch: Dispatch) => dispatch(actions.setPasswordResetEmail(email));
};

/**
 * パスワードリセット用のメールアドレスをクリアする
 */
export const clearPasswordResetEmail = () => {
  return (dispatch: Dispatch) => dispatch(actions.clearPasswordResetEmail());
};

export default {
  regist,
  confirm,
  login,
  loginMfa,
  logout,
  changePassword,
  changeEmail,
  confirmEmailCode,
  forgotPassword,
  confirmForgotPassword,
  clearError,
  setPasswordResetEmail,
  clearPasswordResetEmail,
};
