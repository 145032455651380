import React, { PropsWithChildren } from 'react';
import { Icon as MuiIcon } from '@material-ui/core';
import styled from 'styled-components';

export type IconProps = PropsWithChildren<{
  iconColor: string;
  hoverColor?: string;
  iconSize?: number;
}>;

type StyledMuiIconProps = {
  iconcolor: string;
  hovercolor: string;
  iconsize?: number;
};

const StyledMuiIcon = styled(MuiIcon)<StyledMuiIconProps>`
  &.material-icons {
    color: ${({ iconcolor }: StyledMuiIconProps) => iconcolor};
    font-size: ${({ iconsize }: StyledMuiIconProps) => iconsize}px;
  }
  &.material-icons:hover {
    color: ${({ hovercolor }: StyledMuiIconProps) => hovercolor};
  }
`;

/**
 * 【部品】【原子】【文章】アイコン <Icon>タグ
 * アイコン一覧 https://material.io/resources/icons/
 * @param {string} iconColor - アイコンフォントカラー
 * @param {number} iconSize - アイコンフォントサイズ
 * @param {string} hoverColor - ホバー時のアイコンフォントカラー
 * @param {React.ReactNode} children - Iconタグの中身
 */
const Icon = ({
  iconColor,
  iconSize = 13,
  hoverColor,
  children,
}: IconProps) => {
  return (
    <StyledMuiIcon
      iconcolor={iconColor}
      iconsize={iconSize}
      hovercolor={hoverColor || iconColor}
    >
      {children}
    </StyledMuiIcon>
  );
};

export default Icon;
