import React, { useLayoutEffect, useMemo } from 'react';
import * as api from 'oapi/api';
import * as TYPES from 'common/Types';
import { getSliderListShortageValue } from 'common/Utility';
import { generateTsxToReplacedBr } from 'commontsx/Utility';
import useSliderList, { generateDataList } from 'hooks/useSliderList';
import { HandleSetAnswerProps } from 'presentational/general/templates/template/InputTemplate';
import Form from 'presentational/general/molecules/form/Form';
import FormDescription from 'presentational/general/molecules/other/FormDescription';
import InputArea from 'presentational/general/molecules/form/InputArea';
import SliderList, {
  Slider,
  Shortage,
} from 'presentational/general/atoms/compositeInput/SliderList';

export type SliderListFormProps = {
  device: TYPES.DEVICE_TYPES;
  form: api.Form;
  disabled: boolean;
  visible: boolean;
  handleSetAnswer: (answer: HandleSetAnswerProps) => void;
};

/**
 * 【部品】【有機体】【テンプレート入力フォーム】スライダーリスト（汎用版への実装禁止）
 * // TODO データの持ち方や部品の設計がフォームデータのパターンに対応しきれていない（フェールセーフでない）
 * // TODO handleSetAnswerとの接続部分が強制的
 * // TODO データ構造に従い非効率なコード（要リファクタリング）
 * @param {TYPES.DEVICE_TYPES} device - 表示デバイスの種類
 * @param {api.Form} form - フォーム情報
 * @param {boolean} disabled - 非活性フラグ
 * @param {boolean} visible - 表示フラグ
 * @param {(answer: HandleSetAnswerProps) => void} handleSetAnswer - InputTemplateに入力状態を渡す関数
 */
const SliderListForm = ({
  device,
  form,
  disabled,
  visible,
  handleSetAnswer,
}: SliderListFormProps) => {
  const sliderListForm = form.slider_list_form as api.SliderListForm;
  const initialValue = useMemo((): number[] => {
    return sliderListForm.forms.map((sliderForm) => {
      return sliderForm.answers && sliderForm.answers.length > 0
        ? parseFloat(sliderForm.answers[0].value)
        : sliderForm.slider_form.min;
    });
  }, [sliderListForm.forms]);

  const hasInitialInput = useMemo((): boolean => {
    let hasInitialInputCache = false;
    for (let i = 0; i < sliderListForm.forms.length; i += 1) {
      if (sliderListForm.forms[i].type === 'slider') {
        hasInitialInputCache =
          hasInitialInputCache ||
          initialValue[i] !== sliderListForm.forms[i].slider_form.min;
      }
    }
    return hasInitialInputCache;
  }, [initialValue, sliderListForm.forms]);

  const sliders = useMemo((): Slider[] => {
    const slidersCache = [];
    for (let i = 0; i < sliderListForm.forms.length; i += 1) {
      if (sliderListForm.forms[i].type === 'slider') {
        slidersCache.push({
          id: sliderListForm.forms[i].id,
          label: sliderListForm.forms[i].subscribe,
          value: initialValue[i],
          defaultValue: sliderListForm.forms[i].slider_form.min,
          description: sliderListForm.forms[i].information,
        });
      }
    }
    return slidersCache;
  }, [initialValue, sliderListForm.forms]);

  const shortage = useMemo((): Shortage => {
    for (let i = 0; i < sliderListForm.forms.length; i += 1) {
      if (sliderListForm.forms[i].type === 'remain_slider') {
        return {
          insertSlidersIndex: i,
          id: sliderListForm.forms[i].id,
          total: sliderListForm.total || 0,
          label: sliderListForm.forms[i].subscribe,
          value: getSliderListShortageValue(sliders, sliderListForm.total || 0),
          description: sliderListForm.forms[i].information,
        };
      }
    }
    // TODO このリターンに入る様なフォームデータは想定外
    return {
      insertSlidersIndex: sliderListForm.forms.length,
      id: 'SliderListFormSettingError_Remain_sliderIsNone',
      total: sliderListForm.total || 0,
      label: '',
      value: 0,
      description: '',
    };
  }, [sliders, sliderListForm.forms, sliderListForm.total]);

  const valueLimit = useMemo((): {
    sliders: { [key: string]: number }[];
    shortage: { [key: string]: number };
  } => {
    const slidersCache = [];
    let shortageCache = {};
    let isFirstshortageCache = true;
    for (let i = 0; i < sliderListForm.forms.length; i += 1) {
      if (sliderListForm.forms[i].type === 'slider') {
        slidersCache.push({
          max: sliderListForm.forms[i].slider_form.max,
          min: sliderListForm.forms[i].slider_form.min,
          step: sliderListForm.forms[i].slider_form.step,
        });
      } else if (isFirstshortageCache) {
        shortageCache = {
          max: sliderListForm.forms[i].slider_form.max,
          min: sliderListForm.forms[i].slider_form.min,
          step: sliderListForm.forms[i].slider_form.step,
        };
        isFirstshortageCache = false;
      }
    }
    return { sliders: slidersCache, shortage: shortageCache };
  }, [sliderListForm.forms]);

  const hooks = useSliderList(
    sliders,
    shortage,
    hasInitialInput,
    [`max:${sliderListForm.total}`, 'min:0'],
    { min: '合計が24を超えています' },
  );

  const handleChange = (changeValue: number, key: number) => {
    hooks.onChange(changeValue, key);
    /* const { dataList, error, hasInput } = hooks.onChange(changeValue, key);
    dataList.forEach((data: { [key: string]: string }): void => {
      handleSetAnswer({
        formId: data.id,
        values: [data.value],
        hasInput,
        isError: error.isError,
        isInitial: false,
        setHasInputTrue: hooks.setHasInputTrue,
      });
    }); */
  };

  const handleChangeCommitted = (changeValue: number, key: number): void => {
    const { dataList, error, hasInput } = hooks.onChangeCommitted(
      changeValue,
      key,
    );
    dataList.forEach((data: { [key: string]: string }): void => {
      handleSetAnswer({
        formId: data.id,
        values: [data.value],
        hasInput,
        isError: error.isError,
        isInitial: false,
        setHasInputTrue: hooks.setHasInputTrue,
      });
    });
  };

  useLayoutEffect((): void => {
    if (disabled && hooks.hasInput) {
      const { dataList, error, hasInput } = hooks.clear();
      dataList.forEach((data: { [key: string]: string }): void => {
        handleSetAnswer({
          formId: data.id,
          values: [data.value],
          hasInput,
          isError: error.isError,
          isInitial: true,
          setHasInputTrue: hooks.setHasInputTrue,
        });
      });
    }
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useLayoutEffect((): void => {
    const dataList = generateDataList(hooks.sliders, hooks.shortage);
    dataList.forEach((data: { [key: string]: string }): void => {
      handleSetAnswer({
        formId: data.id,
        values: [data.value],
        hasInput: hooks.hasInput,
        isError: hooks.error.isError,
        isInitial: true,
        setHasInputTrue: hooks.setHasInputTrue,
      });
    });
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {visible && (
        <Form
          device={device}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question={generateTsxToReplacedBr(form.subscribe)}
          complement={<FormDescription form={form} />}
          required={disabled ? false : form.is_required}
          visible={visible}
        >
          <InputArea
            error={hooks.hasInput && hooks.error.isError}
            errorMessage={hooks.hasInput ? hooks.error.errorMessage : ''}
          >
            <SliderList
              sliders={hooks.sliders}
              shortage={hooks.shortage}
              valueLimit={valueLimit}
              onChange={handleChange}
              onChangeCommitted={handleChangeCommitted}
            />
          </InputArea>
        </Form>
      )}
    </>
  );
};

export default SliderListForm;
