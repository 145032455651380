import React, { useState } from 'react';
import styled from 'styled-components';
import { usePasswordField, useTextStringField } from 'hooks';
import { VALIDATE_MESSAGES } from 'common/Messages';
import {
  isDisabledInputList,
  replaceMessage,
  isDisplayError,
} from 'common/Utility';
import * as TYPES from 'common/Types';
import CONSTS from 'common/Consts';
import { AppState } from 'duck/Reducer';
import { connect } from 'react-redux';
import { CommonState } from 'duck/common/State';
import { Setting } from 'duck/manager/setting/State';
import Form from 'presentational/general/molecules/form/Form';
import InputArea from 'presentational/general/molecules/form/InputArea';
import TextField from 'presentational/general/atoms/basicInput/TextField';
import PasswordField from 'presentational/general/atoms/basicInput/PasswordField';
import Panel from 'presentational/general/organisms/area/Panel';
import Text from 'presentational/general/atoms/text/Text';
import Link from 'presentational/general/atoms/button/Link';
import Button from 'presentational/general/atoms/button/Button';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import useReactRouter from 'use-react-router';
import authActions from 'duck/client/auth/Actions';
import { ButtonColorType } from 'duck/color/State';
import { APIError } from 'common/APIWrapper';
import * as Utility from 'common/Utility';

export type ResetPasswordProps = {
  common: CommonState;
  setting: Setting;
  buttonBackgroundColorMain: ButtonColorType;
  confirmForgotPassword: (code: string, password: string) => Promise<boolean>;
  authError?: APIError;
  passwordResetEmail?: string;
};

const ButtonArea = styled.div`
  padding: 20px 0;
  text-align: center;
`;

const validatorRules = {
  confirmCode: ['required', 'regex:/^\\S+$/'],
  confirmCodeMessage: {
    regex: '確認コードに不要なスペースが含まれています',
  },
  newPassword: [
    'required',
    'min:8',
    'max:256',
    'regex:/\\d+/',
    'regex:/[a-z]+/',
    'regex:/[A-Z]+/',
  ],
};

const LinkArea = styled.div`
  padding-right: 10px;
  text-align: right;
`;

/**
 * 【画面】GC010_パスワード再設定
 * @param {CommonState} common 共通Redux
 * @param {ButtonColorType} buttonBackgroundColorMain ボタン背景色：メイン(Redux)
 */
const ResetPassword = ({
  common,
  setting,
  buttonBackgroundColorMain,
  confirmForgotPassword,
  authError,
  passwordResetEmail,
}: ResetPasswordProps) => {
  const { history } = useReactRouter();
  const [isError, setIsError] = useState(false);
  // パスワード再設定完了ダイアログ
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = useState(
    false,
  );

  // 確認コード
  const confirmCode = useTextStringField(
    '',
    false,
    validatorRules.confirmCode,
    validatorRules.confirmCodeMessage,
  );
  // 新パスワード
  const newPassword = usePasswordField('', false, validatorRules.newPassword, {
    regex: '半角英字小文字・大文字・数字を、各1字以上含めてください',
    max: replaceMessage(VALIDATE_MESSAGES.ERROR_MAX_DIGIT, ['256']),
    min: replaceMessage(VALIDATE_MESSAGES.ERROR_MIN_DIGIT, ['8']),
  });
  // 再入力
  const confirmationNewPassword = usePasswordField(
    '',
    false,
    ['required', `regex:/^${Utility.escapeRegExp(newPassword.value)}$/`],
    {
      regex: '新パスワードと一致しません',
    },
  );

  const resetPasswordDialogOnClose = async () => {
    setIsResetPasswordDialogOpen(false);
    history.push(CONSTS.PATH_LOGIN);
  };

  const confirmDialogButtons = [
    {
      text: 'OK',
      onClick: resetPasswordDialogOnClose,
    },
  ];

  // 設定ボタン
  const buttonOnClick = async () => {
    // TODO ログイン処理
    // TODO エラーダイアログ出し分け
    const isSucceed = await confirmForgotPassword(
      confirmCode.value,
      newPassword.value,
    );
    if (isSucceed) {
      setIsResetPasswordDialogOpen(true);
    } else {
      setIsError(true);
    }
  };

  const disabledInputList = [
    {
      hasInput: confirmCode.hasInput,
      isError: confirmCode.error.isError,
    },
    {
      hasInput: newPassword.hasInput,
      isError: newPassword.error.isError,
    },
    {
      hasInput: confirmationNewPassword.hasInput,
      isError: confirmationNewPassword.error.isError,
    },
  ];

  return (
    <BeforeLoginTemplate
      device={common.deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <Panel device={common.deviceType}>
        <Text>確認コードを入力してください。</Text>
        <Form
          device={common.deviceType}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question="確認コード"
        >
          <InputArea
            error={isDisplayError(
              confirmCode.hasInput,
              confirmCode.error.isError,
            )}
            errorMessage={confirmCode.error.errorMessage}
          >
            <TextField
              id="confirmCode"
              value={confirmCode.value}
              onChange={confirmCode.onChange}
              onBlur={() => {
                confirmCode.onBlur();
              }}
              error={isDisplayError(
                confirmCode.hasInput,
                confirmCode.error.isError,
              )}
              inputProps={{ maxLength: 10 }}
            />
          </InputArea>
        </Form>
        <Text>パスワードを設定してください。</Text>
        <Form
          device={common.deviceType}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question="新パスワード"
        >
          <InputArea
            error={isDisplayError(
              newPassword.hasInput,
              newPassword.error.isError,
            )}
            errorMessage={newPassword.error.errorMessage}
          >
            <PasswordField
              id="newPassword"
              value={newPassword.value}
              onChange={newPassword.onChange}
              onBlur={() => {
                newPassword.onBlur();
                if (confirmationNewPassword.hasInput) {
                  confirmationNewPassword.onBlur();
                }
              }}
              error={isDisplayError(
                newPassword.hasInput,
                newPassword.error.isError,
              )}
              displayPasswordStrength
              inputProps={{ maxLength: 256 }}
            />
          </InputArea>
        </Form>
        <Form
          device={common.deviceType}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question="再入力"
        >
          <InputArea
            error={isDisplayError(
              confirmationNewPassword.hasInput,
              confirmationNewPassword.error.isError,
            )}
            errorMessage={confirmationNewPassword.error.errorMessage}
          >
            <PasswordField
              id="confirmationNewPassword"
              value={confirmationNewPassword.value}
              onChange={confirmationNewPassword.onChange}
              onBlur={confirmationNewPassword.onBlur}
              error={isDisplayError(
                confirmationNewPassword.hasInput,
                confirmationNewPassword.error.isError,
              )}
            />
          </InputArea>
        </Form>
        <ButtonArea>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            disabled={isDisabledInputList(disabledInputList)}
            onClick={buttonOnClick}
            {...buttonBackgroundColorMain}
          >
            設定
          </Button>
        </ButtonArea>
        <LinkArea>
          <Link to={CONSTS.PATH_SEND_RESET_PASSWORD_EMAIL}>
            確認コードを再送信
          </Link>
        </LinkArea>
      </Panel>
      <Dialog
        open={isResetPasswordDialogOpen}
        onClose={resetPasswordDialogOnClose}
        title="パスワード再設定完了"
        buttons={confirmDialogButtons}
      >
        <Text>パスワードを再設定しました。</Text>
      </Dialog>
      <Dialog
        open={isError}
        onClose={async () => {
          setIsError(false);
        }}
        title="パスワード再設定失敗"
        buttons={[
          {
            text: 'OK',
            onClick: async () => {
              setIsError(false);
            },
            ...buttonBackgroundColorMain,
          },
        ]}
      >
        {authError && authError.detail}
      </Dialog>
      <Dialog
        open={passwordResetEmail === undefined}
        onClose={() => {
          history.push(CONSTS.PATH_SEND_RESET_PASSWORD_EMAIL);
        }}
        title="有効期限切れ"
        buttons={[
          {
            text: 'OK',
            onClick: () => {
              history.push(CONSTS.PATH_SEND_RESET_PASSWORD_EMAIL);
            },
            ...buttonBackgroundColorMain,
          },
        ]}
      >
        <Text>再度パスワード再設定確認コードを送信してください</Text>
      </Dialog>
    </BeforeLoginTemplate>
  );
};

export default connect(
  ({ common, color, auth, managerSetting }: AppState) => ({
    common,
    buttonBackgroundColorMain: color.buttonBackgroundColorMain,
    authError: auth.error,
    passwordResetEmail: auth.passwordResetEmail,
    setting: managerSetting.setting,
  }),
  {
    confirmForgotPassword: authActions.confirmForgotPassword,
  },
)(ResetPassword);
