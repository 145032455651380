import { useState } from 'react';
import { ErrorProps, validateValue } from 'hooks/Validator';
import { VALIDATE_ERROR_MESSAGE } from 'common/Messages';

type TextStringFieldUpdateProps = {
  value: string;
  error: ErrorProps;
  hasInput: boolean;
};

export type TextStringFieldHooksProps = {
  value: string;
  error: ErrorProps;
  hasInput: boolean;
  setHasInputTrue: () => void;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => TextStringFieldUpdateProps;
  onBlur: () => TextStringFieldUpdateProps;
  clear: () => TextStringFieldUpdateProps;
};

const VALIDATOR_DEFAULT_MESSAGE = {
  MAX: VALIDATE_ERROR_MESSAGE.TEXT_STRING_FIELD_MAX,
  MIN: VALIDATE_ERROR_MESSAGE.TEXT_STRING_FIELD_MIN,
};

/**
 * テキストフィールド<TextField>を使う場合の部品生成（文字列）
 * @param {string} initialValue - 初期値
 * @param {boolean} hasInitialInput - 入力済フラグ初期値
 * @param {string[]} validatorRules - バリデーション内容
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ上書き用
 * @returns {TextStringFieldHooksProps} - 生成された部品
 */
const useTextStringField = (
  initialValue: string,
  hasInitialInput: boolean,
  validatorRules?: string[],
  validatorMessage?: { [key: string]: string },
): TextStringFieldHooksProps => {
  const customValidatorMessage = validatorMessage
    ? { ...VALIDATOR_DEFAULT_MESSAGE, ...validatorMessage }
    : VALIDATOR_DEFAULT_MESSAGE;

  const [value, setValue] = useState<string>(initialValue);
  const [error, setError] = useState<ErrorProps>(
    validateValue({
      value: initialValue,
      validatorRules,
      validatorMessage: customValidatorMessage,
    }),
  );
  const [hasInput, setHasInput] = useState<boolean>(hasInitialInput);

  const setHasInputTrue = (): void => {
    setHasInput(true);
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): TextStringFieldUpdateProps => {
    const validateResult = validateValue({
      value: e.target.value,
      validatorRules,
      validatorMessage: customValidatorMessage,
    });
    const update: TextStringFieldUpdateProps = {
      value: e.target.value,
      error: validateResult,
      hasInput,
    };
    setValue(update.value);
    setError(validateResult);
    return update;
  };

  const onBlur = (): TextStringFieldUpdateProps => {
    const validateResult = validateValue({
      value,
      validatorRules,
      validatorMessage: customValidatorMessage,
    });
    const update: TextStringFieldUpdateProps = {
      value,
      error: validateResult,
      hasInput: true,
    };
    setError(validateResult);
    setHasInput(update.hasInput);
    return update;
  };

  const clear = (): TextStringFieldUpdateProps => {
    const validateResult = validateValue({
      value: '',
      validatorRules,
      validatorMessage: customValidatorMessage,
    });
    const update: TextStringFieldUpdateProps = {
      value: '',
      error: validateResult,
      hasInput: false,
    };
    setValue(update.value);
    setError(validateResult);
    setHasInput(update.hasInput);
    return update;
  };

  return { value, error, hasInput, setHasInputTrue, onChange, onBlur, clear };
};

export default useTextStringField;
