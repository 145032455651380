import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { checkPasswordStrength } from 'common/Utility';
import PasswordStrength from 'presentational/general/atoms/basicInput/PasswordStrength';
import { TextField as MuiTextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from 'presentational/general/atoms/button/IconButton';
/* import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'; */
import { AppState } from 'duck/Reducer';

export type PasswordFieldProps = {
  id: string;
  value: string;
  width?: number;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onBlur?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  error?: boolean;
  displayPasswordStrength?: boolean;
  inputProps?: { maxLength: number };
};

const StyledDiv = styled.div``;

type StyledPasswordFieldProps = {
  width: number;
  errorBasic: string;
  errorDark: string;
  frameBasic: string;
  frameLight: string;
  frameDark: string;
  textDark: string;
  accentDark: string;
  accentLight: string;
  disabledBasic: string;
  disabledLight: string;
};

const StyledPasswordField = connect(({ color }: AppState) => ({
  errorBasic: color.errorBasic,
  errorDark: color.errorDark,
  frameLight: color.frameLight,
  frameDark: color.frameDark,
  frameBasic: color.frameBasic,
  textDark: color.textDark,
  accentDark: color.accentDark,
  accentLight: color.accentLight,
  disabledBasic: color.disabledBasic,
  disabledLight: color.disabledLight,
}))(styled(
  ({
    errorBasic,
    errorDark,
    frameBasic,
    frameLight,
    frameDark,
    textDark,
    accentDark,
    accentLight,
    disabledBasic,
    disabledLight,
    dispatch,
    ...props
  }) => <MuiTextField {...props} />,
)<StyledPasswordFieldProps>`
  /* error */
  .Mui-error input {
    color: ${({ errorBasic }) => errorBasic};
  }
  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${({ errorBasic }) => errorBasic};
  }
  .MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ errorDark }) => errorDark};
  }

  /* hover */
  .MuiOutlinedInput-notchedOutline:hover {
    border-color: ${({ frameDark }) => frameDark};
  }

  /* base */
  .MuiOutlinedInput-root {
    width: ${({ width }: StyledPasswordFieldProps) => width}px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: solid 1px ${({ frameBasic }) => frameBasic};
    border-radius: 10px;
  }
  input {
    color: ${({ textDark }) => textDark};
    background-color: ${({ frameLight }) => frameLight};
    padding: 6px 8px 7px;
    border-radius: 10px;
    transition: all 0.125s linear;
  }
  .MuiOutlinedInput-input:-webkit-autofill {
    border-radius: 10px;
  }

  /* focus */
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: solid 1px ${({ accentDark }) => accentDark};
  }
  .Mui-focused input {
    color: ${({ textDark }) => textDark};
    background-color: ${({ accentLight }) => accentLight};
  }

  /* disabled */
  .Mui-disabled input {
    color: ${({ disabledBasic }) => disabledBasic};
    background-color: ${({ disabledLight }) => disabledLight};
  }
`);

/**
 * 【部品】【原子】【基本入力】パスワード <PasswordField>タグ
 * @param {string} id - ID
 * @param {string} value - 値
 * @param {number} width - 幅(px)
 * @param {boolean} disabled - 非活性フラグ
 * @param {string} placeholder - プレースホルダ
 * @param {(
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void} onChange - 値変更イベント
 * @param {(
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void} onBlur - フォーカスアウトイベント
 * @param {boolean} error - エラーフラグ
 */
const PasswordField = ({
  id,
  value,
  width = 200,
  disabled = false,
  placeholder,
  onChange,
  onBlur,
  error = false,
  displayPasswordStrength = false,
  inputProps,
}: PasswordFieldProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const visiblePassword = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          iconName={isVisible ? 'visibility' : 'visibility_off'}
          iconColor="black"
          aria-label="toggle password visibility"
          backgroundColor="rgba(0,0,0,0)"
          onClick={() => setIsVisible(!isVisible)}
        />
      </InputAdornment>
    ),
  };
  const iProps = inputProps
    ? Object.assign(inputProps, visiblePassword)
    : visiblePassword;
  return (
    <StyledDiv>
      <StyledPasswordField
        type={isVisible ? 'text' : 'password'}
        variant="outlined"
        id={id}
        value={value}
        width={width}
        disabled={disabled}
        placeholder={placeholder}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        InputProps={iProps}
      />
      {displayPasswordStrength && (
        <PasswordStrength
          passwordStrengthType={checkPasswordStrength(value)}
          width={width}
        />
      )}
    </StyledDiv>
  );
};

export default PasswordField;
