import { combineReducers } from 'redux';
import commonReducer from 'duck/common/Reducer';
import CommonState from 'duck/common/State';
import templateReducer from 'duck/client/template/Reducer';
import TemplateState from 'duck/client/template/State';
import groupsReducer from 'duck/client/groups/Reducer';
import GroupsState from 'duck/client/groups/State';
import AuthState from 'duck/client/auth/State';
import authReducer from 'duck/client/auth/Reducer';
import SubjectState from 'duck/custom/subject/State';
import subjectReducer from 'duck/custom/subject/Reducer';
import customCommonState from 'duck/custom/common/State';
import customCommonReducer from 'duck/custom/common/Reducer';
import ColorState from 'duck/color/State';
import colorReducer from 'duck/color/Reducer';
import MembersState from 'duck/custom/members/State';
import membersReducer from 'duck/custom/members/Reducer';
import SampleidInfoState from 'duck/custom/sampleidInfo/State';
import sampleidInfoReducer from 'duck/custom/sampleidInfo/Reducer';
import CustomGroupsState from 'duck/custom/groups/State';
import customGroupsReducer from 'duck/custom/groups/Reducer';
import ManagerSettingState from 'duck/manager/setting/State';
import managerSettingReducer from 'duck/manager/setting/Reducer';
import ManagerAuthState from 'duck/manager/auth/State';
import managerAuthReducer from 'duck/manager/auth/Reducer';
import CustomManagerState from 'duck/custom/manager/State';
import customManagerReducer from 'duck/custom/manager/Reducer';

export type AppState = {
  common: CommonState;
  template: TemplateState;
  groups: GroupsState;
  auth: AuthState;
  customCommon: customCommonState;
  subject: SubjectState;
  color: ColorState;
  members: MembersState;
  sampleidInfo: SampleidInfoState;
  customGroups: CustomGroupsState;
  managerSetting: ManagerSettingState;
  managerAuth: ManagerAuthState;
  customManager: CustomManagerState;
};

/**
 * index reducer
 */
const reducer = combineReducers({
  common: commonReducer,
  template: templateReducer,
  groups: groupsReducer,
  auth: authReducer,
  customCommon: customCommonReducer,
  subject: subjectReducer,
  color: colorReducer,
  members: membersReducer,
  sampleidInfo: sampleidInfoReducer,
  customGroups: customGroupsReducer,
  managerSetting: managerSettingReducer,
  managerAuth: managerAuthReducer,
  customManager: customManagerReducer,
});

export default reducer;
