import React, { useState, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import ProgresRound from 'presentational/general/atoms/other/ProgresRound';
import IconButton from 'presentational/general/atoms/button/IconButton';
import Text from 'presentational/general/atoms/text/Text';

export type PanelProps = {
  panelType: TYPES.PANEL_TYPES;
  to: string;
};

export type ProgresBarProps = {
  panels: PanelProps[];
  displayingIdx: number;
  setIdx?: (idx: number) => void;
};

const DISPLAY_COUNT: number = 5;

const RoundWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconButtonArea = styled.div`
  width: 20px;
  height: 20px;
`;

const RoundArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 40px;

  div {
    margin: 0 5px;
  }
`;

const NumArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  margin-left: 10px;
  margin-right: 10px;
`;

/**
 * 進捗円表示終了インデックスの取得
 * @param {number} dispStartIdx - 進捗円表示開始インデックス
 */
const getDispEndIdx = (dispStartIdx: number) => {
  return dispStartIdx + DISPLAY_COUNT;
};

/**
 * 【部品】【分子】【フォーム】進捗バー <ProgresBar>タグ
 * @param {{
 *  panelType: TYPES.PANEL_TYPES;
 *  to: string;
 *  }[]} panels - パネル配列
 * @param {number} displayingIdx - 表示中パネルのインデックス
 * @param {(idx: number) => void} setIdx - クリックされたidxを取得する
 */
const ProgresBar = ({
  panels,
  displayingIdx,
  setIdx,
  textLight,
}: ProgresBarProps & { textLight: string }) => {
  const [dispStartIdx, setDispStartIdx] = useState(0);

  const handleClickBefore = () => {
    if (dispStartIdx - DISPLAY_COUNT < 0) {
      // 先頭表示の場合
      setDispStartIdx(0);
    } else {
      setDispStartIdx(dispStartIdx - DISPLAY_COUNT);
    }
  };

  const handleClickNext = () => {
    if (dispStartIdx + DISPLAY_COUNT >= panels.length - 4) {
      // 終端表示の場合
      setDispStartIdx(panels.length - DISPLAY_COUNT);
    } else {
      setDispStartIdx(dispStartIdx + DISPLAY_COUNT);
    }
  };

  useLayoutEffect((): void => {
    if (displayingIdx >= dispStartIdx + DISPLAY_COUNT) {
      setDispStartIdx(displayingIdx + 1 - DISPLAY_COUNT);
    }
    if (displayingIdx < dispStartIdx) {
      setDispStartIdx(displayingIdx);
    }
    // 選択中のIndexが変わったときだけ表示したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayingIdx]);

  return (
    <>
      <RoundWrap>
        <IconButtonArea>
          {panels.length > DISPLAY_COUNT && dispStartIdx > 0 && (
            <IconButton
              iconSize={20}
              iconName="navigate_before"
              backgroundColor="transparent"
              onClick={handleClickBefore}
              iconColor={textLight}
            />
          )}
        </IconButtonArea>
        <RoundArea>
          {panels.map((panel, i) => {
            // TODO 表示中パネルインデックスの変更時、進捗円表示位置を変更
            return (
              i >= dispStartIdx &&
              i < getDispEndIdx(dispStartIdx) && (
                // 進捗円表示範囲内の場合
                <ProgresRound
                  key={panel.to}
                  panelType={panel.panelType}
                  isDisplaying={displayingIdx === i}
                  to={panel.to}
                  idx={i + 1}
                  onClick={() => setIdx && setIdx(i)}
                />
              )
            );
          })}
        </RoundArea>
        <IconButtonArea>
          {panels.length > DISPLAY_COUNT &&
            getDispEndIdx(dispStartIdx) <= panels.length - 1 && (
              <IconButton
                iconSize={20}
                iconName="navigate_next"
                backgroundColor="transparent"
                onClick={handleClickNext}
                iconColor={textLight}
              />
            )}
        </IconButtonArea>
      </RoundWrap>
      <NumArea>
        <Text fontSize={16} fontWeight="bold" color={textLight}>
          {`${displayingIdx + 1}/${panels.length}`}
        </Text>
      </NumArea>
    </>
  );
};

export default connect(({ color }: AppState) => ({
  textLight: color.textLight,
}))(ProgresBar);
