import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import { AppState } from 'duck/Reducer';

export type PanelProps = PropsWithChildren<{
  device: TYPES.DEVICE_TYPES;
  surfaceBasic: string;
  children: React.ReactNode;
}>;

type PanelAreaProps = {
  device: TYPES.DEVICE_TYPES;
  surfaceBasic: string;
};

const PanelArea = styled.div<PanelAreaProps>`
  background-color: ${({ surfaceBasic }) => surfaceBasic};
  width: ${({ device }: PanelAreaProps) =>
    device === TYPES.DEVICE_TYPES.PC
      ? 'calc(100% - 35px * 2)'
      : 'calc(100% - 15px * 2)'};
  margin: ${({ device }: PanelAreaProps) => {
    if (device === TYPES.DEVICE_TYPES.PC) {
      return '0 auto';
    }
    return 'auto';
  }};
  padding: ${({ device }: PanelAreaProps) =>
    device === TYPES.DEVICE_TYPES.PC ? '0 35px' : '15px 15px'};

  ${({ device }: PanelAreaProps) =>
    device === TYPES.DEVICE_TYPES.MOBILE &&
    `top: 0;
      right: 0;
      bottom: 20px;
      left: 0;
      height: fit-content;`}
`;

/**
 * 【部品】【有機体】【エリア】パネル <Panel>タグ
 * @param {DEVICE_TYPES} device - 表示デバイスの種類
 * @param {string} surfaceBasic - サーフェス色
 */
const Panel = ({ device, surfaceBasic, children }: PanelProps) => {
  return (
    <PanelArea device={device} surfaceBasic={surfaceBasic}>
      {children}
    </PanelArea>
  );
};

export default connect(({ color }: AppState) => ({
  surfaceBasic: color.surfaceBasic,
}))(Panel);
