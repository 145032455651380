/* eslint-disable max-len */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import * as api from 'oapi/api';
import * as TYPES from 'common/Types';
import { generateTsxToReplacedBr } from 'commontsx/Utility';
import { HandleSetAnswerProps } from 'presentational/general/templates/template/InputTemplate';
import MixedSelectForm from 'presentational/general/organisms/inputTemplateForm/MixedSelectForm';
import MixedTextNumberFieldForm from 'presentational/general/organisms/inputTemplateForm/MixedTextNumberFieldForm';
import MixedTextStringFieldForm from 'presentational/general/organisms/inputTemplateForm/MixedTextStringFieldForm';
import Form from 'presentational/general/molecules/form/Form';
import FormDescription from 'presentational/general/molecules/other/FormDescription';

export type SelectFormProps = {
  device: TYPES.DEVICE_TYPES;
  form: api.Form;
  disabled: boolean;
  visible: boolean;
  handleSetAnswer: (answer: HandleSetAnswerProps) => void;
};

const Wrap = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * 【部品】【有機体】【テンプレート入力フォーム】複数単位
 * @param {TYPES.DEVICE_TYPES} device - 表示デバイスの種類
 * @param {api.Form} form - フォーム情報
 * @param {boolean} disabled - 非活性フラグ
 * @param {boolean} visible - 表示フラグ
 * @param {(answer: HandleSetAnswerProps) => void} handleSetAnswer - InputTemplateに入力状態を渡す関数
 */
const MixedForm = ({
  device,
  form,
  disabled,
  visible,
  handleSetAnswer,
}: SelectFormProps) => {
  return (
    <>
      {visible && (
        <Form
          device={device}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question={generateTsxToReplacedBr(form.subscribe)}
          complement={<FormDescription form={form} />}
          required={disabled ? false : form.is_required}
          visible={visible}
        >
          <Wrap>
            {form.mixed_form?.forms.map((mixedForm: any) => {
              switch (mixedForm.type) {
                case 'select':
                  return (
                    <MixedSelectForm
                      key={mixedForm.id}
                      form={mixedForm}
                      disabled={disabled}
                      handleSetAnswer={handleSetAnswer}
                    />
                  );
                case 'text':
                  switch (mixedForm.text_form.type) {
                    case 'number':
                      return (
                        <MixedTextNumberFieldForm
                          key={mixedForm.id}
                          device={device}
                          form={mixedForm}
                          disabled={disabled}
                          visible={visible}
                          handleSetAnswer={handleSetAnswer}
                        />
                      );
                    case 'text':
                      return (
                        <MixedTextStringFieldForm
                          key={mixedForm.id}
                          device={device}
                          form={mixedForm}
                          disabled={disabled}
                          visible={visible}
                          handleSetAnswer={handleSetAnswer}
                        />
                      );
                    default:
                      return <Fragment key={form.id} />;
                  }
                default:
                  return <Fragment key={form.id} />;
              }
            })}
          </Wrap>
        </Form>
      )}
    </>
  );
};

export default MixedForm;
