import { reducerWithInitialState } from 'typescript-fsa-reducers';
import CustomGroupsState from 'duck/custom/groups/State';
import { actions } from 'duck/custom/groups/Actions';

const initialState: CustomGroupsState = {
  isLoading: false,
};

const customGroupsReducer = reducerWithInitialState<CustomGroupsState>(
  initialState,
)
  .case(actions.load.started, (state) => {
    return {
      ...state,
      isLoading: true,
    };
  })
  .case(actions.load.done, (state) => {
    return {
      ...state,
      isLoading: false,
    };
  })
  .case(actions.load.failed, (state, payload) => {
    return {
      ...state,
      isLoading: false,
      error: payload.error,
    };
  });

export default customGroupsReducer;
