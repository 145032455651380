import React, { useState, PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import Collapse from '@material-ui/core/Collapse';
import styled from 'styled-components';
import IconButton from 'presentational/general/atoms/button/IconButton';
import Text from 'presentational/general/atoms/text/Text';

export type DescriptionProps = PropsWithChildren<{
  textLight: string;
}>;

const Wrap = styled.div`
  display: inline;
`;

const StyledDescription = connect(({ color }: AppState) => ({
  accentLight: color.accentLight,
}))(styled.div<{ accentLight: string }>`
  margin: 0 10px;
  padding: 3px;
  background-color: ${({ accentLight }) => accentLight};
`);

/**
 * 【部品】【原子】【基本入力】説明
 * @param {ReactNode} children - 子要素（説明）
 * @param {string} textLight - テキスト色
 */
const Description = ({ children, textLight }: DescriptionProps) => {
  const [isDisplayedInfo, setIsDisplayedInfo] = useState(false);
  return (
    <Wrap>
      {isDisplayedInfo ? (
        <IconButton
          iconName="keyboard_arrow_up"
          iconColor={textLight}
          iconSize={20}
          onClick={() => setIsDisplayedInfo(false)}
        />
      ) : (
        <IconButton
          iconColor={textLight}
          iconName="keyboard_arrow_down"
          iconSize={20}
          onClick={() => setIsDisplayedInfo(true)}
        />
      )}
      <Collapse in={isDisplayedInfo}>
        <StyledDescription>
          <Text fontSize={14}>{children}</Text>
        </StyledDescription>
      </Collapse>
    </Wrap>
  );
};

export default connect(({ color }: AppState) => ({
  textLight: color.textLight,
}))(Description);
