import React, { useState } from 'react';
import useReactRouter from 'use-react-router';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import CONSTS from 'common/Consts';
import { hex2rgba } from 'common/Utility';
import IconButton from 'presentational/general/atoms/button/IconButton';
import MenuItem from 'presentational/general/atoms/button/MenuItem';
import Link from 'presentational/general/atoms/button/Link';
import Image from 'presentational/general/atoms/other/Image';
import logo from 'images/custom/symgram_logo.svg';
import { logout as authLogout } from 'duck/client/auth/Actions';

// モバイル用の色設定
const backgroundColorMobile = {
  color: '#FFFFFF',
  backgroundColor: hex2rgba('#000000', 0.7),
  backgroundColorHover: hex2rgba('#000000', 0.5),
  backgroundColorFocus: hex2rgba('#000000', 0.7),
};

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
  width: 100%;
`;

const PARTS = {
  HEIGHT: 40,
  PADDING_TOP_BOTTOM: 5,
  PADDING_LEFT_RIGHT: 10,
};

type HeaderAreaProps = {
  backgroundColor: string;
};

const HeaderArea = styled.div<HeaderAreaProps>`
  position: relative;
  background-color: ${({ backgroundColor }: HeaderAreaProps) =>
    backgroundColor};
  margin: 0 auto;
  padding: ${PARTS.PADDING_TOP_BOTTOM}px ${PARTS.PADDING_LEFT_RIGHT}px;
  width: calc(100% - ${PARTS.PADDING_LEFT_RIGHT}px * 2);
  height: ${PARTS.HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type MenuAreaProps = {
  device: TYPES.DEVICE_TYPES;
  isDisplayedMenu: boolean;
};

const MenuArea = styled.div<MenuAreaProps>`
  overflow: hidden;
  opacity: ${({ isDisplayedMenu }: MenuAreaProps) =>
    isDisplayedMenu ? '1' : '0'};
  position: absolute;
  right: 0;
  width: 240px;
  height: ${({ isDisplayedMenu }: MenuAreaProps) =>
    isDisplayedMenu ? 'auto' : '0px'};
  transition: opacity 0.5s linear 0s;
  ${({ device }: MenuAreaProps) =>
    device === TYPES.DEVICE_TYPES.PC &&
    'box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.3);'}
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  min-height: 100vh;
`;

export const height = PARTS.HEIGHT + PARTS.PADDING_TOP_BOTTOM * 2;

type dispatchByProps = {
  logout: () => Promise<boolean>;
};

export type HeaderProps = {
  device: TYPES.DEVICE_TYPES;
  textLight: string;
  conceptLight: string;
  conceptDark: string;
  src?: string;
  alt?: string;
  backgroundColor?: string;
} & dispatchByProps;

/**
 * 【部品】【有機体】【エリア】【SSI個別】ヘッダー <Header>タグ
 * @param {DEVICE_TYPES} device - 表示デバイスの種類
 * @param {string} textLight - テキスト色
 * @param {string} conceptLight - コンセプト色(ライト)
 * @param {string} conceptDark - コンセプト色(ダーク)
 */
const Header = ({
  device,
  logout,
  textLight,
  conceptLight,
  conceptDark,
  src = logo,
  alt = 'SYMGRAM',
  backgroundColor = '#9fc2e3',
}: HeaderProps) => {
  const { history } = useReactRouter();
  const [isDisplayedMenu, setIsDisplayedMenu] = useState(false);
  // PC用の色設定
  const backgroundColorPc = {
    backgroundColorHover: hex2rgba(conceptLight, 1),
    backgroundColorFocus: hex2rgba(conceptDark, 0.7),
  };

  /* eslint-disable react/jsx-props-no-spreading */
  if (device === TYPES.DEVICE_TYPES.MOBILE) {
    return (
      <>
        <Wrap>
          <HeaderArea backgroundColor={backgroundColor}>
            <Image src={src} height={40} alt={alt} />
            <IconButton
              iconName={isDisplayedMenu ? 'clear' : 'menu'}
              iconSize={35}
              backgroundColor="unset"
              iconColor={textLight}
              onClick={() => {
                setIsDisplayedMenu(!isDisplayedMenu);
              }}
            />
          </HeaderArea>
          <MenuArea device={device} isDisplayedMenu={isDisplayedMenu}>
            <Link to={CONSTS.PATH_TOP} hiddenUnderline>
              <MenuItem {...backgroundColorMobile}>アンケート一覧</MenuItem>
            </Link>
            <Link to={CONSTS.PATH_UPDATE_USER} hiddenUnderline>
              <MenuItem {...backgroundColorMobile}>
                利用者情報確認・変更
              </MenuItem>
            </Link>
            <Link to={CONSTS.PATH_UPDATE_SUBJECT} hiddenUnderline>
              <MenuItem {...backgroundColorMobile}>
                被検者情報確認・変更
              </MenuItem>
            </Link>
            <Link
              to={CONSTS.PATH_VIEW_STUDY_PARTICIPATION_CONSENT}
              hiddenUnderline
            >
              <MenuItem {...backgroundColorMobile}>研究参加について</MenuItem>
            </Link>
            <Link to={CONSTS.PATH_SET_SUBJECT} hiddenUnderline>
              <MenuItem {...backgroundColorMobile}>被検者変更</MenuItem>
            </Link>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              onClick={async () => {
                await logout();
                history.push(CONSTS.PATH_LOGIN);
              }}
              to="#"
              hiddenUnderline
            >
              <MenuItem {...backgroundColorMobile}>ログアウト</MenuItem>
            </Link>
          </MenuArea>
        </Wrap>
        {isDisplayedMenu && (
          <Overlay onClick={() => setIsDisplayedMenu(false)} />
        )}
      </>
    );
  }
  return (
    <>
      <Wrap>
        <HeaderArea backgroundColor={backgroundColor}>
          <Image src={src} height={40} alt={alt} />
          <IconButton
            iconName="menu"
            iconSize={35}
            backgroundColor="unset"
            iconColor={textLight}
            onClick={() => {
              setIsDisplayedMenu(!isDisplayedMenu);
            }}
          />
        </HeaderArea>
        <MenuArea device={device} isDisplayedMenu={isDisplayedMenu}>
          <Link to={CONSTS.PATH_TOP} hiddenUnderline>
            <MenuItem {...backgroundColorPc}>アンケート一覧</MenuItem>
          </Link>
          <Link to={CONSTS.PATH_UPDATE_USER} hiddenUnderline>
            <MenuItem {...backgroundColorPc}>利用者情報確認・変更</MenuItem>
          </Link>
          <Link to={CONSTS.PATH_UPDATE_SUBJECT} hiddenUnderline>
            <MenuItem {...backgroundColorPc}>被検者情報確認・変更</MenuItem>
          </Link>
          <Link
            to={CONSTS.PATH_VIEW_STUDY_PARTICIPATION_CONSENT}
            hiddenUnderline
          >
            <MenuItem {...backgroundColorPc}>研究参加について</MenuItem>
          </Link>
          <Link to={CONSTS.PATH_SET_SUBJECT} hiddenUnderline>
            <MenuItem {...backgroundColorPc}>被検者変更</MenuItem>
          </Link>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            onClick={async () => {
              await logout();
              history.push(CONSTS.PATH_LOGIN);
            }}
            to="#"
            hiddenUnderline
          >
            <MenuItem {...backgroundColorPc}>ログアウト</MenuItem>
          </Link>
        </MenuArea>
      </Wrap>
      {isDisplayedMenu && <Overlay onClick={() => setIsDisplayedMenu(false)} />}
    </>
  );
};

export default connect(
  ({ color }: AppState) => ({
    textLight: color.textLight,
    conceptLight: color.conceptLight,
    conceptDark: color.conceptDark,
  }),
  { logout: authLogout },
)(Header);
