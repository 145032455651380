import { Dispatch } from 'redux';
import actionCreatorFactory from 'typescript-fsa';
import APIWrapper, { APIError, DefaultApiFp } from 'common/APIWrapper';
import {
  ResultsCustomApiManagerMembersAnswersGet,
  ResultsCustomApiManagerMembersMemberIdGet,
  CustomMembersAnswers,
  CustomMemberDetail,
} from 'oapi/api';

const {
  customApiManagerMembersAnswersGet,
  customApiManagerMembersMemberIdGet,
} = DefaultApiFp();

const actionCreator = actionCreatorFactory();
export const actions = {
  load: actionCreator.async<{}, void, APIError>('CUSTOM_MANAGER_LOAD'),
};

export type dispatchGetMembersAnswersProgress = (
  sampleId?: string,
) => Promise<CustomMembersAnswers | null>;
export type dispatchGetMember = (
  id: string,
) => Promise<CustomMemberDetail | null>;

/**
 * 被検者の回答進捗を取得
 * @param sampleId サンプルID(指定しない場合は全て)
 */
export const getMembersAnswersProgress = (sampleId?: string) => {
  return async (dispatch: Dispatch): Promise<CustomMembersAnswers | null> => {
    try {
      dispatch(actions.load.started({}));
      const res = await APIWrapper(
        await customApiManagerMembersAnswersGet(undefined, sampleId),
      );
      dispatch(actions.load.done({ params: {} }));
      return (res.data as ResultsCustomApiManagerMembersAnswersGet).data;
    } catch (e) {
      dispatch(actions.load.failed({ params: {}, error: e }));
      return null;
    }
  };
};

/**
 * 被検者情報を取得する
 * @param id 被検者ID
 */
export const getMember = (id: string) => {
  return async (dispatch: Dispatch): Promise<CustomMemberDetail | null> => {
    try {
      dispatch(actions.load.started({}));
      const res = await APIWrapper(
        await customApiManagerMembersMemberIdGet(id),
      );
      dispatch(actions.load.done({ params: {} }));
      return (res.data as ResultsCustomApiManagerMembersMemberIdGet).data;
    } catch (e) {
      dispatch(actions.load.failed({ params: {}, error: e }));
      return null;
    }
  };
};

export default {
  getMembersAnswersProgress,
  getMember,
};
