import React, { Fragment, useLayoutEffect, useEffect, useState } from 'react';
import EventListener from 'react-event-listener';
import useReactRouter from 'use-react-router';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { ActionCreator } from 'typescript-fsa';
import sampleidInfoActions from 'duck/custom/sampleidInfo/Actions';
import membersActions from 'duck/custom/members/Actions';
import * as TYPES from 'common/Types';
import CONSTS from 'common/Consts';
import colorActions, { ColorCodeProps } from 'duck/color/Actions';
import { ButtonColorType } from 'duck/color/State';
import commonActions from 'duck/common/Actions';
import settingActions from 'duck/manager/setting/Actions';
import managerAuthActions, {
  dispatchAuthCheck,
} from 'duck/manager/auth/Actions';
import { AppState } from 'duck/Reducer';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import { getDeviceOs, getDeviceType } from 'common/Utility';
import { ResultSetting } from 'oapi';

/**
 * 改行を含んだ文字列から改行を含んだTSXを生成
 * @param {string} message - \nが含まれる可能性のある元の文字列
 * @return {React.ReactNode} - \nを<br />に置き換えたTSX
 */
export const generateTsxToReplacedBr = (message: string): React.ReactNode => {
  return (
    <>
      {message.split('\n').map((msg, i) => {
        if (i === 0) {
          return msg;
        }
        return (
          <Fragment key={String(i)}>
            <br />
            {msg}
          </Fragment>
        );
      })}
    </>
  );
};

/**
 * ページ遷移時にスクロールバーが最上位に戻る機能
 */
export const ScrollToTop = () => {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

type ReadCookiesToReduxProps = {
  setSampleIdAndKitId: ActionCreator<{ sampleId: string; kitId: string }>;
  mustAddMember: () => void;
};
/**
 * Cookieの情報を読み込む
 */
export const ReadCookiesToRedux = connect(undefined, {
  setSampleIdAndKitId: sampleidInfoActions.setSampleIdAndKitId,
  mustAddMember: membersActions.mustAddMember,
})(({ setSampleIdAndKitId, mustAddMember }: ReadCookiesToReduxProps) => {
  const [cookies, setCookies] = useCookies(['web_form']);
  useLayoutEffect(() => {
    if (cookies.kitId && cookies.sampleId) {
      setSampleIdAndKitId({
        kitId: cookies.kitId,
        sampleId: cookies.sampleId,
      });
    }
    if (cookies.mustAddMember) {
      mustAddMember();
    }
  }, [cookies, setCookies, setSampleIdAndKitId, mustAddMember]);
  return null;
});

type UnauthorizedProps = {
  has401Error: boolean;
  mainBasic: string;
  mainDark: string;
  mainLight: string;
  willApplyPermissionAuth: () => void;
};
/**
 * 401エラーが発生した場合にダイアログを表示してログイン画面に戻す
 */
export const Unauthorized = connect(
  ({ color, common }: AppState) => ({
    has401Error: common.has401Error,
    mainBasic: color.mainBasic,
    mainDark: color.mainDark,
    mainLight: color.mainLight,
  }),
  {
    willApplyPermissionAuth: commonActions.willApplyPermissionAuth,
  },
)(
  ({
    has401Error,
    mainBasic,
    mainDark,
    mainLight,
    willApplyPermissionAuth,
  }: UnauthorizedProps) => {
    const { history } = useReactRouter();
    if (has401Error) {
      return (
        <Dialog
          open
          onClose={() => {
            willApplyPermissionAuth();
            history.push(CONSTS.PATH_LOGIN);
          }}
          title="ログインセッション切れ"
          buttons={[
            {
              text: 'OK',
              onClick: () => {
                willApplyPermissionAuth();
                history.push(CONSTS.PATH_LOGIN);
              },
              backgroundColor: mainBasic,
              hoverBackgroundColor: mainDark,
              focusBackgroundColor: mainLight,
            },
          ]}
        >
          セッションが切れています。
          <br />
          再度ログインし直してください。
        </Dialog>
      );
    }
    return null;
  },
);

type UnauthorizedManagerProps = {
  authCheck: dispatchAuthCheck;
  has401Error: boolean;
  children: any;
};
export const UnauthorizedManager = connect(
  ({ common }: AppState) => ({
    has401Error: common.has401Error,
  }),
  {
    authCheck: managerAuthActions.authCheck,
  },
)(({ authCheck, has401Error, children }: UnauthorizedManagerProps) => {
  const { history } = useReactRouter();
  const [isEnable, setIsEnable] = useState(false);
  useLayoutEffect(() => {
    const exec = async () => {
      const result = await authCheck();
      if (!result) {
        setIsEnable(true);
      } else {
        history.push(CONSTS.PATH_MANAGER_LOGIN);
      }
    };
    exec();
  }, [authCheck, setIsEnable, history]);
  return !has401Error && isEnable ? children : null;
});

type EnableManagerProps = {
  authCheck: dispatchAuthCheck;
  children: any;
};

export const EnableManager = connect(undefined, {
  authCheck: managerAuthActions.authCheck,
})(({ authCheck, children }: EnableManagerProps) => {
  const { history } = useReactRouter();
  const [isEnable, setIsEnable] = useState(false);
  useLayoutEffect(() => {
    const exec = async () => {
      const result = await authCheck();
      if (!result) {
        setIsEnable(true);
      } else if (result.status === 401) {
        setIsEnable(true);
      } else {
        history.push(CONSTS.PATH_LOGIN);
      }
    };
    exec();
  }, [authCheck, setIsEnable, history]);
  return isEnable ? children : null;
});

export const ServerSetting = connect(undefined, {
  getSetting: settingActions.getSetting,
  setSetting: settingActions.setSetting,
})(
  ({
    getSetting,
    setSetting,
  }: {
    getSetting: () => Promise<ResultSetting | null>;
    setSetting: (setting: ResultSetting) => void;
  }) => {
    useLayoutEffect(() => {
      const exec = async () => {
        const result = await getSetting();
        if (result) {
          setSetting(result);
        }
      };
      exec();
    }, [getSetting, setSetting]);
    return null;
  },
);

type CheckSampleIdProps = {
  sampleId: string;
  buttonBackgroundColorMain: ButtonColorType;
  to: string;
};

export const CheckSampleId = connect(({ sampleidInfo, color }: AppState) => ({
  sampleId: sampleidInfo.sampleId,
  buttonBackgroundColorMain: color.buttonBackgroundColorMain,
}))(({ to, sampleId, buttonBackgroundColorMain }: CheckSampleIdProps) => {
  const { history } = useReactRouter();
  return (
    <Dialog
      open={sampleId === ''}
      onClose={() => {
        history.push(to);
      }}
      title="サンプルIDが存在しません"
      buttons={[
        {
          text: 'OK',
          onClick: () => {
            history.push(to);
          },
          backgroundColor: buttonBackgroundColorMain.backgroundColor,
          hoverBackgroundColor: buttonBackgroundColorMain.hoverBackgroundColor,
          focusBackgroundColor: buttonBackgroundColorMain.focusBackgroundColor,
        },
      ]}
    >
      サンプルIDを先に登録してください。
    </Dialog>
  );
});

export const VirtualKeyboardCheck = connect(undefined, {
  setIsVirtualKeyboardActive: commonActions.setIsVirtualKeyboardActive,
})(
  ({
    setIsVirtualKeyboardActive,
  }: {
    setIsVirtualKeyboardActive: (isActive: boolean) => void;
  }) => {
    useEffect(() => {
      const osType = getDeviceOs();
      if (
        osType !== TYPES.DEVICE_OS_TYPES.IPHONE &&
        osType !== TYPES.DEVICE_OS_TYPES.ANDROID
      ) {
        return undefined;
      }
      const focuscheckTypes = [
        'text',
        'search',
        'tel',
        'url',
        'email',
        'password',
        'number',
      ];
      const focusInput = (event: Event): boolean => {
        if (event.srcElement === null) {
          return false;
        }
        const element = event.srcElement as Element;
        if (element.tagName !== 'INPUT' && element.tagName !== 'TEXTAREA') {
          return false;
        }
        if (element.tagName === 'INPUT') {
          const eleType = element.getAttribute('type');
          if (eleType === null || focuscheckTypes.indexOf(eleType) === -1) {
            return false;
          }
        }
        return true;
      };
      const focusIn = (event: Event) => {
        if (!focusInput(event)) {
          return;
        }
        setIsVirtualKeyboardActive(true);
      };
      const focusOut = (event: Event) => {
        if (!focusInput(event)) {
          return;
        }
        setIsVirtualKeyboardActive(false);
      };

      window.document.addEventListener('DOMFocusIn', focusIn);
      window.document.addEventListener('DOMFocusOut', focusOut);
      return () => {
        window.document.removeEventListener('DOMFocusIn', focusIn);
        window.document.removeEventListener('DOMFocusOut', focusOut);
      };
    }, [setIsVirtualKeyboardActive]);
    return null;
  },
);

export const ChangeDeviceType = connect(undefined, {
  changeDeviceType: commonActions.changeDeviceType,
})(
  ({
    changeDeviceType,
  }: {
    changeDeviceType: (type: TYPES.DEVICE_TYPES) => void;
  }) => {
    return (
      <EventListener
        target="window"
        onResize={() => {
          changeDeviceType(getDeviceType());
        }}
      />
    );
  },
);

/**
 * SSIのカラー設定用
 * TODO: カスタム用に移動する
 */
export const SSIColor = connect(undefined, {
  setColorCode: colorActions.setColorCode,
})(({ setColorCode }: { setColorCode: ActionCreator<ColorCodeProps> }) => {
  useLayoutEffect(() => {
    const list = [
      {
        colorType: TYPES.COLOR_TYPES.MAIN,
        swatchType: TYPES.SWATCH_TYPES.BASIC,
        colorCode: '#6D8DAD',
      },
      {
        colorType: TYPES.COLOR_TYPES.MAIN,
        swatchType: TYPES.SWATCH_TYPES.LIGHT,
        colorCode: '#E6EBF0',
      },
      {
        colorType: TYPES.COLOR_TYPES.MAIN,
        swatchType: TYPES.SWATCH_TYPES.DARK,
        colorCode: '#00568E',
      },
      {
        colorType: TYPES.COLOR_TYPES.ACCENT,
        swatchType: TYPES.SWATCH_TYPES.BASIC,
        colorCode: '#739F6C',
      },
      {
        colorType: TYPES.COLOR_TYPES.ACCENT,
        swatchType: TYPES.SWATCH_TYPES.LIGHT,
        colorCode: '#E7F5EE',
      },
      {
        colorType: TYPES.COLOR_TYPES.ACCENT,
        swatchType: TYPES.SWATCH_TYPES.DARK,
        colorCode: '#5D7B58',
      },
      {
        colorType: TYPES.COLOR_TYPES.ERROR,
        swatchType: TYPES.SWATCH_TYPES.BASIC,
        colorCode: '#CB4B4B',
      },
      {
        colorType: TYPES.COLOR_TYPES.ERROR,
        swatchType: TYPES.SWATCH_TYPES.LIGHT,
        colorCode: '#F2BDBD',
      },
      {
        colorType: TYPES.COLOR_TYPES.ERROR,
        swatchType: TYPES.SWATCH_TYPES.MIDDLE_LIGHT,
        colorCode: '#E76769',
      },
      {
        colorType: TYPES.COLOR_TYPES.ERROR,
        swatchType: TYPES.SWATCH_TYPES.DARK,
        colorCode: '#AC3F3D',
      },
      {
        colorType: TYPES.COLOR_TYPES.WARNING,
        swatchType: TYPES.SWATCH_TYPES.BASIC,
        colorCode: '#B5AF64',
      },
      {
        colorType: TYPES.COLOR_TYPES.WARNING,
        swatchType: TYPES.SWATCH_TYPES.LIGHT,
        colorCode: '#F0EFC7',
      },
      {
        colorType: TYPES.COLOR_TYPES.WARNING,
        swatchType: TYPES.SWATCH_TYPES.MIDDLE_LIGHT,
        colorCode: '#DCDC83',
      },
      {
        colorType: TYPES.COLOR_TYPES.WARNING,
        swatchType: TYPES.SWATCH_TYPES.DARK,
        colorCode: '#7C7943',
      },
      {
        colorType: TYPES.COLOR_TYPES.SUCCESS,
        swatchType: TYPES.SWATCH_TYPES.BASIC,
        colorCode: '#51C783',
      },
      {
        colorType: TYPES.COLOR_TYPES.SUCCESS,
        swatchType: TYPES.SWATCH_TYPES.LIGHT,
        colorCode: '#B9E6C9',
      },
      {
        colorType: TYPES.COLOR_TYPES.SUCCESS,
        swatchType: TYPES.SWATCH_TYPES.MIDDLE_LIGHT,
        colorCode: '#77C799',
      },
      {
        colorType: TYPES.COLOR_TYPES.SUCCESS,
        swatchType: TYPES.SWATCH_TYPES.DARK,
        colorCode: '#008D39',
      },
      {
        colorType: TYPES.COLOR_TYPES.DISABLED,
        swatchType: TYPES.SWATCH_TYPES.BASIC,
        colorCode: '#ADADAD',
      },
      {
        colorType: TYPES.COLOR_TYPES.DISABLED,
        swatchType: TYPES.SWATCH_TYPES.LIGHT,
        colorCode: '#F0F0F0',
      },
      {
        colorType: TYPES.COLOR_TYPES.SURFACE,
        swatchType: TYPES.SWATCH_TYPES.BASIC,
        colorCode: '#FCFCFC',
      },
      {
        colorType: TYPES.COLOR_TYPES.SURFACE,
        swatchType: TYPES.SWATCH_TYPES.DARK,
        colorCode: '#393939',
      },
      {
        colorType: TYPES.COLOR_TYPES.FRAME,
        swatchType: TYPES.SWATCH_TYPES.BASIC,
        colorCode: '#989898',
      },
      {
        colorType: TYPES.COLOR_TYPES.FRAME,
        swatchType: TYPES.SWATCH_TYPES.LIGHT,
        colorCode: '#FFFFFF',
      },
      {
        colorType: TYPES.COLOR_TYPES.FRAME,
        swatchType: TYPES.SWATCH_TYPES.DARK,
        colorCode: '#393939',
      },
      {
        colorType: TYPES.COLOR_TYPES.TEXT,
        swatchType: TYPES.SWATCH_TYPES.LIGHT,
        colorCode: '#FFFFFF',
      },
      {
        colorType: TYPES.COLOR_TYPES.TEXT,
        swatchType: TYPES.SWATCH_TYPES.DARK,
        colorCode: '#000000',
      },
    ];
    list.forEach((colorCode) => {
      setColorCode(colorCode);
    });
  }, [setColorCode]);
  return null;
});

export const SetTitle = connect(({ managerSetting }: AppState) => ({
  serviceName: managerSetting.setting.serviceName,
}))(({ serviceName }: { serviceName: string }) => {
  useLayoutEffect(() => {
    document.title = serviceName;
  }, [serviceName]);
  return null;
});
