import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

export type ButtonAreaProps = PropsWithChildren<{
  onClick?: (e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => void;
  disabled?: boolean;
  hoverBackgroundColor?: string;
  focusBackgroundColor?: string;
}>;

type StyledButtonProps = PropsWithChildren<{
  hoverBackgroundColor: string;
  focusBackgroundColor: string;
  disabledLight: string;
  disabledBasic: string;
}>;

const StyledButton = connect(({ color }: AppState) => ({
  disabledLight: color.disabledLight,
  disabledBasic: color.disabledBasic,
}))(styled(
  ({
    focusBackgroundColor,
    hoverBackgroundColor,
    disabledLight,
    disabledBasic,
    dispatch,
    ...props
  }) => <Button {...props} />,
)<StyledButtonProps>`
  /* base */
  width: 100%;
  &.MuiButton-text {
    padding: 0;
  }

  /* focus */
  & .MuiTouchRipple-root {
    color: ${({ focusBackgroundColor }: StyledButtonProps) =>
      focusBackgroundColor};
  }

  /* hover */
  &.MuiButton-root:hover {
    background-color: ${({ hoverBackgroundColor }: StyledButtonProps) =>
      hoverBackgroundColor};
  }

  /* disabled */
  &.MuiButton-root.Mui-disabled {
    background-color: ${({ disabledLight }) => disabledLight};
    color: ${({ disabledBasic }) => disabledBasic};
  }
`);

/**
 * 【部品】【原子】【ボタン】ボタンエリア <ButtonArea>タグ
 * @param {(
 *  e: React.MouseEvent<HTMLButtonElement | MouseEvent>
 *  ) => void} onClick - クリックイベント
 * @param {boolean} disabled - 非活性フラグ
 * @param {string} hoverBackgroundColor - 背景色:hover時
 * @param {string} focusBackgroundColor - 背景色:focus時
 * @param {React.ReactNode} children - Buttonタグの中身
 */
const ButtonArea = ({
  onClick,
  disabled = false,
  hoverBackgroundColor = 'transparent',
  focusBackgroundColor = 'transparent',
  children,
}: ButtonAreaProps) => {
  return (
    <StyledButton
      disabled={disabled}
      onClick={onClick}
      hoverBackgroundColor={hoverBackgroundColor}
      focusBackgroundColor={focusBackgroundColor}
    >
      {children}
    </StyledButton>
  );
};

export default ButtonArea;
