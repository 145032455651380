import styled from 'styled-components';
import { connect } from 'react-redux';
import * as TYPES from 'common/Types';
import CONSTS from 'common/Consts';
import { AppState } from 'duck/Reducer';

const PARTS = {
  PADDING_TOP_BOTTOM_PC: 30,
  PADDING_LFET_RIGHT_PC: 40,
};

export type ContentsAreaProps = {
  device: TYPES.DEVICE_TYPES;
  heightMargin?: number;
  surfaceBasic: string;
  innerHeight?: number;
};

/**
 * 【部品】【有機体】【エリア】コンテンツエリア <ContentsArea>タグ
 * @param {DEVICE_TYPES} device - 表示デバイスの種類
 * @param {number} heightMargin - ヘッダとフッターの高さ
 */
const ContentsArea = styled.div<ContentsAreaProps>`
  position: relative;
  background-color: ${({ device, surfaceBasic }: ContentsAreaProps) =>
    device === TYPES.DEVICE_TYPES.MOBILE ? 'transparent' : surfaceBasic};
  margin: 0 auto;
  padding: ${({ device }: ContentsAreaProps) =>
    device === TYPES.DEVICE_TYPES.MOBILE
      ? `0`
      : `${PARTS.PADDING_TOP_BOTTOM_PC}px ${PARTS.PADDING_LFET_RIGHT_PC}px`};
  ${({ device }: ContentsAreaProps) =>
    device === TYPES.DEVICE_TYPES.PC &&
    `width: calc(${CONSTS.CONTENTS_AREA_WIDTH}px - ${PARTS.PADDING_LFET_RIGHT_PC}px *2);`}
  min-height: ${({
    device,
    heightMargin = 0,
    innerHeight = window.innerHeight,
  }: ContentsAreaProps) =>
    device === TYPES.DEVICE_TYPES.MOBILE
      ? `calc(${innerHeight}px - ${heightMargin}px)`
      : `calc(100vh - ${PARTS.PADDING_TOP_BOTTOM_PC}px * 2 - ${heightMargin}px)`};  

    /* パソコンの場合は上下Paddingを引いた高さを指定 */
  ${({ device }: ContentsAreaProps) =>
    device === TYPES.DEVICE_TYPES.PC &&
    `height: calc(100% - ${PARTS.PADDING_TOP_BOTTOM_PC}px * 2);`}
  
`;

export default connect(({ color }: AppState) => ({
  surfaceBasic: color.surfaceBasic,
}))(ContentsArea);
