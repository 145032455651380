import React from 'react';
import useReactRouter from 'use-react-router';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import { connect } from 'react-redux';
import { CommonState } from 'duck/common/State';
import { Setting } from 'duck/manager/setting/State';
import Panel from 'presentational/general/organisms/area/Panel';
import Text from 'presentational/general/atoms/text/Text';
import Button from 'presentational/general/atoms/button/Button';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import { AppState } from 'duck/Reducer';
import CONSTS from 'common/Consts';

export type RegistrationCheckHistoryProps = {
  common: CommonState;
  setting: Setting;
  buttonBackgroundColorMain: {
    hoverBackgroundColor: string;
    focusBackgroundColor: string;
  };
};

type ButtonAreaProps = {
  device: TYPES.DEVICE_TYPES;
};

type AreaProps = {
  device: TYPES.DEVICE_TYPES;
};

const Area = styled.div<AreaProps>`
  ${({ device }: IndentAreaProps) => {
    return device === TYPES.DEVICE_TYPES.PC ? `text-align: center;` : '';
  }}
`;

const ButtonArea = styled.div<ButtonAreaProps>`
  display: flex;
  justify-content: center;
  padding: 20px 0;
  text-align: center;
  button {
    ${({ device }: ButtonAreaProps) =>
      device === TYPES.DEVICE_TYPES.MOBILE
        ? `
    display: block;
    margin: 10px auto;
    `
        : `
    margin: 0 5px;
    `}
  }
`;

type IndentAreaProps = {
  device: TYPES.DEVICE_TYPES;
};

const IndentArea = styled.div<IndentAreaProps>`
  ${({ device }: IndentAreaProps) => {
    return device === TYPES.DEVICE_TYPES.MOBILE ? `text-align: left;` : '';
  }}
  margin-left: 1em;
  text-align: left;
`;

/**
 * 【画面】GC029_利用者登録履歴確認
 * @param {CommonState} common 共通Redux
 * @param {ButtonColorType} buttonBackgroundColorMain ボタン背景色：メイン(Redux)
 */
const RegistrationCheckHistory = ({
  common,
  setting,
  buttonBackgroundColorMain,
}: RegistrationCheckHistoryProps) => {
  const { history } = useReactRouter();
  const confirmBtnWidth =
    common.deviceType === TYPES.DEVICE_TYPES.MOBILE ? 170 : 250;

  const confirmBtnFontSize =
    common.deviceType === TYPES.DEVICE_TYPES.MOBILE ? 13 : 16;

  const onClickYes = () => {
    history.push(CONSTS.PATH_LOGIN, { from: 'RegistrationCheckHistory' });
  };

  const onClickNo = () => {
    history.push(CONSTS.PATH_INPUT_USER);
  };

  return (
    <BeforeLoginTemplate
      device={common.deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <Area device={common.deviceType}>
        <Panel device={common.deviceType}>
          <Text fontWeight="bold" fontSize={16}>
            以前、利用者登録をしたことがありますか？
            <br />
            SYMGRAMを初めて利用される方は「ない」のボタンを押してください。
            <br />
            <br />
            ※WEBでアンケートを回答する場合、利用者登録が必要です。
          </Text>
          <ButtonArea device={common.deviceType}>
            <Button
              buttonType={TYPES.BUTTON_TYPES.ROUND}
              onClick={onClickYes}
              width={confirmBtnWidth}
              fontSize={confirmBtnFontSize}
              {...buttonBackgroundColorMain}
            >
              ある
            </Button>
            <Button
              buttonType={TYPES.BUTTON_TYPES.ROUND}
              onClick={onClickNo}
              width={confirmBtnWidth}
              fontSize={confirmBtnFontSize}
              {...buttonBackgroundColorMain}
            >
              ない
              <br />
              （利用者登録に進みます）
            </Button>
          </ButtonArea>
          <IndentArea device={common.deviceType}>
            ■「利用者」について
            <br />
            利用者とは、利用者情報を登録し、このWEBサイトにログインする人を意味します。
            16歳未満の方は、利用者情報の登録ができません。親族や親権者が代諾者となってご登録する必要があります。
          </IndentArea>
          <IndentArea device={common.deviceType}>
            ■「被検者」について
            <br />
            被検者とは、検査を受ける対象者を意味します。何歳でも検査を受けることができますが、
            被検者が16歳未満の場合やメールアドレスを取得していないなどの場合には、利用者が被検者の代諾者となり登録する必要があります。
            代諾者は被検者様の最善の利益を図りうる方（親族、親権者、法定後見人など）のみがなることができます。
          </IndentArea>
        </Panel>
      </Area>
    </BeforeLoginTemplate>
  );
};

export default connect(({ common, color, managerSetting }: AppState) => ({
  common,
  setting: managerSetting.setting,
  buttonBackgroundColorMain: color.buttonBackgroundColorMain,
}))(RegistrationCheckHistory);
