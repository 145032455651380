import React from 'react';
import styled from 'styled-components';

export type ImageProps = {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  verticalAlign?: 'top';
};

type StyledImgProps = {
  width?: number;
  height?: number;
  verticalAlign?: 'top';
};

const StyledImg = styled.img<StyledImgProps>`
  ${({ width }: StyledImgProps) => width && `width: ${width}px;`}
  ${({ height }: StyledImgProps) => height && `height: ${height}px;`}
  ${({ verticalAlign }: StyledImgProps) =>
    verticalAlign && `vertical-align: ${verticalAlign};`}
  max-width: 100%;
`;

/**
 * 【部品】【原子】【その他】画像 <Image>タグ
 * @param {string} src - ソース
 * @param {string} alt - 代替文
 * @param {number} width - 幅(px)
 * @param {number} height - 高さ(px)
 * @param {string} verticalAlign - 画像の行揃えプロパティ
 */
const Image = ({ src, alt, width, height, verticalAlign }: ImageProps) => {
  return (
    <StyledImg
      src={src}
      alt={alt}
      width={width}
      height={height}
      verticalAlign={verticalAlign}
    />
  );
};

export default Image;
