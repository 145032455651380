import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import useReactRouter from 'use-react-router';
import { useTextStringField } from 'hooks';
import { isDisplayError } from 'common/Utility';
import CONSTS from 'common/Consts';
import { AppState } from 'duck/Reducer';
import { CommonState } from 'duck/common/State';
import { ButtonColorType } from 'duck/color/State';
import authActions from 'duck/client/auth/Actions';
import { Setting } from 'duck/manager/setting/State';
import { APIError } from 'common/APIWrapper';
import CommonTemplate from 'presentational/custom/templates/CommonTemplate';
import BackPageTitle from 'presentational/general/molecules/other/BackPageTitle';
import Form from 'presentational/general/molecules/form/Form';
import InputArea from 'presentational/general/molecules/form/InputArea';
import TextField from 'presentational/general/atoms/basicInput/TextField';
import Panel from 'presentational/general/organisms/area/Panel';
import Text from 'presentational/general/atoms/text/Text';
import Button from 'presentational/general/atoms/button/Button';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import * as TYPES from 'common/Types';

export type InputUpdateEmailConfirmationCodeProps = {
  common: CommonState;
  buttonBackgroundColorError: ButtonColorType;
  authError?: APIError;
  confirmEmailCode: (code: string) => Promise<boolean>;
  setting: Setting;
};

const ButtonArea = styled.div`
  padding: 20px 0 40px 0;
  text-align: center;
  button {
    margin: 0 5px;
  }
`;

const validatorRules = {
  emailConfirmationCode: ['required', 'regex:/^\\S+$/'],
  emailConfirmationMessage: {
    regex: '確認コードに不要なスペースが含まれています',
  },
};

const InputUpdateEmailConfirmationCode: FC<InputUpdateEmailConfirmationCodeProps> = ({
  common,
  buttonBackgroundColorError,
  authError,
  confirmEmailCode,
  setting,
}) => {
  const [willOpenBackModal, setWillOpenBackModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const { history } = useReactRouter();
  const confirmationCode = useTextStringField(
    '',
    false,
    validatorRules.emailConfirmationCode,
    validatorRules.emailConfirmationMessage,
  );

  const backpageOnClick = (): void => {
    setWillOpenBackModal(true);
  };

  const buttonOnClick = async () => {
    // TODO 正常系および異常系の挙動確認を実施
    const isSucceed = await confirmEmailCode(confirmationCode.value);
    if (isSucceed) {
      history.push(CONSTS.PATH_UPDATE_USER);
    } else {
      setIsError(true);
    }
  };

  return (
    <CommonTemplate
      device={common.deviceType}
      headerColor={setting.headerColor}
      footerColor={setting.footerColor}
      footerText={setting.footerText}
    >
      <BackPageTitle device={common.deviceType} onClick={backpageOnClick}>
        確認コード入力
      </BackPageTitle>
      <Panel device={common.deviceType}>
        <Text>メールアドレス確認コードを入力してください。</Text>
        <Form
          device={common.deviceType}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question="メールアドレス確認コード"
        >
          <InputArea
            error={isDisplayError(
              confirmationCode.hasInput,
              confirmationCode.error.isError,
            )}
            errorMessage={confirmationCode.error.errorMessage}
          >
            <TextField
              id="confirmationCode"
              value={confirmationCode.value}
              onChange={confirmationCode.onChange}
              onBlur={confirmationCode.onBlur}
              error={isDisplayError(
                confirmationCode.hasInput,
                confirmationCode.error.isError,
              )}
              width={250}
            />
          </InputArea>
        </Form>
        <ButtonArea>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            onClick={() => {
              history.push(CONSTS.PATH_UPDATE_EMAIL);
            }}
            {...buttonBackgroundColorError}
          >
            戻る
          </Button>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            disabled={confirmationCode.error.isError}
            onClick={buttonOnClick}
          >
            送信
          </Button>
        </ButtonArea>
      </Panel>
      <Dialog
        open={isError}
        onClose={() => {
          setIsError(false);
        }}
        title="確認コード送信エラー"
        buttons={[
          {
            text: 'OK',
            onClick: () => {
              setIsError(false);
            },
          },
        ]}
      >
        {authError && authError.detail}
      </Dialog>
      <Dialog
        open={willOpenBackModal}
        title="メールアドレス変更中止確認"
        onClose={() => {
          setWillOpenBackModal(false);
        }}
        buttons={[
          {
            text: 'キャンセル',
            onClick: () => {
              setWillOpenBackModal(false);
            },
          },
          {
            text: '中止',
            onClick: () => {
              history.push(CONSTS.PATH_UPDATE_USER);
            },
            ...buttonBackgroundColorError,
          },
        ]}
      >
        メールアドレスは変更済みです。認証を中止しますか？
      </Dialog>
    </CommonTemplate>
  );
};

export default connect(
  ({ common, color, auth, managerSetting }: AppState) => ({
    common,
    buttonBackgroundColorError: color.buttonBackgroundColorError,
    authError: auth.error,
    setting: managerSetting.setting,
  }),
  {
    confirmEmailCode: authActions.confirmEmailCode,
  },
)(InputUpdateEmailConfirmationCode);
