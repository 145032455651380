import React from 'react';
import Link from 'presentational/general/atoms/button/Link';
import CONSTS from 'common/Consts';

const ManagerSettings = () => {
  return (
    <div>
      <div>
        <Link to={CONSTS.PATH_MANAGER_SETTING}>基本設定</Link>
      </div>
      <div>
        <Link to={CONSTS.PATH_MANAGER_SETTING_MAIL}>メール設定</Link>
      </div>
      <div>
        <Link to={CONSTS.PATH_MANAGER_MEMBER_PROGRESS}>
          メンバー回答状況確認
        </Link>
      </div>
    </div>
  );
};

export default ManagerSettings;
