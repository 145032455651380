import React, { ChangeEvent, FocusEvent } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import { OutlinedInput as MuiTextField } from '@material-ui/core';

export type TextAreaProps = {
  id: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  rows?: number;
  width?: number;
  error?: boolean;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  inputProps?: { maxLength?: number };
};

type StyledMuiTextAreaProps = {
  width?: number;
  errorBasic: string;
  errorDark: string;
  frameBasic: string;
  frameDark: string;
  frameLight: string;
  textDark: string;
  accentDark: string;
  accentLight: string;
  disabledLight: string;
  disabledBasic: string;
};

const StyledMuiTextArea = connect(({ color }: AppState) => ({
  errorBasic: color.errorBasic,
  errorDark: color.errorDark,
  frameBasic: color.frameBasic,
  frameDark: color.frameDark,
  frameLight: color.frameLight,
  textDark: color.textDark,
  accentDark: color.accentDark,
  accentLight: color.accentLight,
  disabledLight: color.disabledLight,
  disabledBasic: color.disabledBasic,
}))(styled(
  ({
    errorBasic,
    errorDark,
    frameBasic,
    frameDark,
    frameLight,
    textDark,
    accentDark,
    accentLight,
    disabledLight,
    disabledBasic,
    dispatch,
    ...props
  }) => <MuiTextField {...props} />,
)<StyledMuiTextAreaProps>`
  /* error */
  &.Mui-error textarea {
    color: ${({ errorBasic }) => errorBasic};
  }
  &.MuiOutlinedInput-multiline.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${({ errorBasic }) => errorBasic};
  }
  &.MuiOutlinedInput-multiline.Mui-error:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: ${({ errorDark }) => errorDark};
  }

  /* hover */
  &.MuiOutlinedInput-multiline:hover {
    border-color: ${({ frameDark }) => frameDark};
  }

  /* base */
  &.MuiOutlinedInput-multiline {
    border-color: ${({ frameBasic }) => frameBasic};
    border-radius: 10px;
    background-color: ${({ frameLight }) => frameLight};
    padding: 6px 8px 7px;
    width: ${({ width }: StyledMuiTextAreaProps) =>
      width ? `${width}px` : '100%'};
    transition: all 0.125s linear;
  }
  textarea {
    color: ${({ textDark }) => textDark};
    transition: all 0.125s linear;
  }
  textarea::placeholder {
    color: ${({ frameBasic }) => frameBasic};
  }

  /* focused */
  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ accentDark }) => accentDark};
  }
  &.Mui-focused {
    background-color: ${({ accentLight }) => accentLight};
  }
  &.Mui-focused textarea {
    color: ${({ textDark }) => textDark};
  }

  /* disabled */
  &.Mui-disabled {
    background-color: ${({ disabledLight }) => disabledLight};
  }
  &.Mui-disabled textarea {
    color: ${({ disabledBasic }) => disabledBasic};
  }
`);

/**
 * 【部品】【原子】【基本入力】テキストエリア <TextArea>タグ
 * @param {string} id - ID
 * @param {string} value - 入力値
 * @param {boolean} disabled - 非活性フラグ
 * @param {string} placeholder - プレースホルダ
 * @param {number} rows - テキストエリア行数指定
 * @param {number} width - 幅
 * @param {boolean} error - エラーフラグ
 * @param {(e: ChangeEvent<HTMLTextAreaElement>) => void} onChange - チェンジイベント
 * @param {(e: FocusEvent<HTMLTextAreaElement>) => void} onBlur - フォーカスアウトイベント
 * @param {{ maxLength?: number }} TextFieldProps - inputのHTML属性設定
 */
const TextArea = ({
  id,
  value,
  disabled,
  placeholder,
  rows = 2,
  width,
  error = false,
  onChange,
  onBlur,
  inputProps,
}: TextAreaProps) => {
  return (
    <StyledMuiTextArea
      multiline
      id={id}
      value={value}
      width={width}
      disabled={disabled}
      placeholder={placeholder}
      rows={rows}
      error={error}
      onChange={onChange}
      onBlur={onBlur}
      inputProps={inputProps}
    />
  );
};

export default TextArea;
