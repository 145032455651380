import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { setColorCode } from 'duck/color/Actions';
import * as TYPES from 'common/Types';
import ColorState from 'duck/color/State';

const initialColorState: ColorState = {
  // メイン・ベーシックのカラー
  mainBasic: TYPES.COLOR_CODE_MAIN_BASIC,
  // メイン・ライトのカラー
  mainLight: TYPES.COLOR_CODE_MAIN_LIGHT,
  // メイン・ミドルライトのカラー
  mainMiddleLight: '',
  // メイン・ダークのカラー
  mainDark: TYPES.COLOR_CODE_MAIN_DARK,
  // アクセント・ベーシックのカラー
  accentBasic: TYPES.COLOR_CODE_ACCENT_BASIC,
  // アクセント・ライトのカラー
  accentLight: TYPES.COLOR_CODE_ACCENT_LIGHT,
  // アクセント・ミドルライトのカラー
  accentMiddleLight: '',
  // アクセント・ダークのカラー
  accentDark: TYPES.COLOR_CODE_ACCENT_DARK,
  // コンセプト・ベーシックのカラー
  conceptBasic: TYPES.COLOR_CODE_CONCEPT_BASIC,
  // コンセプト・ライトのカラー
  conceptLight: TYPES.COLOR_CODE_CONCEPT_LIGHT,
  // コンセプト・ミドルライトのカラー
  conceptMiddleLight: '',
  // コンセプト・ダークのカラー
  conceptDark: TYPES.COLOR_CODE_CONCEPT_DARK,
  // エラー・ベーシックのカラー
  errorBasic: TYPES.COLOR_CODE_ERROR_BASIC,
  // エラー・ライトのカラー
  errorLight: TYPES.COLOR_CODE_ERROR_LIGHT,
  // エラー・ミドルライトのカラー
  errorMiddleLight: TYPES.COLOR_CODE_ERROR_MIDDLE_LIGHT,
  // エラー・ダークのカラー
  errorDark: TYPES.COLOR_CODE_ERROR_DARK,
  // 警告・ベーシックのカラー
  warningBasic: TYPES.COLOR_CODE_WARNING_BASIC,
  // 警告・ライトのカラー
  warningLight: TYPES.COLOR_CODE_WARNING_LIGHT,
  // 警告・ミドルライトのカラー
  warningMiddleLight: TYPES.COLOR_CODE_WARNING_MIDDLE_LIGHT,
  // 警告・ダークのカラー
  warningDark: TYPES.COLOR_CODE_WARNING_DARK,
  // 成功・ベーシックのカラー
  successBasic: TYPES.COLOR_CODE_SUCCESS_BASIC,
  // 成功・ライトのカラー
  successLight: TYPES.COLOR_CODE_SUCCESS_LIGHT,
  // 成功・ミドルライトのカラー
  successMiddleLight: TYPES.COLOR_CODE_SUCCESS_MIDDLE_LIGHT,
  // 成功・ダークのカラー
  successDark: TYPES.COLOR_CODE_SUCCESS_DARK,
  // 非活性・ベーシックのカラー
  disabledBasic: TYPES.COLOR_CODE_DISABLED_BASIC,
  // 非活性・ライトのカラー
  disabledLight: TYPES.COLOR_CODE_DISABLED_LIGHT,
  // 非活性・ミドルライトのカラー
  disabledMiddleLight: '',
  // 非活性・ダークのカラー
  disabledDark: '',
  // サーフェス・ベーシックのカラー
  surfaceBasic: TYPES.COLOR_CODE_SURFACE_BASIC,
  // サーフェス・ライトのカラー
  surfaceLight: '',
  // サーフェス・ミドルライトのカラー
  surfaceMiddleLight: '',
  // サーフェス・ダークのカラー
  surfaceDark: TYPES.COLOR_CODE_SURFACE_DARK,
  // 背景・ベーシックのカラー
  backgroundBasic: TYPES.COLOR_CODE_BACKGROUND_BASIC,
  // 背景・ライトのカラー
  backgroundLight: '',
  // 背景・ミドルライトのカラー
  backgroundMiddleLight: '',
  // 背景・ダークのカラー
  backgroundDark: '',
  // 枠・ベーシックのカラー
  frameBasic: TYPES.COLOR_CODE_FRAME_BASIC,
  // 枠・ライトのカラー
  frameLight: TYPES.COLOR_CODE_FRAME_LIGHT,
  // 枠・ミドルライトのカラー
  frameMiddleLight: '',
  // 枠・ダークのカラー
  frameDark: TYPES.COLOR_CODE_FRAME_DARK,
  // 文字・ベーシックのカラー
  textBasic: '',
  // 文字・ライトのカラー
  textLight: TYPES.COLOR_CODE_TEXT_LIGHT,
  // 文字・ミドルライトのカラー
  textMiddleLight: '',
  // 文字・ダークのカラー
  textDark: TYPES.COLOR_CODE_TEXT_DARK,

  // ボタンのメイン配色パターン
  buttonBackgroundColorMain: {
    backgroundColor: TYPES.COLOR_CODE_MAIN_BASIC,
    hoverBackgroundColor: TYPES.COLOR_CODE_MAIN_DARK,
    focusBackgroundColor: TYPES.COLOR_CODE_MAIN_LIGHT,
  },
  // ボタンの成功配色パターン
  buttonBackgroundColorSuccess: {
    backgroundColor: TYPES.COLOR_CODE_SUCCESS_BASIC,
    hoverBackgroundColor: TYPES.COLOR_CODE_SUCCESS_DARK,
    focusBackgroundColor: TYPES.COLOR_CODE_SUCCESS_LIGHT,
  },
  // ボタンのエラー配色パターン
  buttonBackgroundColorError: {
    backgroundColor: TYPES.COLOR_CODE_ERROR_BASIC,
    hoverBackgroundColor: TYPES.COLOR_CODE_ERROR_DARK,
    focusBackgroundColor: TYPES.COLOR_CODE_ERROR_LIGHT,
  },
};

const colorReducer = reducerWithInitialState(initialColorState).case(
  setColorCode,
  (state, colorCode) => {
    const code = colorCode.colorCode;
    switch (colorCode.colorType) {
      case TYPES.COLOR_TYPES.MAIN:
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.BASIC) {
          const buttonBackgroundColorMain = {
            ...state.buttonBackgroundColorMain,
          };
          buttonBackgroundColorMain.backgroundColor = code;
          return { ...state, mainBasic: code, buttonBackgroundColorMain };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.LIGHT) {
          const buttonBackgroundColorMain = {
            ...state.buttonBackgroundColorMain,
          };
          buttonBackgroundColorMain.focusBackgroundColor = code;
          return { ...state, mainLight: code, buttonBackgroundColorMain };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.MIDDLE_LIGHT) {
          return { ...state, mainMiddleLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.DARK) {
          const buttonBackgroundColorMain = {
            ...state.buttonBackgroundColorMain,
          };
          buttonBackgroundColorMain.hoverBackgroundColor = code;
          return { ...state, mainDark: code, buttonBackgroundColorMain };
        }
        return { ...state };
      case TYPES.COLOR_TYPES.ACCENT:
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.BASIC) {
          return { ...state, accentBasic: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.LIGHT) {
          return { ...state, accentLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.MIDDLE_LIGHT) {
          return { ...state, accentMiddleLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.DARK) {
          return { ...state, accentDark: code };
        }
        return { ...state };
      case TYPES.COLOR_TYPES.ERROR:
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.BASIC) {
          const buttonBackgroundColorError = {
            ...state.buttonBackgroundColorError,
          };
          buttonBackgroundColorError.backgroundColor = code;
          return { ...state, errorBasic: code, buttonBackgroundColorError };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.LIGHT) {
          const buttonBackgroundColorError = {
            ...state.buttonBackgroundColorError,
          };
          buttonBackgroundColorError.focusBackgroundColor = code;
          return { ...state, errorLight: code, buttonBackgroundColorError };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.MIDDLE_LIGHT) {
          return { ...state, errorMiddleLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.DARK) {
          const buttonBackgroundColorError = {
            ...state.buttonBackgroundColorError,
          };
          buttonBackgroundColorError.hoverBackgroundColor = code;
          return { ...state, errorDark: code, buttonBackgroundColorError };
        }
        return { ...state };
      case TYPES.COLOR_TYPES.WARNING:
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.BASIC) {
          return { ...state, warningBasic: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.LIGHT) {
          return { ...state, warningLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.MIDDLE_LIGHT) {
          return { ...state, warningMiddleLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.DARK) {
          return { ...state, warningDark: code };
        }
        return { ...state };
      case TYPES.COLOR_TYPES.SUCCESS:
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.BASIC) {
          const buttonBackgroundColorSuccess = {
            ...state.buttonBackgroundColorSuccess,
          };
          buttonBackgroundColorSuccess.backgroundColor = code;
          return { ...state, successBasic: code, buttonBackgroundColorSuccess };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.LIGHT) {
          const buttonBackgroundColorSuccess = {
            ...state.buttonBackgroundColorSuccess,
          };
          buttonBackgroundColorSuccess.focusBackgroundColor = code;
          return { ...state, successLight: code, buttonBackgroundColorSuccess };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.MIDDLE_LIGHT) {
          return { ...state, successMiddleLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.DARK) {
          const buttonBackgroundColorSuccess = {
            ...state.buttonBackgroundColorSuccess,
          };
          buttonBackgroundColorSuccess.hoverBackgroundColor = code;
          return { ...state, successDark: code, buttonBackgroundColorSuccess };
        }
        return { ...state };
      case TYPES.COLOR_TYPES.DISABLED:
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.BASIC) {
          return { ...state, disabledBasic: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.LIGHT) {
          return { ...state, disabledLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.MIDDLE_LIGHT) {
          return { ...state, disabledMiddleLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.DARK) {
          return { ...state, disabledDark: code };
        }
        return { ...state };
      case TYPES.COLOR_TYPES.SURFACE:
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.BASIC) {
          return { ...state, surfaceBasic: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.LIGHT) {
          return { ...state, surfaceLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.MIDDLE_LIGHT) {
          return { ...state, surfaceMiddleLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.DARK) {
          return { ...state, surfaceDark: code };
        }
        return { ...state };
      case TYPES.COLOR_TYPES.BACKGROUND:
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.BASIC) {
          return { ...state, backgroundBasic: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.LIGHT) {
          return { ...state, backgroundLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.MIDDLE_LIGHT) {
          return { ...state, backgroundMiddleLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.DARK) {
          return { ...state, backgroundDark: code };
        }
        return { ...state };
      case TYPES.COLOR_TYPES.TEXT:
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.BASIC) {
          return { ...state, textBasic: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.LIGHT) {
          return { ...state, textLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.MIDDLE_LIGHT) {
          return { ...state, textMiddleLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.DARK) {
          return { ...state, textDark: code };
        }
        return { ...state };
      case TYPES.COLOR_TYPES.FRAME:
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.BASIC) {
          return { ...state, frameBasic: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.LIGHT) {
          return { ...state, frameLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.MIDDLE_LIGHT) {
          return { ...state, frameMiddleLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.DARK) {
          return { ...state, frameDark: code };
        }
        return { ...state };
      case TYPES.COLOR_TYPES.CONCEPT:
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.BASIC) {
          return { ...state, conceptBasic: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.LIGHT) {
          return { ...state, conceptLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.MIDDLE_LIGHT) {
          return { ...state, conceptMiddleLight: code };
        }
        if (colorCode.swatchType === TYPES.SWATCH_TYPES.DARK) {
          return { ...state, conceptDark: code };
        }
        return { ...state };
      default:
        return { ...state };
    }
  },
);

export default colorReducer;
