import React, { useLayoutEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AppState } from 'duck/Reducer';
import { Setting } from 'duck/manager/setting/State';
import useInputTemplate, { HandleSetAnswerProps } from 'hooks/useInputTemplate';
import Button from 'presentational/general/atoms/button/Button';
import Loading from 'presentational/general/atoms/other/Loading';
import FormPanel from 'presentational/general/organisms/inputTemplateForm/FormPanel';
import TemplatePanel from 'presentational/general/organisms/area/TemplatePanel';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import Text from 'presentational/general/atoms/text/Text';
import PageTitle from 'presentational/general/atoms/text/PageTitle';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import inputSubjectData from 'custom/formData/inputSubject.json';
import * as TYPES from 'common/Types';
import * as membersActions from 'duck/custom/members/Actions';
import { Member } from 'duck/custom/members/State';
import { ButtonColorType } from 'duck/color/State';
import { Template } from 'duck/Types';
import { CustomMemberDetail } from 'oapi/api';
import CONSTS, { MEMBER_ID_MAP } from 'common/Consts';
import { changeRefValue } from 'common/Utility';

const FinishButtonArea = styled.div`
  text-align: center;
`;

type PanelAreaProps = {
  device: TYPES.DEVICE_TYPES;
};

const PanelArea = styled.div<PanelAreaProps>`
  padding-left: 8px;
  background-color: #dd8e6c;
  ${({ device }: PanelAreaProps) =>
    device === TYPES.DEVICE_TYPES.PC && 'margin-left: 7px;'}
`;

const generateNewMember = (): CustomMemberDetail => {
  return {
    id: '',
    identification: '代諾者',
    relation: '',
    relation_detail: '',
    first_name: '',
    last_name: '',
    first_name_kana: '',
    last_name_kana: '',
    zip: '',
    address: '',
    building: '',
    phone_number: '',
    birthdate: '',
    sample_ids: [],
    sample_id: '',
    has_informed_consent_checked: false,
    sample_id_version: '',
  };
};

const amIincludedInMembers = (members: Member[]): boolean => {
  const m = members.find((member) => member.identification === '本人');
  if (m) {
    return true;
  }
  return false;
};

type InputSubjectProps = {
  deviceType: TYPES.DEVICE_TYPES;
  self?: CustomMemberDetail;
  members: Member[];
  newMember?: CustomMemberDetail;
  getSelf: () => Promise<boolean>;
  fetchAll: () => Promise<boolean>;
  setNewMember: (member: CustomMemberDetail) => void;
  isLoading: boolean;
  textLight: string;
  sampleId: string;
  buttonBackgroundColorSuccess: ButtonColorType;
  buttonBackgroundColorError: ButtonColorType;
  setting: Setting;
};

const InputSubject = ({
  deviceType,
  self,
  members,
  getSelf,
  fetchAll,
  setNewMember,
  isLoading,
  newMember,
  textLight,
  sampleId,
  buttonBackgroundColorSuccess,
  buttonBackgroundColorError,
  setting,
}: InputSubjectProps) => {
  const [isOpenErrorDialog, setIsOpenErrorDialog] = useState(false);
  const errorDialogButtons = [
    {
      text: 'OK',
      onClick: () => setIsOpenErrorDialog(false),
    },
  ];
  const { history } = useReactRouter();
  const [isOpenWarningDialog, setIsOpenWarningDialog] = useState(false);
  const data: Template = { ...(inputSubjectData as Template) };
  let defMember = generateNewMember();
  if (newMember) {
    defMember = newMember;
  } else if (self && !amIincludedInMembers(members)) {
    defMember = self;
  }
  const refs: { [key: string]: React.RefObject<any> } = {};
  data.panels.forEach((panel, i) => {
    panel.forms.forEach((form, j) => {
      let title = '';
      switch (form.id) {
        case 'identification':
          data.panels[i].forms[j].disabled = amIincludedInMembers(members);
          title = defMember.identification;
          break;
        case 'relation':
          title = defMember.relation;
          break;
        case 'relation_detail':
          title = defMember.relation_detail;
          break;
        case 'first_name':
          title = defMember.first_name;
          break;
        case 'last_name':
          title = defMember.last_name;
          break;
        case 'first_name_kana':
          title = defMember.first_name_kana;
          break;
        case 'last_name_kana':
          title = defMember.last_name_kana;
          break;
        case 'zip':
          title = defMember.zip;
          break;
        case 'address':
          title = defMember.address;
          break;
        case 'building':
          title = defMember.building;
          break;
        case 'phone_number':
          title = defMember.phone_number;
          break;
        case 'birthdate':
          title = defMember.birthdate;
          break;
        default:
          break;
      }
      refs[form.id] = React.createRef();
      data.panels[i].forms[j].inputRef = refs[form.id];
      data.panels[i].forms[j].answers = [
        {
          title,
          value: title,
        },
      ];
    });
  });

  const {
    answers,
    isVisiblePanels,
    isVisibleForms,
    isDisabledFinishButton,
    handleSetAnswer,
  } = useInputTemplate(data);

  useLayoutEffect(() => {
    getSelf();
    fetchAll();
  }, [getSelf, fetchAll]);

  const handleControlAnswer = (payload: HandleSetAnswerProps) => {
    if (payload.formId === 'identification') {
      if (payload.values.length === 1 && payload.values[0] === '代諾者') {
        const initMember = generateNewMember();
        changeRefValue(refs.first_name, initMember.first_name);
        changeRefValue(refs.last_name, initMember.last_name);
        changeRefValue(refs.first_name_kana, initMember.first_name_kana);
        changeRefValue(refs.last_name_kana, initMember.last_name_kana);
        changeRefValue(refs.zip, initMember.zip);
        changeRefValue(refs.address, initMember.address);
        changeRefValue(refs.building, initMember.building);
        changeRefValue(refs.phone_number, initMember.phone_number);
        changeRefValue(refs.birthdate, initMember.birthdate);
      } else if (payload.values.length === 1 && payload.values[0] === '本人') {
        changeRefValue(refs.first_name, self ? self.first_name : '');
        changeRefValue(refs.last_name, self ? self.last_name : '');
        changeRefValue(refs.first_name_kana, self ? self.first_name_kana : '');
        changeRefValue(refs.last_name_kana, self ? self.last_name_kana : '');
        changeRefValue(refs.zip, self ? self.zip : '');
        changeRefValue(refs.address, self ? self.address : '');
        changeRefValue(refs.building, self ? self.building : '');
        changeRefValue(refs.phone_number, self ? self.phone_number : '');
        changeRefValue(refs.birthdate, self ? self.birthdate : '');
      }
    }
    handleSetAnswer(payload);
  };

  const generateMemberFromAnswers = () => {
    const member = generateNewMember();
    answers.current.forEach((answer) => {
      if (answer.values.length === 0) {
        return;
      }
      const val = answer.values[0];
      switch (answer.formId) {
        case 'identification':
          member.identification = val;
          break;
        case 'relation':
          member.relation = val;
          break;
        case 'relation_detail':
          member.relation_detail = val;
          break;
        case 'first_name':
          member.first_name = val;
          break;
        case 'last_name':
          member.last_name = val;
          break;
        case 'first_name_kana':
          member.first_name_kana = val;
          break;
        case 'last_name_kana':
          member.last_name_kana = val;
          break;
        case 'zip':
          member.zip = val;
          break;
        case 'address':
          member.address = val;
          break;
        case 'building':
          member.building = val;
          break;
        case 'phone_number':
          member.phone_number = val;
          break;
        case 'birthdate':
          member.birthdate = val;
          break;
        default:
          break;
      }
    });
    return member;
  };

  const handleClickButton = () => {
    if (isDisabledFinishButton) {
      setIsOpenErrorDialog(true);
      return;
    }
    const member = generateMemberFromAnswers();
    if (
      member.identification === '代諾者' &&
      self &&
      member.first_name === self.first_name &&
      member.last_name === self.last_name
    ) {
      setIsOpenWarningDialog(true);
      return;
    }
    member.sample_id = sampleId;
    member.sample_ids = [sampleId];
    setNewMember(member);
    history.push(CONSTS.PATH_VIEW_SUBJECT);
  };

  const handleClickDialogOkButton = () => {
    const member = generateMemberFromAnswers();
    member.sample_id = sampleId;
    member.sample_ids = [sampleId];
    setNewMember(member);
    history.push(CONSTS.PATH_VIEW_SUBJECT);
  };

  if (sampleId === '') {
    history.push(CONSTS.PATH_TOP);
  }

  return (
    <BeforeLoginTemplate
      device={deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <PanelArea device={deviceType}>
        <Text color={textLight} fontWeight="bold">
          被検者の個人情報を登録します
        </Text>
      </PanelArea>
      <PageTitle device={deviceType}>
        被検者情報登録
        {answers.current[1]?.values[0] === '代諾者' && '（代諾）'}
      </PageTitle>
      {self &&
        data.panels.map((panel: any, panelIndex: number) => {
          return (
            <FormPanel
              key={panel.id}
              device={deviceType}
              panel={panel}
              visible={isVisiblePanels[panelIndex]}
              isVisibleForms={isVisibleForms[panelIndex]}
              handleSetAnswer={handleControlAnswer}
            />
          );
        })}
      <TemplatePanel
        device={deviceType}
        templateType={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
      >
        <FinishButtonArea>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            onClick={handleClickButton}
          >
            確認
          </Button>
        </FinishButtonArea>
      </TemplatePanel>
      <Dialog
        open={isOpenWarningDialog}
        onClose={() => {
          setIsOpenWarningDialog(false);
        }}
        title="被検者情報"
        buttons={[
          {
            text: '修正',
            onClick: () => {
              setIsOpenWarningDialog(false);
            },
            ...buttonBackgroundColorError,
          },
          {
            text: '確定',
            onClick: () => {
              handleClickDialogOkButton();
              setIsOpenWarningDialog(false);
            },
            ...buttonBackgroundColorSuccess,
          },
        ]}
      >
        一部の被検者情報が利用者（代諾者）の情報と同じになっています。
        <br />
        被検者の入力情報に誤りはありませんか？
      </Dialog>
      <Dialog
        open={isOpenErrorDialog}
        onClose={() => setIsOpenErrorDialog(false)}
        title="入力情報に誤りがあります"
        buttons={errorDialogButtons}
      >
        {answers.current.map((ans) =>
          ans.isError ? (
            <div>
              {MEMBER_ID_MAP[ans.formId]}
              の入力を確認してください。
            </div>
          ) : null,
        )}
      </Dialog>
      <Loading display={isLoading} />
    </BeforeLoginTemplate>
  );
};

export default connect(
  ({ common, members, color, sampleidInfo, managerSetting }: AppState) => ({
    self: members.self,
    members: members.members,
    newMember: members.newMember,
    deviceType: common.deviceType,
    isLoading: members.isLoading,
    textLight: color.textLight,
    sampleId: sampleidInfo.sampleId,
    buttonBackgroundColorSuccess: color.buttonBackgroundColorSuccess,
    buttonBackgroundColorError: color.buttonBackgroundColorError,
    setting: managerSetting.setting,
  }),
  {
    fetchAll: membersActions.fetchAll,
    getSelf: membersActions.getSelf,
    setNewMember: membersActions.setNewMember,
  },
)(InputSubject);
