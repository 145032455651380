import * as TYPES from 'common/Types';
import { Slider } from 'presentational/general/atoms/compositeInput/SliderList';

/**
 * カラーコードを返却
 * @param {string} colorType - 色区分
 * @param {string} swatchType - スウォッチ区分
 * @return {string} カラーコード
 */

type colorCodeType = {
  colorType: TYPES.COLOR_TYPES;
  swatchType: TYPES.SWATCH_TYPES;
  colorCode: string;
};
export const getColorCode = (
  colorType: TYPES.COLOR_TYPES,
  swatchType: TYPES.SWATCH_TYPES,
): string => {
  // TODO DB取得
  const colorCodeList: colorCodeType[] = [
    {
      colorType: TYPES.COLOR_TYPES.MAIN,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_MAIN_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.MAIN,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_MAIN_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.MAIN,
      swatchType: TYPES.SWATCH_TYPES.DARK,
      colorCode: TYPES.COLOR_CODE_MAIN_DARK,
    },
    {
      colorType: TYPES.COLOR_TYPES.ACCENT,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_ACCENT_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.ACCENT,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_ACCENT_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.ACCENT,
      swatchType: TYPES.SWATCH_TYPES.DARK,
      colorCode: TYPES.COLOR_CODE_ACCENT_DARK,
    },
    {
      colorType: TYPES.COLOR_TYPES.ERROR,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_ERROR_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.ERROR,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_ERROR_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.ERROR,
      swatchType: TYPES.SWATCH_TYPES.MIDDLE_LIGHT,
      colorCode: TYPES.COLOR_CODE_ERROR_MIDDLE_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.ERROR,
      swatchType: TYPES.SWATCH_TYPES.DARK,
      colorCode: TYPES.COLOR_CODE_ERROR_DARK,
    },
    {
      colorType: TYPES.COLOR_TYPES.WARNING,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_WARNING_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.WARNING,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_WARNING_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.WARNING,
      swatchType: TYPES.SWATCH_TYPES.MIDDLE_LIGHT,
      colorCode: TYPES.COLOR_CODE_WARNING_MIDDLE_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.WARNING,
      swatchType: TYPES.SWATCH_TYPES.DARK,
      colorCode: TYPES.COLOR_CODE_WARNING_DARK,
    },
    {
      colorType: TYPES.COLOR_TYPES.SUCCESS,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_SUCCESS_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.SUCCESS,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_SUCCESS_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.SUCCESS,
      swatchType: TYPES.SWATCH_TYPES.MIDDLE_LIGHT,
      colorCode: TYPES.COLOR_CODE_SUCCESS_MIDDLE_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.SUCCESS,
      swatchType: TYPES.SWATCH_TYPES.DARK,
      colorCode: TYPES.COLOR_CODE_SUCCESS_DARK,
    },
    {
      colorType: TYPES.COLOR_TYPES.DISABLED,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_DISABLED_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.DISABLED,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_DISABLED_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.SURFACE,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_SURFACE_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.BACKGROUND,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_BACKGROUND_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.TEXT,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_TEXT_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.TEXT,
      swatchType: TYPES.SWATCH_TYPES.DARK,
      colorCode: TYPES.COLOR_CODE_TEXT_DARK,
    },
    {
      colorType: TYPES.COLOR_TYPES.FRAME,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_FRAME_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.FRAME,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_FRAME_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.FRAME,
      swatchType: TYPES.SWATCH_TYPES.DARK,
      colorCode: TYPES.COLOR_CODE_FRAME_DARK,
    },
    {
      colorType: TYPES.COLOR_TYPES.CONCEPT,
      swatchType: TYPES.SWATCH_TYPES.BASIC,
      colorCode: TYPES.COLOR_CODE_CONCEPT_BASIC,
    },
    {
      colorType: TYPES.COLOR_TYPES.CONCEPT,
      swatchType: TYPES.SWATCH_TYPES.LIGHT,
      colorCode: TYPES.COLOR_CODE_CONCEPT_LIGHT,
    },
    {
      colorType: TYPES.COLOR_TYPES.CONCEPT,
      swatchType: TYPES.SWATCH_TYPES.DARK,
      colorCode: TYPES.COLOR_CODE_CONCEPT_DARK,
    },
  ];

  const color = colorCodeList.find(
    (e) => e.colorType === colorType && e.swatchType === swatchType,
  );
  if (color) {
    return color.colorCode;
  }
  return '';
};

/**
 * ヘクサコード(#FFFFFF)の形からrgbaに変換する
 * @param {string} hex - 色コード(#FFFFFF)
 * @param {number} alpha - アルファ値(0.5)
 * @return {string} rgba値
 */
export const hex2rgba = (hex: string, alpha: number): string => {
  let color = hex;
  if (hex.slice(0, 1) === '#') {
    color = hex.slice(1);
  }
  if (color.length === 3)
    color =
      color.slice(0, 1) +
      color.slice(0, 1) +
      color.slice(1, 2) +
      color.slice(1, 2) +
      color.slice(2, 3) +
      color.slice(2, 3);
  return `rgba(${[color.slice(0, 2), color.slice(2, 4), color.slice(4, 6)]
    .map((str: string) => {
      return parseInt(str, 16);
    })
    .join(',')},${alpha})`;
};

/**
 * ウィンドウサイズを取得する
 */
export const getWindowDimensions = (): { width: number; height: number } => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

/**
 * ウィンドウ幅を取得する
 */
export const getWindowWidth = (): number => {
  return getWindowDimensions().width;
};

/**
 * ウィンドウの高さを取得する
 */
export const getWindowHeight = (): number => {
  return getWindowDimensions().height;
};

/**
 * デバイス区分を取得する
 */
export const getDeviceType = (): TYPES.DEVICE_TYPES => {
  const { width } = getWindowDimensions();
  if (width < 1000) {
    return TYPES.DEVICE_TYPES.MOBILE;
  }
  return TYPES.DEVICE_TYPES.PC;
};

export const getDeviceOs = (): TYPES.DEVICE_OS_TYPES => {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0) {
    return TYPES.DEVICE_OS_TYPES.IPHONE;
  }
  if (ua.indexOf('ipad') > 0) {
    return TYPES.DEVICE_OS_TYPES.IPAD;
  }
  if (ua.indexOf('android') > 0) {
    return TYPES.DEVICE_OS_TYPES.ANDROID;
  }
  return TYPES.DEVICE_OS_TYPES.PC;
};

/**
 * 埋め込みメッセージを取得する
 * @param {string} message メッセージ({n}を置換文字列に変換)
 * @param {string[]} embedStrings 置換文字列配列
 */
export const replaceMessage = (
  message: string,
  embedStrings: string[],
): string => {
  let embeddedMessage = message;
  embedStrings.forEach((s, i) => {
    embeddedMessage = embeddedMessage.replace(`{${i}}`, s);
  });
  return embeddedMessage;
};

/**
 * 単一項目バリデーション
 * @param {Validator.Validator<
 *  value: string | number;
 * }>} validation バリデーション
 */
export const basicValidation = (
  validation: Validator.Validator<{
    value: string | number;
  }>,
) => {
  if (validation.fails()) {
    return false;
  }
  return true;
};

/**
 * 画像の元サイズを取得する関数
 * @param {string} url - 画像URL
 */
export const fetchImageSize = (url: string) => {
  return new Promise<{ width: number; height: number }>((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.src = url;
  });
};

/**
 * 最大公約数を取得
 * @param {number} x - 数値X
 * @param {number} y - 数値Y
 */
export function getMaxMetric(x: number, y: number): number {
  if (y === 0) return x;
  return getMaxMetric(y, x % y);
}

/**
 * アスペクト比を算出する
 * @param {number} width - 幅
 * @param {number} height - 高さ
 * @param {object} アスペクト比
 */
export const getAspectRatio = (
  width: number,
  height: number,
): { width: number; height: number } => {
  const metric: number = getMaxMetric(width, height);

  return {
    width: width / metric,
    height: height / metric,
  };
};

/**
 * スライダー合計値を取得
 * @param {Slider[]} sliders スライダー配列
 */
export const getSliderListSumValue = (sliders: Slider[]): number => {
  let sum = 0;
  sliders.forEach((slider) => {
    sum += slider.value;
  });
  return sum;
};

/**
 * スライダーリスト不足値を取得
 * @param {Slider[]} sliders スライダー配列
 * @param {number} total 合計
 */
export const getSliderListShortageValue = (
  sliders: Slider[],
  total: number,
): number => {
  return total - getSliderListSumValue(sliders);
};

/**
 * 入力項目配列の状態を非活性として扱うか判定
 * @param {{ hasInput: boolean; isError: boolean }[]} inputList 入力項目配列
 */
export const isDisabledInputList = (
  inputList: { hasInput: boolean; isError: boolean }[],
) => {
  return inputList.some((input) => {
    if (!input.hasInput || input.isError) {
      return true;
    }
    return false;
  });
};

/**
 * エラーを表示するか判定
 * @param {boolean} hasInput 入力済フラグ
 * @param {boolean} isError エラーフラグ
 */
export const isDisplayError = (hasInput: boolean, isError: boolean) => {
  return hasInput ? isError : false;
};

/**
 * パスワードの強度を調査する
 * @param {string} password - パスワード
 */
export const checkPasswordStrength = (
  password: string,
): TYPES.PASSWORD_STRENGTH_TYPES => {
  let strength = 0; // 強さ
  if (password.length < 8) {
    return TYPES.PASSWORD_STRENGTH_TYPES.LOW;
  }
  // 文字数が7より大きいければ+1
  if (password.length >= 8) strength += 1;
  // 英字の大文字と小文字を含んでいれば+1
  if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) strength += 1;
  // 英字と数字を含んでいれば+1
  if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) strength += 1;
  // 記号を含んでいれば+1
  if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) strength += 1;
  // 記号を2つ含んでいれば+1
  if (password.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,%,&,@,#,$,^,*,?,_,~])/))
    strength += 1;

  // 点数を元に強さを計測
  if (strength < 2) {
    return TYPES.PASSWORD_STRENGTH_TYPES.LOW;
  }
  if (strength === 2) {
    return TYPES.PASSWORD_STRENGTH_TYPES.MIDDLE;
  }
  return TYPES.PASSWORD_STRENGTH_TYPES.HIGH;
};

/**
 * 指定した配列内に、第二引数と同じ値の要素がいくつあるかを求める。
 *
 * @param {boolean[]} array - 対象配列
 * @param {boolean} value - 検索値
 */
export const getSpecificValueCountFromArray = (
  array: boolean[],
  value: boolean,
): number => {
  return array.reduce<number>((acc, curr) => {
    if (value === curr) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

/**
 * 配列(1次元)を比較する(高速)
 * @param {Array<any>} arr1 - 配列1
 * @param {Array<any>} arr2 - 配列2
 */
export const isEqualMembers = (arr1: Array<any>, arr2: Array<any>): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

/**
 * 配列(1次元)をソートして比較する(高速)
 * @param {Array<any>} arr1 - 配列1
 * @param {Array<any>} arr2 - 配列2
 */
export const isSameMembers = (arr1: Array<any>, arr2: Array<any>): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  arr1.sort();
  arr2.sort();
  return isEqualMembers(arr1, arr2);
};

/**
 * 配列/連想配列(多次元)をソートして比較する(低速)
 * @param {{ [index: string]: any } & Array<any>} obj1 - 配列/連想配列1
 * @param {{ [index: string]: any } & Array<any>} obj2 - 配列/連想配列2
 */
export const isEqualObjectMembers = (
  obj1: { [key: string]: any },
  obj2: { [key: string]: any },
): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

/**
 * 配列1(1次元)のメンバー全員が配列2(1次元)に含まれているか判定する
 * @param {Array<any>} arr1 - 配列1
 * @param {Array<any>} arr2 - 配列2
 */
export const inSameMembers = (arr1: Array<any>, arr2: Array<any>): boolean => {
  for (let i = 0; i < arr1.length; i += 1) {
    if (arr2.indexOf(arr1[i]) === -1) {
      return false;
    }
  }
  return true;
};

/*
 * 正規表現に使用する文字を全てエスケープする
 * @param {string} val - 対象の文字列
 */
export const escapeRegExp = (val: string) => {
  const specials = [
    '-',
    '[',
    ']',
    '/',
    '{',
    '}',
    '(',
    ')',
    '*',
    '+',
    '?',
    '.',
    '\\',
    '^',
    '$',
    '|',
  ];
  const regex = RegExp(`[${specials.join('\\')}]`, 'g');
  return val.replace(regex, '\\$&');
};

export const changeRefValue = (ref: React.RefObject<any>, val: string) => {
  if (!ref || !ref.current) {
    return;
  }
  const nativeInputValueSetter = (Object as any).getOwnPropertyDescriptor(
    HTMLInputElement.prototype,
    'value',
  ).set;
  nativeInputValueSetter.call(ref.current, val);
  const ev2 = new Event('input', { bubbles: true });
  ref.current.dispatchEvent(ev2);
};

export const isParaCheckBio = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  return apiUrl ? apiUrl.includes('paracheck') : false;
};

export default {
  getColorCode,
  hex2rgba,
  getWindowDimensions,
  getWindowWidth,
  getDeviceType,
  replaceMessage,
  fetchImageSize,
  getMaxMetric,
  getAspectRatio,
  getSliderListSumValue,
  getSliderListShortageValue,
  isDisabledInputList,
  checkPasswordStrength,
  getSpecificValueCountFromArray,
  isEqualMembers,
  isSameMembers,
  isEqualObjectMembers,
  escapeRegExp,
  changeRefValue,
  isParaCheckBio,
};
