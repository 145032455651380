import React, { useState, useLayoutEffect } from 'react';
import { AppState } from 'duck/Reducer';
import { connect } from 'react-redux';
import managerSettingActions from 'duck/manager/setting/Actions';
import { APIError } from 'common/APIWrapper';
import * as TYPES from 'common/Types';
import CONSTS from 'common/Consts';
import SettingTemplate from 'presentational/custom/templates/SettingTemplate';
import { ButtonColorType } from 'duck/color/State';
import { Answers } from 'duck/client/template/State';
import { generateRequestAnswer } from 'duck/client/template/Actions';
import { AnswerProgress, FormAnswer, ResultSetting } from 'oapi';

type SettingProps = {
  getSetting: () => Promise<ResultSetting | null>;
  putSetting: (answers: Array<FormAnswer>) => Promise<boolean>;
  deviceType: TYPES.DEVICE_TYPES;
  buttonBackgroundColorError: ButtonColorType;
  isLoading: boolean;
  error?: APIError;
};

const Setting = ({
  getSetting,
  putSetting,
  buttonBackgroundColorError,
  deviceType,
  isLoading,
  error,
}: SettingProps) => {
  const [setting, setSetting] = useState<ResultSetting | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const handleSaveAnswers = async (
    answersType: AnswerProgress,
    answers: Answers,
  ) => {
    if (!setting) {
      return false;
    }
    const saveAnswers = generateRequestAnswer(
      setting.template,
      answers,
      AnswerProgress.ANSWERED,
    );
    if (await putSetting(saveAnswers.answers)) {
      return true;
    }
    setIsError(true);
    return false;
  };
  useLayoutEffect(() => {
    const exec = async () => {
      const data = await getSetting();
      if (!data) {
        setIsError(true);
        return;
      }
      setSetting(data);
    };
    exec();
  }, [getSetting, setIsError]);
  return (
    <div>
      <SettingTemplate
        deviceType={deviceType}
        setting={setting}
        buttonBackgroundColorError={buttonBackgroundColorError}
        saveAnswers={handleSaveAnswers}
        error={error}
        setIsError={setIsError}
        backTo={CONSTS.PATH_MANAGER}
        isError={isError}
        isLoading={isLoading}
      />
    </div>
  );
};

export default connect(
  ({ common, color, managerSetting }: AppState) => ({
    deviceType: common.deviceType,
    isLoading: managerSetting.isLoading,
    error: managerSetting.error,
    buttonBackgroundColorError: color.buttonBackgroundColorError,
  }),
  {
    getSetting: managerSettingActions.getSetting,
    putSetting: managerSettingActions.putSetting,
  },
)(Setting);
