import styled from 'styled-components';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';

export type TextProps = {
  color?: string;
  fontSize?: number;
  fontWeight?: 'bold';
  textDark: string;
};

/**
 * 【部品】【原子】【文章】文字 <Text>タグ
 * @param {string} color - フォントカラー
 * @param {number} fontSize - フォントサイズ
 * @param {string} fontWeight - 太字指定
 * @param {string} textDark - 色
 */
const Text = styled.span<TextProps>`
  color: ${({ color, textDark }: TextProps) => color || textDark};
  font-size: ${({ fontSize = 13 }: TextProps) => fontSize}px;
  ${({ fontWeight }: TextProps) => fontWeight && `font-weight: ${fontWeight};`}
`;

export default connect(({ color }: AppState) => ({
  textDark: color.textDark,
}))(Text);
