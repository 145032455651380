import actionCreatorFactory from 'typescript-fsa';
import Axios from 'axios';
import { APIError } from 'common/APIWrapper';

interface ZipCodeResultData {
  // 郵便番号
  zipcode: string;
  // 都道府県コード
  prefcode: string;
  // 都道府県名
  address1: string;
  // 市区町村名
  address2: string;
  // 町域名
  address3: string;
  // 都道府県名カナ
  kana1: string;
  // 市区町村名カナ
  kana2: string;
  // 町域名カナ
  kana3: string;
}
interface ZipCodeResult {
  // ステータス
  status: number;
  // メッセージ
  message: string | null;
  // 郵便番号情報
  results: Array<ZipCodeResultData>;
}

const STATUS = {
  // 成功
  SUCCESS: 200,
  // 入力パラメータエラー
  INPUT_FAILED: 400,
  // API内部エラー
  INTERNAL_ERROR: 500,
  // 予期せぬエラー
  UNEXPECTED_ERROR: 999,
};

const actionCreator = actionCreatorFactory();

export const changeDisplayedOperationGuideDialog = actionCreator<boolean>(
  'CHANGE_DISPLAYED_OPERATION_GUIDE_DIALOG',
);

/**
 * 郵便番号から住所を取得する
 * @param zipCode 郵便番号
 * @returns 住所
 */
export const getAddressByZipCode = async (
  zipCode: string | number,
): Promise<ZipCodeResult | undefined> => {
  try {
    const zipData = {
      params: {
        zipcode: zipCode,
      },
    };
    const url = process.env.REACT_APP_ZIP_CODE_SEARCH_ENDPOINT as string;
    const result = (await Axios.get(url, zipData)).data as ZipCodeResult;
    let status: number;

    switch (result.status) {
      case STATUS.SUCCESS:
        status = STATUS.SUCCESS;
        break;
      case STATUS.INPUT_FAILED:
        status = STATUS.INPUT_FAILED;
        break;
      case STATUS.INTERNAL_ERROR:
        status = STATUS.INTERNAL_ERROR;
        break;
      default:
        status = STATUS.UNEXPECTED_ERROR;
        break;
    }

    if (status !== STATUS.SUCCESS) {
      throw new APIError(
        'エラーが発生しました',
        status,
        result.message as string,
      );
    }

    return result;
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
    return undefined;
  }
};

export default { changeDisplayedOperationGuideDialog, getAddressByZipCode };
