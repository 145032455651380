import React from 'react';
import styled from 'styled-components';
import Text from 'presentational/general/atoms/text/Text';

/**
 * 【部品】【分子】【その他】Symgram個人情報の取り扱いについて <SymgramPersonalInfoContent>タグ
 */
const SymgramPersonalInfoContent = () => {
  const TextWrap = styled.div`
    margin: 10px 0;
  `;

  const BlockText = styled(Text)`
    display: block;
  `;

  return (
    <React.Fragment>
      <TextWrap>
        <BlockText fontWeight="bold">個人情報の取り扱いについて</BlockText>
        <BlockText>
          当社は、JIS Q
          15001:2017のA.3.4.2.5（A.3.4.2.4のうち本人から直接書面によって取得する場合の措置）に従い、
          個人情報を収集・保管いたします。
          <br />
          サービスで取得する個人情報の取り扱いは下記3項の利用目的のためであり、この目的の範囲を超えて利用することはございません。
          <br />
          なお、個人情報を提供される方と同意される方が異なる場合（代諾者様による同意）であっても、取得する個人情報については同様に扱います。
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">1. 事業者名</BlockText>
        <BlockText>シンバイオシス・ソリューションズ株式会社</BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">
          2. 個人情報に関する管理者の所属および連絡先
        </BlockText>
        <BlockText>
          個人情報保護管理者
          <br />
          {
            '所属\u3000：\u3000研究開発本部研究開発部\u3000プロダクト開発室\u3000室長'
          }
          <br />
          {'連絡先：\u3000info@symbiosis-solutions.co.jp'}
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">3. 個人情報の利用目的</BlockText>
        <BlockText>
          お預かりした個人情報は、以下の目的のために利用いたします。
          <br />
          1) サービスを提供するため
          <br />
          {
            '\u3000データ分析／レポート作成／検体、書類、登録情報などに不備があった場合の問い合わせ／レポート送付／医療機関へのレポート提供'
          }
          <br />
          2)
          サービスの利用に伴う連絡・メールマガジン・DM・各種お知らせなどの配信・送付のため
          <br />
          3) サービスの改善・新規サービスの開発およびマーケティングのため
          <br />
          4) キャンペーン・アンケート・モニター・取材などの実施のため
          <br />
          5) サービスに関するご意見、お問い合わせ内容の確認・回答のため
          <br />
          6) 利用規約などで禁じている行為などの調査のため
          <br />
          7) 当社の研究成果などの最新情報をお知らせするため
          <br />
          8) サービス改善や社内での研究を目的とした統計情報作成のため
          <br />
          （個人情報を伏せたかたちで、アンケートデータや腸内細菌叢データを解析します）
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">4. 個人情報の第三者提供</BlockText>
        <BlockText>
          当社は、ご提供いただいた個人情報を次の場合を除き第三者に開示・提供いたしません。
          <br />
          ・ご本人の同意がある場合
          <br />
          ・法令に基づく場合
          <br />
          ・人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
          <br />
          ・公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
          <br />
          ・国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、
          本人の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがあるとき
          <br />
          ・統計的なデータなどとして、被検者様個人を識別できない状態に加工した場合
          <br />
          ・当社と個人情報に関する秘密保持契約または秘密保持条項を含む取引契約を締結済みの業務委託先または提携先へ、
          業務に必要な範囲で提供する場合
          <br />
          ・医療機関への分析結果提供の場合
          <br />
          ・学術研究や研究成果の公表を目的とする場合。ただし、ここで提供する情報は、その情報だけでは個人を特定できない情報のみとします。（例：腸内細菌叢関連のデータ、性別データなど）
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">5. 個人情報取り扱いの委託</BlockText>
        <BlockText>
          当社は、事業運営上、よりよいサービスを提供するために業務の一部を外部に委託することがあります。
          業務委託先に対しては、個人情報を預けることがあります。この場合、個人情報を適切に取り扱っていると認められる委託先を選び、
          契約などにおいて個人情報の適正管理・機密保持などにより個人情報の漏洩防止に必要な事項を取決め、適切な管理を実施させます。
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">6. 個人情報の開示などの請求</BlockText>
        <BlockText>
          個人情報を提供される方は、ご自身の個人情報の開示など（利用目的の通知、開示、内容の訂正・追加・削除、利用の停止または消去、第三者への提供の停止、第三者提供記録）を、
          当社に申し出ることができます。その際、当社はご本人を確認させていただいたうえで、合理的な期間内に対応いたします。
          開示などの申し出の詳細につきましては、下記の窓口までお問い合わせください。
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText>
          〒101-0064
          <br />
          東京都千代田区神田猿楽町2-8-11 VORT水道橋Ⅲ 3F
          <br />
          シンバイオシス・ソリューションズ株式会社
          <br />
          苦情・相談窓口
          <br />
          メールアドレス：info@symbiosis-solutions.co.jp
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText>※特定電子メールはご遠慮ください</BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">
          7. 個人情報を提供されることの任意性について
        </BlockText>
        <BlockText>
          当社に個人情報を提供されるかどうかは、任意によるものです。
          ただし、必要な情報をいただけない場合、サービスなどが適切な状態で提供できない場合があります。
        </BlockText>
      </TextWrap>
    </React.Fragment>
  );
};

export default SymgramPersonalInfoContent;
