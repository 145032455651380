import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  KeyboardDatePicker as MuiKeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';
import { AppState } from 'duck/Reducer';
import Text from 'presentational/general/atoms/text/Text';
import 'moment/locale/ja';

export type DateFieldProps = {
  id: string;
  value?: Moment | null;
  width?: number;
  disabled?: boolean;
  prefix?: string;
  suffix?: string;
  placeholder?: string;
  onChange: (date: Moment | null, value?: string | null | undefined) => void;
  onBlur: () => void;
  onAccept: (date: Moment | null) => void;
  error?: boolean;
};

moment.locale('ja');

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin: 0 3px;
`;

type KeyboardDatePickerProps = {
  error: boolean;
  errorDark: string;
  frameDark: string;
  errorBasic: string;
  frameBasic: string;
  frameLight: string;
  textDark: string;
  accentBasic: string;
  accentLight: string;
  accentDark: string;
  disabledLight: string;
  disabledBasic: string;
};

const KeyboardDatePicker = connect(({ color }: AppState) => ({
  errorDark: color.errorDark,
  frameDark: color.frameDark,
  errorBasic: color.errorBasic,
  frameBasic: color.frameBasic,
  frameLight: color.frameLight,
  textDark: color.textDark,
  accentBasic: color.accentBasic,
  accentLight: color.accentLight,
  accentDark: color.accentDark,
  disabledLight: color.disabledLight,
  disabledBasic: color.disabledBasic,
}))(
  styled(
    ({
      error,
      errorDark,
      frameDark,
      errorBasic,
      frameBasic,
      frameLight,
      textDark,
      accentBasic,
      accentLight,
      accentDark,
      disabledLight,
      disabledBasic,
      dispatch,
      ...props
    }) => <MuiKeyboardDatePicker {...props} />,
  )<KeyboardDatePickerProps>`
    /* hover */
    .MuiInputBase-root:hover {
      border: solid 1px
        ${({ error, errorDark, frameDark }) => (error ? errorDark : frameDark)};
    }

    /* base & error */
    &.MuiTextField-root {
      width: 140px;
    }
    .MuiInputBase-root {
      border: solid 1px
        ${({ error, errorBasic, frameBasic }) =>
          error ? errorBasic : frameBasic};
      background-color: ${({ frameLight }) => frameLight};
      border-radius: 10px;
      transition: all 0.125s linear;
    }
    input {
      color: ${({ error, errorBasic, textDark }: KeyboardDatePickerProps) =>
        error ? errorBasic : textDark};
      background-color: ${({ frameLight }) => frameLight};
      padding: 6px 8px 7px;
      border-radius: 10px;
      transition: all 0.125s linear;
    }
    .MuiInput-underline:before {
      display: none;
    }
    .MuiInput-underline:after {
      display: none;
    }
    .MuiInputAdornment-positionEnd {
      margin-left: 0;
    }
    .MuiIconButton-root {
      padding: 4px;
      color: ${({ textDark }) => textDark};
    }
    .MuiPickersDay-daySelected {
      background-color: ${({ accentBasic }) => accentBasic};
    }
    .MuiPickersDay-current {
      color: ${({ accentBasic }) => accentBasic};
    }

    /* focus */
    .MuiInputBase-root.Mui-focused {
      background-color: ${({ accentLight }) => accentLight};
      border: solid 1px ${({ accentDark }) => accentDark};
    }
    .Mui-focused input {
      color: ${({ textDark }) => textDark};
      background-color: ${({ accentLight }) => accentLight};
    }
    .Mui-focused .MuiIconButton-root {
      color: ${({ textDark }) => textDark};
    }

    /* disabled */
    .MuiInputBase-root.Mui-disabled {
      background-color: ${({ disabledLight }) => disabledLight};
    }
    .MuiInputBase-root.Mui-disabled:hover {
      border-color: ${({ frameBasic }) => frameBasic};
    }
    .Mui-disabled input {
      color: ${({ disabledBasic }) => disabledBasic};
      background-color: ${({ disabledLight }) => disabledLight};
    }
    .Mui-disabled .MuiIconButton-root {
      color: ${({ disabledBasic }) => disabledBasic};
    }
  `,
);

/**
 * 日付ピッカー日本語化
 */
class ExtendedUtils extends MomentUtils {
  getCalendarHeaderText(date: Moment) {
    return date.format(this.yearMonthFormat);
  }

  getDatePickerHeaderText(date: Moment) {
    return date.format(this.dateFormat);
  }
}

/**
 * 【部品】【原子】【基本入力】年月日 <DateField>タグ
 * @param {string} id - ID
 * @param {string} value - 値
 * @param {boolean} disabled - 非活性フラグ
 * @param {boolean} required - 必須フラグ
 * @param {string} prefix - プレフィックス
 * @param {string} suffix - サフィックス
 * @param {(date: any, value?: string | null | undefined) => void} onChange - 値変更イベント
 * @param {boolean} error - エラーフラグ
 */
const DateField = ({
  id,
  value,
  disabled = false,
  prefix,
  suffix,
  onChange,
  onBlur,
  onAccept,
  error = false,
}: DateFieldProps) => {
  return (
    <StyledDiv>
      {prefix && <StyledText>{prefix}</StyledText>}
      <MuiPickersUtilsProvider utils={ExtendedUtils}>
        <KeyboardDatePicker
          variant="inline"
          format="YYYY/MM/DD"
          invalidDateMessage=""
          id={id}
          value={value}
          disabled={disabled}
          placeholder="____/__/__"
          onChange={onChange}
          onBlur={onBlur}
          onAccept={onAccept}
          error={error}
          autoOk
        />
      </MuiPickersUtilsProvider>
      {suffix && <StyledText>{suffix}</StyledText>}
    </StyledDiv>
  );
};

export default DateField;
