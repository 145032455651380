import React, { useLayoutEffect } from 'react';
import { VALIDATE_MESSAGES } from 'common/Messages';
import { replaceMessage, escapeRegExp, isDisplayError } from 'common/Utility';
import * as TYPES from 'common/Types';
import { generateTsxToReplacedBr } from 'commontsx/Utility';
import { usePasswordField } from 'hooks';
import * as api from 'oapi/api';
import { HandleSetAnswerProps } from 'presentational/general/templates/template/InputTemplate';
import PasswordField from 'presentational/general/atoms/basicInput/PasswordField';
import Form from 'presentational/general/molecules/form/Form';
import InputArea from 'presentational/general/molecules/form/InputArea';
import FormDescription from 'presentational/general/molecules/other/FormDescription';
import CONSTS from 'common/Consts';

const validatorRules = {
  password: [
    'required',
    'min:8',
    'max:256',
    'regex:/\\d+/',
    'regex:/[a-z]+/',
    'regex:/[A-Z]+/',
  ],
};

export type PasswordWithConfirmFormType = {
  device: TYPES.DEVICE_TYPES;
  form: api.Form;
  disabled: boolean;
  visible: boolean;
  handleSetAnswer: (answer: HandleSetAnswerProps) => void;
};

/**
 * パスワード登録
 * @param {TYPES.DEVICE_TYPES} device - 表示デバイスの種類
 * @param {api.Form} form - フォーム情報
 * @param {boolean} disabled - 非活性フラグ
 * @param {boolean} visible - 表示フラグ
 * @param {(answer: HandleSetAnswerProps) => void} handleSetAnswer - InputTemplateに入力状態を渡す関数
 */
const PasswordWithConfirmForm = ({
  device,
  form,
  disabled,
  visible,
  handleSetAnswer,
}: PasswordWithConfirmFormType) => {
  // 新パスワード
  const password = usePasswordField('', false, validatorRules.password, {
    regex: VALIDATE_MESSAGES.ERROR_REGEX_PASSWORD,
    max: replaceMessage(VALIDATE_MESSAGES.ERROR_MAX_DIGIT, ['256']),
    min: replaceMessage(VALIDATE_MESSAGES.ERROR_MIN_DIGIT, ['8']),
  });
  // 再入力
  const confirmationPassword = usePasswordField(
    '',
    false,
    ['required', `regex:/^${escapeRegExp(password.value)}$/`],
    {
      regex: VALIDATE_MESSAGES.ERROR_REGEX_CONFIRM,
    },
  );

  const handleBlur = (): void => {
    const { value, error, hasInput } = password.onBlur();
    const blurValue = confirmationPassword.onBlur();
    if (!error.isError) {
      error.isError = blurValue.error.isError;
    }
    handleSetAnswer({
      formId: form.id,
      values: [value],
      hasInput,
      isError: error.isError,
      isInitial: false,
      setHasInputTrue: password.setHasInputTrue,
    });
  };

  useLayoutEffect((): void => {
    handleSetAnswer({
      formId: form.id,
      values: [password.value],
      hasInput: password.hasInput,
      isError: password.error.isError,
      isInitial: true,
      setHasInputTrue: password.setHasInputTrue,
    });
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form
        device={device}
        templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
        complement={<FormDescription form={form} />}
        required={disabled ? false : form.is_required}
        question={generateTsxToReplacedBr(form.subscribe)}
        visible={visible}
      >
        <InputArea
          error={isDisplayError(password.hasInput, password.error.isError)}
          errorMessage={password.error.errorMessage}
        >
          <PasswordField
            id={form.id}
            value={password.value}
            onChange={password.onChange}
            onBlur={handleBlur}
            error={password.hasInput && password.error.isError}
            displayPasswordStrength
            inputProps={{ maxLength: 256 }}
            width={CONSTS.TEXT_STRING_FIELD_WIDTH}
          />
        </InputArea>
      </Form>
      <Form
        device={device}
        templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
        required={disabled ? false : form.is_required}
        question="パスワード再入力"
        complement="確認のためもう一度入力してください。"
        visible={visible}
      >
        <InputArea
          error={isDisplayError(
            confirmationPassword.hasInput,
            confirmationPassword.error.isError,
          )}
          errorMessage={confirmationPassword.error.errorMessage}
        >
          <PasswordField
            id="confirmationNewPassword"
            value={confirmationPassword.value}
            onChange={confirmationPassword.onChange}
            onBlur={handleBlur}
            error={isDisplayError(
              confirmationPassword.hasInput,
              confirmationPassword.error.isError,
            )}
            disabled={disabled}
            inputProps={{ maxLength: 256 }}
            width={CONSTS.TEXT_STRING_FIELD_WIDTH}
          />
        </InputArea>
      </Form>
    </>
  );
};

export default PasswordWithConfirmForm;
