import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import { TextField as MuiTextField } from '@material-ui/core';
import Text from 'presentational/general/atoms/text/Text';

export type TextFieldProps = {
  id: string;
  value?: string;
  width?: number;
  disabled?: boolean;
  prefix?: string;
  suffix?: string;
  placeholder?: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onBlur?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  error?: boolean;
  textAlignLight?: boolean;
  inputProps?: { type?: 'text' | 'number'; maxLength?: number };
  inputRef?: React.Ref<any>;
};

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin: 0 3px;
`;

type StyledTextFieldProps = {
  width: number;
  textAlignLight: boolean;
  errorBasic: string;
  frameDark: string;
  frameLight: string;
  frameBasic: string;
  textDark: string;
  accentDark: string;
  accentLight: string;
  disabledBasic: string;
  disabledLight: string;
};

const StyledTextField = connect(({ color }: AppState) => ({
  errorBasic: color.errorBasic,
  frameDark: color.frameDark,
  frameLight: color.frameLight,
  frameBasic: color.frameBasic,
  textDark: color.textDark,
  accentDark: color.accentDark,
  accentLight: color.accentLight,
  disabledBasic: color.disabledBasic,
  disabledLight: color.disabledLight,
}))(styled(
  ({
    width,
    textAlignLight,
    errorBasic,
    frameDark,
    frameLight,
    frameBasic,
    textDark,
    accentDark,
    accentLight,
    disabledBasic,
    disabledLight,
    dispatch,
    ...props
  }) => <MuiTextField {...props} />,
)<StyledTextFieldProps>`
  /* error */
  .Mui-error input {
    color: ${({ errorBasic }) => errorBasic};
  }
  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${({ errorBasic }) => errorBasic};
  }
  .MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ errorDark }) => errorDark};
  }

  /* hover */
  .MuiOutlinedInput-notchedOutline:hover {
    border-color: ${({ frameDark }) => frameDark};
  }

  /* base */
  .MuiOutlinedInput-root {
    width: ${({ width }: StyledTextFieldProps) => width}px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: solid 1px ${({ frameBasic }) => frameBasic};
    border-radius: 10px;
  }
  input {
    color: ${({ textDark }) => textDark};
    background-color: ${({ frameLight }) => frameLight};
    padding: 6px 8px 7px;
    border-radius: 10px;
    transition: 0.125s linear;
    ${({ textAlignLight }: StyledTextFieldProps) =>
      textAlignLight && 'text-align: right;'}
  }
  .MuiOutlinedInput-input:-webkit-autofill {
    border-radius: 10px;
  }

  /* focus */
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: solid 1px ${({ accentDark }) => accentDark};
  }
  .Mui-focused input {
    color: ${({ textDark }) => textDark};
    background-color: ${({ accentLight }) => accentLight};
  }

  /* disabled */
  .Mui-disabled input {
    color: ${({ disabledBasic }) => disabledBasic};
    background-color: ${({ disabledLight }) => disabledLight};
  }
`);

/**
 * 【部品】【原子】【基本入力】テキストフィールド <TextField>タグ
 * @param {string} id - ID
 * @param {string} value - 値
 * @param {number} width - 幅(px)
 * @param {boolean} disabled - 非活性フラグ
 * @param {string} prefix - プレフィックス
 * @param {string} suffix - サフィックス
 * @param {string} placeholder - プレースホルダ
 * @param {(
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void} onChange - 値変更イベント
 * @param {(
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void} onBlur - フォーカスアウトイベント
 * @param {boolean} error - エラーフラグ
 * @param {boolean} textAlignLight - テキスト右寄せフラグ
 * @param {{ type?: 'text' | 'number'; maxLength?: number }} TextFieldProps - inputのHTML属性設定
 */
const TextField = ({
  id,
  value,
  width = 200,
  disabled,
  prefix,
  suffix,
  placeholder,
  onChange,
  onBlur,
  error = false,
  textAlignLight = false,
  inputProps,
  inputRef,
}: TextFieldProps) => {
  return (
    <StyledDiv>
      {prefix && <StyledText>{prefix}</StyledText>}
      <StyledTextField
        type="text"
        variant="outlined"
        id={id}
        value={value}
        width={width}
        disabled={disabled}
        placeholder={placeholder}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        textAlignLight={textAlignLight}
        inputProps={inputProps}
        inputRef={inputRef}
      />
      {suffix && <StyledText>{suffix}</StyledText>}
    </StyledDiv>
  );
};

export default TextField;
