import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { changeDisplayedOperationGuideDialog } from 'duck/custom/common/Actions';
import CommonState from 'duck/custom/common/State';

const initialCommonState: CommonState = {
  displayedOperationGuideDialog: false,
};

export const subjectReducer = reducerWithInitialState(initialCommonState).case(
  changeDisplayedOperationGuideDialog,
  (state, displayedOperationGuideDialog) => {
    return {
      ...state,
      displayedOperationGuideDialog,
    };
  },
);

export default subjectReducer;
