import React, { useState } from 'react';
import useReactRouter from 'use-react-router';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import CONSTS from 'common/Consts';
import { APIError } from 'common/APIWrapper';
import { AppState } from 'duck/Reducer';
import { connect } from 'react-redux';
import { CommonState } from 'duck/common/State';
import { Setting } from 'duck/manager/setting/State';
import Form from 'presentational/general/molecules/form/Form';
import BackPageTitle from 'presentational/general/molecules/other/BackPageTitle';
import Button from 'presentational/general/atoms/button/Button';
import TemplatePanel from 'presentational/general/organisms/area/TemplatePanel';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import ApiErrorDialog from 'presentational/general/templates/dialog/ApiErrorDialog';
import NetworkErrorDialog from 'presentational/general/templates/dialog/NetworkErrorDialog';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import Text from 'presentational/general/atoms/text/Text';
import Loading from 'presentational/general/atoms/other/Loading';
import { ButtonColorType } from 'duck/color/State';
import { RequestsCustomApiClientRegister } from 'oapi/api';
import membersActions from 'duck/custom/members/Actions';

export type RegistrationCheckUserProps = {
  common: CommonState;
  setting: Setting;
  buttonBackgroundColorSuccess: ButtonColorType;
  buttonBackgroundColorError: ButtonColorType;
  newUser?: RequestsCustomApiClientRegister;
  regist: (user: RequestsCustomApiClientRegister) => Promise<boolean>;
  isLoading: boolean;
  error?: APIError;
};

const PanelArea = styled.div`
  & div:first-child {
    margin-top: 0;
  }
`;

const ButtonArea = styled.div`
  padding: 20px 0 40px 0;
  text-align: center;
  button {
    margin: 0 5px;
  }
`;

/**
 * 【画面】GC018_利用者登録確認
 * @param {CommonState} common 共通Redux
 * @param {ButtonColorType} buttonBackgroundColorSuccess ボタン背景色：成功(Redux)
 * @param {ButtonColorType} buttonBackgroundColorError ボタン背景色：エラー(Redux)
 */
const RegistrationCheckUser = ({
  common,
  setting,
  buttonBackgroundColorSuccess,
  buttonBackgroundColorError,
  newUser,
  regist,
  isLoading,
  error,
}: RegistrationCheckUserProps) => {
  const { history } = useReactRouter();
  const user: RequestsCustomApiClientRegister = newUser as RequestsCustomApiClientRegister;
  // メールアドレス確認コード送信完了ダイアログ
  const [isSendedEmailDialogOpen, setIsSendedEmailDialogOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const sendedEmailDialogOnClose = () => {
    setIsSendedEmailDialogOpen(false);
    history.push(CONSTS.PATH_INPUT_REGIST_EMAIL_CONFIRMATION_CODE);
  };
  const sendedEmailDialogOkOnClick = () => {
    setIsSendedEmailDialogOpen(false);
    history.push(CONSTS.PATH_INPUT_REGIST_EMAIL_CONFIRMATION_CODE);
  };
  const confirmDialogButtons = [
    {
      text: 'OK',
      onClick: sendedEmailDialogOkOnClick,
    },
  ];

  // エラーダイアログ
  // TODO Reduxのエラー取得に変更
  const [isApiErrorDialogOpen, setIsApiErrorDialogOpen] = useState(false);
  const apiErrorDialogOnClose = () => {
    // TODO エラークリア
    setIsApiErrorDialogOpen(false);
  };
  const apiErrorDialogOkOnClick = () => {
    // TODO エラークリア
    setIsApiErrorDialogOpen(false);
  };
  // TODO Reduxのエラー取得に変更
  const [isNetworkErrorDialogOpen, setIsNetworkErrorDialogOpen] = useState(
    false,
  );
  const networkErrorDialogOnClose = () => {
    // TODO エラークリア
    setIsNetworkErrorDialogOpen(false);
  };
  const networkErrorDialogOkOnClick = () => {
    // TODO エラークリア
    setIsNetworkErrorDialogOpen(false);
  };

  // 戻るボタン
  const handleBackIconClick = () => {
    // TODO 利用者入力 遷移処理
    history.push(CONSTS.PATH_INPUT_USER);
  };
  // 修正ボタン
  const handleFixButtonClick = () => {
    history.push(CONSTS.PATH_INPUT_USER);
  };
  // 確定ボタン
  const handleConfirmButtonClick = async () => {
    const isSucceed = await regist(user);
    if (isSucceed) {
      setIsSendedEmailDialogOpen(true);
    } else {
      setIsError(true);
    }
  };

  // パスワード
  const passwordComplement = (
    <>
      ログイン時に利用します。
      <br />
      以下の半角文字を組み合わせて、8文字以上のパスワードにしてください。
      <br />
      大文字A〜Z、小文字a〜z、数値0〜9
    </>
  );
  if (!newUser) {
    history.push(CONSTS.PATH_INPUT_USER);
    return null;
  }

  return (
    <BeforeLoginTemplate
      device={common.deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <BackPageTitle device={common.deviceType} onClick={handleBackIconClick}>
        利用者情報登録確認
      </BackPageTitle>
      <PanelArea>
        <TemplatePanel
          device={common.deviceType}
          templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="メールアドレス"
            complement="ログイン時に利用します。"
          >
            {user.email}
          </Form>
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="パスワード"
            complement={passwordComplement}
          >
            ●●●●●●●●
          </Form>
        </TemplatePanel>
        <TemplatePanel
          device={common.deviceType}
          templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="姓"
          >
            {user.last_name}
          </Form>
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="名"
          >
            {user.first_name}
          </Form>
        </TemplatePanel>
        <TemplatePanel
          device={common.deviceType}
          templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="姓（フリガナ）"
          >
            {user.last_name_kana}
          </Form>
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="名（フリガナ）"
          >
            {user.first_name_kana}
          </Form>
        </TemplatePanel>
        <TemplatePanel
          device={common.deviceType}
          templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="生年月日"
          >
            {user.birthdate}
          </Form>
        </TemplatePanel>
        <TemplatePanel
          device={common.deviceType}
          templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="郵便番号"
          >
            {user.zip}
          </Form>
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="住所１（都道府県・市区町村）"
          >
            {user.address}
          </Form>
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="住所２（番地・建物名・部屋番号）"
          >
            {user.building}
          </Form>
        </TemplatePanel>
        <TemplatePanel
          device={common.deviceType}
          templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="電話番号"
          >
            {user.phone_number}
          </Form>
        </TemplatePanel>
        <TemplatePanel
          device={common.deviceType}
          templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="個人情報の取り扱いについて"
          >
            同意する
          </Form>
        </TemplatePanel>
        <TemplatePanel
          device={common.deviceType}
          templateType={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <Form
            device={common.deviceType}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
            required
            question="Web登録システム利用規約"
          >
            同意する
          </Form>
        </TemplatePanel>
      </PanelArea>
      <ButtonArea>
        <Button
          buttonType={TYPES.BUTTON_TYPES.ROUND}
          onClick={handleFixButtonClick}
          {...buttonBackgroundColorError}
        >
          修正
        </Button>
        <Button
          buttonType={TYPES.BUTTON_TYPES.ROUND}
          onClick={handleConfirmButtonClick}
          {...buttonBackgroundColorSuccess}
        >
          確定
        </Button>
      </ButtonArea>
      <Dialog
        open={isSendedEmailDialogOpen}
        onClose={sendedEmailDialogOnClose}
        title="メールアドレス確認コード送信完了"
        buttons={confirmDialogButtons}
      >
        <Text>
          登録メールアドレス宛に、メールアドレス確認コードが送信されました。
          <br />
          <br />
          ※５分以内にメールが届かない場合は、メールアドレスが間違っている可能性があるので、
          戻るボタンを押して再度メールアドレスの入力をお願いします。
        </Text>
      </Dialog>
      <Dialog
        open={isError}
        onClose={() => setIsError(false)}
        title="エラー"
        showCloseButton
        buttons={[{ text: 'OK', onClick: () => setIsError(false) }]}
      >
        <Text>{error?.message}</Text>
      </Dialog>
      <ApiErrorDialog
        open={isApiErrorDialogOpen}
        onClose={apiErrorDialogOnClose}
        okOnClick={apiErrorDialogOkOnClick}
        apiLabel="メールアドレス確認コード送信"
      />
      <NetworkErrorDialog
        open={isNetworkErrorDialogOpen}
        onClose={networkErrorDialogOnClose}
        okOnClick={networkErrorDialogOkOnClick}
      />
      <Loading display={isLoading} />
    </BeforeLoginTemplate>
  );
};

export default connect(
  ({ common, color, members, managerSetting }: AppState) => ({
    common,
    setting: managerSetting.setting,
    buttonBackgroundColorSuccess: color.buttonBackgroundColorSuccess,
    buttonBackgroundColorError: color.buttonBackgroundColorError,
    newUser: members.newUser,
    isLoading: members.isLoading,
    error: members.error,
  }),
  {
    regist: membersActions.regist,
  },
)(RegistrationCheckUser);
