import { Dispatch } from 'redux';
import actionCreatorFactory from 'typescript-fsa';
import APIWrapper, { APIError, DefaultApiFp } from 'common/APIWrapper';
import { ResultsManagerLogin, ResultLogin } from 'oapi';

const {
  apiManagerLoginPost,
  apiManagerLoginMfaPost,
  apiManagerLogoutGet,
  apiManagerAuthorityGet,
} = DefaultApiFp();
const actionCreator = actionCreatorFactory();

export const actions = {
  load: actionCreator.async<{}, void, APIError>('MANAGER_AUTH_LOAD'),
};

export type dispatchLogin = (
  loginId: string,
  password: string,
) => Promise<ResultLogin | undefined>;
/**
 * ログイン
 * @param loginId - ユーザーID
 * @param password - パスワード
 */
export const login = (loginId: string, password: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      const res = await APIWrapper(
        await apiManagerLoginPost({ login_id: loginId, password }),
      );
      dispatch(actions.load.done({ params: {} }));
      return (res.data as ResultsManagerLogin).data;
    } catch (e) {
      dispatch(actions.load.failed({ params: {}, error: e }));
      return undefined;
    }
  };
};

export type dispatchLoginMfa = (code: string) => Promise<boolean>;
/**
 * MFAコードを送信
 * @param code - MFAコード
 */
export const loginMfa = (code: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(await apiManagerLoginMfaPost({ code }));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      dispatch(actions.load.failed({ params: {}, error: e }));
      return false;
    }
  };
};

/**
 * ログアウト
 */
export const logout = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(await apiManagerLogoutGet({}));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      dispatch(actions.load.failed({ params: {}, error: e }));
      return false;
    }
  };
};

export type dispatchAuthCheck = () => Promise<APIError | null>;
export const authCheck = () => {
  return async (dispatch: Dispatch): Promise<APIError | null> => {
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(await apiManagerAuthorityGet({}));
      dispatch(actions.load.done({ params: {} }));
      return null;
    } catch (e) {
      dispatch(actions.load.failed({ params: {}, error: e }));
      return e;
    }
  };
};

export default {
  login,
  logout,
  loginMfa,
  authCheck,
};
