import React, { useState } from 'react';
import useReactRouter from 'use-react-router';
import { ActionCreator } from 'typescript-fsa';
import { useCookies } from 'react-cookie';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import { connect } from 'react-redux';
import { CommonState } from 'duck/common/State';
import { Setting } from 'duck/manager/setting/State';
import Panel from 'presentational/general/organisms/area/Panel';
import Text from 'presentational/general/atoms/text/Text';
import Button from 'presentational/general/atoms/button/Button';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import Id from 'presentational/custom/atoms/text/Id';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import { AppState } from 'duck/Reducer';
import CONSTS from 'common/Consts';
import actions from 'duck/custom/sampleidInfo/Actions';
import Loading from 'presentational/general/atoms/other/Loading';
import { APIError } from 'common/APIWrapper';
import MaintenanceDialog from 'presentational/custom/organisms/modal/MaintenanceDialog';

export type ViewCertificationCodeProps = {
  common: CommonState;
  setting: Setting;
  buttonBackgroundColorMain: {
    hoverBackgroundColor: string;
    focusBackgroundColor: string;
  };
  kitId: string;
  sampleId: string;
  checkSampleId: () => Promise<boolean>;
  setSampleIdAndKitId: ({
    sampleId,
    kitId,
  }: {
    sampleId: string;
    kitId: string;
  }) => void;
  isLoading: boolean;
  errorBasicColor: string;
  error?: APIError;
  clearError: ActionCreator<void>;
} & RouteComponentProps<{ kitId?: string; sampleId?: string }>;

type ButtonAreaProps = {
  device: TYPES.DEVICE_TYPES;
};

type AreaProps = {
  device: TYPES.DEVICE_TYPES;
};

const Area = styled.div<AreaProps>`
  ${({ device }: IndentAreaProps) => {
    return device === TYPES.DEVICE_TYPES.PC ? `text-align: center;` : '';
  }}
`;

const TextIdArea = styled.div`
  text-align: center;
  margin-top: 12px;
  margin-bottom: 5px;
`;

const TextArea = styled.div`
  text-align: center;
  margin-bottom: 5px;
`;

const WarningTextArea = styled.div`
  margin: 10px 0;
`;

const ButtonArea = styled.div<ButtonAreaProps>`
  padding: 20px 0;
  text-align: center;
  button {
    ${({ device }: ButtonAreaProps) =>
      device === TYPES.DEVICE_TYPES.MOBILE
        ? `
    display: block;
    margin: 10px auto;
    `
        : `
    margin: 0 5px;
    `}
  }
`;

type IndentAreaProps = {
  device: TYPES.DEVICE_TYPES;
};

/**
 * 【画面】GC014_認証コード参照
 * @param {CommonState} common 共通Redux
 * @param {ButtonColorType} buttonBackgroundColorMain ボタン背景色：メイン(Redux)
 */
const ViewCertificationCode = ({
  common,
  setting,
  buttonBackgroundColorMain,
  errorBasicColor,
  kitId,
  sampleId,
  match,
  checkSampleId,
  setSampleIdAndKitId,
  isLoading,
  clearError,
}: ViewCertificationCodeProps) => {
  const { history } = useReactRouter();
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const [isMaintenance, setIsMaintenance] = useState<boolean>(true);
  const useCookie = useCookies(['web_form']);
  const setCookie = useCookie[1];
  const checkedSampleId = sampleId || match.params.sampleId;
  const checkedKitId = kitId || match.params.kitId;
  const confirmBtnWidth =
    common.deviceType === TYPES.DEVICE_TYPES.MOBILE ? 300 : 250;

  const onClickYes = async () => {
    setSampleIdAndKitId({
      sampleId: checkedSampleId as string,
      kitId: checkedKitId as string,
    });
    const isSucceed = await checkSampleId();
    if (isSucceed) {
      setCookie('sampleId', checkedSampleId, { path: '/', maxAge: 36000 });
      setCookie('kitId', checkedKitId, { path: '/', maxAge: 36000 });
      history.push(CONSTS.PATH_REGISTRATION_CHECK_HISTORY);
    } else {
      setIsApiError(true);
    }
  };

  const onClickNo = () => {
    history.push(CONSTS.PATH_INPUT_CERTIFICATION_CODE);
  };

  if (!checkedSampleId || !checkedKitId) {
    return (
      <Dialog
        open
        onClose={() => {
          history.push(CONSTS.PATH_INPUT_CERTIFICATION_CODE);
        }}
        title="エラー"
        showCloseButton
        buttons={[
          {
            text: 'OK',
            onClick: () => {
              history.push(CONSTS.PATH_INPUT_CERTIFICATION_CODE);
            },
          },
        ]}
      >
        <Text>認証コードおよびサンプルIDの確認が取れていません</Text>
      </Dialog>
    );
  }

  const handleClose = () => {
    setIsMaintenance(false);
  };

  return (
    <BeforeLoginTemplate
      device={common.deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <Area device={common.deviceType}>
        <Panel device={common.deviceType}>
          <Text>
            腸内細菌叢の検査・分析サービス『
            {setting.serviceName}
            』をご利用いただきありがとうございます。
            <br />
            認証コードとサンプルIDは正しいですか？
          </Text>
          <TextIdArea>
            <Text fontWeight="bold" fontSize={16}>
              認証コード
            </Text>
          </TextIdArea>
          <TextArea>
            <Id>{checkedKitId}</Id>
          </TextArea>
          <TextIdArea>
            <Text fontWeight="bold" fontSize={16}>
              サンプルID
            </Text>
          </TextIdArea>
          <TextArea>
            <Id>{checkedSampleId}</Id>
          </TextArea>
          <ButtonArea device={common.deviceType}>
            <Button
              buttonType={TYPES.BUTTON_TYPES.ROUND}
              onClick={onClickYes}
              width={confirmBtnWidth}
              {...buttonBackgroundColorMain}
            >
              はい
            </Button>
            <Button
              buttonType={TYPES.BUTTON_TYPES.ROUND}
              onClick={onClickNo}
              width={confirmBtnWidth}
              {...buttonBackgroundColorMain}
            >
              いいえ
            </Button>
          </ButtonArea>
          <WarningTextArea>
            <Text color={errorBasicColor}>
              {setting.serviceName.replace('®️', '')}
              のご利用は、Chrome、Safari、Edge、FireFoxのブラウザをお使いください。
              {common.deviceType === TYPES.DEVICE_TYPES.PC && <br />}
              現在のURLアドレスをコピーしてブラウザで開き直すことが可能です。
              <br />
              上記のブラウザ以外を使用した場合、正常に動作しない場合があります。
            </Text>
          </WarningTextArea>
        </Panel>
      </Area>
      <Loading display={isLoading} />
      <Dialog
        open={isApiError}
        onClose={() => {
          clearError();
          history.push(CONSTS.PATH_INPUT_CERTIFICATION_CODE);
        }}
        title="エラー"
        showCloseButton
        buttons={[
          {
            text: 'OK',
            onClick: () => {
              clearError();
              history.push(CONSTS.PATH_INPUT_CERTIFICATION_CODE);
            },
          },
        ]}
      >
        <Text>
          認証コードまたはサンプルIDが正しくありません。
          <br />
          再度入力してください。
        </Text>
      </Dialog>
      <MaintenanceDialog open={isMaintenance} onClose={handleClose} />
    </BeforeLoginTemplate>
  );
};

export default connect(
  ({ common, color, sampleidInfo, managerSetting }: AppState) => ({
    common,
    setting: managerSetting.setting,
    buttonBackgroundColorMain: color.buttonBackgroundColorMain,
    errorBasicColor: color.errorBasic,
    kitId: sampleidInfo.kitId,
    sampleId: sampleidInfo.sampleId,
    isLoading: sampleidInfo.isLoading,
    error: sampleidInfo.error,
  }),
  {
    checkSampleId: actions.checkSampleId,
    setSampleIdAndKitId: actions.setSampleIdAndKitId,
    clearError: actions.clearError,
  },
)(ViewCertificationCode);
