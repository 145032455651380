import { Dispatch } from 'redux';
import actionCreatorFactory from 'typescript-fsa';
import APIWrapper, { APIError, DefaultApiFp } from 'common/APIWrapper';
import {
  CustomMember,
  CustomMemberDetail,
  RequestsCustomApiClientRegister,
  RequestsCustomApiClientMembers,
  RequestsCustomApiClientSelf,
  RequestsCustomApiClientMembersMemberId,
  RequestsCustomApiClientMembersMemberIdSampleIds,
  ResultsCustomApiClientConfirmedMysampleidData,
} from 'oapi';
import { actions as commonActions } from 'duck/common/Actions';

const {
  customApiClientMembersGet,
  customApiClientMembersPost,
  customApiClientMembersMemberIdPut,
  customApiClientMembersMemberIdSelectionPost,
  customApiClientSelectedMemberGet,
  customApiClientSelfGet,
  customApiClientSelfPut,
  customApiClientRegisterPost,
  customApiClientMembersMemberIdSampleidsPost,
  customApiClientConfirmedMysampleidGet,
} = DefaultApiFp();
const actionCreator = actionCreatorFactory();

export const actions = {
  load: actionCreator.async<{}, void, APIError>('MEMBERS_LOAD'),
  fetchAll: actionCreator<Array<CustomMember>>('FETCH_ALL_MEMBERS'),
  addMember: actionCreator<CustomMemberDetail>('ADD_MEMBER'),
  updateMember: actionCreator<CustomMemberDetail>('UPDATE_MEMBER'),
  selectMember: actionCreator<CustomMemberDetail>('SELECT_DETAIL'),
  clearError: actionCreator<void>('CLEAR_ERROR_MEMBERS'),
  setMustAddMember: actionCreator<boolean>('SET_MUST_ADD_MEMBER'),
  setSelf: actionCreator<CustomMemberDetail>('SET_SELF_MEMBER'),
  setNewMember: actionCreator<CustomMemberDetail | undefined>('SET_NEW_MEMBER'),
  setNewUser: actionCreator<RequestsCustomApiClientRegister>('SET_NEW_USER'),
  setSampleIdConfirmedStatus: actionCreator<
    ResultsCustomApiClientConfirmedMysampleidData
  >('SET_SAMPLE_ID_CONFIRMED_STATUS_MEMBERS'),
};

/**
 * メンバー一覧情報を取得
 * @param {void} params - 不要
 */
export const fetchAll = (params: {} = {}) => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    try {
      dispatch(actions.load.started(params));
      const res = await APIWrapper(await customApiClientMembersGet());
      dispatch(actions.fetchAll(res.data.data));
      dispatch(actions.load.done({ params }));
      return true;
    } catch (e) {
      dispatch(actions.fetchAll([]));
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params }));
      } else {
        dispatch(actions.load.failed({ params, error: e }));
      }
      return false;
    }
  };
};

/**
 * 利用者を登録する
 * @param {RequestsCustomApiClientRegister} params - 利用者情報
 */
export const regist = (params: RequestsCustomApiClientRegister) => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(await customApiClientRegisterPost(params));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      dispatch(actions.load.failed({ params: {}, error: e }));
      return false;
    }
  };
};

/**
 * 自身の情報を取得する
 */
export const getSelf = () => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    try {
      dispatch(actions.load.started({}));
      const res = await APIWrapper(await customApiClientSelfGet());
      dispatch(actions.setSelf(res.data.data));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return false;
    }
  };
};

/**
 * メンバーを追加する
 * @param {RequestsCustomApiClientMembers} param - 追加するメンバーの情報
 */
export const addMember = (param: RequestsCustomApiClientMembers) => {
  return async (
    dispatch: Dispatch,
  ): Promise<CustomMemberDetail | undefined> => {
    try {
      dispatch(actions.load.started({}));
      const res = await APIWrapper(await customApiClientMembersPost(param));
      dispatch(actions.addMember(res.data.data));
      dispatch(actions.load.done({ params: {} }));
      return res.data.data;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return undefined;
    }
  };
};

/**
 * メンバー情報を更新する
 * @param {RequestsCustomApiClientMembers} param - 追加するメンバーの情報
 */
export const updateMember = (member: CustomMemberDetail) => {
  const { id } = member;
  const param: RequestsCustomApiClientMembersMemberId = {
    identification: member.identification,
    relation: member.relation,
    relation_detail: member.relation_detail,
    first_name: member.first_name,
    last_name: member.last_name,
    first_name_kana: member.first_name_kana,
    last_name_kana: member.last_name_kana,
    zip: member.zip,
    address: member.address,
    building: member.building,
    phone_number: member.phone_number,
    birthdate: member.birthdate,
    sample_ids: member.sample_ids,
    sample_id: member.sample_id,
    has_informed_consent_checked: member.has_informed_consent_checked,
    sample_id_version: member.sample_id_version,
  };
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(await customApiClientMembersMemberIdPut(id, param));
      dispatch(actions.updateMember(member));
      const res = await APIWrapper(await customApiClientSelfGet());
      dispatch(actions.setSelf(res.data.data));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return false;
    }
  };
};

/**
 * 選択されているメンバー情報をサーバーから取得する
 */
export const fetchSelectedMember = () => {
  return async (
    dispatch: Dispatch,
  ): Promise<CustomMemberDetail | undefined> => {
    try {
      dispatch(actions.load.started({}));
      const res = await APIWrapper(await customApiClientSelectedMemberGet());
      dispatch(actions.selectMember(res.data.data));
      dispatch(actions.load.done({ params: {} }));
      return res.data.data;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
      }
      dispatch(actions.load.failed({ params: {}, error: e }));
      return undefined;
    }
  };
};

/**
 * 自身の情報を更新する
 * @param {RequestsCustomApiClientSelf} param - 追加するメンバーの情報
 */
export const updateSelf = (param: RequestsCustomApiClientSelf) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(await customApiClientSelfPut(param));
      const res = await APIWrapper(await customApiClientSelectedMemberGet());
      dispatch(actions.selectMember(res.data.data));
      const resSelf = await APIWrapper(await customApiClientSelfGet());
      dispatch(actions.setSelf(resSelf.data.data));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return false;
    }
  };
};

/**
 * メンバーを選択する
 * @param {string} id - メンバーのID
 */
export const selectMember = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      await APIWrapper(await customApiClientMembersMemberIdSelectionPost(id));
      const res = await APIWrapper(await customApiClientSelectedMemberGet());
      dispatch(actions.selectMember(res.data.data));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return false;
    }
  };
};

/**
 * 被検者に対してサンプルIDを追加する
 * @param {string} sampleId - サンプルID
 * @param {string} kitId - キットID
 */
export const addSampleId = (
  sampleId: string,
  kitId: string,
  member?: CustomMemberDetail,
) => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    try {
      if (!member) {
        return false;
      }
      dispatch(actions.load.started({}));
      const requests: RequestsCustomApiClientMembersMemberIdSampleIds = {
        sample_id: sampleId,
        kit_id: kitId,
      };
      await APIWrapper(
        await customApiClientMembersMemberIdSampleidsPost(member.id, requests),
      );
      const res = await APIWrapper(await customApiClientSelectedMemberGet());
      dispatch(actions.selectMember(res.data.data));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return false;
    }
  };
};

/**
 * 選択されているサンプルIDに対して、回答が確定しているかどうかを確認する
 * 確定している場合は、自身のサンプルIDかどうかを確認する
 */
export const checkConfirmedAnswers = () => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    try {
      dispatch(actions.load.started({}));
      const res = await APIWrapper(
        await customApiClientConfirmedMysampleidGet(),
      );
      dispatch(actions.setSampleIdConfirmedStatus(res.data.data));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        // 別のエラーハンドルで動作
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return false;
    }
  };
};

/**
 * 追加するメンバー情報を一時保存
 * @param member - 追加するメンバーの情報
 */
export const setNewMember = (member: CustomMemberDetail) => {
  return (dispatch: Dispatch) => dispatch(actions.setNewMember(member));
};

export const clearNewMember = () => {
  return (dispatch: Dispatch) => dispatch(actions.setNewMember(undefined));
};

export const setNewUser = (user: RequestsCustomApiClientRegister) => {
  return (dispatch: Dispatch) => dispatch(actions.setNewUser(user));
};

/**
 * メンバーを追加必須フラグを立てる
 */
export const mustAddMember = () => {
  return (dispatch: Dispatch) => dispatch(actions.setMustAddMember(true));
};

/**
 * メンバー追加必須フラグを下ろす
 */
export const clearMustAddMember = () => {
  return (dispatch: Dispatch) => dispatch(actions.setMustAddMember(false));
};
/**
 * エラーをクリアする
 */
export const clearError = () => {
  return (dispatch: Dispatch) => dispatch(actions.clearError());
};

export default {
  fetchAll,
  addMember,
  updateMember,
  selectMember,
  fetchSelectedMember,
  clearError,
  mustAddMember,
  clearMustAddMember,
  getSelf,
  updateSelf,
  setNewMember,
  clearNewMember,
  setNewUser,
  regist,
  addSampleId,
  checkConfirmedAnswers,
};
