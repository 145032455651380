import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actions } from 'duck/custom/members/Actions';
import { Member, initialState } from 'duck/custom/members/State';

const membersReducer = reducerWithInitialState(initialState)
  .case(actions.load.started, (state) => {
    return {
      ...state,
      isLoading: true,
    };
  })
  .case(actions.load.done, (state) => {
    return {
      ...state,
      isLoading: false,
    };
  })
  .case(actions.load.failed, (state, payload) => {
    return {
      ...state,
      isLoading: false,
      error: payload.error,
    };
  })
  .case(actions.fetchAll, (state, payload) => {
    const members: Array<Member> = payload.map(
      (member): Member => {
        return {
          id: member.id,
          name: `${member.last_name} ${member.first_name}`,
          identification: member.identification,
          relation: member.relation,
        };
      },
    );
    return {
      ...state,
      members,
    };
  })
  .case(actions.addMember, (state, payload) => {
    const member: Member = {
      id: payload.id,
      identification: payload.identification,
      relation: payload.relation,
      name: `${payload.last_name} ${payload.first_name}`,
    };
    const members = [...state.members, member];
    return {
      ...state,
      members,
      selectedMember: payload,
      mustAddMember: false,
    };
  })
  .case(actions.updateMember, (state, payload) => {
    const members = [...state.members];
    for (let i = 0; i < members.length; i += 1) {
      if (members[i].id === payload.id) {
        members[i].name = `${payload.last_name} ${payload.first_name}`;
        members[i].identification = payload.identification;
        members[i].relation = payload.relation;
        break;
      }
    }
    return {
      ...state,
      members,
      selectedMember: payload,
    };
  })
  .case(actions.selectMember, (state, payload) => {
    return {
      ...state,
      selectedMember: payload,
    };
  })
  .case(actions.setMustAddMember, (state, payload) => {
    return {
      ...state,
      mustAddMember: payload,
    };
  })
  .case(actions.setSelf, (state, payload) => {
    return {
      ...state,
      self: payload,
    };
  })
  .case(actions.setNewMember, (state, payload) => {
    return {
      ...state,
      newMember: payload,
    };
  })
  .case(actions.clearError, (state) => {
    return {
      ...state,
      error: undefined,
    };
  })
  .case(actions.setNewUser, (state, payload) => {
    return {
      ...state,
      newUser: payload,
    };
  })
  .case(actions.setSampleIdConfirmedStatus, (state, payload) => {
    return {
      ...state,
      isSampleIdConfirmed: payload.does_exist,
      isSampleIdMine: payload.is_mine,
    };
  });

export default membersReducer;
