import React from 'react';
import * as api from 'oapi/api';
import * as TYPES from 'common/Types';
import styled from 'styled-components';
import { generateTsxToReplacedBr } from 'commontsx/Utility';
import Form from 'presentational/general/molecules/form/Form';
import FormDescription from 'presentational/general/molecules/other/FormDescription';

export type NoneFormProps = {
  device: TYPES.DEVICE_TYPES;
  form: api.Form;
  disabled: boolean;
  visible: boolean;
  headline: boolean;
};

type WrapProps = {
  headline: boolean;
};

const Wrap = styled.div<WrapProps>`
  padding-bottom: ${({ headline }: WrapProps) => (headline ? '15' : '0')}px;
`;

/**
 * 【部品】【有機体】【テンプレート入力フォーム】ラベルのみ
 * @param {TYPES.DEVICE_TYPES} device - 表示デバイスの種類
 * @param {api.Form} form - フォーム情報
 * @param {boolean} disabled - 非活性フラグ
 * @param {boolean} visible - 表示フラグ
 * @param {boolean} headline - 見出しフラグ(後に別のformが存在するか)
 */
const NoneForm = ({
  device,
  form,
  disabled,
  visible,
  headline,
}: NoneFormProps) => {
  return (
    <>
      {visible && (
        <Wrap headline={headline}>
          <Form
            device={device}
            templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
            question={generateTsxToReplacedBr(form.subscribe)}
            complement={<FormDescription form={form} />}
            required={disabled ? false : form.is_required}
            visible={visible}
          />
        </Wrap>
      )}
    </>
  );
};

export default NoneForm;
