import { APIError } from 'common/APIWrapper';

export type CustomManagerState = {
  isLoading: boolean;
  error?: APIError;
};

export const initialState = {
  isLoading: false,
};

export default CustomManagerState;
