import React, { PropsWithChildren } from 'react';
import Text from 'presentational/general/atoms/text/Text';

export type QuestionProps = PropsWithChildren<{}>;

/**
 * 【部品】【原子】【文章】設問文 <Question>タグ
 * @param {React.ReactNode} children - Questionタグの中身
 */
const Question = ({ children }: QuestionProps) => {
  return (
    <Text fontSize={16} fontWeight="bold">
      {children}
    </Text>
  );
};

export default Question;
