import React from 'react';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import { generateTsxToReplacedBr } from 'commontsx/Utility';
import { Form } from 'oapi/api';
import SupplementalImage from 'presentational/general/molecules/form/SupplementalImage';
import Description from 'presentational/general/atoms/basicInput/Description';

const DescriptionSpan = styled.span`
  margin-left: 9px;
`;

const ImagesDiv = styled.div`
  display: flex;
`;

const ImageDiv = styled.div`
  margin-left: 20px;
`;

/**
 * フォームの説明
 * @param {Form} form - フォーム
 */
const FormDescription = ({ form }: { form: Form }) => {
  return (
    <>
      {generateTsxToReplacedBr(form.description)}
      {form.information && (
        <DescriptionSpan>
          <Description>{form.information}</Description>
        </DescriptionSpan>
      )}
      <ImagesDiv>
        {form.images &&
          form.images.map((image, i) => {
            const child = (
              <SupplementalImage
                src={image.path}
                alt={image.name}
                width={100}
                modalTitle={image.name}
                device={TYPES.DEVICE_TYPES.MOBILE}
              />
            );
            if (i === 0) {
              return <React.Fragment key={image.path}>{child}</React.Fragment>;
            }
            return <ImageDiv key={image.path}>{child}</ImageDiv>;
          })}
      </ImagesDiv>
    </>
  );
};

export default FormDescription;
