import Text from 'presentational/general/atoms/text/Text';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import React from 'react';

type MaintenanceDialogProps = {
  open: boolean;
  onClose: () => void;
};

const MaintenanceDialog = ({ open, onClose }: MaintenanceDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="重要なお知らせ"
      showCloseButton={false}
      buttons={[
        {
          text: 'OK',
          onClick: onClose,
        },
      ]}
    >
      <Text>
        システムのメンテナンスを実施するため、下記の期間はこちらのサービスをご利用いただくことができません。
        <br />
        何卒、ご理解のほどよろしくお願いいたします。
        <br />
        <br />
        メンテナンス中ご利用いただけないこと：
        <br />
        ・WEB登録
        <br />
        ・アンケート回答
        <br />
        <br />
        メンテナンス期間：
        <br />
        2024年9月25日（水）～2024年9月30日（月）
      </Text>
    </Dialog>
  );
};

export default MaintenanceDialog;
