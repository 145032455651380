import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { changeSubject } from 'duck/custom/subject/Actions';
import SubjectState from 'duck/custom/subject/State';

const initialSubjectState: SubjectState = {
  // TODO 初期値変更
  identification: '本人',
  relation: '',
  relation_detail: '',
  familyName: '腸内',
  firstName: '太郎',
  familyNameKana: 'チョウナイ',
  firstNameKana: 'タロウ',
  postalCode: '000-0000',
  address: '住所',
  building: 'ビル',
  telephoneCode: '000-0000-0000',
  birthday: '2020/09/09',
};

export const subjectReducer = reducerWithInitialState(initialSubjectState).case(
  changeSubject,
  (state, subject) => {
    return { ...state, ...subject };
  },
);

export default subjectReducer;
