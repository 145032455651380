import { Dispatch } from 'redux';
import actionCreatorFactory from 'typescript-fsa';
import * as TYPES from 'common/Types';

const actionCreator = actionCreatorFactory();

export const actions = {
  changeDeviceType: actionCreator<TYPES.DEVICE_TYPES>('CHANGE_DEVICE_TYPE'),
  setIsVirtualKeyboardActive: actionCreator<boolean>(
    'SET_IS_VIRTUAL_KEYBOARD_ACTIVE_COMMON',
  ),
  revokePermission: actionCreator<void>('REVOKE_PERMISSION'),
  willApplyPermissionAuth: actionCreator<void>('WILL_APPLY_PERMISSIONAUTH'),
};

export const changeDeviceType = (type: TYPES.DEVICE_TYPES) => {
  return (dispatch: Dispatch) => dispatch(actions.changeDeviceType(type));
};

export const setIsVirtualKeyboardActive = (isActive: boolean) => {
  return (dispatch: Dispatch) =>
    dispatch(actions.setIsVirtualKeyboardActive(isActive));
};

export const willApplyPermissionAuth = () => {
  return (dispatch: Dispatch) => dispatch(actions.willApplyPermissionAuth());
};

export default {
  changeDeviceType,
  willApplyPermissionAuth,
  setIsVirtualKeyboardActive,
};
