import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import Text from 'presentational/general/atoms/text/Text';
import Icon from 'presentational/general/atoms/text/Icon';

export type ProgressLabelProps = {
  progressType: TYPES.PROGRESS_TYPES;
  displayInline?: boolean;
  textLight: string;
  errorBasic: string;
  warningBasic: string;
  successBasic: string;
  disabledBasic: string;
};

type DivProps = {
  bgColor: string;
  displayInline: boolean;
};

const StyledDiv = styled.div<DivProps>`
  width: 65px;
  height: 35px;
  border-radius: 10px;
  display: ${({ displayInline }: DivProps) =>
    displayInline ? 'inline-flex' : 'flex'};
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }: DivProps) => bgColor};
`;

/**
 * 【部品】【原子】【ラベル】進捗ラベル <ProgressLabel>タグ
 * @param {PROGRESS_TYPES} progressType - 進捗区分
 * @param {boolean} displayInline - インライン表示フラグ
 * @param {string} textLight - テキスト色
 * @param {string} errorBasic - エラー色
 * @param {string} warningBasic - 警告色
 * @param {string} successBasic - 成功色
 * @param {string} disabledBasic - 非活性色
 */
const ProgressLabel = ({
  progressType,
  displayInline = false,
  textLight,
  errorBasic,
  warningBasic,
  successBasic,
  disabledBasic,
}: ProgressLabelProps) => {
  if (progressType === TYPES.PROGRESS_TYPES.DISABLED) {
    return (
      <StyledDiv bgColor={disabledBasic} displayInline={displayInline}>
        <Icon iconColor={textLight} iconSize={20}>
          lock
        </Icon>
      </StyledDiv>
    );
  }
  let bgColor = '';
  let text = '';
  if (progressType === TYPES.PROGRESS_TYPES.ANSWERED) {
    bgColor = successBasic;
    text = '回答済';
  } else if (progressType === TYPES.PROGRESS_TYPES.CONFIRMED) {
    bgColor = disabledBasic;
    text = '送信済';
  } else if (progressType === TYPES.PROGRESS_TYPES.TMPSAVED) {
    bgColor = warningBasic;
    text = '回答中';
  } else if (progressType === TYPES.PROGRESS_TYPES.PREVANSWERD) {
    bgColor = errorBasic;
    text = '前回回答';
  } else if (progressType === TYPES.PROGRESS_TYPES.UNANSWERED) {
    bgColor = errorBasic;
    text = '未回答';
  }

  return (
    <StyledDiv bgColor={bgColor} displayInline={displayInline}>
      <Text color={textLight} fontSize={15} fontWeight="bold">
        {text}
      </Text>
    </StyledDiv>
  );
};

export default connect(({ color }: AppState) => ({
  textLight: color.textLight,
  errorBasic: color.errorBasic,
  warningBasic: color.warningBasic,
  successBasic: color.successBasic,
  disabledBasic: color.disabledBasic,
}))(ProgressLabel);
