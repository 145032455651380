import React, { FC, useLayoutEffect, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useReactRouter from 'use-react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Template } from 'duck/Types';
import { AppState } from 'duck/Reducer';
import CommonState from 'duck/common/State';
import { Setting } from 'duck/manager/setting/State';
import * as TYPES from 'common/Types';
import CONSTS from 'common/Consts';
import InputTemplate from 'presentational/general/templates/template/InputTemplate';
import { CustomMemberDetail, AnswerProgress } from 'oapi/api';
import templateActions, {
  FetchTemplateParams,
} from 'duck/client/template/Actions';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import Loading from 'presentational/general/atoms/other/Loading';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import Text from 'presentational/general/atoms/text/Text';
import { Answers } from 'duck/client/template/State';
import { ButtonColorType } from 'duck/color/State';

export type TemplateInputProps = {
  common: CommonState;
  setting: Setting;
  buttonBackgroundColorError: ButtonColorType;
  template: Template;
  fetchAll: (params: FetchTemplateParams) => Promise<boolean>;
  groupId: string;
  templateId: string;
  isLoading: boolean;
  saveAnswers: (
    progressType: AnswerProgress,
    answers: Answers,
    customId: string,
  ) => Promise<boolean>;
  selectedMember?: CustomMemberDetail;
} & RouteComponentProps<{ groupId: string; templateId: string }>;

const TemplateInputPage: FC<TemplateInputProps> = ({
  match,
  common,
  setting,
  buttonBackgroundColorError,
  template,
  fetchAll,
  groupId,
  templateId,
  isLoading,
  saveAnswers,
  selectedMember,
}: TemplateInputProps) => {
  const { history } = useReactRouter();
  const sampleId = selectedMember ? selectedMember.sample_id : '';
  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  useLayoutEffect(() => {
    if (
      (match.params.groupId !== groupId ||
        match.params.templateId !== templateId) &&
      sampleId !== ''
    ) {
      setIsFetching(true);
      fetchAll({
        groupId: match.params.groupId,
        templateId: match.params.templateId,
        customId: sampleId,
      }).then(() => {
        setIsFetching(false);
      });
    }
  }, [
    sampleId,
    groupId,
    templateId,
    match.params.groupId,
    match.params.templateId,
    fetchAll,
  ]);
  useEffect(() => {
    window.history.pushState(null, '', null);
    window.onpopstate = () => {
      window.history.pushState({ scrollY: window.scrollY }, '', null);
      setIsOpen(true);
    };
    return () => {
      window.onpopstate = null;
    };
  }, []);

  const handleClose = (): void => {
    setIsOpen(false);
    if (window.history.state && window.history.state.scrollY) {
      setTimeout(() => {
        window.scrollTo(0, window.history.state.scrollY);
      });
    }
  };
  const handleDiscard = (): void => {
    setIsOpen(false);
    history.push(CONSTS.PATH_TOP);
  };
  const buttons = [
    { text: '入力に戻る', onClick: handleClose, ...buttonBackgroundColorError },
    { text: '破棄', onClick: handleDiscard },
  ];
  if (!selectedMember) {
    history.push(CONSTS.PATH_TOP);
    return null;
  }
  if (template.panels.length === 0) {
    return null;
  }
  const handleSaveAnswers = async (
    answersType: AnswerProgress,
    answers: Answers,
  ) => {
    const isSucceed = await saveAnswers(answersType, answers, sampleId);
    return isSucceed;
  };

  if (isFetching) {
    return <Loading display={isFetching} />;
  }
  if (template.progress === AnswerProgress.CONFIRMED) {
    return (
      <Dialog
        open
        onClose={() => history.push(CONSTS.PATH_TOP)}
        title="確定済み"
        buttons={[{ text: 'OK', onClick: () => history.push(CONSTS.PATH_TOP) }]}
      >
        <Text>こちらの回答はすでに確定されているため変更はできません。</Text>
      </Dialog>
    );
  }
  return (
    <BeforeLoginTemplate
      device={common.deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <InputTemplate
        device={common.deviceType}
        allDecision={TYPES.TEMPLATE_INPUT_ALL_DECISION_TYPES.ON}
        query={template}
        saveAnswers={handleSaveAnswers}
      />
      <Loading display={isLoading} />
      <Dialog
        open={isOpen}
        onClose={handleClose}
        title="入力内容破棄確認"
        buttons={buttons}
      >
        <Text>
          画面を離れると入力内容は破棄されます。
          <br />
          入力内容を破棄しますか？
        </Text>
      </Dialog>
    </BeforeLoginTemplate>
  );
};

export default connect(
  ({ common, template, members, color, managerSetting }: AppState) => ({
    common,
    setting: managerSetting.setting,
    buttonBackgroundColorError: color.buttonBackgroundColorError,
    template: template.template,
    groupId: template.groupId,
    templateId: template.templateId,
    isLoading: template.isLoading,
    selectedMember: members.selectedMember,
  }),
  {
    fetchAll: templateActions.fetchAll,
    saveAnswers: templateActions.saveAnswers,
  },
)(TemplateInputPage);
