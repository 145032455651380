import React, { useState } from 'react';
import useReactRouter from 'use-react-router';
import styled from 'styled-components';
import { useTextStringField } from 'hooks';
import { isDisabledInputList, isDisplayError } from 'common/Utility';
import * as TYPES from 'common/Types';
import CONSTS from 'common/Consts';
import { AppState } from 'duck/Reducer';
import { Setting } from 'duck/manager/setting/State';
import { connect } from 'react-redux';
import { CommonState } from 'duck/common/State';
import Form from 'presentational/general/molecules/form/Form';
import InputArea from 'presentational/general/molecules/form/InputArea';
import TextField from 'presentational/general/atoms/basicInput/TextField';
import Panel from 'presentational/general/organisms/area/Panel';
import Text from 'presentational/general/atoms/text/Text';
import Button from 'presentational/general/atoms/button/Button';
import Link from 'presentational/general/atoms/button/Link';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import Loading from 'presentational/general/atoms/other/Loading';
import { ButtonColorType } from 'duck/color/State';
import authActions from 'duck/client/auth/Actions';
import { APIError } from 'common/APIWrapper';

export type SendResetPasswordEmailProps = {
  common: CommonState;
  setting: Setting;
  buttonBackgroundColorMain: ButtonColorType;
  buttonBackgroundColorSuccess: ButtonColorType;
  forgotPassword: (email: string) => Promise<boolean>;
  authError?: APIError;
  isLoading: boolean;
  setPasswordResetEmail: (email: string) => void;
};

const ButtonArea = styled.div`
  padding: 20px 0;
  text-align: center;
`;

const LinkArea = styled.div`
  padding-right: 10px;
  text-align: right;
`;

// TODO バリデーション変更
const validatorRules = {
  email: ['required', 'email'],
};

/**
 * 【画面】GC008_パスワード再設定メール送信
 * @param {CommonState} common 共通Redux
 * @param {ButtonColorType} buttonBackgroundColorMain ボタン色
 */
const SendResetPasswordEmail = ({
  common,
  setting,
  buttonBackgroundColorMain,
  buttonBackgroundColorSuccess,
  forgotPassword,
  authError,
  setPasswordResetEmail,
  isLoading,
}: SendResetPasswordEmailProps) => {
  const { history } = useReactRouter();
  const [didSend, setDidSend] = useState(false);
  const [isError, setIsError] = useState(false);
  // メールアドレス
  const email = useTextStringField('', false, validatorRules.email);
  // 送信ボタン
  const buttonDisabledInputList = [
    {
      hasInput: email.hasInput,
      isError: email.error.isError,
    },
  ];
  const buttonOnClick = async () => {
    const isSucceed = await forgotPassword(email.value);
    if (isSucceed) {
      setPasswordResetEmail(email.value);
      setDidSend(true);
    } else {
      setIsError(true);
    }
  };

  return (
    <BeforeLoginTemplate
      device={common.deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <Panel device={common.deviceType}>
        <Text>
          利用者情報に登録したメールアドレス宛に、
          パスワード再設定用の確認コードを送信します。
        </Text>
        <Form
          device={common.deviceType}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question="メールアドレス"
        >
          <InputArea
            error={isDisplayError(email.hasInput, email.error.isError)}
            errorMessage={email.error.errorMessage}
          >
            <TextField
              id="certificationCode"
              value={email.value}
              onChange={email.onChange}
              onBlur={email.onBlur}
              error={isDisplayError(email.hasInput, email.error.isError)}
              width={250}
            />
          </InputArea>
        </Form>
        <ButtonArea>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            disabled={isDisabledInputList(buttonDisabledInputList)}
            onClick={buttonOnClick}
            {...buttonBackgroundColorMain}
          >
            送信
          </Button>
        </ButtonArea>
        <LinkArea>
          <Link to={CONSTS.PATH_LOGIN}>ログイン</Link>
        </LinkArea>
      </Panel>
      <Dialog
        open={isError}
        onClose={() => {
          setIsError(false);
        }}
        title="確認コード送信エラー"
        buttons={[
          {
            text: 'OK',
            onClick: () => {
              setIsError(false);
            },
            ...buttonBackgroundColorSuccess,
          },
        ]}
      >
        {authError && authError.detail}
      </Dialog>
      <Dialog
        open={didSend}
        onClose={() => {
          history.push(CONSTS.PATH_RESET_PASSWORD);
        }}
        title="メールを送信"
        buttons={[
          {
            text: 'OK',
            onClick: () => {
              history.push(CONSTS.PATH_RESET_PASSWORD);
            },
            ...buttonBackgroundColorMain,
          },
        ]}
      >
        指定のメールアドレスに確認コードの送信しました。
        <br />
        メールをご確認ください。
        <br />
        <br />
        ※５分以内にメールが届かない場合は、メールアドレスが間違っている可能性があるので、
        戻るボタンを押して再度メールアドレスの入力をお願いします。
      </Dialog>
      <Loading display={isLoading} />
    </BeforeLoginTemplate>
  );
};

export default connect(
  ({ common, color, auth, managerSetting }: AppState) => ({
    common,
    setting: managerSetting.setting,
    buttonBackgroundColorMain: color.buttonBackgroundColorMain,
    buttonBackgroundColorSuccess: color.buttonBackgroundColorSuccess,
    authError: auth.error,
    isLoading: auth.isLoading,
  }),
  {
    forgotPassword: authActions.forgotPassword,
    setPasswordResetEmail: authActions.setPasswordResetEmail,
  },
)(SendResetPasswordEmail);
