import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import Text from 'presentational/general/atoms/text/Text';
import Bar from 'presentational/general/atoms/other/Bar';

export type PageTitleProps = PropsWithChildren<{
  device: TYPES.DEVICE_TYPES;
  mainBasic: string;
  children: React.ReactNode;
}>;

const TitleBlock = styled.div`
  display: inline-block;
  width: calc(100% - 7px);
  padding-left: 7px;
`;

/**
 * 【部品】【原子】【文章】ページタイトル <PageTitle>タグ
 * @param {DEVICE_TYPES} device - 表示デバイスの種類
 * @param {React.ReactNode} children - PageTitleタグの中身
 */
const PageTitle = ({ device, mainBasic, children }: PageTitleProps) => {
  if (device === TYPES.DEVICE_TYPES.MOBILE) {
    return (
      <TitleBlock>
        <Text color={mainBasic} fontSize={28} fontWeight="bold">
          {children}
        </Text>
      </TitleBlock>
    );
  }
  return (
    <TitleBlock>
      <Text color={mainBasic} fontSize={24} fontWeight="bold">
        {children}
      </Text>
      <Bar color={mainBasic} />
    </TitleBlock>
  );
};

export default connect(({ color }: AppState) => ({
  mainBasic: color.mainBasic,
}))(PageTitle);
