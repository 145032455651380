import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import CONSTS from 'common/Consts';
import * as TYPES from 'common/Types';
import { AppState } from 'duck/Reducer';
import { connect } from 'react-redux';
import { CommonState } from 'duck/common/State';
import { Setting } from 'duck/manager/setting/State';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import Text from 'presentational/general/atoms/text/Text';
import Panel from 'presentational/general/organisms/area/Panel';
import SupplementalImage from 'presentational/general/molecules/form/SupplementalImage';
// eslint-disable-next-line max-len
import StudyParticipationConcentForm from 'presentational/custom/molecules/other/StudyParticipationConcentForm';
// eslint-disable-next-line max-len
import StudyParticipationConcentWarningDialog from 'presentational/custom/organisms/modal/StudyParticipationConcentWarningDialog';
import Button from 'presentational/general/atoms/button/Button';
import Link from 'presentational/general/atoms/button/Link';
import Loading from 'presentational/general/atoms/other/Loading';
import useReactRouter from 'use-react-router';
import { ButtonColorType } from 'duck/color/State';
import membersActions from 'duck/custom/members/Actions';
import sampleidInfoActions from 'duck/custom/sampleidInfo/Actions';
import { useTerms } from 'hooks';
import { CustomMemberDetail, RequestsCustomApiClientMembers } from 'oapi/api';
import { isParaCheckBio } from 'common/Utility';

export type InputStudyParticipationConsentProps = {
  common: CommonState;
  setting: Setting;
  buttonBackgroundColorSuccess: ButtonColorType;
  buttonBackgroundColorMain: ButtonColorType;
  newMember?: CustomMemberDetail;
  addMember: (
    member: RequestsCustomApiClientMembers,
  ) => Promise<CustomMemberDetail | undefined>;
  selectMember: (id: string) => Promise<boolean>;
  clearNewMember: () => void;
  setSampleIdAndKitId: ({
    sampleId,
    kitId,
  }: {
    sampleId: string;
    kitId: string;
  }) => void;
  textLight: string;
  serviceName: string;
  kitId: string;
  isLoading: boolean;
  sampleId: string;
  sampleIdVersion: string;
};

const PanelArea = styled.div`
  background-color: #dd8e6c;
`;

const TextWrap = styled.div`
  margin: 10px 0;
`;

const ImageArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    margin: 0 3px;
  }
`;

const ButtonArea = styled.div`
  padding: 20px 0;
  text-align: center;
`;

/**
 * 【画面】GC024_研究参加同意入力
 * @param {CommonState} common 共通(Redux)
 * @param {ButtonColorType} buttonBackgroundColorSuccess ボタン背景色：サクセス(Redux)
 */
const InputStudyParticipationConsent = ({
  common,
  setting,
  buttonBackgroundColorSuccess,
  buttonBackgroundColorMain,
  newMember,
  addMember,
  selectMember,
  clearNewMember,
  textLight,
  isLoading,
  serviceName,
  kitId,
  setSampleIdAndKitId,
  sampleId,
  sampleIdVersion,
}: InputStudyParticipationConsentProps) => {
  const { history } = useReactRouter();
  const useCookie = useCookies(['web_form']);
  const setCookies = useCookie[1];
  const removeCookie = useCookie[2];
  const member = newMember as CustomMemberDetail;
  const isVersionFourGeneral =
    sampleIdVersion.charAt(0) === '4' &&
    !(sampleId.includes('U') || sampleId.includes('V'));
  const termsState = useTerms(
    {
      value: '1',
      label:
        member && member.identification === '代諾者'
          ? '私は、この研究についての説明文書を受けとり、事前に内容について読み、よく理解しましたので、本人を研究へ参加させることに同意します。'
          : '私は、この研究についての説明文書を受けとり、事前に内容について読み、よく理解しましたので、研究への参加に同意します。',
      checked: true,
    },
    true,
  );
  // メンバー追加ダイアログ
  const [isOpenAddMemberDialog, setIsOpenAddMemberDialog] = useState(false);

  // 研究参加同意確認ダイアログ
  const [isConcentCheckDialogOpen, setIsConcentCheckDialogOpen] = useState(
    false,
  );
  const concentCheckDialogOnClose = () => {
    setIsConcentCheckDialogOpen(false);
  };
  const concentCheckDialogOkOnClick = async () => {
    setIsConcentCheckDialogOpen(false);
    const mem = await addMember({
      ...member,
      kit_id: kitId,
    });
    if (mem) {
      const isSucceed = await selectMember(mem.id);
      if (isSucceed) {
        removeCookie('sampleId', { path: '/' });
        removeCookie('kitId', { path: '/' });
        removeCookie('mustAddMember', { path: '/' });
        setCookies('selectedMemberId', mem.id, { path: '/' });
        setSampleIdAndKitId({ sampleId: '', kitId: '' });
        setIsOpenAddMemberDialog(true);
      }
    }
  };
  // 次へボタン
  const nextButtonOnClick = async () => {
    if (termsState.value.length !== 0) {
      member.has_informed_consent_checked = true;
      await concentCheckDialogOkOnClick();
      return;
    }
    setIsConcentCheckDialogOpen(true);
  };

  if (isOpenAddMemberDialog) {
    clearNewMember();
    return (
      <Dialog
        open={isOpenAddMemberDialog}
        onClose={() => {
          history.push(CONSTS.PATH_TOP);
        }}
        title="被検者情報登録"
        buttons={[
          {
            text: 'OK',
            onClick: () => {
              history.push(CONSTS.PATH_TOP);
            },
            ...buttonBackgroundColorMain,
          },
        ]}
      >
        被検者情報が登録されました。
      </Dialog>
    );
  }

  if (!newMember) {
    // 被検者が選択されている状態じゃないのにこのページに繊維した場合は強制的にTOPへ
    history.push(CONSTS.PATH_INPUT_SUBJECT);
    return null;
  }

  return (
    <>
      <BeforeLoginTemplate
        device={common.deviceType}
        headerColor={setting.headerColor}
        footerText={setting.footerText}
        footerColor={setting.footerColor}
      >
        <PanelArea>
          <Text color={textLight} fontWeight="bold">
            研究参加への同意を確認します
          </Text>
        </PanelArea>
        <Panel device={common.deviceType}>
          <TextWrap>
            <Text>
              {isParaCheckBio()
                ? '本サービスの検査・分析会社であるシンバイオシス・ソリューションズ株式会社では'
                : '当社では'}
              被検者様の同意をいただいたうえで、
              ご回答いただいたアンケート情報と腸内細菌叢データを皆様の健康増進に役立てる研究（※）に使用させていただきます。
              <br />
              研究参加に同意すると、当社の研究成果について、最新の情報をお届けします。
            </Text>
          </TextWrap>
          <TextWrap>
            <Text>
              ※『100万人の腸内健やかプロジェクト』
              <br />
              <Link
                to="https://www.symbiosis-solutions.co.jp/project/"
                external
              >
                https://www.symbiosis-solutions.co.jp/project/
              </Link>
            </Text>
          </TextWrap>
          <TextWrap>
            <Text>
              {isVersionFourGeneral
                ? '研究参加に同意し、うつ傾向・睡眠のアンケートにご回答いただいた方は、健康状態に関する追加のレポートをご提供いたします。'
                : '研究参加に同意し、うつ傾向・睡眠・食事・運動のアンケートにご回答いただいた方は、健康状態に関する追加のレポートをご提供いたします。'}
              <br />
              腸内細菌叢の検査・分析サービス『
              {serviceName}
              』のレポートと併せて是非ご活用ください。
            </Text>
          </TextWrap>
          <ImageArea>
            <SupplementalImage
              src="/api/contents/image/sample_cesd.jpg"
              alt="うつ傾向・睡眠評価レポートサンプル"
              width={75}
              height={75}
              modalTitle="うつ傾向・睡眠評価レポートサンプル"
              device={common.deviceType}
            />
            {!isVersionFourGeneral && (
              <>
                <SupplementalImage
                  src="/api/contents/image/sample_eiyoukun_1.jpg"
                  alt="食事・運動評価レポートサンプル"
                  width={75}
                  height={75}
                  modalTitle="食事・運動評価レポートサンプル"
                  device={common.deviceType}
                />
                <SupplementalImage
                  src="/api/contents/image/sample_eiyoukun_2.jpg"
                  alt="食事・運動評価レポートサンプル"
                  width={75}
                  height={75}
                  modalTitle="食事・運動評価レポートサンプル"
                  device={common.deviceType}
                />
              </>
            )}
          </ImageArea>
          <StudyParticipationConcentForm
            device={common.deviceType}
            identification={member.identification}
            onChange={termsState.onChange}
            checked={termsState.value.length === 1}
          />
          <ButtonArea>
            <Button
              buttonType={TYPES.BUTTON_TYPES.ROUND}
              onClick={nextButtonOnClick}
              {...buttonBackgroundColorSuccess}
            >
              確認
            </Button>
          </ButtonArea>
        </Panel>
        <StudyParticipationConcentWarningDialog
          open={isConcentCheckDialogOpen}
          onClickCancel={concentCheckDialogOnClose}
          onClickOK={concentCheckDialogOkOnClick}
        />
      </BeforeLoginTemplate>
      <Loading display={isLoading} />
    </>
  );
};

export default connect(
  ({ common, color, members, managerSetting, sampleidInfo }: AppState) => ({
    common,
    setting: managerSetting.setting,
    textLight: color.textLight,
    buttonBackgroundColorSuccess: color.buttonBackgroundColorSuccess,
    buttonBackgroundColorMain: color.buttonBackgroundColorMain,
    newMember: members.newMember,
    serviceName: managerSetting.setting.serviceName,
    kitId: sampleidInfo.kitId,
    isLoading: members.isLoading,
    sampleId: sampleidInfo.sampleId,
    sampleIdVersion: sampleidInfo.sampleIdVersion,
  }),
  {
    addMember: membersActions.addMember,
    selectMember: membersActions.selectMember,
    clearNewMember: membersActions.clearNewMember,
    setSampleIdAndKitId: sampleidInfoActions.setSampleIdAndKitId,
  },
)(InputStudyParticipationConsent);
