import React, { ReactText } from 'react';
import Text from 'presentational/general/atoms/text/Text';

type IdProps = {
  children: ReactText;
};

/**
 * 【部品】【原子】【文章】【SSI個別】ID
 * @param {ReactText} children - 子要素(文字列か数値のみ)
 */
const Id = ({ children }: IdProps) => {
  return (
    <Text color="#000000" fontSize={24} fontWeight="bold">
      {children}
    </Text>
  );
};

export default Id;
