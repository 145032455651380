import React from 'react';
import { APIError } from 'common/APIWrapper';
import * as TYPES from 'common/Types';
import InputTemplate from 'presentational/general/templates/template/InputTemplate';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import Loading from 'presentational/general/atoms/other/Loading';
import { ButtonColorType } from 'duck/color/State';
import { Answers } from 'duck/client/template/State';
import { AnswerProgress, ResultSetting } from 'oapi';

type SettingTemplateProps = {
  setting: ResultSetting | null;
  backTo: string;
  saveAnswers: (
    answerType: AnswerProgress,
    answers: Answers,
  ) => Promise<boolean>;
  deviceType: TYPES.DEVICE_TYPES;
  buttonBackgroundColorError: ButtonColorType;
  isLoading: boolean;
  isError: boolean;
  setIsError: (e: boolean) => void;
  error?: APIError;
};

const SettingTemplate = ({
  setting,
  backTo,
  saveAnswers,
  buttonBackgroundColorError,
  deviceType,
  isLoading,
  isError,
  setIsError,
  error,
}: SettingTemplateProps) => {
  return (
    <div>
      {setting && (
        <InputTemplate
          device={deviceType}
          allDecision={TYPES.TEMPLATE_INPUT_ALL_DECISION_TYPES.ON}
          query={setting.template}
          saveAnswers={saveAnswers}
          enableTemporarySave={false}
          backTo={backTo}
        />
      )}
      <Loading display={isLoading} />
      {error && (
        <Dialog
          open={isError}
          onClose={() => setIsError(false)}
          title="設定エラー"
          buttons={[
            {
              text: 'OK',
              onClick: () => setIsError(false),
              ...buttonBackgroundColorError,
            },
          ]}
        >
          {error.detail}
        </Dialog>
      )}
    </div>
  );
};

export default SettingTemplate;
