import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import CONSTS from 'common/Consts';
import { AppState } from 'duck/Reducer';
import Text from 'presentational/general/atoms/text/Text';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';
import ListSubheader from '@material-ui/core/ListSubheader';

export type SelectProps = {
  id: string;
  value?: string;
  items: {
    value: string;
    label: string;
    type?: string;
    information?: string;
  }[];
  width?: number;
  disabled?: boolean;
  prefix?: string;
  suffix?: string;
  placeholder?: string;
  onChange?: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode,
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  error?: boolean;
  disableScrollLock?: boolean;
  inputRef?: React.RefObject<any>;
};

const StyledDiv = styled.div`
  display: inline-flex;
  align-items: center;
`;

const StyledText = styled(Text)`
  display: inline-flex;
  margin: 0 3px;
`;

const StyledListSubheader = styled(ListSubheader)`
  &.MuiListSubheader-root {
    background-color: #6d8dad;
    color: white;
  }
`;

type StyledSelectProps = {
  width: number;
  error: boolean;
  frameDark: string;
  frameBasic: string;
  frameLight: string;
  textDark: string;
  errorBasic: string;
  accentLight: string;
  accentDark: string;
  disabledBasic: string;
  disabledLight: string;
};

const StyledSelect = connect(({ color }: AppState) => ({
  frameDark: color.frameDark,
  frameBasic: color.frameBasic,
  frameLight: color.frameLight,
  textDark: color.textDark,
  errorBasic: color.errorBasic,
  accentLight: color.accentLight,
  accentDark: color.accentDark,
  disabledBasic: color.disabledBasic,
  disabledLight: color.disabledLight,
}))(styled(
  ({
    frameDark,
    frameBasic,
    frameLight,
    textDark,
    errorBasic,
    accentLight,
    accentDark,
    disabledBasic,
    disabledLight,
    dispatch,
    ...props
  }) => <MuiSelect {...props} />,
)<StyledSelectProps>`
  /* hover */
  .MuiOutlinedInput-notchedOutline:hover {
    border-color: ${({ frameDark }) => frameDark};
  }

  /* base */
  .MuiOutlinedInput-input {
    padding: 10px 14px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: solid 1px ${({ frameBasic }) => frameBasic};
  }
  input {
    color: ${({ textDark }) => textDark};
  }
  .MuiInputBase-input {
    background-color: ${({ frameLight }) => frameLight};
  }
  .MuiSelect-icon {
    color: ${({ textDark }) => textDark};
  }
  .MuiSelect-select {
    min-width: ${({ width }: StyledSelectProps) => width}px;
  }

  /* error */
  ${({ error, errorBasic }: StyledSelectProps) =>
    error &&
    `
  input {
    color: ${errorBasic};
  }
  .MuiSelect-icon {
    color: ${errorBasic};
  }
  `};

  /* focus */
  .MuiSelect-select:focus {
    color: ${({ textDark }) => textDark};
    background-color: ${({ accentLight }) => accentLight};
  }
  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ accentDark }) => accentDark};
    border-width: 1px;
  }
  &.MuiOutlinedInput-root.Mui-focused .MuiSelect-icon {
    color: ${({ textDark }) => textDark};
  }

  /* disabled */
  &.Mui-disabled .MuiSelect-root {
    color: ${({ disabledBasic }) => disabledBasic};
    background-color: ${({ disabledLight }) => disabledLight};
  }
`);

/**
 * 【部品】【原子】【基本入力】セレクトボックス <Select>タグ
 * @param {string} id - ID
 * @param {string} value - 値
 * @param {{ value: string; label: string }[]} items - 選択肢配列
 * @param {number} width - 幅(px)
 * @param {boolean} disabled - 非活性フラグ
 * @param {string} prefix - プレフィックス
 * @param {string} suffix - サフィックス
 * @param {(
 *  e: React.ChangeEvent<{
 *  name?: string | undefined;
 *  value: unknown;
 * }>,
 *  child: React.ReactNode,
 * ) => void} onChange - 値変更イベント
 * @param {(
 *  event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
 * ) => void)} onBlur - フォーカスアウトイベント
 * @param {boolean} error - エラーフラグ
 */
const Select = ({
  id,
  value,
  items,
  width = 50,
  disabled = false,
  prefix,
  suffix,
  onChange,
  onBlur,
  error = false,
  disableScrollLock = false,
  inputRef,
}: SelectProps) => {
  const onChangeSelect = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode,
  ) => {
    if (e.target.value === undefined) {
      return;
    }
    if (onChange) onChange(e, child);
  };
  return (
    <StyledDiv>
      {prefix && <StyledText>{prefix}</StyledText>}
      <FormControl variant="outlined">
        <StyledSelect
          id={id}
          value={value}
          onChange={onChangeSelect}
          onBlur={onBlur}
          disabled={disabled}
          width={width}
          error={error}
          MenuProps={{ disableScrollLock }}
          inputRef={inputRef}
        >
          <MenuItem value={CONSTS.SELECT_EMPTY_VALUE}>&nbsp;</MenuItem>
          {items.map((item, i) => {
            const key = `${i}`;
            if (item.type === 'header') {
              return (
                <StyledListSubheader key={key} disableSticky>
                  {item.label}
                </StyledListSubheader>
              );
            }
            return (
              <MenuItem key={key} value={item.value}>
                {item.label}
                {item.information && (
                  <>
                    {item.information.split('\n').map((info, index) => {
                      const infoKey = `${index}`;
                      return (
                        <React.Fragment key={infoKey}>
                          <br />
                          {info}
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </MenuItem>
            );
          })}
        </StyledSelect>
      </FormControl>
      {suffix && <StyledText>{suffix}</StyledText>}
    </StyledDiv>
  );
};

export default Select;
