import React, { useState, useLayoutEffect } from 'react';
import useReactRouter from 'use-react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AppState } from 'duck/Reducer';
import { Setting } from 'duck/manager/setting/State';
import useInputTemplate from 'hooks/useInputTemplate';
import Link from 'presentational/general/atoms/button/Link';
import Button from 'presentational/general/atoms/button/Button';
import Loading from 'presentational/general/atoms/other/Loading';
import FormPanel from 'presentational/general/organisms/inputTemplateForm/FormPanel';
import TemplatePanel from 'presentational/general/organisms/area/TemplatePanel';
import Text from 'presentational/general/atoms/text/Text';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import BackPageTitle from 'presentational/general/molecules/other/BackPageTitle';
import CommonTemplate from 'presentational/custom/templates/CommonTemplate';
import Form from 'presentational/general/molecules/form/Form';
import updateUserData from 'custom/formData/updateUser.json';
import * as TYPES from 'common/Types';
import membersActions from 'duck/custom/members/Actions';
import { Template } from 'duck/Types';
import { CustomMemberDetail, RequestsCustomApiClientSelf } from 'oapi/api';
import CONSTS from 'common/Consts';
import { APIError } from 'common/APIWrapper';

const FinishButtonArea = styled.div`
  text-align: center;
`;

const Wrap = styled.span`
  margin-left: 20px;
`;

type UpdateSubjectProps = {
  getSelf: () => Promise<boolean>;
  deviceType: TYPES.DEVICE_TYPES;
  self?: CustomMemberDetail;
  updateSelf: (member: RequestsCustomApiClientSelf) => Promise<boolean>;
  isLoading: boolean;
  setting: Setting;
  error?: APIError;
};

const UpdateSubject = ({
  deviceType,
  isLoading,
  self,
  updateSelf,
  setting,
  getSelf,
  error,
}: UpdateSubjectProps) => {
  const { history } = useReactRouter();
  const [isOpenSucceedDialog, setIsOpenSucceedDialog] = useState(false);
  const [isOpenErrorDialog, setIsOpenErrorDialog] = useState(false);
  const data: Template = { ...(updateUserData as Template) };

  useLayoutEffect(() => {
    getSelf();
  }, [getSelf]);

  if (self) {
    data.panels.forEach((panel, i) => {
      panel.forms.forEach((form, j) => {
        let title = '';
        switch (form.id) {
          case 'first_name':
            title = self.first_name;
            data.panels[i].forms[j].disabled = true;
            break;
          case 'last_name':
            title = self.last_name;
            data.panels[i].forms[j].disabled = true;
            break;
          case 'first_name_kana':
            title = self.first_name_kana;
            data.panels[i].forms[j].disabled = true;
            break;
          case 'last_name_kana':
            title = self.last_name_kana;
            data.panels[i].forms[j].disabled = true;
            break;
          case 'zip':
            title = self.zip;
            break;
          case 'address':
            title = self.address;
            break;
          case 'building':
            title = self.building;
            break;
          case 'phone_number':
            title = self.phone_number;
            break;
          case 'birthdate':
            title = self.birthdate;
            data.panels[i].forms[j].disabled = true;
            break;
          default:
            break;
        }
        data.panels[i].forms[j].answers = [
          {
            title,
            value: title,
          },
        ];
      });
    });
  }

  const {
    answers,
    isVisiblePanels,
    isVisibleForms,
    isDisabledFinishButton,
    handleSetAnswer,
  } = useInputTemplate(data);

  const handleClickButton = async () => {
    const member: RequestsCustomApiClientSelf = {
      first_name: self ? self.first_name : '',
      last_name: self ? self.last_name : '',
      first_name_kana: self ? self.first_name_kana : '',
      last_name_kana: self ? self.last_name_kana : '',
      zip: '',
      address: '',
      building: '',
      phone_number: '',
      birthdate: self ? self.birthdate : '',
    };
    answers.current.forEach((answer) => {
      switch (answer.formId) {
        case 'first_name':
          // 変更しない
          break;
        case 'last_name':
          // 変更しない
          break;
        case 'first_name_kana':
          // 変更しない
          break;
        case 'last_name_kana':
          // 変更しない
          break;
        case 'zip':
          member.zip = answer.values.length > 0 ? answer.values[0] : '';
          break;
        case 'address':
          member.address = answer.values.length > 0 ? answer.values[0] : '';
          break;
        case 'building':
          member.building = answer.values.length > 0 ? answer.values[0] : '';
          break;
        case 'phone_number':
          member.phone_number =
            answer.values.length > 0 ? answer.values[0] : '';
          break;
        case 'birthdate':
          // 変更しない
          break;
        default:
          break;
      }
    });
    const isSucceed = await updateSelf(member);
    if (isSucceed) {
      setIsOpenSucceedDialog(true);
    } else {
      setIsOpenErrorDialog(true);
    }
  };

  if (!self) {
    return null;
  }

  const mailDescription = (
    <>
      メールアドレス
      <Wrap>
        <Link to={CONSTS.PATH_UPDATE_EMAIL}>変更</Link>
      </Wrap>
    </>
  );
  const passwordDescription = (
    <>
      パスワード
      <Wrap>
        <Link to={CONSTS.PATH_UPDATE_PASSWORD}>変更</Link>
      </Wrap>
    </>
  );
  return (
    <CommonTemplate
      device={deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <BackPageTitle
        device={deviceType}
        onClick={() => history.push(CONSTS.PATH_TOP)}
      >
        利用者情報確認・変更
      </BackPageTitle>
      <TemplatePanel
        id="sampleid"
        device={deviceType}
        templateType={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
        isDisplayed
      >
        <Form
          device={deviceType}
          question={mailDescription}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <div>
            {self?.email}
            <Wrap>{self && self.email_verified ? '(確認済)' : '(未確認)'}</Wrap>
          </div>
        </Form>
      </TemplatePanel>
      <TemplatePanel
        id="sampleid"
        device={deviceType}
        templateType={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
        isDisplayed
      >
        <Form
          device={deviceType}
          question={passwordDescription}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.OUTPUT}
        >
          <div>●●●●●●●●</div>
        </Form>
      </TemplatePanel>
      {data.panels.map((panel: any, panelIndex: number) => {
        return (
          <FormPanel
            key={panel.id}
            device={deviceType}
            panel={panel}
            visible={isVisiblePanels[panelIndex]}
            isVisibleForms={isVisibleForms[panelIndex]}
            handleSetAnswer={handleSetAnswer}
          />
        );
      })}
      <TemplatePanel
        device={deviceType}
        templateType={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
      >
        <FinishButtonArea>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            onClick={handleClickButton}
            disabled={isDisabledFinishButton}
          >
            保存
          </Button>
        </FinishButtonArea>
      </TemplatePanel>
      <Dialog
        open={isOpenSucceedDialog}
        onClose={() => history.push(CONSTS.PATH_TOP)}
        title="利用者情報更新"
        buttons={[{ text: 'OK', onClick: () => history.push(CONSTS.PATH_TOP) }]}
      >
        <Text>利用者情報が更新されました。</Text>
      </Dialog>
      <Dialog
        open={isOpenErrorDialog}
        onClose={() => setIsOpenErrorDialog(false)}
        title="利用者情報更新失敗"
        buttons={[{ text: 'OK', onClick: () => setIsOpenErrorDialog(false) }]}
      >
        <Text>利用者情報の更新に失敗しました。</Text>
        <br />
        <Text>{error && error.detail}</Text>
      </Dialog>
      <Loading display={isLoading} />
    </CommonTemplate>
  );
};

export default connect(
  ({ groups, common, members, managerSetting }: AppState) => ({
    self: members.self,
    deviceType: common.deviceType,
    setting: managerSetting.setting,
    error: members.error,
    isLoading: members.isLoading || groups.isLoading,
  }),
  {
    updateSelf: membersActions.updateSelf,
    getSelf: membersActions.getSelf,
  },
)(UpdateSubject);
