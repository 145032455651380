import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import styled from 'styled-components';
import CONSTS from 'common/Consts';
import * as TYPES from 'common/Types';
import SendResetPasswordEmail from 'pages/custom/SendResetPasswordEmail';
import ResetPassword from 'pages/custom/ResetPassword';
import UpdatePassword from 'pages/custom/UpdatePassword';
import Top from 'pages/custom/Top';
import UpdateEmail from 'pages/custom/UpdateEmail';
import ViewEnquete from 'pages/custom/ViewEnquete';
import ViewCertificationCode from 'pages/custom/ViewCertificationCode';
import ViewPasswordResetEmailSendResult from 'pages/custom/ViewPasswordResetEmailSendResult';
import InputCertificationCode from 'pages/custom/InputCertificationCode';
import InputRegistEmailConfirmationCode from 'pages/custom/InputRegistEmailConfirmationCode';
import RegistrationUser from 'pages/custom/RegistrationUser';
import RegistrationCheckUser from 'pages/custom/RegistrationCheckUser';
import RegistrationCheckSubject from 'pages/custom/RegistrationCheckSubject';
import RegistrationCheckHistory from 'pages/custom/RegistrationCheckHistory';
import InputStudyParticipationConsent from 'pages/custom/InputStudyParticipationConsent';
import InputUpdateEmailConfirmationCode from 'pages/custom/InputUpdateEmailConfirmationCode';
import SetSubject from 'pages/custom/SetSubject';
import ViewSubject from 'pages/custom/ViewSubject';
import ViewStudyParticipationConsent from 'pages/custom/ViewStudyParticipationConsent';
import CustomLogin from 'pages/custom/Login';
import InputSubject from 'pages/custom/InputSubject';
import UpdateSubject from 'pages/custom/UpdateSubject';
import UpdateUser from 'pages/custom/UpdateUser';
import Setting from 'pages/custom/Setting';
import ManagerLogin from 'pages/custom/ManagerLogin';
import ManagerSettings from 'pages/custom/ManagerSettings';
import ManagerMembersProgresses from 'pages/custom/ManagerMembersProgresses';
import MailSetting from 'pages/custom/MailSetting';
import TemplateInputPage from 'pages/custom/TemplateInputPage';
import {
  ScrollToTop,
  SSIColor,
  ReadCookiesToRedux,
  Unauthorized,
  UnauthorizedManager,
  EnableManager,
  ChangeDeviceType,
  ServerSetting,
  SetTitle,
} from 'commontsx/Utility';

const StyledDiv = styled.div`
  font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN',
    'Hiragino Sans', Meiryo, sans-serif;
  background-color: ${TYPES.COLOR_CODE_BACKGROUND_BASIC};
  width: 100%;
  .MuiTypography-body1 {
    font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN',
      'Hiragino Sans', Meiryo, sans-serif;
  }
`;

function App() {
  return (
    <StyledDiv className="App">
      <ChangeDeviceType />
      <SSIColor />
      <ReadCookiesToRedux />
      <SetTitle />
      <Router>
        <ScrollToTop />
        <ServerSetting />
        <Unauthorized />
        <Switch>
          <Route path={CONSTS.PATH_LOGIN} component={CustomLogin} />
          <Route
            path={CONSTS.PATH_VIEW_STUDY_PARTICIPATION_CONSENT}
            component={ViewStudyParticipationConsent}
          />
          <Route
            path={CONSTS.PATH_SEND_RESET_PASSWORD_EMAIL}
            component={SendResetPasswordEmail}
          />
          <Route path={CONSTS.PATH_RESET_PASSWORD} component={ResetPassword} />
          <Route
            path={CONSTS.PATH_UPDATE_PASSWORD}
            component={UpdatePassword}
          />
          <Route
            path={CONSTS.PATH_VIEW_PASSWORD_RESET_EMAIL_SEND_RESULT}
            component={ViewPasswordResetEmailSendResult}
          />
          <Route
            path={CONSTS.PATH_REGISTRATION_CHECK_SUBJECT}
            component={RegistrationCheckSubject}
          />
          <Route
            path={CONSTS.PATH_VIEW_CERTIFICATION_CODE_WITH_ID}
            component={ViewCertificationCode}
          />
          <Route
            path={CONSTS.PATH_VIEW_CERTIFICATION_CODE}
            component={ViewCertificationCode}
          />
          <Route
            path={CONSTS.PATH_REGISTRATION_CHECK_HISTORY}
            component={RegistrationCheckHistory}
          />
          <Route
            path={CONSTS.PATH_CHECK_SAMPLEID_KITID}
            component={InputCertificationCode}
          />
          <Route
            path={CONSTS.PATH_INPUT_CERTIFICATION_CODE}
            component={InputCertificationCode}
          />
          <Route
            path={CONSTS.PATH_INPUT_REGIST_EMAIL_CONFIRMATION_CODE}
            component={InputRegistEmailConfirmationCode}
          />
          <Route path={CONSTS.PATH_INPUT_SUBJECT} component={InputSubject} />
          <Route path={CONSTS.PATH_UPDATE_SUBJECT} component={UpdateSubject} />
          <Route path={CONSTS.PATH_UPDATE_USER} component={UpdateUser} />
          <Route
            path={CONSTS.PATH_REGISTRATION_CHECK_USER}
            component={RegistrationCheckUser}
          />
          <Route
            path={CONSTS.PATH_INPUT_STUDY_PARTICIPATION_CONSENT}
            component={InputStudyParticipationConsent}
          />
          <Route path={CONSTS.PATH_TOP} component={Top} />
          <Route
            path={CONSTS.PATH_INPUT_ENQUETE}
            component={TemplateInputPage}
          />
          <Route path={CONSTS.PATH_VIEW_ENQUETE} component={ViewEnquete} />
          <Route path={CONSTS.PATH_SET_SUBJECT} component={SetSubject} />
          <Route path={CONSTS.PATH_VIEW_SUBJECT} component={ViewSubject} />
          <Route path={CONSTS.PATH_INPUT_USER} component={RegistrationUser} />
          <Route
            path={CONSTS.PATH_VIEW_STUDY_PARTICIPATION_CONSENT}
            component={ViewStudyParticipationConsent}
          />
          <Route path={CONSTS.PATH_UPDATE_EMAIL} component={UpdateEmail} />
          <Route
            path={CONSTS.PATH_INPUT_UPDATE_EMAIL_CONFIRMATION_CODE}
            component={InputUpdateEmailConfirmationCode}
          />
          <Route
            path={CONSTS.PATH_INPUT_CERTIFICATION_CODE_SHORT}
            component={InputCertificationCode}
          />
          <Route path={CONSTS.PATH_MANAGER_LOGIN}>
            <EnableManager>
              <Route
                path={CONSTS.PATH_MANAGER_LOGIN}
                component={ManagerLogin}
              />
            </EnableManager>
          </Route>
          <Route path={CONSTS.PATH_MANAGER}>
            <UnauthorizedManager>
              <Switch>
                <Route
                  path={CONSTS.PATH_MANAGER_SETTING_MAIL}
                  component={MailSetting}
                />
                <Route path={CONSTS.PATH_MANAGER_SETTING} component={Setting} />
                <Route
                  path={CONSTS.PATH_MANAGER_MEMBER_PROGRESS}
                  component={ManagerMembersProgresses}
                />
                <Route
                  exact
                  path={CONSTS.PATH_MANAGER}
                  component={ManagerSettings}
                />
                <Route path={CONSTS.PATH_MANAGER}>
                  <Redirect to={CONSTS.PATH_MANAGER_LOGIN} />
                </Route>
              </Switch>
            </UnauthorizedManager>
          </Route>
          <Route exact path="/">
            <Redirect to={CONSTS.PATH_LOGIN} />
          </Route>
        </Switch>
      </Router>
    </StyledDiv>
  );
}

export default App;
