import React, {
  PropsWithChildren,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import useReactRouter from 'use-react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AppState } from 'duck/Reducer';
import { Setting } from 'duck/manager/setting/State';
import useInputTemplate from 'hooks/useInputTemplate';
import Button from 'presentational/general/atoms/button/Button';
import Loading from 'presentational/general/atoms/other/Loading';
import FormPanel from 'presentational/general/organisms/inputTemplateForm/FormPanel';
import TemplatePanel from 'presentational/general/organisms/area/TemplatePanel';
import Text from 'presentational/general/atoms/text/Text';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import BackPageTitle from 'presentational/general/molecules/other/BackPageTitle';
import CommonTemplate from 'presentational/custom/templates/CommonTemplate';
import Panel from 'presentational/general/organisms/area/Panel';
// eslint-disable-next-line max-len
import StudyParticipationConcentForm from 'presentational/custom/molecules/other/StudyParticipationConcentForm';
// eslint-disable-next-line max-len
import StudyParticipationConcentWarningDialog from 'presentational/custom/organisms/modal/StudyParticipationConcentWarningDialog';
import inputSubjectData from 'custom/formData/inputSubject.json';
import * as TYPES from 'common/Types';
import * as membersActions from 'duck/custom/members/Actions';
import { Template } from 'duck/Types';
import { CustomMemberDetail } from 'oapi/api';
import CONSTS from 'common/Consts';
import { APIError } from 'common/APIWrapper';

const FinishButtonArea = styled.div`
  text-align: center;
`;

type WrapProps = PropsWithChildren<{
  device: TYPES.DEVICE_TYPES;
}>;

const Wrap = styled.div<WrapProps>`
  ${({ device }: WrapProps) => {
    if (device === TYPES.DEVICE_TYPES.PC) {
      return `margin: 5px 20px 0px;`;
    }
    return '';
  }}
`;

const WrapSampleId = styled.span`
  padding-left: 8px;
`;

type UpdateSubjectProps = {
  deviceType: TYPES.DEVICE_TYPES;
  selectedMember?: CustomMemberDetail;
  updateMember: (member: CustomMemberDetail) => Promise<boolean>;
  checkConfirmedAnswers: () => Promise<boolean>;
  setting: Setting;
  isSampleIdConfirmed: boolean;
  isSampleIdMine: boolean;
  isLoading: boolean;
  error?: APIError;
};

const UpdateSubject = ({
  deviceType,
  isLoading,
  selectedMember,
  updateMember,
  checkConfirmedAnswers,
  setting,
  isSampleIdConfirmed,
  isSampleIdMine,
  error,
}: UpdateSubjectProps) => {
  const { history } = useReactRouter();
  const [isOpenSucceedDialog, setIsOpenSucceedDialog] = useState(false);
  const [isOpenErrorDialog, setIsOpenErrorDialog] = useState(false);
  const [isChecked, setIsChecked] = useState(
    Boolean(selectedMember && selectedMember.has_informed_consent_checked),
  );
  const [isConcentCheckDialogOpen, setConcentCheckDialogOpen] = useState(false);
  const data: Template = { ...(inputSubjectData as Template) };
  const member = selectedMember as CustomMemberDetail;
  useLayoutEffect(() => {
    checkConfirmedAnswers();
  }, [checkConfirmedAnswers]);
  useEffect(() => {
    setIsChecked(
      Boolean(selectedMember && selectedMember.has_informed_consent_checked),
    );
  }, [selectedMember]);

  if (selectedMember) {
    data.panels.forEach((panel, i) => {
      panel.forms.forEach((form, j) => {
        let title = '';
        switch (form.id) {
          case 'identification':
            title = member.identification;
            data.panels[i].forms[j].disabled = true;
            break;
          case 'relation':
            title = member.relation;
            break;
          case 'relation_detail':
            title = member.relation_detail;
            break;
          case 'first_name':
            title = member.first_name;
            data.panels[i].forms[j].disabled = true;
            break;
          case 'last_name':
            title = member.last_name;
            data.panels[i].forms[j].disabled = true;
            break;
          case 'first_name_kana':
            title = member.first_name_kana;
            data.panels[i].forms[j].disabled = true;
            break;
          case 'last_name_kana':
            title = member.last_name_kana;
            data.panels[i].forms[j].disabled = true;
            break;
          case 'zip':
            title = member.zip;
            break;
          case 'address':
            title = member.address;
            break;
          case 'building':
            title = member.building;
            break;
          case 'phone_number':
            title = member.phone_number;
            break;
          case 'birthdate':
            title = member.birthdate;
            data.panels[i].forms[j].disabled = true;
            break;
          default:
            break;
        }
        data.panels[i].forms[j].answers = [
          {
            title,
            value: title,
          },
        ];
      });
    });
  }

  const {
    answers,
    isVisiblePanels,
    isVisibleForms,
    isDisabledFinishButton,
    handleSetAnswer,
  } = useInputTemplate(data);

  const generateMemberFromAnswers = () => {
    answers.current.forEach((answer) => {
      if (answer.values.length === 0) {
        return;
      }
      const val = answer.values[0];
      switch (answer.formId) {
        case 'relation':
          member.relation = val;
          break;
        case 'relation_detail':
          member.relation_detail = val;
          break;
        case 'first_name':
          // 変更しない
          break;
        case 'last_name':
          // 変更しない
          break;
        case 'first_name_kana':
          // 変更しない
          break;
        case 'last_name_kana':
          // 変更しない
          break;
        case 'zip':
          member.zip = val;
          break;
        case 'address':
          member.address = val;
          break;
        case 'building':
          member.building = val;
          break;
        case 'phone_number':
          member.phone_number = val;
          break;
        case 'birthdate':
          // 変更しない
          break;
        default:
          break;
      }
    });
  };

  const concentCheckDialogOnClose = () => {
    setConcentCheckDialogOpen(false);
  };

  const concentCheckDialogOkOnClick = async () => {
    const isSucceed = await updateMember(member);
    if (isSucceed) {
      setIsOpenSucceedDialog(true);
    } else {
      setIsOpenErrorDialog(true);
    }
    setConcentCheckDialogOpen(false);
  };

  const handleClickButton = async () => {
    generateMemberFromAnswers();
    member.has_informed_consent_checked = isChecked;
    if (!isChecked) {
      setConcentCheckDialogOpen(true);
      return;
    }
    concentCheckDialogOkOnClick();
  };
  if (!selectedMember) {
    if (isLoading) {
      return <Loading display={isLoading} />;
    }
    return (
      <Dialog
        open
        onClose={() => history.push(CONSTS.PATH_SET_SUBJECT)}
        title="被検者が選択されていません"
        buttons={[
          { text: 'OK', onClick: () => history.push(CONSTS.PATH_SET_SUBJECT) },
        ]}
      >
        <Text>被検者を選択してください。</Text>
      </Dialog>
    );
  }
  return (
    <CommonTemplate
      device={deviceType}
      headerColor={setting.headerColor}
      footerColor={setting.footerColor}
      footerText={setting.footerText}
    >
      <BackPageTitle
        device={deviceType}
        onClick={() => history.push(CONSTS.PATH_TOP)}
      >
        被検者情報確認・変更
      </BackPageTitle>
      <TemplatePanel
        id="sampleid"
        device={deviceType}
        templateType={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
        isDisplayed
      >
        <Wrap device={deviceType}>
          <Text fontSize={16} fontWeight="bold">
            サンプルID:
            <WrapSampleId>{selectedMember?.sample_id}</WrapSampleId>
          </Text>
        </Wrap>
      </TemplatePanel>
      {data.panels.map((panel: any, panelIndex: number) => {
        return (
          <FormPanel
            key={panel.id}
            device={deviceType}
            panel={panel}
            visible={isVisiblePanels[panelIndex]}
            isVisibleForms={isVisibleForms[panelIndex]}
            handleSetAnswer={handleSetAnswer}
          />
        );
      })}
      <Panel device={deviceType}>
        <StudyParticipationConcentForm
          device={deviceType}
          identification={member ? member.identification : '本人'}
          onChange={(e) => {
            setIsChecked(e.target.checked);
          }}
          disabled={isSampleIdConfirmed && isSampleIdMine}
          checked={isChecked}
        />
      </Panel>
      <TemplatePanel
        device={deviceType}
        templateType={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
      >
        <FinishButtonArea>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            onClick={handleClickButton}
            disabled={isDisabledFinishButton}
          >
            保存
          </Button>
        </FinishButtonArea>
      </TemplatePanel>
      <Dialog
        open={isOpenSucceedDialog}
        onClose={() => history.push(CONSTS.PATH_TOP)}
        title="被検者情報更新"
        buttons={[{ text: 'OK', onClick: () => history.push(CONSTS.PATH_TOP) }]}
      >
        <Text>被検者情報が更新されました。</Text>
      </Dialog>
      <Dialog
        open={isOpenErrorDialog}
        onClose={() => setIsOpenErrorDialog(false)}
        title="被検者情報更新失敗"
        buttons={[{ text: 'OK', onClick: () => setIsOpenErrorDialog(false) }]}
      >
        <Text>被検者情報の更新に失敗しました。</Text>
        <br />
        <Text>{error && error.detail}</Text>
      </Dialog>
      <StudyParticipationConcentWarningDialog
        open={isConcentCheckDialogOpen}
        onClickCancel={concentCheckDialogOnClose}
        onClickOK={concentCheckDialogOkOnClick}
      />
      <Loading display={isLoading} />
    </CommonTemplate>
  );
};

export default connect(
  ({ common, members, managerSetting }: AppState) => ({
    selectedMember: members.selectedMember,
    deviceType: common.deviceType,
    isSampleIdConfirmed: members.isSampleIdConfirmed,
    isSampleIdMine: members.isSampleIdMine,
    setting: managerSetting.setting,
    error: members.error,
    isLoading: members.isLoading,
  }),
  {
    updateMember: membersActions.updateMember,
    checkConfirmedAnswers: membersActions.checkConfirmedAnswers,
  },
)(UpdateSubject);
