import React, { useState, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import useReactRouter from 'use-react-router';
import { AppState } from 'duck/Reducer';
import { ButtonColorType } from 'duck/color/State';
import styled from 'styled-components';
import * as api from 'oapi/api';
import * as TYPES from 'common/Types';
import useInputTemplate from 'hooks/useInputTemplate';
import TemplatePanel from 'presentational/general/organisms/area/TemplatePanel';
import InputFooter from 'presentational/general/organisms/area/InputFooter';
import Dialog, {
  ButtonsProps,
} from 'presentational/general/organisms/modal/Dialog';
import FormPanel from 'presentational/general/organisms/inputTemplateForm/FormPanel';
import BackPageTitle from 'presentational/general/molecules/other/BackPageTitle';
import Text from 'presentational/general/atoms/text/Text';
import Button from 'presentational/general/atoms/button/Button';
import CONSTS from 'common/Consts';
import { AnswerProgress } from 'oapi';
import { Answers } from 'duck/client/template/State';

export type InputTemplateProps = {
  device: TYPES.DEVICE_TYPES;
  allDecision: TYPES.TEMPLATE_INPUT_ALL_DECISION_TYPES;
  query: api.Template;
  saveAnswers?: (
    answerType: AnswerProgress,
    answers: Answers,
  ) => Promise<boolean>;
  buttonBackgroundColorError: ButtonColorType;
  backTo?: string;
  enableTemporarySave?: boolean;
  displayHeader?: boolean;
};

type AnswerProps = {
  panelIndex: number;
  formIndex: number;
  formId: string;
  values: string[];
  hasInput: boolean;
  isError: boolean;
  setHasInputTrue: () => void;
};

export type HandleSetAnswerProps = Pick<
  AnswerProps,
  'formId' | 'values' | 'hasInput' | 'isError' | 'setHasInputTrue'
> & {
  isInitial: boolean;
};

type useBackPageDialogHooksProps = {
  isOpen: boolean;
  buttons: ButtonsProps[];
  handleOpen: () => void;
  handleClose: () => void;
};

type useSaveDialogHooksProps = {
  isOpen: boolean;
  buttons: ButtonsProps[];
  handleOpen: () => Promise<void>;
};

const FinishButtonArea = styled.div`
  text-align: center;
`;

interface CommentProps {
  device: TYPES.DEVICE_TYPES;
}

const Comment = styled.div<CommentProps>`
  font-size: ${({ device }) => (device === TYPES.DEVICE_TYPES.PC ? 11 : 10)}px;
  color: #cc0000;
  padding: ${({ device }) =>
    device === TYPES.DEVICE_TYPES.PC ? '0 42px' : '0 15px'};

  ${({ device }) =>
    device === TYPES.DEVICE_TYPES.PC
      ? `margin-bottom: 8px;`
      : 'margin: 8px 0 4px;'}
`;

/**
 * 戻るボタンのカスタムフックス
 */
const useBackPageDialog = (
  buttonBackgroundColorError: ButtonColorType,
  backTo: string,
): useBackPageDialogHooksProps => {
  const { history } = useReactRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = (): void => {
    setIsOpen(true);
  };
  const handleClose = (): void => {
    setIsOpen(false);
  };
  const handleDiscard = (): void => {
    setIsOpen(false);
    history.push(backTo);
  };
  const buttons: ButtonsProps[] = [
    { text: '入力に戻る', onClick: handleClose, ...buttonBackgroundColorError },
    { text: '破棄', onClick: handleDiscard },
  ];

  return {
    isOpen,
    buttons,
    handleOpen,
    handleClose,
  };
};

/**
 * 一時保存ボタンのカスタムフックス
 * @param {React.MutableRefObject<AnswerProps[]>} answers - 回答データ
 */
const useTemporarySaveDialog = (
  answers: React.MutableRefObject<AnswerProps[]>,
  backTo: string,
  saveAnswers?: (
    answerType: AnswerProgress,
    answers: Answers,
  ) => Promise<boolean>,
): useSaveDialogHooksProps => {
  const { history } = useReactRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = async (): Promise<void> => {
    if (saveAnswers) {
      const saveData = answers.current.map((answer) => {
        return { id: answer.formId, values: answer.values };
      });
      const isSucceed = await saveAnswers(AnswerProgress.TMPSAVED, saveData);
      if (isSucceed) {
        setIsOpen(true);
      }
    }
  };
  const handleClose = async () => {
    setIsOpen(false);
    history.push(backTo);
  };
  const buttons: ButtonsProps[] = [{ text: 'OK', onClick: handleClose }];

  return {
    isOpen,
    buttons,
    handleOpen,
  };
};

/**
 * 保存ボタンのカスタムフックス
 * @param {React.MutableRefObject<AnswerProps[]>} answers - 回答データ
 */
const useAllDecisionSaveDialog = (
  answers: React.MutableRefObject<AnswerProps[]>,
  backTo: string,
  saveAnswers?: (
    answerType: AnswerProgress,
    answers: Answers,
  ) => Promise<boolean>,
): useSaveDialogHooksProps => {
  const { history } = useReactRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = async (): Promise<void> => {
    if (saveAnswers) {
      const saveData = answers.current.map((answer) => {
        return { id: answer.formId, values: answer.values };
      });
      const isSucceed = await saveAnswers(AnswerProgress.ANSWERED, saveData);
      if (isSucceed) {
        setIsOpen(true);
      }
    }
  };
  const handleClose = async () => {
    setIsOpen(false);
    history.push(backTo);
  };
  const buttons: ButtonsProps[] = [{ text: 'OK', onClick: handleClose }];

  return {
    isOpen,
    buttons,
    handleOpen,
  };
};

/**
 * 【画面】GB002_テンプレート入力
 * @param {DEVICE_TYPES} device - 表示デバイス区分
 * @param {TEMPLATE_INPUT_ALL_DECISION_TYPES} allDecision - 全体確定区分
 * @param {api.Template} query - 質問JSONデータ
 */
const InputTemplate = ({
  device,
  allDecision,
  query,
  saveAnswers,
  buttonBackgroundColorError,
  backTo = CONSTS.PATH_TOP,
  enableTemporarySave = true,
  displayHeader = true,
}: InputTemplateProps) => {
  const {
    answers,
    isVisiblePanels,
    isVisibleForms,
    isDisabledFinishButton,
    inputFooterIndex,
    setInputFooterIndex,
    inputFooterPanels,
    handleSetAnswer,
  } = useInputTemplate(query);
  // 各モーダルの管理
  const backPageDialog = useBackPageDialog(buttonBackgroundColorError, backTo);
  const temporarySaveDialog = useTemporarySaveDialog(
    answers,
    backTo,
    saveAnswers,
  );
  const allDecisionSaveDialog = useAllDecisionSaveDialog(
    answers,
    backTo,
    saveAnswers,
  );

  useLayoutEffect((): void => {
    setInputFooterIndex(0);
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO ContentsAreaをページで指定するかここで指定するか決める
  return (
    <>
      {displayHeader && (
        <>
          <Comment device={device}>
            ※再読み込みや長時間の放置により、入力した回答が消えてしまうことがございます。こまめに右下の「一時保存」ボタンを押してください。
          </Comment>
          <BackPageTitle device={device} onClick={backPageDialog.handleOpen}>
            {query.name}
          </BackPageTitle>
        </>
      )}
      {query.panels.map((panel: api.Panel, panelIndex: number) => {
        return (
          <FormPanel
            key={panel.id}
            device={device}
            panel={panel}
            visible={isVisiblePanels[panelIndex]}
            isVisibleForms={isVisibleForms[panelIndex]}
            handleSetAnswer={handleSetAnswer}
          />
        );
      })}

      <TemplatePanel
        device={device}
        templateType={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
      >
        <FinishButtonArea>
          {allDecision === TYPES.TEMPLATE_INPUT_ALL_DECISION_TYPES.ON ? (
            <Button
              buttonType={TYPES.BUTTON_TYPES.ROUND}
              onClick={allDecisionSaveDialog.handleOpen}
              disabled={isDisabledFinishButton}
            >
              回答完了
            </Button>
          ) : (
            <Button
              buttonType={TYPES.BUTTON_TYPES.ROUND}
              disabled={isDisabledFinishButton}
            >
              確認
            </Button>
          )}
        </FinishButtonArea>
      </TemplatePanel>

      <InputFooter
        device={device}
        panels={inputFooterPanels}
        onClickTemporarySave={() => {
          temporarySaveDialog.handleOpen();
        }}
        displayingIdx={inputFooterIndex}
        setIdx={setInputFooterIndex}
        enableTemporarySave={enableTemporarySave}
      />

      <Dialog
        open={backPageDialog.isOpen}
        onClose={backPageDialog.handleClose}
        title="入力内容破棄確認"
        buttons={backPageDialog.buttons}
      >
        <Text>
          画面を離れると入力内容は破棄されます。
          <br />
          入力内容を破棄しますか？
        </Text>
      </Dialog>
      <Dialog
        open={allDecisionSaveDialog.isOpen}
        onClose={() => {}}
        title="回答保存完了"
        buttons={allDecisionSaveDialog.buttons}
      >
        <Text>
          回答を保存しました。
          <br />
          ※ 回答はまだ送信されていません。
          <br />
        </Text>
      </Dialog>
      <Dialog
        open={temporarySaveDialog.isOpen}
        onClose={() => {}}
        title="回答一時保存完了"
        buttons={temporarySaveDialog.buttons}
      >
        <Text>回答を一時保存しました。</Text>
      </Dialog>
    </>
  );
};

export default connect(({ color }: AppState) => ({
  buttonBackgroundColorError: color.buttonBackgroundColorError,
}))(InputTemplate);
