import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

export type LoadingProps = {
  display?: boolean;
};

type OverlayProps = {
  display: boolean;
};

const Overlay = styled(({ display, ...props }) => <div {...props} />)<
  OverlayProps
>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ display }: OverlayProps) => (display ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;

const StyledCircularProgress = connect(({ color }: AppState) => ({
  mainLight: color.mainLight,
}))(styled(({ mainLight, dispatch, ...props }) => (
  <CircularProgress {...props} />
))<{ mainLight: string }>`
  .MuiCircularProgress-svg {
    color: ${({ mainLight }) => mainLight};
  }
`);

/**
 * 【部品】【原子】【その他】ローディング <Loading>タグ
 * @param {boolean} display - 表示フラグ
 */
const Loading = ({ display = false }: LoadingProps) => (
  <Overlay display={display}>
    <StyledCircularProgress />
  </Overlay>
);

export default Loading;
