import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from 'presentational/general/atoms/button/IconButton';
import { AppState } from 'duck/Reducer';
import * as TYPES from 'common/Types';

export type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  title: string;
  showCloseButton: boolean;
}>;

const StyledModal = connect(({ color, common }: AppState) => ({
  surfaceBasic: color.surfaceBasic,
  deviceType: common.deviceType,
}))(styled(({ surfaceBasic, deviceType, dispatch, ...props }) => (
  <MuiDialog {...props} />
))<{
  surfaceBasic: string;
  deviceType: TYPES.DEVICE_TYPES;
}>`
  .MuiDialog-container .MuiPaper-root {
    padding: ${({ deviceType }) =>
      deviceType === TYPES.DEVICE_TYPES.MOBILE ? '10px' : '20px'};
    background-color: ${({ surfaceBasic }) => surfaceBasic};
  }

  .MuiDialog-container .MuiPaper-root.MuiDialog-paper {
    ${({ deviceType }) =>
      deviceType === TYPES.DEVICE_TYPES.MOBILE && `margin: 0px;`}
  }

  .MuiDialog-paperFullWidth {
    ${({ deviceType }) =>
      deviceType === TYPES.DEVICE_TYPES.MOBILE && `width: calc(100% - 34px);`}
  }
`);

const StyledModalTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    padding: 10px 15px;
  }
  .MuiTypography-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .MuiButtonBase-root {
    height: fit-content;
  }
`;

const StyledModalContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
`;

/**
 * 【部品】【有機体】【モーダル】 <Modal>タグ
 * @param {boolean} open - 表示フラグ
 * @param {string} title - タイトル
 * @param {boolean} showCloseButton - 閉じるボタン表示フラグ
 * @param {() => void} onClose - モーダルクローズ関数
 * @param {React.ReactNode} children - 表示内容
 */
const Modal = ({
  open,
  title,
  showCloseButton,
  onClose,
  children,
}: ModalProps) => {
  return (
    <StyledModal open={open} onClose={onClose} fullWidth>
      <StyledModalTitle>
        {title}
        {showCloseButton && (
          <IconButton
            iconName="close"
            iconSize={32}
            backgroundColor="transparent"
            onClick={onClose}
          />
        )}
      </StyledModalTitle>
      <StyledModalContent>{children}</StyledModalContent>
    </StyledModal>
  );
};

export default Modal;
