import { useState } from 'react';
import { ErrorProps, validateValue } from 'hooks/Validator';
import { VALIDATE_ERROR_MESSAGE } from 'common/Messages';

type TextNumberFieldUpdateProps = {
  value: string;
  error: ErrorProps;
  hasInput: boolean;
};

export type TextNumberFieldHooksProps = {
  value: string;
  error: ErrorProps;
  hasInput: boolean;
  setHasInputTrue: () => void;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => TextNumberFieldUpdateProps;
  onBlur: () => TextNumberFieldUpdateProps;
  clear: () => TextNumberFieldUpdateProps;
};

const TEXT_NUMBER_FIELD_DEFAULT_RULES = ['numeric'];
const VALIDATOR_DEFAULT_MESSAGE = {
  max: VALIDATE_ERROR_MESSAGE.TEXT_NUMBER_FIELD_MAX,
  min: VALIDATE_ERROR_MESSAGE.TEXT_NUMBER_FIELD_MIN,
};

/**
 * テキストフィールド<TextField>を使う場合の部品生成（数値）
 * @param {string} initialValue - 初期値
 * @param {boolean} hasInitialInput - 入力済フラグ初期値
 * @param {string[]} validatorRules - バリデーション内容
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ上書き用
 * @returns {TextNumberFieldHooksProps} - 生成された部品
 */
const useTextNumberFiled = (
  initialValue: string,
  hasInitialInput: boolean,
  validatorRules?: string[],
  validatorMessage?: { [key: string]: string },
): TextNumberFieldHooksProps => {
  const customValidatorRules = validatorRules
    ? [...TEXT_NUMBER_FIELD_DEFAULT_RULES, ...validatorRules]
    : TEXT_NUMBER_FIELD_DEFAULT_RULES;
  const customValidatorMessage = validatorMessage
    ? { ...VALIDATOR_DEFAULT_MESSAGE, ...validatorMessage }
    : VALIDATOR_DEFAULT_MESSAGE;

  const [value, setValue] = useState<string>(initialValue);
  const [error, setError] = useState<ErrorProps>(
    validateValue({
      value: initialValue,
      validatorRules: customValidatorRules,
      validatorMessage: customValidatorMessage,
    }),
  );
  const [hasInput, setHasInput] = useState<boolean>(hasInitialInput);

  const setHasInputTrue = (): void => {
    setHasInput(true);
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): TextNumberFieldUpdateProps => {
    const validateResult = validateValue({
      value: e.target.value,
      validatorRules: customValidatorRules,
      validatorMessage: customValidatorMessage,
    });
    const update: TextNumberFieldUpdateProps = {
      value: e.target.value,
      error: validateResult,
      hasInput,
    };
    setValue(update.value);
    setError(validateResult);
    return update;
  };

  const onBlur = (): TextNumberFieldUpdateProps => {
    const validateResult = validateValue({
      value,
      validatorRules: customValidatorRules,
      validatorMessage: customValidatorMessage,
    });
    const update: TextNumberFieldUpdateProps = {
      value,
      error: validateResult,
      hasInput: true,
    };
    setError(validateResult);
    setHasInput(update.hasInput);
    return update;
  };

  const clear = (): TextNumberFieldUpdateProps => {
    const validateResult = validateValue({
      value: '',
      validatorRules: customValidatorRules,
      validatorMessage: customValidatorMessage,
    });
    const update: TextNumberFieldUpdateProps = {
      value: '',
      error: validateResult,
      hasInput: false,
    };
    setValue(update.value);
    setError(validateResult);
    setHasInput(update.hasInput);
    return update;
  };

  return { value, error, hasInput, setHasInputTrue, onChange, onBlur, clear };
};

export default useTextNumberFiled;
