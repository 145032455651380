import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actions } from 'duck/client/template/Actions';
import TemplateState, {
  AnswersTemplate,
  AnswersPanel,
} from 'duck/client/template/State';
import {
  AnswerProgress,
  Panel,
  Form,
  TextForm,
  SelectForm,
  RadioForm,
  CheckboxForm,
  HiddenForm,
  FormTypeEnum,
  SliderListForm,
  MixedForm,
  FormAnswer,
} from 'oapi/api';
import { Template } from 'duck/Types';
import { APIError } from 'common/APIWrapper';

const {
  fetchAll,
  fetchTemplate,
  fetchCurrentAnswers,
  clearError,
  setError,
  load,
} = actions;

const initialTemplate = (): Template => {
  return {
    group_id: '',
    id: '',
    name: '',
    description: '',
    panels: [],
    created_at: '',
    updated_at: '',
    progress: AnswerProgress.UNANSWERED,
  };
};

const initialAnswers = (): AnswersTemplate => {
  return {
    id: '',
    custom_id: '',
    name: '',
    progress: AnswerProgress.UNANSWERED,
    panels: [],
  };
};

const initialState: TemplateState = {
  groupId: '',
  templateId: '',
  customId: '',
  isLoading: false,
  isUploading: false,
  template: initialTemplate(),
  answers: initialAnswers(),
};

/**
 * タイトルを作成する
 * @param {string} value 値
 * @param {TextForm} form フォーム情報
 */
const createTextTitle = (value: string, form: TextForm): string => {
  const prefix = form.prefix || '';
  const suffix = form.suffix || '';
  return prefix + value + suffix;
};

/**
 * タイトルを作成する
 * @param value - 値
 * @param form - フォーム情報
 */
const createCheckboxTitle = (value: string, form: CheckboxForm): string => {
  const v = form.list.find((val) => {
    return val.value === value;
  });
  if (v) {
    return v.label;
  }
  return value;
};

/**
 * タイトルを作成する
 * @param value - 値
 * @param form - フォーム情報
 */
const createRadioTitle = (
  value: string,
  form: RadioForm | SelectForm,
): string => {
  const v = form.list.find((val) => {
    return val.value === value;
  });
  if (v) {
    return v.label;
  }
  return value;
};

/**
 * タイトルを作成
 * @param {string} value - 値
 * @param {formType} formType - フォームのタイプ
 * @param {TextForm | SelectForm | RadioForm | CheckboxForm | HiddenForm} form - フォームの設定
 */
const createTitle = (
  value: string,
  formType: FormTypeEnum,
  form: TextForm | SelectForm | RadioForm | CheckboxForm | HiddenForm,
): string => {
  if (formType === FormTypeEnum.Text) {
    return createTextTitle(value, form as TextForm);
  }
  if (formType === FormTypeEnum.Checkbox) {
    return createCheckboxTitle(value, form as CheckboxForm);
  }
  if (formType === FormTypeEnum.Radio) {
    return createRadioTitle(value, form as RadioForm);
  }
  if (formType === FormTypeEnum.Select) {
    return createRadioTitle(value, form as SelectForm);
  }
  return value;
};

/**
 * 回答のコピーを作成する
 * @param {AnswersTemplate} answers - 回答
 */
const copyAnswers = (answers: AnswersTemplate): AnswersTemplate => {
  const copy = { ...answers };
  copy.panels = answers.panels.map(
    (panel): AnswersPanel => {
      const tempPanel = { ...panel };
      tempPanel.forms = panel.forms.map((form) => {
        const tempForm = form;
        if (form.answers) {
          tempForm.answers = form.answers.map((answer) => {
            return { ...answer };
          });
        }
        return tempForm;
      });
      return tempPanel;
    },
  );
  return copy;
};

const setFormsDefaultAnswers = (baseForms: Form[]): Form[] => {
  const forms = [...baseForms];
  return forms.map((form, i) => {
    if (
      form.type === FormTypeEnum.Text &&
      form.text_form &&
      form.text_form.default_value
    ) {
      const title = createTitle(
        form.text_form.default_value,
        form.type,
        form.text_form,
      );
      forms[i].answers = [
        {
          value: form.text_form.default_value,
          title,
        },
      ];
    } else if (
      form.type === FormTypeEnum.Date &&
      form.date_form &&
      form.date_form.default_value
    ) {
      const title = createTitle(
        form.date_form.default_value,
        form.type,
        form.date_form,
      );
      forms[i].answers = [
        {
          value: form.date_form.default_value,
          title,
        },
      ];
    } else if (
      form.type === FormTypeEnum.Radio &&
      form.radio_form &&
      form.radio_form.default_value
    ) {
      const title = createTitle(
        form.radio_form.default_value,
        form.type,
        form.radio_form,
      );
      forms[i].answers = [
        {
          value: form.radio_form.default_value,
          title,
        },
      ];
    } else if (
      form.type === FormTypeEnum.Checkbox &&
      form.checkbox_form &&
      form.checkbox_form.default_value
    ) {
      const title = createTitle(
        form.checkbox_form.default_value,
        form.type,
        form.checkbox_form,
      );
      forms[i].answers = [
        {
          value: form.checkbox_form.default_value,
          title,
        },
      ];
    } else if (
      form.type === FormTypeEnum.Select &&
      form.select_form &&
      form.select_form.default_value
    ) {
      const title = createTitle(
        form.select_form.default_value,
        form.type,
        form.select_form,
      );
      forms[i].answers = [
        {
          value: form.select_form.default_value,
          title,
        },
      ];
    } else if (form.type === FormTypeEnum.MixedForm && form.mixed_form) {
      (forms[i].mixed_form as MixedForm).forms = setFormsDefaultAnswers(
        form.mixed_form.forms,
      );
    } else if (
      form.type === FormTypeEnum.Slider &&
      form.slider_form &&
      form.slider_form.default_value
    ) {
      forms[i].answers = [
        {
          value: form.slider_form.default_value,
          title: form.slider_form.default_value,
        },
      ];
    } else if (form.type === FormTypeEnum.SliderList && form.slider_list_form) {
      form.slider_list_form.forms.forEach((slider, j) => {
        if (slider.slider_form.default_value) {
          (forms[i].slider_list_form as SliderListForm).forms[j].answers = [
            {
              value: slider.slider_form.default_value,
              title: slider.slider_form.default_value,
            },
          ];
        }
      });
    }
    return form;
  });
};

const setFormsAnswers = (baseForms: Form[], answers: FormAnswer[]): Form[] => {
  const forms = [...baseForms];
  forms.forEach((form, j) => {
    let ans = answers.find((answer) => answer.id === form.id);
    if (ans) {
      forms[j].answers = ans.answers;
    } else if (form.mixed_form) {
      (forms[j].mixed_form as MixedForm).forms = setFormsAnswers(
        form.mixed_form.forms,
        answers,
      );
    } else if (form.slider_list_form) {
      (forms[j]
        .slider_list_form as SliderListForm).forms = form.slider_list_form.forms.map(
        (sliderForm, k) => {
          ans = answers.find((answer) => answer.id === sliderForm.id);
          if (ans) {
            (forms[j].slider_list_form as SliderListForm).forms[k].answers =
              ans.answers;
          }
          return sliderForm;
        },
      );
    }
  });
  return forms;
};

/**
 * パネルの回答一覧の箱を作成する
 * @param {Array<Panel>} panels テンプレート内のパネル一覧
 */
const generatePanelsAnswers = (panels: Array<Panel>): Array<AnswersPanel> => {
  return panels.map(
    (panel): AnswersPanel => {
      return {
        id: panel.id,
        name: panel.name,
        description: panel.description,
        options: panel.options,
        forms: setFormsDefaultAnswers(panel.forms),
      };
    },
  );
};

const templateReducer = reducerWithInitialState<TemplateState>(initialState)
  .case(load.started, (state) => {
    return {
      ...state,
      isLoading: true,
    };
  })
  .case(load.done, (state) => {
    return {
      ...state,
      isLoading: false,
    };
  })
  .case(load.failed, (state, payload) => {
    return {
      ...state,
      error: payload.error,
      isLoading: false,
    };
  })
  .case(fetchAll.started, (state, { groupId, templateId, customId }) => {
    return {
      ...state,
      groupId,
      templateId,
      customId,
      template: initialTemplate(),
      answers: initialAnswers(),
      isLoading: true,
    };
  })
  .case(fetchAll.done, (state) => {
    return {
      ...state,
      isLoading: false,
    };
  })
  .case(fetchAll.failed, (state, payload) => {
    return {
      ...state,
      isLoading: false,
      error: payload.error,
    };
  })
  .case(fetchTemplate.started, (state) => {
    return state;
  })
  .case(fetchTemplate.done, (state, payload) => {
    const answers: AnswersTemplate = {
      id: payload.result.id,
      custom_id: payload.result.id,
      name: payload.result.name,
      progress: AnswerProgress.ANSWERED,
      panels: generatePanelsAnswers(payload.result.panels),
      options: payload.result.options,
    };
    const template: Template = {
      ...payload.result,
      progress: AnswerProgress.ANSWERED,
    };
    return {
      ...state,
      template,
      answers,
    };
  })
  .case(fetchTemplate.failed, (state, payload) => {
    return {
      ...state,
      error: payload.error,
    };
  })
  .case(fetchCurrentAnswers, (state, payload) => {
    if (state.template.id === '') {
      return {
        ...state,
        error: new APIError(
          'テンプレートが存在しません。',
          999,
          'テンプレートが存在しません。',
        ),
      };
    }
    const template = { ...state.template };
    const answers = copyAnswers(state.answers);
    answers.progress = payload.progress;
    template.progress = payload.progress;
    answers.panels.forEach((panel, i) => {
      answers.panels[i].forms = setFormsAnswers(panel.forms, payload.answers);
    });

    return {
      ...state,
      template,
      answers,
      customId: payload.custom_id,
      isConfirmed: true,
    };
  })
  .case(setError, (state, payload) => {
    return {
      ...state,
      error: payload,
    };
  })
  .case(clearError, (state) => {
    return {
      ...state,
      error: undefined,
    };
  });
export default templateReducer;
