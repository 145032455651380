import React, { useLayoutEffect } from 'react';
import * as TYPES from 'common/Types';
import { generateTsxToReplacedBr } from 'commontsx/Utility';
import useSlider from 'hooks/useSlider';
import { HandleSetAnswerProps } from 'presentational/general/templates/template/InputTemplate';
import Form from 'presentational/general/molecules/form/Form';
import FormDescription from 'presentational/general/molecules/other/FormDescription';
import InputArea from 'presentational/general/molecules/form/InputArea';
import Slider from 'presentational/general/atoms/basicInput/Slider';
import * as api from 'oapi/api';

export type SliderFormProps = {
  device: TYPES.DEVICE_TYPES;
  form: api.Form;
  disabled: boolean;
  visible: boolean;
  handleSetAnswer: (answer: HandleSetAnswerProps) => void;
};

/**
 * 【部品】【有機体】【テンプレート入力フォーム】スライダー
 * @param {TYPES.DEVICE_TYPES} device - 表示デバイスの種類
 * @param {api.Form} form - フォーム情報
 * @param {boolean} disabled - 非活性フラグ
 * @param {boolean} visible - 表示フラグ
 * @param {(answer: HandleSetAnswerProps) => void} handleSetAnswer - InputTemplateに入力状態を渡す関数
 */
const SliderForm = ({
  device,
  form,
  disabled,
  visible,
  handleSetAnswer,
}: SliderFormProps) => {
  const sliderForm = form.slider_form as api.SliderForm;
  const initialValue: number =
    form.answers && form.answers.length > 0
      ? parseInt(form.answers[0].value, 10)
      : sliderForm.min;
  const hasInitialInput: boolean =
    form.answers && form.answers.length > 0
      ? true
      : initialValue !== sliderForm.min;
  const hooks = useSlider(
    initialValue,
    sliderForm.min,
    hasInitialInput,
    sliderForm.validations.values,
  );

  const handleChange = (changeValue: number | number[]): void => {
    const { value, error, hasInput } = hooks.onChange(changeValue);
    handleSetAnswer({
      formId: form.id,
      values: [value],
      hasInput,
      isError: error.isError,
      isInitial: false,
      setHasInputTrue: hooks.setHasInputTrue,
    });
  };

  const handleChangeCommitted = (changeValue: number | number[]): void => {
    const { value, error, hasInput } = hooks.onChangeCommitted(changeValue);
    handleSetAnswer({
      formId: form.id,
      values: [value],
      hasInput,
      isError: error.isError,
      isInitial: false,
      setHasInputTrue: hooks.setHasInputTrue,
    });
  };

  useLayoutEffect((): void => {
    if (disabled && hooks.hasInput) {
      const { value, error, hasInput } = hooks.clear();
      handleSetAnswer({
        formId: form.id,
        values: [value],
        hasInput,
        isError: error.isError,
        isInitial: true,
        setHasInputTrue: hooks.setHasInputTrue,
      });
    }
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useLayoutEffect((): void => {
    handleSetAnswer({
      formId: form.id,
      values: [String(hooks.value)],
      hasInput: hooks.hasInput,
      isError: hooks.error.isError,
      isInitial: true,
      setHasInputTrue: hooks.setHasInputTrue,
    });
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {visible && (
        <Form
          device={device}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question={generateTsxToReplacedBr(form.subscribe)}
          complement={<FormDescription form={form} />}
          required={disabled ? false : form.is_required}
          visible={visible}
        >
          <InputArea
            error={hooks.hasInput && hooks.error.isError}
            errorMessage={hooks.hasInput ? hooks.error.errorMessage : ''}
          >
            <Slider
              value={hooks.value}
              defaultValue={sliderForm.min}
              max={sliderForm.max}
              min={sliderForm.min}
              step={sliderForm.step}
              onChange={handleChange}
              onChangeCommitted={handleChangeCommitted}
              disabled={disabled}
            />
          </InputArea>
        </Form>
      )}
    </>
  );
};

export default SliderForm;
