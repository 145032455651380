import React from 'react';
// eslint-disable-next-line max-len
import ParaCheckPersonalInfoContent from 'presentational/custom/molecules/other/ParaCheckPersonalInfoContent';
// eslint-disable-next-line max-len
import ParaCheckTermsOfUseContent from 'presentational/custom/molecules/other/ParaCheckTermsOfUseContent';
import { TermsHooksProps } from 'hooks/useTerms';
import Form from 'presentational/general/molecules/form/Form';
import InputArea from 'presentational/general/molecules/form/InputArea';
import Terms from 'presentational/general/atoms/compositeInput/Terms';
import Panel from 'presentational/general/organisms/area/Panel';
import * as TYPES from 'common/Types';

export type TermsPanelProps = {
  deviceType: TYPES.DEVICE_TYPES;
  personalInfoTermsState: TermsHooksProps;
  termsOfServiceState: TermsHooksProps;
};

/**
 * 【部品】【有機体】【エリア】Para Check BIO規約パネル <ParaCheckTermsPanel>タグ
 * @param {DEVICE_TYPES} device - 表示デバイス区分
 * @param {TermsHooksProps} personalInfoTermsState - 個人情報取り扱いについての状態
 */
const ParaCheckTermsPanel = ({
  deviceType,
  personalInfoTermsState,
  termsOfServiceState,
}: TermsPanelProps) => {
  return (
    <React.Fragment>
      <Panel device={deviceType}>
        <Form
          device={deviceType}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question="個人情報の取り扱いについて"
        >
          <InputArea error={false} errorMessage="">
            <Terms
              inlineHeight={200}
              checkboxDataList={personalInfoTermsState.checkboxData}
              onChange={personalInfoTermsState.onChange}
            >
              <ParaCheckPersonalInfoContent />
            </Terms>
          </InputArea>
        </Form>
      </Panel>
      <Panel device={deviceType}>
        <Form
          device={deviceType}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question="Web登録システム利用規約"
        >
          <InputArea error={false} errorMessage="">
            <Terms
              inlineHeight={200}
              checkboxDataList={termsOfServiceState.checkboxData}
              onChange={termsOfServiceState.onChange}
            >
              <ParaCheckTermsOfUseContent />
            </Terms>
          </InputArea>
        </Form>
      </Panel>
    </React.Fragment>
  );
};

export default ParaCheckTermsPanel;
