import React, { useLayoutEffect, useState, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { Form as APIForm } from 'duck/Types';
import * as api from 'oapi/api';
import * as TYPES from 'common/Types';
import CONSTS from 'common/Consts';
import { generateTsxToReplacedBr } from 'commontsx/Utility';
import useDateField, { DateUpdateProps } from 'hooks/useDateField';
import InputArea from 'presentational/general/molecules/form/InputArea';
import { HandleSetAnswerProps } from 'presentational/general/templates/template/InputTemplate';
import Form from 'presentational/general/molecules/form/Form';
import FormDescription from 'presentational/general/molecules/other/FormDescription';
import Select from 'presentational/general/atoms/basicInput/Select';
import { DateValidatorRules } from 'hooks/Validator';

export type DateFieldFormProps = {
  device: TYPES.DEVICE_TYPES;
  form: APIForm;
  disabled: boolean;
  visible: boolean;
  handleSetAnswer: (answer: HandleSetAnswerProps) => void;
};

/**
 * 【部品】【有機体】【テンプレート入力フォーム】年月日
 * @param {TYPES.DEVICE_TYPES} device - 表示デバイスの種類
 * @param {api.Form} form - フォーム情報
 * @param {boolean} disabled - 非活性フラグ
 * @param {boolean} visible - 表示フラグ
 * @param {(answer: HandleSetAnswerProps) => void} handleSetAnswer - InputTemplateに入力状態を渡す関数
 */
const DateFieldForm = ({
  device,
  form,
  disabled,
  visible,
  handleSetAnswer,
}: DateFieldFormProps) => {
  const dateForm = form.date_form as api.DateForm;
  const initialValue: Moment | null =
    form.answers && form.answers.length > 0
      ? moment(form.answers[0].value, CONSTS.DATE_FORMAT)
      : null;
  const hasInitialInput: boolean =
    form.answers && form.answers.length > 0 ? true : initialValue !== null;
  const initYear =
    initialValue && initialValue.isValid() ? initialValue.format('YYYY') : '';
  const initMonth =
    initialValue && initialValue.isValid() ? initialValue.format('MM') : '';
  const initDay =
    initialValue && initialValue.isValid() ? initialValue.format('DD') : '';
  const [hiddenVal, setHiddenVal] = useState(
    initialValue ? initialValue.format(CONSTS.DATE_FORMAT) : '',
  );
  const [yearVal, setYearVal] = useState(initYear);
  const [monthVal, setMonthVal] = useState(initMonth);
  const [dayVal, setDayVal] = useState(initDay);
  const hooks = useDateField(
    initialValue,
    hasInitialInput,
    dateForm.validations.values as DateValidatorRules,
    dateForm.validations.messages,
  );
  const thisYear = parseInt(moment().format('YYYY'), 10);
  const yearList: Array<api.FormValue> = useMemo(() => {
    const list: Array<api.FormValue> = [];
    for (let i = thisYear; i >= thisYear - 120; i -= 1) {
      list.push({ value: `${i}`, label: `${i}` });
    }
    return list;
  }, [thisYear]);
  const monthList: Array<api.FormValue> = [];
  for (let i = 1; i <= 12; i += 1) {
    const val = `0${i}`.slice(-2);
    monthList.push({ value: val, label: `${i}` });
  }
  const dayList: Array<api.FormValue> = [];
  for (let i = 1; i <= 31; i += 1) {
    const val = `0${i}`.slice(-2);
    dayList.push({ value: val, label: `${i}` });
  }

  const handleDateChange = (year: string, month: string, day: string): void => {
    let change: DateUpdateProps;
    if (year === '' || month === '' || day === '') {
      change = hooks.onChange(null, '');
    } else {
      const dateValue = `${year}/${month}/${day}`;
      const date = moment(dateValue, 'YYYY/MM/DD');
      change = hooks.onChange(date, dateValue);
    }
    setHiddenVal(`${year}/${month}/${day}`);
    handleSetAnswer({
      formId: form.id,
      values: [change.value],
      hasInput: change.hasInput,
      isError: change.error.isError,
      isInitial: false,
      setHasInputTrue: hooks.setHasInputTrue,
    });
  };
  const handleYearChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ): void => {
    setYearVal(e.target.value as string);
    handleDateChange(e.target.value as string, monthVal, dayVal);
  };

  const handleMonthChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ): void => {
    setMonthVal(e.target.value as string);
    handleDateChange(yearVal, e.target.value as string, dayVal);
  };

  const handleDayChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ): void => {
    setDayVal(e.target.value as string);
    handleDateChange(yearVal, monthVal, e.target.value as string);
  };

  const handleHiddenDateChange = (
    e: React.ChangeEvent<{ value: string }>,
  ): void => {
    let day = '';
    let month = '';
    let year = '';
    const vals = e.target.value.split('/');
    switch (vals.length) {
      case 3:
        [year, month, day] = vals;
        break;
      case 2:
        [year, month] = vals;
        break;
      case 1:
        [year] = vals;
        break;
      default:
        break;
    }
    setYearVal(year);
    setMonthVal(month);
    setDayVal(day);
    handleDateChange(year, month, day);
  };

  useLayoutEffect((): void => {
    if (disabled && hooks.hasInput) {
      const { value, error, hasInput } = hooks.clear();
      handleSetAnswer({
        formId: form.id,
        values: [value],
        hasInput,
        isError: error.isError,
        isInitial: true,
        setHasInputTrue: hooks.setHasInputTrue,
      });
    }
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useLayoutEffect((): void => {
    handleSetAnswer({
      formId: form.id,
      values: [hooks.value || ''],
      hasInput: hooks.hasInput,
      isError: hooks.error.isError,
      isInitial: true,
      setHasInputTrue: hooks.setHasInputTrue,
    });
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {visible && (
        <Form
          device={device}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question={generateTsxToReplacedBr(form.subscribe)}
          complement={<FormDescription form={form} />}
          required={disabled ? false : form.is_required}
          visible={visible}
        >
          <input
            style={{ display: 'none' }}
            type="text"
            ref={form.inputRef}
            onChange={handleHiddenDateChange}
            value={hiddenVal}
          />
          <InputArea
            error={hooks.hasInput && hooks.error.isError}
            errorMessage={hooks.hasInput ? hooks.error.errorMessage : ''}
          >
            <Select
              id={form.id}
              suffix="/"
              items={yearList}
              value={yearVal}
              onChange={handleYearChange}
              disabled={disabled || form.disabled}
              onBlur={() => (document.body.style.overflow = '')}
            />
            <Select
              id={`${form.id}_month`}
              suffix="/"
              items={monthList}
              value={monthVal}
              onChange={handleMonthChange}
              disabled={disabled || form.disabled}
              onBlur={() => (document.body.style.overflow = '')}
            />
            <Select
              id={`${form.id}_day`}
              items={dayList}
              value={dayVal}
              onChange={handleDayChange}
              disabled={disabled || form.disabled}
              onBlur={() => (document.body.style.overflow = '')}
            />
          </InputArea>
        </Form>
      )}
    </>
  );
};

export default DateFieldForm;
