/* eslint-disable max-len */
import React, { Fragment } from 'react';
import * as api from 'oapi/api';
import * as TYPES from 'common/Types';
import { HandleSetAnswerProps } from 'presentational/general/templates/template/InputTemplate';
import TemplatePanel from 'presentational/general/organisms/area/TemplatePanel';
import NoneForm from 'presentational/general/organisms/inputTemplateForm/NoneForm';
import SelectForm from 'presentational/general/organisms/inputTemplateForm/SelectForm';
import CheckboxGroupForm from 'presentational/general/organisms/inputTemplateForm/CheckboxGroupForm';
import TextNumberFieldForm from 'presentational/general/organisms/inputTemplateForm/TextNumberFieldForm';
import TextStringFieldForm from 'presentational/general/organisms/inputTemplateForm/TextStringFieldForm';
import PasswordFieldForm from 'presentational/general/organisms/inputTemplateForm/PasswordFieldForm';
import TextAreaForm from 'presentational/general/organisms/inputTemplateForm/TextAreaForm';
import RadioGroupForm from 'presentational/general/organisms/inputTemplateForm/RadioGroupForm';
import CalendarFieldForm from 'presentational/general/organisms/inputTemplateForm/CalendarFieldForm';
import DateFieldForm from 'presentational/general/organisms/inputTemplateForm/DateFieldForm';
import SliderForm from 'presentational/general/organisms/inputTemplateForm/SliderForm';
import SliderListForm from 'presentational/general/organisms/inputTemplateForm/SliderListForm';
import MixedForm from 'presentational/general/organisms/inputTemplateForm/MixedForm';
import MixedPostalWithAddressForm from 'presentational/general/organisms/inputTemplateForm/MixedPostalWithAddressForm';
import PasswordWithConfirmForm from 'presentational/general/organisms/inputTemplateForm/PasswordWithConfirmForm';
import Text from 'presentational/general/atoms/text/Text';
/* eslint-enable max-len */

export type FormPanelProps = {
  device: TYPES.DEVICE_TYPES;
  panel: api.AnswersPanel;
  visible: boolean;
  isVisibleForms: boolean[];
  handleSetAnswer: (answer: HandleSetAnswerProps) => void;
};

/**
 * 【部品】【有機体】【テンプレート入力フォーム】テキストフィールド(数値)
 * @param {TYPES.DEVICE_TYPES} device - 表示デバイスの種類
 * @param {api.AnswersPanel} panel - パネル情報
 * @param {boolean} visible - 表示フラグ
 * @param {(answer: HandleSetAnswerProps) => void} handleSetAnswer - InputTemplateに入力状態を渡す関数
 */
const FormPanel = ({
  device,
  panel,
  visible,
  isVisibleForms,
  handleSetAnswer,
}: FormPanelProps) => {
  return (
    <TemplatePanel
      id={panel.id}
      device={device}
      templateType={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
      isDisplayed={visible}
    >
      <Text fontSize={24} fontWeight="bold">
        {panel.title}
      </Text>
      {panel.forms.map((form: api.Form, formIndex: number) => {
        switch (form.type) {
          case 'none':
            return (
              <NoneForm
                key={form.id}
                device={device}
                form={form}
                disabled={!visible || !isVisibleForms[formIndex]}
                visible={isVisibleForms[formIndex]}
                headline={formIndex + 1 !== panel.forms.length}
              />
            );
          case 'text':
            switch (form.text_form?.type) {
              case 'number':
                return (
                  <TextNumberFieldForm
                    key={form.id}
                    device={device}
                    form={form}
                    disabled={!visible || !isVisibleForms[formIndex]}
                    visible={isVisibleForms[formIndex]}
                    handleSetAnswer={handleSetAnswer}
                  />
                );
              case 'text':
                return (
                  <TextStringFieldForm
                    key={form.id}
                    device={device}
                    form={form}
                    disabled={!visible || !isVisibleForms[formIndex]}
                    visible={isVisibleForms[formIndex]}
                    handleSetAnswer={handleSetAnswer}
                  />
                );
              case 'password':
                return (
                  <PasswordFieldForm
                    key={form.id}
                    device={device}
                    form={form}
                    disabled={!visible || !isVisibleForms[formIndex]}
                    visible={isVisibleForms[formIndex]}
                    handleSetAnswer={handleSetAnswer}
                  />
                );
              case 'password_with_confirm':
                return (
                  <PasswordWithConfirmForm
                    key={form.id}
                    device={device}
                    form={form}
                    disabled={!visible || !isVisibleForms[formIndex]}
                    visible={isVisibleForms[formIndex]}
                    handleSetAnswer={handleSetAnswer}
                  />
                );
              default:
                return <Fragment key={form.id} />;
            }
          case 'text_area':
            return (
              <TextAreaForm
                key={form.id}
                device={device}
                form={form}
                disabled={!visible || !isVisibleForms[formIndex]}
                visible={isVisibleForms[formIndex]}
                handleSetAnswer={handleSetAnswer}
              />
            );
          case 'select':
            return (
              <SelectForm
                key={form.id}
                device={device}
                form={form}
                disabled={!visible || !isVisibleForms[formIndex]}
                visible={isVisibleForms[formIndex]}
                handleSetAnswer={handleSetAnswer}
              />
            );
          case 'checkbox':
            return (
              <CheckboxGroupForm
                key={form.id}
                device={device}
                form={form}
                disabled={!visible || !isVisibleForms[formIndex]}
                visible={isVisibleForms[formIndex]}
                handleSetAnswer={handleSetAnswer}
              />
            );
          case 'radio':
            return (
              <RadioGroupForm
                key={form.id}
                device={device}
                form={form}
                disabled={!visible || !isVisibleForms[formIndex]}
                visible={isVisibleForms[formIndex]}
                handleSetAnswer={handleSetAnswer}
              />
            );
          case 'calendar':
            return (
              <CalendarFieldForm
                key={form.id}
                device={device}
                form={form}
                disabled={!visible || !isVisibleForms[formIndex]}
                visible={isVisibleForms[formIndex]}
                handleSetAnswer={handleSetAnswer}
              />
            );
          case 'date':
            return (
              <DateFieldForm
                key={form.id}
                device={device}
                form={form}
                disabled={!visible || !isVisibleForms[formIndex]}
                visible={isVisibleForms[formIndex]}
                handleSetAnswer={handleSetAnswer}
              />
            );
          case 'slider':
            return (
              <SliderForm
                key={form.id}
                device={device}
                form={form}
                disabled={!visible || !isVisibleForms[formIndex]}
                visible={isVisibleForms[formIndex]}
                handleSetAnswer={handleSetAnswer}
              />
            );
          case 'slider_list':
            return (
              <SliderListForm
                key={form.id}
                device={device}
                form={form}
                disabled={!visible || !isVisibleForms[formIndex]}
                visible={isVisibleForms[formIndex]}
                handleSetAnswer={handleSetAnswer}
              />
            );
          case 'mixed_form':
            switch (form.text_form?.type) {
              case 'postal_with_address':
                return (
                  <MixedPostalWithAddressForm
                    key={form.id}
                    device={device}
                    form={form}
                    disabled={!visible || !isVisibleForms[formIndex]}
                    visible={isVisibleForms[formIndex]}
                    handleSetAnswer={handleSetAnswer}
                  />
                );
              default:
                return (
                  <MixedForm
                    key={form.id}
                    device={device}
                    form={form}
                    disabled={!visible || !isVisibleForms[formIndex]}
                    visible={isVisibleForms[formIndex]}
                    handleSetAnswer={handleSetAnswer}
                  />
                );
            }
          default:
            return <Fragment key={form.id} />;
        }
      })}
    </TemplatePanel>
  );
};

export default FormPanel;
