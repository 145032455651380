import { AxiosInstance, AxiosPromise } from 'axios';
import { Configuration, DefaultApiFp as oapiDefaultApiFp } from 'oapi';

type wrapper = (axios?: AxiosInstance, basePath?: string) => AxiosPromise;
export class APIError extends Error {
  public status: number;

  public detail: string;

  constructor(message: string, status: number, detail: string) {
    super();
    this.message = message;
    this.name = 'APIError';
    this.status = status;
    this.detail = detail;
  }
}

let defaultHost = 'http://localhost:3000';
export const SetAPIWrapperDefaultHost = (host: string) => {
  defaultHost = host;
};

let defaultConfiguration: Configuration = new Configuration({
  baseOptions: {
    headers: {
      'Accept-Language': 'ja-JP,en-US;q=0.9,en;q=0.8',
    },
  },
});
let apiList = oapiDefaultApiFp(defaultConfiguration);
export const SetConfiguration = (configuration: Configuration) => {
  defaultConfiguration = configuration;
  apiList = oapiDefaultApiFp(defaultConfiguration);
};

export const DefaultApiFp = () => {
  return apiList;
};

const APIWrapper = async (api: wrapper) => {
  try {
    return await api(undefined, defaultHost);
  } catch (error) {
    let status = 999;
    let detail = '';
    if (error.response) {
      status = error.response.status;
      if (error.response.data && error.response.data.errors) {
        detail = error.response.data.errors[0].detail;
      } else {
        detail = error.response.data;
      }
    } else if (error.Error) {
      detail = error.Error();
    }
    throw new APIError('エラーが発生しました', status, detail);
  }
};

export default APIWrapper;
