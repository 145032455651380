import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import * as TYPES from 'common/Types';
import CONSTS from 'common/Consts';
import Text from 'presentational/general/atoms/text/Text';

const PARTS = {
  HEIGHT: 20,
  PADDING: 10,
};

type CopyrightProps = {
  device: TYPES.DEVICE_TYPES;
  backgroundColor: string;
};

const Copyright = styled.div<CopyrightProps>`
  background-color: ${({ backgroundColor }: CopyrightProps) => backgroundColor};
  ${({ device }: CopyrightProps) =>
    device === TYPES.DEVICE_TYPES.PC &&
    `min-width: calc(${CONSTS.CONTENTS_AREA_WIDTH}px - ${PARTS.PADDING}px * 2);`}
  width: calc(100% - ${PARTS.PADDING}px * 2);
  height: ${PARTS.HEIGHT}px;
  padding: ${PARTS.PADDING}px;
`;

export const height = PARTS.HEIGHT + PARTS.PADDING * 2;

export type FooterProps = {
  device: TYPES.DEVICE_TYPES;
  textLight: string;
  text?: string;
  backgroundColor?: string;
};

/**
 * 【部品】【有機体】【エリア】【SSI個別】フッター <Footer>タグ
 * @param {DEVICE_TYPES} device - 表示デバイス区分
 * @param {string} textLight - テキスト色
 */
const Footer = ({
  device,
  textLight,
  text,
  backgroundColor = '#adadad',
}: FooterProps) => {
  return (
    <Copyright device={device} backgroundColor={backgroundColor}>
      <Text fontSize={10} color={textLight}>
        {text}
      </Text>
    </Copyright>
  );
};

export default connect(({ color }: AppState) => ({
  textLight: color.textLight,
}))(Footer);
