import Validator from 'validatorjs';
import moment from 'moment';
import { getSpecificValueCountFromArray } from 'common/Utility';
import { VALIDATE_ERROR_MESSAGE } from 'common/Messages';
import CONSTS from 'common/Consts';

Validator.useLang('ja');

// 不正日付のカスタムバリデーションを追加
Validator.register(
  'valid_date',
  (value) => {
    if (typeof value === 'string' && value.indexOf('_') === -1) {
      return moment(value, CONSTS.DATE_FORMAT).isValid();
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.DATE_INVALID,
);

// 本日以降の日付を入力しているか否かをチェックするカスタムバリデーションを追加
Validator.register(
  'before_today',
  (value) => {
    if (typeof value === 'string') {
      const today = moment(
        moment().format(CONSTS.DATE_FORMAT),
        CONSTS.DATE_FORMAT,
      );
      return today >= moment(value, CONSTS.DATE_FORMAT);
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.DATE_AFTER,
);

// 本日以前の日付を入力しているか否かをチェックするカスタムバリデーションを追加
Validator.register(
  'afore_today',
  (value) => {
    if (typeof value === 'string') {
      const today = moment(
        moment().format(CONSTS.DATE_FORMAT),
        CONSTS.DATE_FORMAT,
      );
      return today <= moment(value, CONSTS.DATE_FORMAT);
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.DATE_BEFORE,
);

// 生年月日入力の際、特定年齢以上か否かをチェックするカスタムバリデーションを追加
Validator.register(
  'years_and_over',
  (value, age) => {
    if (typeof value === 'string') {
      const today = moment(
        moment().format(CONSTS.DATE_FORMAT),
        CONSTS.DATE_FORMAT,
      );
      const yearsAndOver = moment(value, CONSTS.DATE_FORMAT).add(
        parseInt(age, 10),
        'years',
      );
      return today >= yearsAndOver;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.DATE_YEARS_AND_OVER,
);

// 電話番号
Validator.register(
  'phone',
  (value) => {
    if (typeof value === 'string') {
      return value.match(/^[+\d]{1}\d+-?\d+-?\d+$/) !== null;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.PHONE,
);

// 郵便番号
Validator.register(
  'zip',
  (value) => {
    if (typeof value === 'string') {
      return value.match(/^\d{3}-?\d{4}$/) !== null;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.ZIP,
);

// カタカナ
Validator.register(
  'katakana',
  (value) => {
    if (typeof value === 'string') {
      return value.match(/^[ァ-ヴー]+$/) !== null;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.KATAKANA,
);

// カタカナ+英数
Validator.register(
  'katakana_with_alphanumeric',
  (value) => {
    if (typeof value === 'string') {
      return value.match(/^[ァ-ヴー\u{3000}\x20A-Za-z\d]+$/) !== null;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.KATAKANA_WITH_ALPHANUMERIC,
);

// 整数桁数
// 最初に0があった場合でも桁数として考える
// 何も入力がない場合はtrue
// 指数表記はエラー
// 数値に変換できない場合はエラー
// .1などの書き方は一旦認めない(parseInt('.1')はNaNとなるため)
Validator.register(
  'max_integer_digit',
  (value, max) => {
    if (typeof value === 'string') {
      if (value === '') {
        return true;
      }
      if (Number.isNaN(Number(value))) {
        return false;
      }
      if (value.indexOf('e') !== -1) {
        return false;
      }
      const [beforeDecimal] = value.split('.');
      const val = parseInt(beforeDecimal, 10);
      const len = parseInt(max, 10);
      if (Number.isNaN(len) || Number.isNaN(val)) {
        return false;
      }
      if (val < 0) {
        return beforeDecimal.length <= len + 1;
      }
      return beforeDecimal.length <= len;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.MAX_INTEGER_DIGIT,
);

// 小数桁数
// 後ろに0があった場合でも桁数として数える
// 何も入力がない場合はtrue
// 指数表記はエラー
// 数値に変換できない場合はエラー
Validator.register(
  'max_decimal_digit',
  (value, max) => {
    if (typeof value === 'string') {
      if (value === '') {
        return true;
      }
      if (Number.isNaN(Number(value))) {
        return false;
      }
      if (value.indexOf('e') !== -1) {
        return false;
      }
      const afterDecimal = value.split('.')[1];
      if (!afterDecimal || afterDecimal === '') {
        return true;
      }
      const val = parseInt(afterDecimal, 10);
      const len = parseInt(max, 10);
      if (Number.isNaN(len) || Number.isNaN(val)) {
        return false;
      }
      return afterDecimal.length <= len;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.MAX_DECIMAL_DIGIT,
);

export type DateValidatorRules = string[];

export type ErrorProps = {
  isError: boolean;
  errorMessage: string;
};

export type validateValueProps = {
  value: string;
  validatorRules?: string[];
  validatorMessage?: { [key: string]: string };
};

export type validateChecksProps = {
  checks: boolean[];
  validatorRules?: string[];
  validatorMessage?: { [key: string]: string };
};

export type validateSliderProps = {
  value: number;
  validatorRules?: string[];
  validatorMessage?: { [key: string]: string };
};

export const VALIDATOR_DEFAULT_MESSAGE = {
  required: VALIDATE_ERROR_MESSAGE.REQUIRED,
  regex: VALIDATE_ERROR_MESSAGE.REGEX,
  max: VALIDATE_ERROR_MESSAGE.MAX,
  min: VALIDATE_ERROR_MESSAGE.MIN,
  numeric: VALIDATE_ERROR_MESSAGE.NUMERIC,
  integer: VALIDATE_ERROR_MESSAGE.INTEGER,
  email: VALIDATE_ERROR_MESSAGE.EMAIL,
  not_in: VALIDATE_ERROR_MESSAGE.NOT_IN,
};

export const VALIDATOR_DEFAULT_NUMBER_MESSAGE = {
  ...VALIDATOR_DEFAULT_MESSAGE,
  max: VALIDATE_ERROR_MESSAGE.TEXT_NUMBER_FIELD_MAX,
  min: VALIDATE_ERROR_MESSAGE.TEXT_NUMBER_FIELD_MIN,
};

/**
 * バリデーション実施関数
 * @param {string} value - 比較値
 * @param {{value: string[]}} validatorRules - バリデーション内容
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ上書き用
 */
export const validateValue = ({
  value,
  validatorRules,
  validatorMessage,
}: validateValueProps): ErrorProps => {
  let isError: boolean = false;
  let errorMessage: string = '';
  if (validatorRules) {
    const validation = new Validator(
      { value },
      { value: validatorRules },
      {
        ...VALIDATOR_DEFAULT_MESSAGE,
        ...validatorMessage,
      },
    );
    isError = validation.fails() === true;
    errorMessage = isError ? validation.errors.errors.value[0] : '';
  }
  return { isError, errorMessage };
};

/**
 * チェックボックスバリデーション実施関数
 * @param {boolean[]} checks - 比較値
 * @param {{value: string[]}} validatorRules - バリデーション内容
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ上書き用
 */
export const validateChecks = ({
  checks,
  validatorRules,
  validatorMessage,
}: validateChecksProps) => {
  let isError: boolean = false;
  let errorMessage: string = '';
  if (validatorRules) {
    const trueCount = getSpecificValueCountFromArray(checks, true);
    const value = trueCount === 0 ? '' : String(trueCount);
    const validation = new Validator(
      { checks: value },
      { checks: validatorRules },
      {
        ...VALIDATOR_DEFAULT_MESSAGE,
        ...validatorMessage,
      },
    );
    isError = validation.fails() === true;
    errorMessage = isError ? validation.errors.errors.checks[0] : '';
  }
  return { isError, errorMessage };
};

/**
 * スライダーバリデーション実施関数
 * @param {number} value - 比較値
 * @param {{value: string[]}} validatorRules - バリデーション内容
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ上書き用
 */
export const validateSliderValue = ({
  value,
  validatorRules,
  validatorMessage,
}: validateSliderProps): ErrorProps => {
  let isError: boolean = false;
  let errorMessage: string = '';
  if (validatorRules) {
    const validation = new Validator(
      { value },
      { value: validatorRules },
      {
        ...VALIDATOR_DEFAULT_MESSAGE,
        ...validatorMessage,
      },
    );
    isError = validation.fails() === true;
    errorMessage = isError ? validation.errors.errors.value[0] : '';
  }
  return { isError, errorMessage };
};

export default Validator;
