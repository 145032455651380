import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'duck/Reducer';
import styled from 'styled-components';
import MuiSlider from '@material-ui/core/Slider';
import { hex2rgba } from 'common/Utility';

export type SliderProps = {
  value?: number | number[];
  defaultValue?: number;
  max?: number;
  min?: number;
  step?: number;
  disabled?: boolean;
  onChange?: (value: number | number[]) => void;
  onChangeCommitted?: (value: number | number[]) => void;
  valueLabelDisplay?: 'on' | 'auto' | 'off';
};

type StyledSliderProps = {
  accentBasic: string;
  textLight: string;
  disabledBasic: string;
};

const StyledSlider = connect(({ color }: AppState) => ({
  accentBasic: color.accentBasic,
  textLight: color.textLight,
  disabledBasic: color.disabledBasic,
}))(styled(({ accentBasic, textLight, disabledBasic, dispatch, ...props }) => (
  <MuiSlider {...props} />
))<StyledSliderProps>`
  /* base */
  &.MuiSlider-root {
    margin: 50px 5px 13px;
    padding: 0;
    width: calc(100% - 10px * 2);
    color: ${({ accentBasic }) => accentBasic};
  }
  .PrivateValueLabel-label-16 {
    color: ${({ textLight }) => textLight};
  }

  /* hover */
  .MuiSlider-thumb.Mui-focusVisible,
  .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 8px
      ${({ accentBasic }) => hex2rgba(accentBasic, 0.16)};
  }
  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 0px 0px 14px
      ${({ accentBasic }) => hex2rgba(accentBasic, 0.16)};
  }

  /* disabled */
  &.MuiSlider-root.Mui-disabled {
    color: ${({ disabledBasic }) => disabledBasic};
  }
  .MuiSlider-thumb.Mui-disabled {
    width: 12px;
    height: 12px;
  }
`);

const defaultOnChange = () => {};
const defaultOnChangeCommitted = () => {};

/**
 * 【部品】【原子】【基本入力】スライダー <Slider>タグ
 * @param {number | number[]} value - 値
 * @param {number} step - 目盛り単位
 * @param {number} max - 最大値
 * @param {number} min - 最小値
 * @param {boolean} disabled - 非活性フラグ
 * @param {(
 * 	event: React.ChangeEvent<{}>,
 * 	value: number | number[]
 * ) => void} onChangeCommitted - 値変更完了イベント
 * @param {'on' | 'auto' | 'off'} valueLabelDisplay - 値を常時表示するか
 */
const Slider = ({
  value,
  step = 1,
  max = 100,
  min = 0,
  disabled = false,
  onChange = defaultOnChange,
  onChangeCommitted = defaultOnChangeCommitted,
  valueLabelDisplay = 'on',
}: SliderProps) => {
  return (
    <StyledSlider
      defaultValue={value}
      value={value}
      step={step}
      valueLabelDisplay={valueLabelDisplay}
      disabled={disabled}
      max={max}
      min={min}
      onChangeCommitted={(
        e: React.ChangeEvent,
        changeValue: number | number[],
      ) => {
        onChangeCommitted(changeValue);
      }}
      onChange={(e: React.ChangeEvent, changeValue: number | number[]) => {
        onChange(changeValue);
      }}
    />
  );
};

export default Slider;
