import React, { useState, useLayoutEffect, useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { AppState } from 'duck/Reducer';
import { connect } from 'react-redux';
import { CommonState as CustomCommonState } from 'duck/custom/common/State';
import { Setting } from 'duck/manager/setting/State';
import CommonTemplate from 'presentational/custom/templates/CommonTemplate';
import ViewGroup from 'presentational/general/templates/template/ViewGroup';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import Text from 'presentational/general/atoms/text/Text';
import Loading from 'presentational/general/atoms/other/Loading';
import store from 'Store';
import { changeDisplayedOperationGuideDialog } from 'duck/custom/common/Actions';
import membersActions from 'duck/custom/members/Actions';
import groupsActions, { FuncFetchAllType } from 'duck/client/groups/Actions';
import { APIError } from 'common/APIWrapper';
import CONSTS from 'common/Consts';
import * as TYPES from 'common/Types';
import { CustomMemberDetail } from 'oapi/api';
import MaintenanceDialog from 'presentational/custom/organisms/modal/MaintenanceDialog';

export type TopProps = {
  deviceType: TYPES.DEVICE_TYPES;
  customCommon: CustomCommonState;
  fetchAll: FuncFetchAllType;
  sampleId: string;
  membersError?: APIError;
  isLoading: boolean;
  selectedMember?: CustomMemberDetail;
  isSampleIdConfirmed: boolean;
  isSampleIdMine: boolean;
  self?: CustomMemberDetail;
  setting: Setting;
  getSelf: () => Promise<boolean>;
  fetchSelectedMember: () => Promise<CustomMemberDetail | undefined>;
  checkConfirmedAnswers: () => Promise<boolean>;
};

/**
 * 【画面】GC004_トップページ
 * @param {CommonState} customCommon 個別画面共通(Redux)
 */
const Top = ({
  deviceType,
  customCommon,
  fetchAll,
  selectedMember,
  self,
  getSelf,
  fetchSelectedMember,
  checkConfirmedAnswers,
  membersError,
  isSampleIdConfirmed,
  isSampleIdMine,
  setting,
  isLoading,
}: TopProps) => {
  const { history } = useReactRouter();
  const [isOperationGuideDialogOpen, setIsOperationGuideDialogOpen] = useState<
    boolean
  >(false);
  const [isMaintenance, setIsMaintenance] = useState<boolean>(
    !customCommon.displayedOperationGuideDialog,
  );
  const operationGuideDialogOnClose = () => {
    setIsOperationGuideDialogOpen(false);
  };
  const operationGuideDialogOkOnClick = () => {
    setIsOperationGuideDialogOpen(false);
  };
  const operationGuideDialogButtons = [
    {
      text: 'OK',
      onClick: operationGuideDialogOkOnClick,
    },
  ];

  const [isError, setIsError] = useState(false);
  const handleCloseErrorDialog = () => {
    setIsError(false);
    history.push(CONSTS.PATH_SET_SUBJECT);
  };
  const operationErrorDialogButtons = [
    {
      text: 'OK',
      onClick: handleCloseErrorDialog,
    },
  ];

  const handleCloseConflictSampleIdDialog = () => {
    history.push(CONSTS.PATH_SET_SUBJECT);
  };
  const operationConflictSampleIdDialogButtons = [
    {
      text: 'OK',
      onClick: handleCloseConflictSampleIdDialog,
    },
  ];

  const handleClose = () => {
    setIsMaintenance(false);
  };

  useLayoutEffect(() => {
    fetchSelectedMember().then((member) => {
      if (member === undefined) {
        setIsError(true);
        return;
      }
      const excludeCategories: Array<string> = [];
      const excludeTemplates: Array<string> = [];
      if (!member.has_informed_consent_checked) {
        excludeCategories.push('特典レポート付き研究参加アンケート');
      }
      if (
        member?.sample_id_version?.charAt(0) === '4' &&
        (member?.sample_id?.includes('U') || member?.sample_id?.includes('V'))
      ) {
        excludeTemplates.push('食事について');
      } else if (member?.sample_id_version.charAt(0) === '4') {
        excludeTemplates.push('摂取食品について', '食事・運動評価');
      }
      fetchAll({ excludeCategories, excludeTemplates });
    });
    checkConfirmedAnswers().then((isSucceed) => {
      if (!isSucceed) {
        setIsError(true);
      }
    });
  }, [fetchSelectedMember, checkConfirmedAnswers, fetchAll]);

  useLayoutEffect(() => {
    if (!self) {
      getSelf();
    }
  }, [getSelf, self]);

  useEffect(() => {
    window.history.pushState(null, '', null);
    window.onpopstate = () => {};
    return () => {
      window.onpopstate = null;
    };
  }, []);

  useEffect(() => {
    if (!(isMaintenance || customCommon.displayedOperationGuideDialog)) {
      setIsOperationGuideDialogOpen(true);
      store.dispatch(changeDisplayedOperationGuideDialog(true));
    }
  }, [isMaintenance, customCommon.displayedOperationGuideDialog]);

  if (isError) {
    return (
      <Dialog
        open={Boolean(membersError && membersError.status !== 401)}
        onClose={handleCloseErrorDialog}
        title="被検者選択"
        buttons={operationErrorDialogButtons}
      >
        <Text>{membersError && membersError.detail}</Text>
      </Dialog>
    );
  }

  if (!selectedMember) {
    return <Loading display={isLoading} />;
  }

  if (isSampleIdConfirmed && !isSampleIdMine) {
    return (
      <Dialog
        open
        onClose={handleCloseConflictSampleIdDialog}
        title="アンケート入力中のサンプルID"
        buttons={operationConflictSampleIdDialogButtons}
      >
        <Text>
          {`現在使用中のサンプルID(${selectedMember.sample_id})は別の被験者によってすでに回答されています。`}
        </Text>
      </Dialog>
    );
  }

  return (
    <CommonTemplate
      device={deviceType}
      headerColor={setting.headerColor}
      footerColor={setting.footerColor}
      footerText={setting.footerText}
    >
      {selectedMember && (
        <>
          <ViewGroup
            device={deviceType}
            customId={selectedMember.sample_id}
            version={selectedMember.sample_id_version}
          />
          <Dialog
            open={isOperationGuideDialogOpen && !isSampleIdConfirmed}
            onClose={operationGuideDialogOnClose}
            title="すべてのアンケートに回答した後、右下の回答送信ボタンを押してください"
            buttons={operationGuideDialogButtons}
          >
            <Text>
              回答送信されるまで、アンケート回答は未提出の状態となります。
            </Text>
          </Dialog>
          <MaintenanceDialog open={isMaintenance} onClose={handleClose} />
        </>
      )}
    </CommonTemplate>
  );
};

export default connect(
  ({
    common,
    customCommon,
    sampleidInfo,
    members,
    managerSetting,
  }: AppState) => ({
    deviceType: common.deviceType,
    customCommon,
    sampleId: sampleidInfo.sampleId,
    selectedMember: members.selectedMember,
    self: members.self,
    membersError: members.error,
    isLoading: members.isLoading,
    isSampleIdConfirmed: members.isSampleIdConfirmed,
    isSampleIdMine: members.isSampleIdMine,
    setting: managerSetting.setting,
  }),
  {
    fetchAll: groupsActions.fetchAll,
    getSelf: membersActions.getSelf,
    fetchSelectedMember: membersActions.fetchSelectedMember,
    checkConfirmedAnswers: membersActions.checkConfirmedAnswers,
  },
)(Top);
