import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import Store from 'Store';
import { SetAPIWrapperDefaultHost } from 'common/APIWrapper';
import App from './App';
import * as serviceWorker from './serviceWorker';

SetAPIWrapperDefaultHost(
  `${window.location.protocol}//${window.location.host}`,
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
