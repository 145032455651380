import React, { useLayoutEffect } from 'react';
import * as api from 'oapi/api';
import CONSTS from 'common/Consts';
import useSelect from 'hooks/useSelect';
import { HandleSetAnswerProps } from 'presentational/general/templates/template/InputTemplate';
import MixedInputArea from 'presentational/general/molecules/form/MixedInputArea';
import Select from 'presentational/general/atoms/basicInput/Select';

export type SelectFormProps = {
  form: api.Form;
  disabled: boolean;
  handleSetAnswer: (answer: HandleSetAnswerProps) => void;
};

/**
 * 【部品】【有機体】【テンプレート入力フォーム】複数セレクトボックス
 * @param {api.Form} form - フォーム情報
 * @param {boolean} disabled - 非活性フラグ
 * @param {(answer: HandleSetAnswerProps) => void} handleSetAnswer - InputTemplateに入力状態を渡す関数
 */
const MixedSelectForm = ({
  form,
  disabled,
  handleSetAnswer,
}: SelectFormProps) => {
  const selectForm = form.select_form as api.SelectForm;
  const initialValue =
    form.answers && form.answers.length > 0
      ? form.answers[0].value
      : CONSTS.SELECT_EMPTY_VALUE;
  const hasInitialInput: boolean =
    form.answers && form.answers.length > 0
      ? true
      : initialValue !== CONSTS.SELECT_EMPTY_VALUE;
  const hooks = useSelect(
    initialValue,
    hasInitialInput,
    selectForm.validations.values,
  );

  const handleChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ): void => {
    const { value, error, hasInput } = hooks.onChange(e);
    handleSetAnswer({
      formId: form.id,
      values: [value],
      hasInput,
      isError: error.isError,
      isInitial: false,
      setHasInputTrue: hooks.setHasInputTrue,
    });
  };

  useLayoutEffect((): void => {
    if (disabled && hooks.hasInput) {
      const { value, error, hasInput } = hooks.clear();
      handleSetAnswer({
        formId: form.id,
        values: [value],
        hasInput,
        isError: error.isError,
        isInitial: true,
        setHasInputTrue: hooks.setHasInputTrue,
      });
    }
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useLayoutEffect((): void => {
    handleSetAnswer({
      formId: form.id,
      values: [hooks.value],
      hasInput: hooks.hasInput,
      isError: hooks.error.isError,
      isInitial: true,
      setHasInputTrue: hooks.setHasInputTrue,
    });
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MixedInputArea
      error={hooks.hasInput && hooks.error.isError}
      errorMessage={hooks.hasInput ? hooks.error.errorMessage : ''}
    >
      <Select
        id={form.id}
        items={selectForm.list}
        prefix={selectForm.prefix}
        suffix={selectForm.suffix}
        value={hooks.value}
        onChange={handleChange}
        error={hooks.hasInput && hooks.error.isError}
        disabled={disabled}
      />
    </MixedInputArea>
  );
};

export default MixedSelectForm;
