import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Radio as MuiRadio, FormControlLabel } from '@material-ui/core';
import { AppState } from 'duck/Reducer';
import { hex2rgba } from 'common/Utility';

export type RadioButtonProps = {
  fontSize?: number;
  value: string;
  label: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

type StyledRadioProps = {
  frameBasic: string;
  accentBasic: string;
  disabledLight: string;
};

const StyledRadio = connect(({ color }: AppState) => ({
  frameBasic: color.frameBasic,
  accentBasic: color.accentBasic,
  disabledLight: color.disabledLight,
}))(styled(({ frameBasic, accentBasic, disabledLight, dispatch, ...props }) => (
  <MuiRadio {...props} />
))<StyledRadioProps>`
  /* base */
  &.MuiRadio-root {
    color: ${({ frameBasic }) => frameBasic};
  }
  &.MuiIconButton-root.Mui-checked {
    color: ${({ accentBasic }) => accentBasic};
  }
  /* hover */
  &.MuiIconButton-root:hover,
  &.MuiIconButton-root.Mui-checked:hover {
    background-color: ${({ accentBasic }) => hex2rgba(accentBasic, 0.1)};
  }
  /* disabled */
  &.MuiIconButton-root.Mui-disabled {
    color: ${({ disabledLight }) => disabledLight};
  }
`);

type StyledFormControlLabelProps = {
  fontSize: number;
};

const StyledFormControlLabel = styled(FormControlLabel)<
  StyledFormControlLabelProps
>`
  &.MuiFormControlLabel-root {
    margin-right: 9px;
  }
  &.MuiFormControlLabel-root > .MuiTypography-body1 {
    font-size: ${({ fontSize }: StyledFormControlLabelProps) => fontSize}px;
  }
`;

/**
 * 【部品】【原子】【基本入力】ラジオボタン
 * @param {boolean} disabled - ラジオボタンの活性・非活性
 * @param {boolean} checked - チェックの有無
 * @param {string} value - 値
 * @param {string} label - ラベル
 * @param {number} fontSize - フォントサイズ
 */
const Radio = ({
  disabled,
  checked,
  value,
  label,
  fontSize = 16,
  onChange,
}: RadioButtonProps) => {
  return (
    <StyledFormControlLabel
      disabled={disabled}
      checked={checked}
      value={value}
      label={label}
      fontSize={fontSize}
      control={<StyledRadio onChange={onChange} />}
    />
  );
};

export default Radio;
