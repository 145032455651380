import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actions } from 'duck/client/groups/Actions';
import GroupsState, {
  Group,
  GroupTemplate,
  Category,
} from 'duck/client/groups/State';
import { AnswerProgress, Category as DefaultCategory } from 'oapi/api';

const initialState: GroupsState = {
  groups: [],
  isLoading: false,
};

const convertCategory = (category: DefaultCategory): Category => {
  const templates = category.templates.map(
    (temp): GroupTemplate => {
      return {
        ...temp,
        progress: AnswerProgress.UNANSWERED,
      };
    },
  );
  const categories = category.categories.map((cat) => convertCategory(cat));
  return {
    ...category,
    templates,
    categories,
  };
};

const findTemplateFromCategory = (
  category: Category,
  id: string,
): GroupTemplate | undefined => {
  let template = category.templates.find((temp) => temp.id === id);
  category.categories.some((cat) => {
    template = findTemplateFromCategory(cat, id);
    return template !== undefined;
  });
  return template;
};

const groupsReducer = reducerWithInitialState<GroupsState>(initialState)
  .case(actions.fetchAll.started, (state) => {
    return {
      ...state,
      isLoading: true,
    };
  })
  .case(actions.fetchAll.done, (state) => {
    return {
      ...state,
      isLoading: false,
    };
  })
  .case(actions.fetchGroups.started, (state) => {
    return state;
  })
  .case(actions.fetchGroups.done, (state, payload) => {
    const groups = payload.result.map(
      (group): Group => {
        const templates = group.templates.map(
          (template): GroupTemplate => {
            return {
              ...template,
              progress: AnswerProgress.UNANSWERED,
            };
          },
        );
        const categories = group.categories.map((category) =>
          convertCategory(category),
        );
        return {
          ...group,
          templates,
          categories,
        };
      },
    );
    return {
      ...state,
      groups,
    };
  })
  .case(actions.fetchGroups.failed, (state, payload) => {
    return {
      ...state,
      error: payload.error,
    };
  })
  .case(actions.fetchAnswers.started, (state) => {
    return state;
  })
  .case(actions.fetchAnswers.done, (state, payload) => {
    payload.result.forEach((answers) => {
      const group = state.groups.find((g) => g.id === answers.group_id);
      if (group) {
        let template = group.templates.find(
          (temp) => temp.id === answers.template_id,
        );
        if (!template) {
          group.categories.some((category) => {
            template = findTemplateFromCategory(category, answers.template_id);
            return template !== undefined;
          });
        }
        if (template) {
          template.progress = answers.progress;
        }
      }
    });
    return {
      ...state,
    };
  })
  .case(actions.fetchAnswers.failed, (state, payload) => {
    return {
      ...state,
      error: payload.error,
    };
  })
  .case(actions.clearError, (state) => {
    return {
      ...state,
      error: undefined,
    };
  });

export default groupsReducer;
