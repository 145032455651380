import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import useReactRouter from 'use-react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AppState } from 'duck/Reducer';
import { Setting } from 'duck/manager/setting/State';
import useInputTemplate from 'hooks/useInputTemplate';
import Button from 'presentational/general/atoms/button/Button';
import TemplatePanel from 'presentational/general/organisms/area/TemplatePanel';
import FormPanel from 'presentational/general/organisms/inputTemplateForm/FormPanel';
import SymgramTermsPanel from 'presentational/custom/organisms/area/SymgramTermsPanel';
import Text from 'presentational/general/atoms/text/Text';
import BeforeLoginTemplate from 'presentational/custom/templates/BeforeLoginTemplate';
import PageTitle from 'presentational/general/atoms/text/PageTitle';
import ParaCheckTermsPanel from 'presentational/custom/organisms/area/ParaCheckTermsPanel';
import inputUserData from 'custom/formData/inputUser.json';
import * as TYPES from 'common/Types';
import CONSTS, { MEMBER_ID_MAP } from 'common/Consts';
import { CheckSampleId } from 'commontsx/Utility';
import * as membersActions from 'duck/custom/members/Actions';
import { RequestsCustomApiClientRegister } from 'oapi/api';
import { useTerms } from 'hooks';
import { Template } from 'oapi';
import Dialog from 'presentational/general/organisms/modal/Dialog';

const FinishButtonArea = styled.div`
  text-align: center;
`;

type PanelAreaProps = {
  device: TYPES.DEVICE_TYPES;
};

const PanelArea = styled.div<PanelAreaProps>`
  padding-left: 8px;
  background-color: #dd8e6c;
  ${({ device }: PanelAreaProps) =>
    device === TYPES.DEVICE_TYPES.PC && 'margin-left: 7px;'}
`;

const generateNewUser = (): RequestsCustomApiClientRegister => {
  return {
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    first_name_kana: '',
    last_name_kana: '',
    zip: '',
    address: '',
    building: '',
    phone_number: '',
    birthdate: '',
    sample_ids: [],
    has_privacy_policy_checked: true,
  };
};

type RegistrationUserProps = {
  deviceType: TYPES.DEVICE_TYPES;
  newUser?: RequestsCustomApiClientRegister;
  setNewUser: (user: RequestsCustomApiClientRegister) => void;
  textLight: string;
  setting: Setting;
};

const RegistrationUser = ({
  deviceType,
  setNewUser,
  newUser,
  textLight,
  setting,
}: RegistrationUserProps) => {
  const [isOpenErrorDialog, setIsOpenErrorDialog] = useState(false);
  const errorDialogButtons = [
    {
      text: 'OK',
      onClick: () => setIsOpenErrorDialog(false),
    },
  ];
  const { history } = useReactRouter();
  const data = { ...inputUserData } as Template;
  const {
    answers,
    isVisiblePanels,
    isVisibleForms,
    isDisabledFinishButton,
    handleSetAnswer,
  } = useInputTemplate(data);
  const useCookie = useCookies(['web_form']);
  const removeCookie = useCookie[2];
  removeCookie('session-key', { path: '/' });

  const personalInfoTermsState = useTerms(
    {
      value: '1',
      label: '個人情報の取り扱いに同意します。',
      checked: false,
    },
    true,
  );

  const termsOfServiceState = useTerms(
    {
      value: '1',
      label: 'Web登録システム利用規約に同意します。',
      checked: false,
    },
    true,
  );

  const handleClickButton = () => {
    let isValid = false;

    if (setting.serviceName === CONSTS.SERVICE_NAME_SYMGRAM) {
      isValid =
        isDisabledFinishButton ||
        personalInfoTermsState.value[0] !== '1' ||
        termsOfServiceState.value[0] !== '1';
    } else if (setting.serviceName === CONSTS.SERVICE_NAME_PARA_CHECK_BIO) {
      isValid =
        isDisabledFinishButton ||
        personalInfoTermsState.value[0] !== '1' ||
        termsOfServiceState.value[0] !== '1';
    }

    if (isValid) {
      setIsOpenErrorDialog(true);
      return;
    }

    const member = generateNewUser();
    answers.current.forEach((answer) => {
      if (answer.values.length === 0) {
        return;
      }
      const val = answer.values[0];
      switch (answer.formId) {
        case 'email':
          member.email = val;
          break;
        case 'password':
          member.password = val;
          break;
        case 'first_name':
          member.first_name = val;
          break;
        case 'last_name':
          member.last_name = val;
          break;
        case 'first_name_kana':
          member.first_name_kana = val;
          break;
        case 'last_name_kana':
          member.last_name_kana = val;
          break;
        case 'zip':
          member.zip = val;
          break;
        case 'address':
          member.address = val;
          break;
        case 'building':
          member.building = val;
          break;
        case 'phone_number':
          member.phone_number = val;
          break;
        case 'birthdate':
          member.birthdate = val;
          break;
        default:
          break;
      }
    });
    setNewUser(member);
    history.push(CONSTS.PATH_REGISTRATION_CHECK_USER);
  };
  if (newUser) {
    data.panels.forEach((panel, i) => {
      panel.forms.forEach((form, j) => {
        switch (form.id) {
          case 'email':
            data.panels[i].forms[j].answers = [
              { value: newUser.email, title: newUser.email },
            ];
            break;
          case 'first_name':
            data.panels[i].forms[j].answers = [
              { value: newUser.first_name, title: newUser.first_name },
            ];
            break;
          case 'last_name':
            data.panels[i].forms[j].answers = [
              { value: newUser.last_name, title: newUser.last_name },
            ];
            break;
          case 'first_name_kana':
            data.panels[i].forms[j].answers = [
              {
                value: newUser.first_name_kana,
                title: newUser.first_name_kana,
              },
            ];
            break;
          case 'last_name_kana':
            data.panels[i].forms[j].answers = [
              { value: newUser.last_name_kana, title: newUser.last_name_kana },
            ];
            break;
          case 'zip':
            data.panels[i].forms[j].answers = [
              { value: newUser.zip, title: newUser.zip },
            ];
            break;
          case 'address':
            data.panels[i].forms[j].answers = [
              { value: newUser.address, title: newUser.address },
            ];
            break;
          case 'building':
            data.panels[i].forms[j].answers = [
              { value: newUser.building, title: newUser.building },
            ];
            break;
          case 'phone_number':
            data.panels[i].forms[j].answers = [
              { value: newUser.phone_number, title: newUser.phone_number },
            ];
            break;
          case 'birthdate':
            data.panels[i].forms[j].answers = [
              { value: newUser.birthdate, title: newUser.birthdate },
            ];
            break;
          default:
            break;
        }
      });
    });
  }

  return (
    <BeforeLoginTemplate
      device={deviceType}
      headerColor={setting.headerColor}
      footerText={setting.footerText}
      footerColor={setting.footerColor}
    >
      <PanelArea device={deviceType}>
        <Text color={textLight} fontWeight="bold">
          利用者の個人情報を登録します
        </Text>
      </PanelArea>
      <PageTitle device={deviceType}>利用者情報登録</PageTitle>
      {data.panels.map((panel: any, panelIndex: number) => {
        return (
          <FormPanel
            key={panel.id}
            device={deviceType}
            panel={panel}
            visible={isVisiblePanels[panelIndex]}
            isVisibleForms={isVisibleForms[panelIndex]}
            handleSetAnswer={handleSetAnswer}
          />
        );
      })}
      {setting.serviceName === CONSTS.SERVICE_NAME_SYMGRAM && (
        <SymgramTermsPanel
          deviceType={deviceType}
          personalInfoTermsState={personalInfoTermsState}
          termsOfServiceState={termsOfServiceState}
        />
      )}
      {setting.serviceName === CONSTS.SERVICE_NAME_PARA_CHECK_BIO && (
        <ParaCheckTermsPanel
          deviceType={deviceType}
          personalInfoTermsState={personalInfoTermsState}
          termsOfServiceState={termsOfServiceState}
        />
      )}
      <TemplatePanel
        device={deviceType}
        templateType={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
      >
        <FinishButtonArea>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            onClick={handleClickButton}
          >
            確認
          </Button>
        </FinishButtonArea>
      </TemplatePanel>
      <CheckSampleId to={CONSTS.PATH_INPUT_CERTIFICATION_CODE} />
      <Dialog
        open={isOpenErrorDialog}
        onClose={() => setIsOpenErrorDialog(false)}
        title="入力情報に誤りがあります"
        buttons={errorDialogButtons}
      >
        <>
          {answers.current.map((ans) =>
            ans.isError ? (
              <div key={ans.formId}>
                {MEMBER_ID_MAP[ans.formId]}
                の入力を確認してください。
              </div>
            ) : null,
          )}
          {personalInfoTermsState.value[0] !== '1' && (
            <div>
              個人情報の取り扱いについてをご確認いただき、同意が必要です。
            </div>
          )}
          {termsOfServiceState.value[0] !== '1' && (
            <div>Web登録システム利用規約をご確認いただき、同意が必要です。</div>
          )}
        </>
      </Dialog>
    </BeforeLoginTemplate>
  );
};

export default connect(
  ({ common, members, color, managerSetting }: AppState) => ({
    deviceType: common.deviceType,
    newUser: members.newUser,
    textLight: color.textLight,
    setting: managerSetting.setting,
  }),
  {
    setNewUser: membersActions.setNewUser,
  },
)(RegistrationUser);
