import actionCreatorFactory from 'typescript-fsa';
import * as TYPES from 'common/Types';

const actionCreator = actionCreatorFactory();

/**
 * カラーコード設定のためのコード
 */
export type ColorCodeProps = {
  colorType: TYPES.COLOR_TYPES;
  swatchType: TYPES.SWATCH_TYPES;
  colorCode: string;
};
export const setColorCode = actionCreator<ColorCodeProps>('SET_COLOR_CODE');

export default { setColorCode };
