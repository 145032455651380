import { Dispatch } from 'redux';
import actionCreatorFactory from 'typescript-fsa';
import {
  Group,
  ResultAnswers,
  RequestsClientAnswersCopy,
  RequestsClientGroupsGroupIdAnswersConfirm,
} from 'oapi';
import APIWrapper, { DefaultApiFp, APIError } from 'common/APIWrapper';
import { actions as commonActions } from 'duck/common/Actions';
import store from 'Store';
import CONSTS from 'common/Consts';

const {
  apiClientAnswersCopyPost,
  apiClientGroupsGet,
  apiClientGroupsGroupIdAnswersGet,
  apiClientGroupsGroupIdAnswersConfirmPost,
} = DefaultApiFp();
const actionCreator = actionCreatorFactory();

export const actions = {
  load: actionCreator.async<{}, void, APIError>('LOAD_GROUP'),
  fetchAll: actionCreator.async<{}, void, APIError>('FETCH_ALL'),
  fetchGroups: actionCreator.async<{}, Array<Group>, APIError>('FETCH_GROUPS'),
  fetchAnswers: actionCreator.async<
    { groupId: string },
    Array<ResultAnswers>,
    APIError
  >('FETCH_ANSWERS'),
  clearError: actionCreator<void>('CLEAR_ERROR_GROUPS'),
};

/**
 * グループ一覧を取得
 */
export const fetchGroups = ({
  excludeCategories = [],
  excludeTemplates = [],
}: {
  excludeCategories?: Array<string>;
  excludeTemplates?: Array<string>;
}) => {
  const params = {};
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchGroups.started(params));
      const res = await APIWrapper(await apiClientGroupsGet());
      const filteredGroups = (res.data.data as Array<Group>).map((group) => {
        let filteredGroup = { ...group };
        if (group.id === CONSTS.GROUP_ID_VERSION_FOUR) {
          filteredGroup = {
            ...filteredGroup,
            categories: group.categories.map((category) => ({
              ...category,
              templates: category.templates.filter(
                (template) => !excludeTemplates.includes(template.name),
              ),
            })),
          };
        }
        filteredGroup = {
          ...filteredGroup,
          categories: filteredGroup.categories.filter(
            (category) => !excludeCategories.includes(category.name),
          ),
        };
        return filteredGroup;
      });
      dispatch(actions.fetchGroups.done({ params, result: filteredGroups }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
      }
      dispatch(actions.fetchGroups.failed({ params, error: e }));
      return false;
    }
  };
};

/**
 * グループに対する回答一覧を取得する
 */
export const fetchAnswers = (groupId: string) => {
  return async (dispatch: Dispatch) => {
    const params = { groupId };
    try {
      dispatch(actions.fetchAnswers.started(params));
      const res = await APIWrapper(
        await apiClientGroupsGroupIdAnswersGet(params.groupId),
      );
      dispatch(actions.fetchAnswers.done({ params, result: res.data.data }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
      } else {
        dispatch(actions.fetchAnswers.failed({ params, error: e }));
      }
      return false;
    }
  };
};

type fetchAllType = {
  excludeCategories?: Array<string>;
  excludeTemplates?: Array<string>;
};

export type FuncFetchAllType = (params: fetchAllType) => Promise<boolean>;
/**
 * グループ一覧情報を取得
 * @param {void} params - 不要
 */
export const fetchAll = ({
  excludeCategories,
  excludeTemplates,
}: fetchAllType) => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    const params = {};
    dispatch(actions.fetchAll.started(params));
    const isSucceed = await store.dispatch(
      fetchGroups({ excludeCategories, excludeTemplates }),
    );
    if (isSucceed) {
      const { groups } = store.getState().groups;
      groups.forEach(async (group) => {
        await store.dispatch(fetchAnswers(group.id));
      });
    }
    dispatch(actions.fetchAll.done({ params }));
    return isSucceed;
  };
};

/**
 * 回答を確定する
 */
export const confirmAnswers = (groupId: string, customId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      const param: RequestsClientGroupsGroupIdAnswersConfirm = {
        custom_id: customId,
      };
      await APIWrapper(
        await apiClientGroupsGroupIdAnswersConfirmPost(groupId, param),
      );
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return false;
    }
  };
};

/**
 *
 */
export const copyAnswers = (fromSampleId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      const param: RequestsClientAnswersCopy = {
        custom_id: fromSampleId,
      };
      await APIWrapper(await apiClientAnswersCopyPost(param));
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return false;
    }
  };
};

export const clearError = () => {
  return (dispatch: Dispatch) => dispatch(actions.clearError());
};

export default {
  fetchGroups,
  fetchAnswers,
  fetchAll,
  confirmAnswers,
  copyAnswers,
  clearError,
};
