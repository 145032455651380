import React from 'react';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import Text from 'presentational/general/atoms/text/Text';

export type NetworkErrorDialogProps = {
  open: boolean;
  onClose: () => void;
  okOnClick: () => void;
};

/**
 * 【部品】【テンプレート】【ダイアログ】ネットワークエラーダイアログ
 * @param {boolean} open - 表示フラグ
 * @param {() => void} onClose - クローズイベント
 * @param {() => void} okOnClick - OKボタンクリックイベント
 */
const NetworkErrorDialog = ({
  open,
  onClose,
  okOnClick,
}: NetworkErrorDialogProps) => {
  const errorDialogButtons = [
    {
      text: 'OK',
      onClick: okOnClick,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="ネットワークエラー"
      buttons={errorDialogButtons}
    >
      <Text>
        ネットワークエラーが発生しました。
        <br />
        通信状況をご確認ください。
      </Text>
    </Dialog>
  );
};

export default NetworkErrorDialog;
