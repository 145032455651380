import React, { useState } from 'react';
import styled from 'styled-components';
import { usePasswordField } from 'hooks';
import { VALIDATE_MESSAGES } from 'common/Messages';
import {
  isDisabledInputList,
  replaceMessage,
  isDisplayError,
  escapeRegExp,
} from 'common/Utility';
import * as TYPES from 'common/Types';
import CONSTS from 'common/Consts';
import { AppState } from 'duck/Reducer';
import { connect } from 'react-redux';
import { CommonState } from 'duck/common/State';
import { Setting } from 'duck/manager/setting/State';
import Form from 'presentational/general/molecules/form/Form';
import InputArea from 'presentational/general/molecules/form/InputArea';
import PasswordField from 'presentational/general/atoms/basicInput/PasswordField';
import Panel from 'presentational/general/organisms/area/Panel';
import Text from 'presentational/general/atoms/text/Text';
import Button from 'presentational/general/atoms/button/Button';
import CommonTemplate from 'presentational/custom/templates/CommonTemplate';
import Dialog from 'presentational/general/organisms/modal/Dialog';
import useReactRouter from 'use-react-router';
import { ButtonColorType } from 'duck/color/State';
import authActions from 'duck/client/auth/Actions';
import BackPageTitle from 'presentational/general/molecules/other/BackPageTitle';
import { APIError } from 'common/APIWrapper';

export type UpdatePasswordProps = {
  common: CommonState;
  buttonBackgroundColorMain: ButtonColorType;
  setting: Setting;
  error?: APIError;
  changePassword: (
    oldPassword: string,
    newPassword: string,
  ) => Promise<boolean>;
};

const ButtonArea = styled.div`
  padding: 20px 0;
  text-align: center;
`;

const validatorRules = {
  newPassword: [
    'required',
    'min:8',
    'max:256',
    'regex:/\\d+/',
    'regex:/[a-z]+/',
    'regex:/[A-Z]+/',
  ],
};

/**
 * 【画面】GC019_パスワード更新
 * @param {CommonState} common 共通Redux
 * @param {ButtonColorType} buttonBackgroundColorMain ボタン背景色：メイン(Redux)
 * @param {(oldPassword: string, newPassword: string) => Promise<boolean>} changePassword パスワードを変更する
 */
const UpdatePassword = ({
  common,
  buttonBackgroundColorMain,
  changePassword,
  setting,
  error,
}: UpdatePasswordProps) => {
  const { history } = useReactRouter();

  const [isError, setIsError] = useState(false);
  // パスワード再設定完了ダイアログ
  const [isUpdatePasswordDialogOpen, setIsUpdatePasswordDialogOpen] = useState(
    false,
  );
  const resetPasswordDialogOnClose = () => {
    setIsUpdatePasswordDialogOpen(false);
  };
  const resetPasswordDialogOkOnClick = () => {
    setIsUpdatePasswordDialogOpen(false);
    history.push(CONSTS.PATH_UPDATE_USER);
  };

  const errorDialogOnClose = () => {
    setIsError(false);
  };
  const confirmDialogButtons = [
    {
      text: 'OK',
      onClick: resetPasswordDialogOkOnClick,
    },
  ];

  const errorDialogButtons = [
    {
      text: 'OK',
      onClick: errorDialogOnClose,
    },
  ];

  // 現在のパスワード
  const nowPassword = usePasswordField(
    '',
    false,
    ['required', 'min:8', 'max:256'],
    {
      max: replaceMessage(VALIDATE_MESSAGES.ERROR_MAX_DIGIT, ['256']),
      min: replaceMessage(VALIDATE_MESSAGES.ERROR_MIN_DIGIT, ['8']),
    },
  );
  // 新パスワード
  const newPassword = usePasswordField('', false, validatorRules.newPassword, {
    regex: VALIDATE_MESSAGES.ERROR_REGEX_PASSWORD,
    max: replaceMessage(VALIDATE_MESSAGES.ERROR_MAX_DIGIT, ['256']),
    min: replaceMessage(VALIDATE_MESSAGES.ERROR_MIN_DIGIT, ['8']),
  });
  // 再入力
  const confirmationNewPassword = usePasswordField(
    '',
    false,
    ['required', `regex:/^${escapeRegExp(newPassword.value)}$/`],
    {
      regex: VALIDATE_MESSAGES.ERROR_REGEX_CONFIRM,
    },
  );

  // 戻る付きタイトルアイコンボタン
  const backIconOnClick = () => {
    history.push(CONSTS.PATH_UPDATE_USER);
  };

  // 設定ボタン
  const buttonOnClick = async () => {
    if (await changePassword(nowPassword.value, newPassword.value)) {
      setIsUpdatePasswordDialogOpen(true);
    } else {
      setIsError(true);
    }
  };

  const disabledInputList = [
    {
      hasInput: newPassword.hasInput,
      isError: newPassword.error.isError,
    },
    {
      hasInput: confirmationNewPassword.hasInput,
      isError: confirmationNewPassword.error.isError,
    },
  ];

  return (
    <CommonTemplate
      device={common.deviceType}
      headerColor={setting.headerColor}
      footerColor={setting.footerColor}
      footerText={setting.footerText}
    >
      <BackPageTitle device={common.deviceType} onClick={backIconOnClick}>
        パスワード更新
      </BackPageTitle>
      <Panel device={common.deviceType}>
        <Text>パスワードを設定してください。</Text>
        <Form
          device={common.deviceType}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question="現在のパスワード"
        >
          <InputArea
            error={isDisplayError(
              nowPassword.hasInput,
              nowPassword.error.isError,
            )}
            errorMessage={nowPassword.error.errorMessage}
          >
            <PasswordField
              id="nowPassword"
              value={nowPassword.value}
              onChange={nowPassword.onChange}
              onBlur={nowPassword.onBlur}
              error={isDisplayError(
                nowPassword.hasInput,
                nowPassword.error.isError,
              )}
              inputProps={{ maxLength: 256 }}
            />
          </InputArea>
        </Form>
        <Form
          device={common.deviceType}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question="新パスワード"
        >
          <InputArea
            error={isDisplayError(
              newPassword.hasInput,
              newPassword.error.isError,
            )}
            errorMessage={newPassword.error.errorMessage}
          >
            <PasswordField
              id="newPassword"
              value={newPassword.value}
              onChange={newPassword.onChange}
              onBlur={() => {
                newPassword.onBlur();
                if (confirmationNewPassword.hasInput) {
                  confirmationNewPassword.onBlur();
                }
              }}
              error={isDisplayError(
                newPassword.hasInput,
                newPassword.error.isError,
              )}
              displayPasswordStrength
              inputProps={{ maxLength: 256 }}
            />
          </InputArea>
        </Form>
        <Form
          device={common.deviceType}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question="再入力"
        >
          <InputArea
            error={isDisplayError(
              confirmationNewPassword.hasInput,
              confirmationNewPassword.error.isError,
            )}
            errorMessage={confirmationNewPassword.error.errorMessage}
          >
            <PasswordField
              id="confirmationNewPassword"
              value={confirmationNewPassword.value}
              onChange={confirmationNewPassword.onChange}
              onBlur={confirmationNewPassword.onBlur}
              error={isDisplayError(
                confirmationNewPassword.hasInput,
                confirmationNewPassword.error.isError,
              )}
            />
          </InputArea>
        </Form>
        <ButtonArea>
          <Button
            buttonType={TYPES.BUTTON_TYPES.ROUND}
            disabled={isDisabledInputList(disabledInputList)}
            onClick={buttonOnClick}
            {...buttonBackgroundColorMain}
          >
            設定
          </Button>
        </ButtonArea>
      </Panel>
      <Dialog
        open={isUpdatePasswordDialogOpen}
        onClose={resetPasswordDialogOnClose}
        title="パスワード再設定完了"
        buttons={confirmDialogButtons}
      >
        <Text>パスワードを再設定しました。</Text>
      </Dialog>
      <Dialog
        open={isError}
        onClose={errorDialogOnClose}
        title="パスワード再設定失敗"
        buttons={errorDialogButtons}
      >
        <Text>{error && error.detail}</Text>
      </Dialog>
    </CommonTemplate>
  );
};

export default connect(
  ({ common, color, auth, managerSetting }: AppState) => ({
    common,
    buttonBackgroundColorMain: color.buttonBackgroundColorMain,
    setting: managerSetting.setting,
    error: auth.error,
  }),
  {
    changePassword: authActions.changePassword,
  },
)(UpdatePassword);
