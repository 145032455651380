import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Radio, {
  RadioButtonProps,
} from 'presentational/general/atoms/basicInput/Radio';
import Description from 'presentational/general/atoms/basicInput/Description';

export type DescriptionRadioProps = PropsWithChildren<RadioButtonProps>;

const StyledDescriptionRadio = styled.div`
  display: inline;
`;

/**
 * 【部品】【原子】【基本入力】説明文付きラジオボタン
 * @param {ReactNode} children - 子要素（説明）
 * @param {RadioButtonProps} props - ラジオボタン用要素
 */
const DescriptionRadio = ({ children, ...props }: DescriptionRadioProps) => {
  return (
    <StyledDescriptionRadio>
      <Radio {...props} />
      <Description>{children}</Description>
    </StyledDescriptionRadio>
  );
};

export default DescriptionRadio;
