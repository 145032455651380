import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core';
import Icon from 'presentational/general/atoms/text/Icon';
import Text from 'presentational/general/atoms/text/Text';
import { AppState } from 'duck/Reducer';

const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    box-shadow: unset;
  }

  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  &.MuiAccordion-root.Mui-expanded:before {
    opacity: unset;
  }
`;

const AccordionSummary = connect(({ color }: AppState) => ({
  mainBasic: color.mainBasic,
}))(styled(({ mainBasic, dispatch, ...props }) => (
  <MuiAccordionSummary {...props} />
))<{ mainBasic: string }>`
  &.MuiAccordionSummary-root {
    background-color: ${({ mainBasic }) => mainBasic};
  }

  &.MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;
  }

  & > .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`);

const AccordionDetails = styled(MuiAccordionDetails)`
  &.MuiAccordionDetails-root {
    display: block;
    padding: 0;
  }
`;

/**
 * カテゴリタグで使うアイコン
 * @return {JSX.Element} - アイコンのタグ
 */
const ExpandMore = connect(({ color }: AppState) => ({
  textLight: color.textLight,
}))(({ textLight }: { textLight: string }) => (
  <Icon iconColor={textLight} iconSize={32}>
    expand_more
  </Icon>
));

export type CategoryProps = PropsWithChildren<{
  title: string;
  defaultExpandedClose?: boolean;
  textLight: string;
  children: React.ReactNode;
}>;

/**
 * 【部品】【分子】【その他】カテゴリ <Category>タグ
 * @param {string} title - 項目名
 * @param {boolean} defaultExpandedClose - デフォルトでアコーディオンを閉じる
 * @param {React.ReactNode} children - Categoryタグの中身
 * @param {string} textLight - テキスト色
 */
const Category = ({
  title,
  children,
  textLight,
  defaultExpandedClose = false,
}: CategoryProps) => {
  return (
    <Accordion defaultExpanded={!defaultExpandedClose}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Text color={textLight} fontSize={20} fontWeight="bold">
          {title}
        </Text>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default connect(({ color }: AppState) => ({
  textLight: color.textLight,
}))(Category);
