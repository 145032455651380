import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';

export type LoadingIconProps = {
  size?: number;
  marginLeft?: number;
  marginRight?: number;
};

type StyledLoadingIconProps = {
  marginLeft?: number;
  marginRight?: number;
};

const StyledLoadingIcon = styled(CircularProgress)<StyledLoadingIconProps>`
  ${({ marginLeft }: StyledLoadingIconProps) =>
    marginLeft && `margin-left: ${marginLeft}px;`}
  ${({ marginRight }: StyledLoadingIconProps) =>
    marginRight && `margin-right: ${marginRight}px;`}
`;

/**
 * 【部品】【原子】【その他】Loadingアイコン <LoadingIcon>タグ
 * @param {number} size - サイズ
 * @param {number} marginLeft - margin-left(px)
 * @param {number} marginRight - margin-right(px)
 */
const LoadingIcon = ({
  size = 40,
  marginRight,
  marginLeft,
}: LoadingIconProps) => {
  return (
    <StyledLoadingIcon
      color="inherit"
      size={size}
      marginLeft={marginLeft}
      marginRight={marginRight}
    />
  );
};

export default LoadingIcon;
