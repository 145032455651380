import { useState } from 'react';
import { ErrorProps, validateSliderValue } from 'hooks/Validator';

type SliderUpdateProps = {
  value: string;
  error: ErrorProps;
  hasInput: boolean;
};

export type SliderHooksProps = {
  value: number;
  error: ErrorProps;
  hasInput: boolean;
  setHasInputTrue: () => void;
  onChange: (value: number | number[]) => SliderUpdateProps;
  onChangeCommitted: (value: number | number[]) => SliderUpdateProps;
  clear: () => SliderUpdateProps;
};

/**
 * スライダー<Slider>を使う場合の部品生成（単一）
 * @param {number} initialValue - 初期値
 * @param {number} defaultValue - デフォルト値
 * @param {boolean} hasInitialInput - 入力済フラグ初期値
 * @param {string[]} validatorRules - バリデーション内容
 * @param {Object} validatorMessage - バリデーションメッセージ上書き用
 * @returns {SliderHooksProps} - 生成された部品
 */
const useSlider = (
  initialValue: number,
  defaultValue: number,
  hasInitialInput: boolean,
  validatorRules?: string[],
  validatorMessage?: { [key: string]: string },
): SliderHooksProps => {
  const [value, setValue] = useState<number>(initialValue);
  const [error, setError] = useState<ErrorProps>(
    validateSliderValue({
      value: initialValue,
      validatorRules,
      validatorMessage,
    }),
  );
  const [hasInput, setHasInput] = useState<boolean>(hasInitialInput);

  const setHasInputTrue = (): void => {
    setHasInput(true);
  };

  const onChange = (changeValue: number | number[]): SliderUpdateProps => {
    if (typeof changeValue === 'number') {
      const validateResult: ErrorProps = validateSliderValue({
        value: changeValue,
        validatorRules,
        validatorMessage,
      });
      const update: SliderUpdateProps = {
        value: String(changeValue),
        error: validateResult,
        hasInput: true,
      };
      setValue(changeValue);
      setError(validateResult);
      return update;
    }
    const now: SliderUpdateProps = {
      value: String(value),
      error,
      hasInput,
    };
    return now;
  };

  const onChangeCommitted = (
    changeValue: number | number[],
  ): SliderUpdateProps => {
    if (typeof changeValue === 'number') {
      const validateResult: ErrorProps = validateSliderValue({
        value: changeValue,
        validatorRules,
        validatorMessage,
      });
      const update: SliderUpdateProps = {
        value: String(changeValue),
        error: validateResult,
        hasInput: true,
      };
      setValue(changeValue);
      setError(validateResult);
      setHasInput(update.hasInput);
      return update;
    }
    const now: SliderUpdateProps = {
      value: String(value),
      error,
      hasInput,
    };
    return now;
  };

  const clear = (): SliderUpdateProps => {
    const validateResult: ErrorProps = validateSliderValue({
      value: defaultValue,
      validatorRules,
      validatorMessage,
    });
    const update: SliderUpdateProps = {
      value: String(defaultValue),
      error: validateResult,
      hasInput: false,
    };
    setValue(defaultValue);
    setError(validateResult);
    setHasInput(false);
    return update;
  };

  return {
    value,
    error,
    hasInput,
    setHasInputTrue,
    onChange,
    onChangeCommitted,
    clear,
  };
};

export default useSlider;
