import React, { useLayoutEffect, Fragment } from 'react';
import * as TYPES from 'common/Types';
import { generateTsxToReplacedBr } from 'commontsx/Utility';
import useRadioGroup from 'hooks/useRadioGroup';
import { HandleSetAnswerProps } from 'presentational/general/templates/template/InputTemplate';
import Form from 'presentational/general/molecules/form/Form';
import InputArea from 'presentational/general/molecules/form/InputArea';
import RadioGroup from 'presentational/general/atoms/basicInput/RadioGroup';
import Radio from 'presentational/general/atoms/basicInput/Radio';
import DescriptionRadio from 'presentational/general/atoms/basicInput/DescriptionRadio';
import FormDescription from 'presentational/general/molecules/other/FormDescription';
import { Form as APIForm } from 'duck/Types';
import { RadioForm, Validations } from 'oapi/api';

export type RadioGroupFormProps = {
  device: TYPES.DEVICE_TYPES;
  form: APIForm;
  disabled: boolean;
  visible: boolean;
  handleSetAnswer: (answer: HandleSetAnswerProps) => void;
};

/**
 * 【部品】【有機体】【テンプレート入力フォーム】ラジオグループ
 * @param {TYPES.DEVICE_TYPES} device - 表示デバイスの種類
 * @param {APIForm} form - フォーム情報
 * @param {boolean} disabled - 非活性フラグ
 * @param {boolean} visible - 表示フラグ
 * @param {(answer: HandleSetAnswerProps) => void} handleSetAnswer - InputTemplateに入力状態を渡す関数
 */
const RadioGroupForm = ({
  device,
  form,
  disabled,
  visible,
  handleSetAnswer,
}: RadioGroupFormProps) => {
  const radioForm = form.radio_form as RadioForm;
  const initialValue: string =
    form.answers && form.answers.length > 0 ? form.answers[0].value : '';
  const hasInitialInput: boolean =
    form.answers && form.answers.length > 0 ? true : initialValue !== '';
  const hooks = useRadioGroup(
    initialValue,
    hasInitialInput,
    (radioForm.validations as Validations).values,
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const { value, error, hasInput } = hooks.onChange(e);
    handleSetAnswer({
      formId: form.id,
      values: [value],
      hasInput,
      isError: error.isError,
      isInitial: false,
      setHasInputTrue: hooks.setHasInputTrue,
    });
  };

  useLayoutEffect((): void => {
    if (disabled && hooks.hasInput) {
      const { value, error, hasInput } = hooks.clear();
      handleSetAnswer({
        formId: form.id,
        values: [value],
        hasInput,
        isError: error.isError,
        isInitial: true,
        setHasInputTrue: hooks.setHasInputTrue,
      });
    }
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useLayoutEffect((): void => {
    handleSetAnswer({
      formId: form.id,
      values: [hooks.value],
      hasInput: hooks.hasInput,
      isError: hooks.error.isError,
      isInitial: true,
      setHasInputTrue: hooks.setHasInputTrue,
    });
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {visible && (
        <Form
          device={device}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question={generateTsxToReplacedBr(form.subscribe)}
          complement={<FormDescription form={form} />}
          required={disabled ? false : form.is_required}
          visible={visible}
        >
          <InputArea
            error={hooks.hasInput && hooks.error.isError}
            errorMessage={hooks.hasInput ? hooks.error.errorMessage : ''}
          >
            <RadioGroup
              id={form.id}
              value={hooks.value}
              onChange={handleChange}
              inputRef={form.inputRef}
            >
              {radioForm.list.map((list: any) => {
                return (
                  <Fragment key={list.value}>
                    {list.information ? (
                      <DescriptionRadio
                        value={list.value}
                        label={list.label}
                        disabled={disabled || form.disabled}
                      >
                        {list.information}
                      </DescriptionRadio>
                    ) : (
                      <Radio
                        value={list.value}
                        label={list.label}
                        disabled={
                          (disabled || form.disabled) &&
                          hooks.value !== list.value
                        }
                      />
                    )}
                  </Fragment>
                );
              })}
            </RadioGroup>
          </InputArea>
        </Form>
      )}
    </>
  );
};

export default RadioGroupForm;
