import React, { PropsWithChildren, useState } from 'react';
import LoginHeader, {
  height as loginHeaderHeight,
} from 'presentational/custom/organisms/area/LoginHeader';
import ContentsArea from 'presentational/general/organisms/area/ContentsArea';
import Footer, {
  height as footerHeight,
} from 'presentational/custom/organisms/area/Footer';
import * as TYPES from 'common/Types';

export type BeforeLoginTemplateProps = PropsWithChildren<{
  device: TYPES.DEVICE_TYPES;
  headerColor?: string;
  footerText?: string;
  footerColor?: string;
}>;

/**
 * 【画面】【テンプレート】【SSI個別】ログイン前テンプレート
 * @param {DEVICE_TYPES} device - 表示デバイスの種類
 * @param {React.ReactNode} children - 画面内容
 */
const BeforeLoginTemplate = ({
  device,
  children,
  headerColor,
  footerText,
  footerColor,
}: BeforeLoginTemplateProps) => {
  const margin =
    device === TYPES.DEVICE_TYPES.MOBILE
      ? loginHeaderHeight.mobile + footerHeight
      : loginHeaderHeight.pc + footerHeight;
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  setInterval(() => {
    if (innerHeight !== window.innerHeight) {
      setInnerHeight(window.innerHeight);
    }
  });
  return (
    <>
      <LoginHeader
        device={device}
        backgroundColor={headerColor}
        src="/api/contents/image/logo.svg"
      />
      <ContentsArea
        device={device}
        heightMargin={margin}
        innerHeight={innerHeight}
      >
        {children}
      </ContentsArea>
      <Footer device={device} text={footerText} backgroundColor={footerColor} />
    </>
  );
};

export default BeforeLoginTemplate;
