// tslint:disable
/**
 * ukiyoe-api
 * UKiYoE用のAPIリファレンス
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@tribeat.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 回答
 * @export
 * @interface Answer
 */
export interface Answer {
    /**
     * 表示値
     * @type {string}
     * @memberof Answer
     */
    title: string;
    /**
     * 実際の値
     * @type {string}
     * @memberof Answer
     */
    value: string;
}
/**
 * 進捗状況
 * @export
 * @enum {string}
 */
export enum AnswerProgress {
    UNANSWERED = 'UNANSWERED',
    TMPSAVED = 'TMP_SAVED',
    ANSWERED = 'ANSWERED',
    DISABLED = 'DISABLED',
    CONFIRMED = 'CONFIRMED',
    PREVANSWERD = 'PREV_ANSWERD'
}

/**
 * パネルの回答情報
 * @export
 * @interface AnswersPanel
 */
export interface AnswersPanel {
    /**
     * パネルID
     * @type {string}
     * @memberof AnswersPanel
     */
    id: string;
    /**
     * パネル名
     * @type {string}
     * @memberof AnswersPanel
     */
    name: string;
    /**
     * パネルの説明
     * @type {string}
     * @memberof AnswersPanel
     */
    description: string;
    /**
     * パネルのタイトル
     * @type {string}
     * @memberof AnswersPanel
     */
    title?: string;
    /**
     * パネル内のフォーム一覧
     * @type {Array<Form>}
     * @memberof AnswersPanel
     */
    forms: Array<Form>;
}
/**
 * テンプレート内回答一覧
 * @export
 * @interface AnswersTemplate
 */
export interface AnswersTemplate {
    /**
     * 回答ID
     * @type {string}
     * @memberof AnswersTemplate
     */
    id: string;
    /**
     * ユーザー定義のID
     * @type {string}
     * @memberof AnswersTemplate
     */
    custom_id: string;
    /**
     * テンプレート名
     * @type {string}
     * @memberof AnswersTemplate
     */
    name: string;
    /**
     * 
     * @type {AnswerProgress}
     * @memberof AnswersTemplate
     */
    progress: AnswerProgress;
    /**
     * パネル一覧
     * @type {Array<AnswersPanel>}
     * @memberof AnswersTemplate
     */
    panels: Array<AnswersPanel>;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * カテゴリ名
     * @type {string}
     * @memberof Category
     */
    name: string;
    /**
     * カテゴリの説明
     * @type {string}
     * @memberof Category
     */
    description: string;
    /**
     * テンプレートのリストまたはカテゴリーのリスト
     * @type {Array<Category>}
     * @memberof Category
     */
    categories: Array<Category>;
    /**
     * テンプレート一覧
     * @type {Array<GroupTemplate>}
     * @memberof Category
     */
    templates: Array<GroupTemplate>;
}
/**
 * checkboxのフォーム
 * @export
 * @interface CheckboxForm
 */
export interface CheckboxForm {
    /**
     * 回答のリスト
     * @type {Array<FormValue>}
     * @memberof CheckboxForm
     */
    list: Array<FormValue>;
    /**
     * デフォルト値
     * @type {string}
     * @memberof CheckboxForm
     */
    default_value?: string;
    /**
     * 
     * @type {number}
     * @memberof CheckboxForm
     */
    min_count?: number;
    /**
     * チェックボックスにチェック可能な数
     * @type {number}
     * @memberof CheckboxForm
     */
    enable_count?: number;
    /**
     * 
     * @type {Validations}
     * @memberof CheckboxForm
     */
    validations: Validations;
}
/**
 * フォームの値
 * @export
 * @interface ConditionValue1
 */
export interface ConditionValue1 {
    /**
     * グループID
     * @type {string}
     * @memberof ConditionValue1
     */
    group_id?: string;
    /**
     * テンプレートID
     * @type {string}
     * @memberof ConditionValue1
     */
    template_id?: string;
    /**
     * パネルID
     * @type {string}
     * @memberof ConditionValue1
     */
    panel_id?: string;
    /**
     * フォームID
     * @type {string}
     * @memberof ConditionValue1
     */
    form_id?: string;
}
/**
 * 被験者情報
 * @export
 * @interface CustomMember
 */
export interface CustomMember {
    /**
     * ユーザーID
     * @type {string}
     * @memberof CustomMember
     */
    id: string;
    /**
     * 本人または代諾者
     * @type {string}
     * @memberof CustomMember
     */
    identification: string;
    /**
     * 利用者本人との関係
     * @type {string}
     * @memberof CustomMember
     */
    relation: string;
    /**
     * 利用者本人との関係の名称
     * @type {string}
     * @memberof CustomMember
     */
    relation_detail: string;
    /**
     * 名
     * @type {string}
     * @memberof CustomMember
     */
    first_name: string;
    /**
     * 姓
     * @type {string}
     * @memberof CustomMember
     */
    last_name: string;
    /**
     * 回答に使用したサンプルID情報
     * @type {Array<string>}
     * @memberof CustomMember
     */
    sample_ids: Array<string>;
    /**
     * 現在使用中のサンプルIDを取得
     * @type {string}
     * @memberof CustomMember
     */
    sample_id: string;
    /**
     * サンプルIDのバージョン
     * @type {string}
     * @memberof CustomMember
     */
    sample_id_version: string;
}
/**
 * メンバーの回答情報一覧
 * @export
 * @interface CustomMemberAnswers
 */
export interface CustomMemberAnswers {
    /**
     * メンバーID
     * @type {string}
     * @memberof CustomMemberAnswers
     */
    member_id: string;
    /**
     * サンプルID
     * @type {string}
     * @memberof CustomMemberAnswers
     */
    sample_id: string;
    /**
     * ユーザーID
     * @type {string}
     * @memberof CustomMemberAnswers
     */
    user_id: string;
    /**
     * 回答の進捗状況
     * @type {Array<MemberAnswersProgress>}
     * @memberof CustomMemberAnswers
     */
    answers: Array<MemberAnswersProgress>;
}
/**
 * 被験者情報詳細
 * @export
 * @interface CustomMemberDetail
 */
export interface CustomMemberDetail {
    /**
     * ユーザーID
     * @type {string}
     * @memberof CustomMemberDetail
     */
    id: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof CustomMemberDetail
     */
    email?: string;
    /**
     * メールアドレスが認証済みかどうか
     * @type {boolean}
     * @memberof CustomMemberDetail
     */
    email_verified?: boolean;
    /**
     * 本人または代諾者
     * @type {string}
     * @memberof CustomMemberDetail
     */
    identification: string;
    /**
     * 続柄
     * @type {string}
     * @memberof CustomMemberDetail
     */
    relation: string;
    /**
     * 続柄名称
     * @type {string}
     * @memberof CustomMemberDetail
     */
    relation_detail: string;
    /**
     * 名
     * @type {string}
     * @memberof CustomMemberDetail
     */
    first_name: string;
    /**
     * 姓
     * @type {string}
     * @memberof CustomMemberDetail
     */
    last_name: string;
    /**
     * 名（カナ）
     * @type {string}
     * @memberof CustomMemberDetail
     */
    first_name_kana: string;
    /**
     * 姓（カナ）
     * @type {string}
     * @memberof CustomMemberDetail
     */
    last_name_kana: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof CustomMemberDetail
     */
    zip: string;
    /**
     * 住所
     * @type {string}
     * @memberof CustomMemberDetail
     */
    address: string;
    /**
     * ビル
     * @type {string}
     * @memberof CustomMemberDetail
     */
    building: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof CustomMemberDetail
     */
    phone_number: string;
    /**
     * 誕生日
     * @type {string}
     * @memberof CustomMemberDetail
     */
    birthdate: string;
    /**
     * 回答に使用したサンプルID情報
     * @type {Array<string>}
     * @memberof CustomMemberDetail
     */
    sample_ids: Array<string>;
    /**
     * 現在使用しているサンプルid
     * @type {string}
     * @memberof CustomMemberDetail
     */
    sample_id: string;
    /**
     * 研究同意チェックの状態
     * @type {boolean}
     * @memberof CustomMemberDetail
     */
    has_informed_consent_checked: boolean;
    /**
     * 利用者ID
     * @type {string}
     * @memberof CustomMemberDetail
     */
    parent_id?: string;
    /**
     * サンプルIDのバージョン
     * @type {string}
     * @memberof CustomMemberDetail
     */
    sample_id_version: string;
}
/**
 * メンバー,サンプルIDおよび回答状況情報一覧
 * @export
 * @interface CustomMembersAnswers
 */
export interface CustomMembersAnswers {
    /**
     * メンバー,サンプルIDおよび回答状況情報一覧
     * @type {Array<CustomMemberAnswers>}
     * @memberof CustomMembersAnswers
     */
    members: Array<CustomMemberAnswers>;
}
/**
 * 日付フィールドのフォームに対する設定
 * @export
 * @interface DateForm
 */
export interface DateForm {
    /**
     * プレースホルダー
     * @type {string}
     * @memberof DateForm
     */
    placeholder?: string;
    /**
     * 
     * @type {Validations}
     * @memberof DateForm
     */
    validations: Validations;
    /**
     * デフォルト値
     * @type {string}
     * @memberof DateForm
     */
    default_value?: string;
    /**
     * プレフィックス
     * @type {string}
     * @memberof DateForm
     */
    prefix?: string;
    /**
     * サフィックス
     * @type {string}
     * @memberof DateForm
     */
    suffix?: string;
}
/**
 * エラー一覧
 * @export
 * @interface ErrorList
 */
export interface ErrorList {
    /**
     * エラー一覧
     * @type {Array<ModelError>}
     * @memberof ErrorList
     */
    errors: Array<ModelError>;
}
/**
 * フォームの情報
 * @export
 * @interface Form
 */
export interface Form {
    /**
     * フォームID
     * @type {string}
     * @memberof Form
     */
    id: string;
    /**
     * フォーム名
     * @type {string}
     * @memberof Form
     */
    name?: string;
    /**
     * フォームの説明
     * @type {string}
     * @memberof Form
     */
    subscribe: string;
    /**
     * 質問文/設問
     * @type {string}
     * @memberof Form
     */
    description: string;
    /**
     * インフォメーション
     * @type {string}
     * @memberof Form
     */
    information?: string;
    /**
     * 画像一覧
     * @type {Array<Image>}
     * @memberof Form
     */
    images?: Array<Image>;
    /**
     * フォームのパターン
     * @type {string}
     * @memberof Form
     */
    type: FormTypeEnum;
    /**
     * 必須かどうか
     * @type {boolean}
     * @memberof Form
     */
    is_required: boolean;
    /**
     * 
     * @type {TextForm}
     * @memberof Form
     */
    text_form?: TextForm;
    /**
     * 
     * @type {SelectForm}
     * @memberof Form
     */
    select_form?: SelectForm;
    /**
     * 
     * @type {RadioForm}
     * @memberof Form
     */
    radio_form?: RadioForm;
    /**
     * 
     * @type {CheckboxForm}
     * @memberof Form
     */
    checkbox_form?: CheckboxForm;
    /**
     * 
     * @type {FormPatternForm}
     * @memberof Form
     */
    form_pattern_form?: FormPatternForm;
    /**
     * 
     * @type {MixedForm}
     * @memberof Form
     */
    mixed_form?: MixedForm;
    /**
     * 
     * @type {DateForm}
     * @memberof Form
     */
    date_form?: DateForm;
    /**
     * 
     * @type {HiddenForm}
     * @memberof Form
     */
    hidden_form?: HiddenForm;
    /**
     * 
     * @type {SliderForm}
     * @memberof Form
     */
    slider_form?: SliderForm;
    /**
     * 
     * @type {SliderListForm}
     * @memberof Form
     */
    slider_list_form?: SliderListForm;
    /**
     * 
     * @type {FormOptions}
     * @memberof Form
     */
    options?: FormOptions;
    /**
     * 作成日
     * @type {string}
     * @memberof Form
     */
    created_at: string;
    /**
     * 更新日
     * @type {string}
     * @memberof Form
     */
    updated_at: string;
    /**
     * 回答を保管する箇所(クライアント再度でのみ使用)
     * @type {Array<Answer>}
     * @memberof Form
     */
    answers?: Array<Answer>;
}

/**
    * @export
    * @enum {string}
    */
export enum FormTypeEnum {
    Text = 'text',
    TextArea = 'text_area',
    Select = 'select',
    Radio = 'radio',
    Checkbox = 'checkbox',
    Hidden = 'hidden',
    FormPattern = 'form_pattern',
    MixedForm = 'mixed_form',
    Slider = 'slider',
    SliderList = 'slider_list',
    Calendar = 'calendar',
    Phone = 'phone',
    Date = 'date',
    Zip = 'zip',
    None = 'none'
}

/**
 * 
 * @export
 * @interface FormAnswer
 */
export interface FormAnswer {
    /**
     * フォームID
     * @type {string}
     * @memberof FormAnswer
     */
    id: string;
    /**
     * フォーム名
     * @type {string}
     * @memberof FormAnswer
     */
    name?: string;
    /**
     * 回答リスト
     * @type {Array<Answer>}
     * @memberof FormAnswer
     */
    answers: Array<Answer>;
}
/**
 * フォームの情報
 * @export
 * @interface FormOnlySlider
 */
export interface FormOnlySlider {
    /**
     * フォームID
     * @type {string}
     * @memberof FormOnlySlider
     */
    id: string;
    /**
     * フォーム名
     * @type {string}
     * @memberof FormOnlySlider
     */
    name?: string;
    /**
     * フォームの説明
     * @type {string}
     * @memberof FormOnlySlider
     */
    subscribe: string;
    /**
     * 質問文/設問
     * @type {string}
     * @memberof FormOnlySlider
     */
    description: string;
    /**
     * インフォメーション
     * @type {string}
     * @memberof FormOnlySlider
     */
    information?: string;
    /**
     * 画像一覧
     * @type {Array<Image>}
     * @memberof FormOnlySlider
     */
    images?: Array<Image>;
    /**
     * フォームのパターン
     * @type {string}
     * @memberof FormOnlySlider
     */
    type: FormOnlySliderTypeEnum;
    /**
     * 必須かどうか
     * @type {boolean}
     * @memberof FormOnlySlider
     */
    is_required: boolean;
    /**
     * 
     * @type {SliderForm}
     * @memberof FormOnlySlider
     */
    slider_form: SliderForm;
    /**
     * 
     * @type {FormOnlySliderOptions}
     * @memberof FormOnlySlider
     */
    options?: FormOnlySliderOptions;
    /**
     * 回答を保管する箇所(クライアント再度でのみ使用)
     * @type {Array<Answer>}
     * @memberof FormOnlySlider
     */
    answers?: Array<Answer>;
    /**
     * 作成日
     * @type {string}
     * @memberof FormOnlySlider
     */
    created_at: string;
    /**
     * 更新日
     * @type {string}
     * @memberof FormOnlySlider
     */
    updated_at: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FormOnlySliderTypeEnum {
    Slider = 'slider',
    RemainSlider = 'remain_slider'
}

/**
 * フォームに対するオプション設定
 * @export
 * @interface FormOnlySliderOptions
 */
export interface FormOnlySliderOptions {
    /**
     * 表示条件一覧
     * @type {Array<VisibleCondition>}
     * @memberof FormOnlySliderOptions
     */
    visibe_conditions?: Array<VisibleCondition>;
    /**
     * CSV入力カラム名
     * @type {string}
     * @memberof FormOnlySliderOptions
     */
    column_name?: string;
}
/**
 * フォームに対するオプション設定
 * @export
 * @interface FormOptions
 */
export interface FormOptions {
    /**
     * 表示条件一覧
     * @type {Array<VisibleCondition>}
     * @memberof FormOptions
     */
    visible_conditions?: Array<VisibleCondition>;
    /**
     * CSV入力カラム名
     * @type {string}
     * @memberof FormOptions
     */
    column_name?: string;
    /**
     * 追加オプション
     * @type {object}
     * @memberof FormOptions
     */
    additional?: object;
}
/**
 * フォームパターン
 * @export
 * @interface FormPatternForm
 */
export interface FormPatternForm {
    /**
     * フォーム一式
     * @type {Array<Form>}
     * @memberof FormPatternForm
     */
    forms: Array<Form>;
    /**
     * 最大個数(0以下または99以上の場合は99)
     * @type {number}
     * @memberof FormPatternForm
     */
    max_count: number;
    /**
     * 最小個数（デフォルト0)
     * @type {number}
     * @memberof FormPatternForm
     */
    min_count?: number;
}
/**
 * チェックボックス・ラジオボタン・セレクトボックス
 * @export
 * @interface FormValue
 */
export interface FormValue {
    /**
     * 表示値
     * @type {string}
     * @memberof FormValue
     */
    label: string;
    /**
     * 実際の値
     * @type {string}
     * @memberof FormValue
     */
    value: string;
    /**
     * 選択肢に対する説明
     * @type {string}
     * @memberof FormValue
     */
    information?: string;
    /**
     * headerに使用するselectの場合のみ使用
     * @type {string}
     * @memberof FormValue
     */
    type?: string;
    /**
     * 表示非表示用のtagリスト
     * @type {Array<string>}
     * @memberof FormValue
     */
    tags?: Array<string>;
}
/**
 * グループ
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * グループID
     * @type {string}
     * @memberof Group
     */
    id: string;
    /**
     * グループ名
     * @type {string}
     * @memberof Group
     */
    name: string;
    /**
     * グループの説明
     * @type {string}
     * @memberof Group
     */
    description: string;
    /**
     * カテゴリ一覧
     * @type {Array<Category>}
     * @memberof Group
     */
    categories: Array<Category>;
    /**
     * テンプレート一覧
     * @type {Array<GroupTemplate>}
     * @memberof Group
     */
    templates: Array<GroupTemplate>;
    /**
     * 
     * @type {GroupOptions}
     * @memberof Group
     */
    options?: GroupOptions;
    /**
     * 作成日
     * @type {string}
     * @memberof Group
     */
    created_at: string;
    /**
     * 更新日
     * @type {string}
     * @memberof Group
     */
    updated_at: string;
}
/**
 * グループに対する高度な設定情報
 * @export
 * @interface GroupOptions
 */
export interface GroupOptions {
    /**
     * 表示・非表示
     * @type {boolean}
     * @memberof GroupOptions
     */
    is_visible?: boolean;
    /**
     * 
     * @type {GroupOptionsColors}
     * @memberof GroupOptions
     */
    colors?: GroupOptionsColors;
}
/**
 * 
 * @export
 * @interface GroupOptionsColors
 */
export interface GroupOptionsColors {
    /**
     * メインとなるカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    main?: string;
    /**
     * メインのダークカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    main_dark?: string;
    /**
     * メインのライトカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    main_light?: string;
    /**
     * アクセントのカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    accent?: string;
    /**
     * アクセントのダークカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    accent_dark?: string;
    /**
     * アクセントのライトカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    accent_light?: string;
    /**
     * エラーのカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    error?: string;
    /**
     * エラーのダークカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    error_dark?: string;
    /**
     * エラーのライトカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    error_light?: string;
    /**
     * 警告のカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    warning?: string;
    /**
     * 警告のダークカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    warning_dark?: string;
    /**
     * 警告のライトカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    warning_light?: string;
    /**
     * 成功のカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    success?: string;
    /**
     * 成功のダークカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    success_dark?: string;
    /**
     * 成功のライトカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    success_light?: string;
    /**
     * 非活性のカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    disabled?: string;
    /**
     * 非活性のダークカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    disabled_dark?: string;
    /**
     * 非活性のライトカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    disabled_light?: string;
    /**
     * パネル表面のカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    surface?: string;
    /**
     * 背景のカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    background?: string;
    /**
     * テキストのライトカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    text_light?: string;
    /**
     * テキストのダークカラーコード
     * @type {string}
     * @memberof GroupOptionsColors
     */
    text_dark?: string;
}
/**
 * 
 * @export
 * @interface GroupTemplate
 */
export interface GroupTemplate {
    /**
     * テンプレートのID
     * @type {string}
     * @memberof GroupTemplate
     */
    id: string;
    /**
     * テンプレート名
     * @type {string}
     * @memberof GroupTemplate
     */
    name: string;
    /**
     * テンプレートの説明
     * @type {string}
     * @memberof GroupTemplate
     */
    description: string;
    /**
     * 必須かどうか
     * @type {boolean}
     * @memberof GroupTemplate
     */
    is_required: boolean;
    /**
     * 
     * @type {TemplateOptions}
     * @memberof GroupTemplate
     */
    options?: TemplateOptions;
}
/**
 * 隠しフォーム
 * @export
 * @interface HiddenForm
 */
export interface HiddenForm {
    /**
     * デフォルト値
     * @type {string}
     * @memberof HiddenForm
     */
    default_value?: string;
}
/**
 * 
 * @export
 * @interface Id
 */
export interface Id {
    /**
     * UUID
     * @type {string}
     * @memberof Id
     */
    id: string;
}
/**
 * イメージファイル
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * イメージファイルパス
     * @type {string}
     * @memberof Image
     */
    path: string;
    /**
     * イメージファイル名
     * @type {string}
     * @memberof Image
     */
    name: string;
}
/**
 * イメージファイル
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {any}
     * @memberof InlineObject
     */
    image?: any;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 名前
     * @type {string}
     * @memberof InlineObject1
     */
    name?: string;
    /**
     * ファイル(Base64)
     * @type {string}
     * @memberof InlineObject1
     */
    content?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * ファイルID(UUID)
     * @type {string}
     * @memberof InlineResponse200
     */
    id?: string;
    /**
     * ファイル名
     * @type {string}
     * @memberof InlineResponse200
     */
    name?: string;
}
/**
 * リンク情報
 * @export
 * @interface Links
 */
export interface Links {
    /**
     * 自身のリンク
     * @type {string}
     * @memberof Links
     */
    self: string;
}
/**
 * ログインパラメーター
 * @export
 * @interface LoginParameters
 */
export interface LoginParameters {
    /**
     * チャレンジネーム
     * @type {string}
     * @memberof LoginParameters
     */
    challenge_name?: LoginParametersChallengeNameEnum;
    /**
     * SMS送信先電話番号(隠された番号)
     * @type {string}
     * @memberof LoginParameters
     */
    phone_number?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LoginParametersChallengeNameEnum {
    SMSMFA = 'SMS_MFA'
}

/**
 * メンバーの回答状況
 * @export
 * @interface MemberAnswersProgress
 */
export interface MemberAnswersProgress {
    /**
     * テンプレートID
     * @type {string}
     * @memberof MemberAnswersProgress
     */
    template_id: string;
    /**
     * 
     * @type {AnswerProgress}
     * @memberof MemberAnswersProgress
     */
    progress: AnswerProgress;
    /**
     * 最終回答日
     * @type {string}
     * @memberof MemberAnswersProgress
     */
    answered_date: string;
}
/**
 * 複合フォーム
 * @export
 * @interface MixedForm
 */
export interface MixedForm {
    /**
     * フォーム一式
     * @type {Array<Form>}
     * @memberof MixedForm
     */
    forms: Array<Form>;
}
/**
 * エラー内容
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * エラーコード
     * @type {number}
     * @memberof ModelError
     */
    status: number;
    /**
     * エラー内容
     * @type {string}
     * @memberof ModelError
     */
    title: string;
    /**
     * エラー詳細
     * @type {string}
     * @memberof ModelError
     */
    detail: string;
}
/**
 * 次に行くための動作
 * @export
 * @interface NextAction
 */
export interface NextAction {
    /**
     * 動作の対象
     * @type {string}
     * @memberof NextAction
     */
    type: NextActionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum NextActionTypeEnum {
    Button = 'button',
    Change = 'change',
    Blur = 'blur',
    None = 'none'
}

/**
 * 
 * @export
 * @interface Panel
 */
export interface Panel {
    /**
     * パネルID
     * @type {string}
     * @memberof Panel
     */
    id: string;
    /**
     * パネル名
     * @type {string}
     * @memberof Panel
     */
    name: string;
    /**
     * パネル説明
     * @type {string}
     * @memberof Panel
     */
    description: string;
    /**
     * パネルのタイトル
     * @type {string}
     * @memberof Panel
     */
    title?: string;
    /**
     * 
     * @type {PanelOptions}
     * @memberof Panel
     */
    options?: PanelOptions;
    /**
     * パネル内のフォーム一覧
     * @type {Array<Form>}
     * @memberof Panel
     */
    forms: Array<Form>;
    /**
     * 作成日
     * @type {string}
     * @memberof Panel
     */
    created_at: string;
    /**
     * 更新日
     * @type {string}
     * @memberof Panel
     */
    updated_at: string;
}
/**
 * アクションの一覧
 * @export
 * @interface PanelActions
 */
export interface PanelActions {
    /**
     * 
     * @type {NextAction}
     * @memberof PanelActions
     */
    next?: NextAction;
}
/**
 * テンプレートに対するオプション設定
 * @export
 * @interface PanelOptions
 */
export interface PanelOptions {
    /**
     * 表示条件一覧
     * @type {Array<VisibleCondition>}
     * @memberof PanelOptions
     */
    visible_conditions?: Array<VisibleCondition>;
    /**
     * 
     * @type {PanelActions}
     * @memberof PanelOptions
     */
    actions?: PanelActions;
}
/**
 * radioのフォーム
 * @export
 * @interface RadioForm
 */
export interface RadioForm {
    /**
     * 回答のリスト
     * @type {Array<FormValue>}
     * @memberof RadioForm
     */
    list: Array<FormValue>;
    /**
     * デフォルト値
     * @type {string}
     * @memberof RadioForm
     */
    default_value?: string;
    /**
     * 
     * @type {Validations}
     * @memberof RadioForm
     */
    validations: Validations;
}
/**
 * 
 * @export
 * @interface Release
 */
export interface Release {
    /**
     * リリース番号
     * @type {string}
     * @memberof Release
     */
    id: string;
    /**
     * リリースタイトル
     * @type {string}
     * @memberof Release
     */
    title: string;
    /**
     * リリース説明
     * @type {string}
     * @memberof Release
     */
    description: string;
    /**
     * 
     * @type {Group}
     * @memberof Release
     */
    group: Group;
    /**
     * リリース日時(YYYY/MM/DD HH:mm)
     * @type {string}
     * @memberof Release
     */
    start_date: string;
    /**
     * 終了日時(YYYY/MM/DD HH:mm)
     * @type {string}
     * @memberof Release
     */
    end_date: string;
    /**
     * 作成日
     * @type {string}
     * @memberof Release
     */
    created_at: string;
    /**
     * 更新日
     * @type {string}
     * @memberof Release
     */
    updated_at: string;
}
/**
 * コピー元のカスタムID
 * @export
 * @interface RequestsClientAnswersCopy
 */
export interface RequestsClientAnswersCopy {
    /**
     * コピー元のカスタムID
     * @type {string}
     * @memberof RequestsClientAnswersCopy
     */
    custom_id: string;
}
/**
 * カスタムID
 * @export
 * @interface RequestsClientCustomIdPost
 */
export interface RequestsClientCustomIdPost {
    /**
     * カスタムID
     * @type {string}
     * @memberof RequestsClientCustomIdPost
     */
    custom_id: string;
}
/**
 * 変更後のメールアドレス
 * @export
 * @interface RequestsClientEmail
 */
export interface RequestsClientEmail {
    /**
     * メールアドレス
     * @type {string}
     * @memberof RequestsClientEmail
     */
    email: string;
}
/**
 * 変更後のメールアドレス確認
 * @export
 * @interface RequestsClientEmailConfirm
 */
export interface RequestsClientEmailConfirm {
    /**
     * 認証コード
     * @type {string}
     * @memberof RequestsClientEmailConfirm
     */
    code: string;
}
/**
 * グループに対する回答を全て確定する（ただし一時保存は削除する）
 * @export
 * @interface RequestsClientGroupsGroupIdAnswersConfirm
 */
export interface RequestsClientGroupsGroupIdAnswersConfirm {
    /**
     * カスタムID
     * @type {string}
     * @memberof RequestsClientGroupsGroupIdAnswersConfirm
     */
    custom_id: string;
}
/**
 * テンプレート内のフォームに対する回答
 * @export
 * @interface RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId
 */
export interface RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId {
    /**
     * 
     * @type {AnswerProgress}
     * @memberof RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId
     */
    progress: AnswerProgress;
    /**
     * 回答のためのリクエスト
     * @type {Array<FormAnswer>}
     * @memberof RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId
     */
    answers: Array<FormAnswer>;
}
/**
 * ログイン用パラメーター
 * @export
 * @interface RequestsClientLogin
 */
export interface RequestsClientLogin {
    /**
     * ログインID
     * @type {string}
     * @memberof RequestsClientLogin
     */
    login_id: string;
    /**
     * ログインパスワード
     * @type {string}
     * @memberof RequestsClientLogin
     */
    password: string;
}
/**
 * 
 * @export
 * @interface RequestsClientLoginMfaPost
 */
export interface RequestsClientLoginMfaPost {
    /**
     * 認証コード
     * @type {string}
     * @memberof RequestsClientLoginMfaPost
     */
    code: string;
}
/**
 * 変更後のパスワード
 * @export
 * @interface RequestsClientPassword
 */
export interface RequestsClientPassword {
    /**
     * 旧パスワード
     * @type {string}
     * @memberof RequestsClientPassword
     */
    old_password: string;
    /**
     * 新パスワード
     * @type {string}
     * @memberof RequestsClientPassword
     */
    new_password: string;
}
/**
 * パスワード変更用のメールアドレス
 * @export
 * @interface RequestsClientRecoveryPassword
 */
export interface RequestsClientRecoveryPassword {
    /**
     * メールアドレス
     * @type {string}
     * @memberof RequestsClientRecoveryPassword
     */
    email: string;
}
/**
 * パスワード忘れ用コード
 * @export
 * @interface RequestsClientRecoveryPasswordConfirm
 */
export interface RequestsClientRecoveryPasswordConfirm {
    /**
     * コード
     * @type {string}
     * @memberof RequestsClientRecoveryPasswordConfirm
     */
    code: string;
    /**
     * 新しいパスワード
     * @type {string}
     * @memberof RequestsClientRecoveryPasswordConfirm
     */
    password: string;
}
/**
 * ユーザー作成情報
 * @export
 * @interface RequestsClientRegister
 */
export interface RequestsClientRegister {
    /**
     * メールアドレス
     * @type {string}
     * @memberof RequestsClientRegister
     */
    email: string;
    /**
     * パスワード
     * @type {string}
     * @memberof RequestsClientRegister
     */
    password: string;
}
/**
 * ユーザー作成用のコード
 * @export
 * @interface RequestsClientRegisterConfirm
 */
export interface RequestsClientRegisterConfirm {
    /**
     * コード
     * @type {string}
     * @memberof RequestsClientRegisterConfirm
     */
    code: string;
}
/**
 * 利用者情報
 * @export
 * @interface RequestsClientSelf
 */
export interface RequestsClientSelf {
    /**
     * 名
     * @type {string}
     * @memberof RequestsClientSelf
     */
    first_name: string;
    /**
     * 姓
     * @type {string}
     * @memberof RequestsClientSelf
     */
    last_name: string;
    /**
     * 名（カナ）
     * @type {string}
     * @memberof RequestsClientSelf
     */
    first_name_kana: string;
    /**
     * 姓（カナ）
     * @type {string}
     * @memberof RequestsClientSelf
     */
    last_name_kana: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof RequestsClientSelf
     */
    zip: string;
    /**
     * 性別
     * @type {string}
     * @memberof RequestsClientSelf
     */
    gender: string;
    /**
     * 住所
     * @type {string}
     * @memberof RequestsClientSelf
     */
    address: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof RequestsClientSelf
     */
    phone_number: string;
}
/**
 * 
 * @export
 * @interface RequestsClientUserMfaPost
 */
export interface RequestsClientUserMfaPost {
    /**
     * 有効・無効
     * @type {boolean}
     * @memberof RequestsClientUserMfaPost
     */
    enabled: boolean;
    /**
     * 電話番号
     * @type {string}
     * @memberof RequestsClientUserMfaPost
     */
    phone?: string;
}
/**
 * グループに対する回答を全て確定する（ただし一時保存は削除する）
 * @export
 * @interface RequestsCustomApiClientGroupsGroupIdAnswersConfirm
 */
export interface RequestsCustomApiClientGroupsGroupIdAnswersConfirm {
    /**
     * カスタムID
     * @type {string}
     * @memberof RequestsCustomApiClientGroupsGroupIdAnswersConfirm
     */
    custom_id: string;
}
/**
 * 被検者情報
 * @export
 * @interface RequestsCustomApiClientMembers
 */
export interface RequestsCustomApiClientMembers {
    /**
     * 本人または代諾者
     * @type {string}
     * @memberof RequestsCustomApiClientMembers
     */
    identification: string;
    /**
     * 続柄
     * @type {string}
     * @memberof RequestsCustomApiClientMembers
     */
    relation: string;
    /**
     * 続柄名
     * @type {string}
     * @memberof RequestsCustomApiClientMembers
     */
    relation_detail: string;
    /**
     * 名
     * @type {string}
     * @memberof RequestsCustomApiClientMembers
     */
    first_name: string;
    /**
     * 姓
     * @type {string}
     * @memberof RequestsCustomApiClientMembers
     */
    last_name: string;
    /**
     * 名（カナ）
     * @type {string}
     * @memberof RequestsCustomApiClientMembers
     */
    first_name_kana: string;
    /**
     * 姓（カナ）
     * @type {string}
     * @memberof RequestsCustomApiClientMembers
     */
    last_name_kana: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof RequestsCustomApiClientMembers
     */
    zip: string;
    /**
     * 住所
     * @type {string}
     * @memberof RequestsCustomApiClientMembers
     */
    address: string;
    /**
     * ビル
     * @type {string}
     * @memberof RequestsCustomApiClientMembers
     */
    building: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof RequestsCustomApiClientMembers
     */
    phone_number: string;
    /**
     * 誕生日
     * @type {string}
     * @memberof RequestsCustomApiClientMembers
     */
    birthdate: string;
    /**
     * 回答に使用したサンプルID情報
     * @type {Array<string>}
     * @memberof RequestsCustomApiClientMembers
     */
    sample_ids: Array<string>;
    /**
     * 現在使用しているサンプルID
     * @type {string}
     * @memberof RequestsCustomApiClientMembers
     */
    sample_id: string;
    /**
     * 認証ID
     * @type {string}
     * @memberof RequestsCustomApiClientMembers
     */
    kit_id: string;
    /**
     * 研究同意チェックの状態
     * @type {boolean}
     * @memberof RequestsCustomApiClientMembers
     */
    has_informed_consent_checked: boolean;
}
/**
 * 被検者情報
 * @export
 * @interface RequestsCustomApiClientMembersMemberId
 */
export interface RequestsCustomApiClientMembersMemberId {
    /**
     * 本人または代諾者
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    identification: string;
    /**
     * 続柄
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    relation: string;
    /**
     * 続柄名称
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    relation_detail: string;
    /**
     * 名
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    first_name: string;
    /**
     * 姓
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    last_name: string;
    /**
     * 名（カナ）
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    first_name_kana: string;
    /**
     * 姓（カナ）
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    last_name_kana: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    zip: string;
    /**
     * 住所
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    address: string;
    /**
     * ビル
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    building: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    phone_number: string;
    /**
     * 誕生日
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    birthdate: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    sample_ids: Array<string>;
    /**
     * 現在使用しているサンプルID
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    sample_id: string;
    /**
     * サンプルIDのバージョン
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    sample_id_version: string;
    /**
     * 研究同意チェックの状態
     * @type {boolean}
     * @memberof RequestsCustomApiClientMembersMemberId
     */
    has_informed_consent_checked: boolean;
}
/**
 * 追加する情報
 * @export
 * @interface RequestsCustomApiClientMembersMemberIdSampleIds
 */
export interface RequestsCustomApiClientMembersMemberIdSampleIds {
    /**
     * サンプルID
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberIdSampleIds
     */
    sample_id: string;
    /**
     * キットID
     * @type {string}
     * @memberof RequestsCustomApiClientMembersMemberIdSampleIds
     */
    kit_id: string;
}
/**
 * 自身の情報
 * @export
 * @interface RequestsCustomApiClientRegister
 */
export interface RequestsCustomApiClientRegister {
    /**
     * メールアドレス
     * @type {string}
     * @memberof RequestsCustomApiClientRegister
     */
    email: string;
    /**
     * パスワード
     * @type {string}
     * @memberof RequestsCustomApiClientRegister
     */
    password: string;
    /**
     * 名
     * @type {string}
     * @memberof RequestsCustomApiClientRegister
     */
    first_name: string;
    /**
     * 姓
     * @type {string}
     * @memberof RequestsCustomApiClientRegister
     */
    last_name: string;
    /**
     * 名（カナ）
     * @type {string}
     * @memberof RequestsCustomApiClientRegister
     */
    first_name_kana: string;
    /**
     * 姓（カナ）
     * @type {string}
     * @memberof RequestsCustomApiClientRegister
     */
    last_name_kana: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof RequestsCustomApiClientRegister
     */
    zip: string;
    /**
     * 住所
     * @type {string}
     * @memberof RequestsCustomApiClientRegister
     */
    address: string;
    /**
     * ビル
     * @type {string}
     * @memberof RequestsCustomApiClientRegister
     */
    building: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof RequestsCustomApiClientRegister
     */
    phone_number: string;
    /**
     * 誕生日(YYYY-MM-DD)
     * @type {string}
     * @memberof RequestsCustomApiClientRegister
     */
    birthdate: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestsCustomApiClientRegister
     */
    sample_ids: Array<string>;
    /**
     * 個人情報保護のチェック状態
     * @type {boolean}
     * @memberof RequestsCustomApiClientRegister
     */
    has_privacy_policy_checked: boolean;
}
/**
 * 利用者情報
 * @export
 * @interface RequestsCustomApiClientSelf
 */
export interface RequestsCustomApiClientSelf {
    /**
     * 名
     * @type {string}
     * @memberof RequestsCustomApiClientSelf
     */
    first_name: string;
    /**
     * 姓
     * @type {string}
     * @memberof RequestsCustomApiClientSelf
     */
    last_name: string;
    /**
     * 名（カナ）
     * @type {string}
     * @memberof RequestsCustomApiClientSelf
     */
    first_name_kana: string;
    /**
     * 姓（カナ）
     * @type {string}
     * @memberof RequestsCustomApiClientSelf
     */
    last_name_kana: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof RequestsCustomApiClientSelf
     */
    zip: string;
    /**
     * 住所
     * @type {string}
     * @memberof RequestsCustomApiClientSelf
     */
    address: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof RequestsCustomApiClientSelf
     */
    phone_number: string;
    /**
     * 誕生日
     * @type {string}
     * @memberof RequestsCustomApiClientSelf
     */
    birthdate: string;
    /**
     * 住所2
     * @type {string}
     * @memberof RequestsCustomApiClientSelf
     */
    building: string;
}
/**
 * メール設定に関する回答
 * @export
 * @interface RequestsManagerContentsSettingMailPut
 */
export interface RequestsManagerContentsSettingMailPut {
    /**
     * 回答のためのリクエスト
     * @type {Array<FormAnswer>}
     * @memberof RequestsManagerContentsSettingMailPut
     */
    answers: Array<FormAnswer>;
}
/**
 * 設定に関する回答
 * @export
 * @interface RequestsManagerContentsSettingPut
 */
export interface RequestsManagerContentsSettingPut {
    /**
     * 回答のためのリクエスト
     * @type {Array<FormAnswer>}
     * @memberof RequestsManagerContentsSettingPut
     */
    answers: Array<FormAnswer>;
}
/**
 * グループ
 * @export
 * @interface RequestsManagerGroupsGroupIdPut
 */
export interface RequestsManagerGroupsGroupIdPut {
    /**
     * グループ名
     * @type {string}
     * @memberof RequestsManagerGroupsGroupIdPut
     */
    name: string;
    /**
     * グループの説明
     * @type {string}
     * @memberof RequestsManagerGroupsGroupIdPut
     */
    description: string;
    /**
     * カテゴリ一覧
     * @type {Array<Category>}
     * @memberof RequestsManagerGroupsGroupIdPut
     */
    categories: Array<Category>;
    /**
     * テンプレート一覧
     * @type {Array<GroupTemplate>}
     * @memberof RequestsManagerGroupsGroupIdPut
     */
    templates: Array<GroupTemplate>;
    /**
     * 
     * @type {GroupOptions}
     * @memberof RequestsManagerGroupsGroupIdPut
     */
    options?: GroupOptions;
}
/**
 * グループのリリース
 * @export
 * @interface RequestsManagerGroupsGroupIdReleasesPost
 */
export interface RequestsManagerGroupsGroupIdReleasesPost {
    /**
     * リリースタイトル
     * @type {string}
     * @memberof RequestsManagerGroupsGroupIdReleasesPost
     */
    title: string;
    /**
     * リリース説明
     * @type {string}
     * @memberof RequestsManagerGroupsGroupIdReleasesPost
     */
    description: string;
    /**
     * リリース日時 (YYYY/MM/DD HH:mmの形) 空の場合は即時
     * @type {string}
     * @memberof RequestsManagerGroupsGroupIdReleasesPost
     */
    start_date: string;
    /**
     * リリース終了日 (YYYY/MM/DD HH:mmの形) 空の場合は終了日なし
     * @type {string}
     * @memberof RequestsManagerGroupsGroupIdReleasesPost
     */
    end_date: string;
}
/**
 * テンプレート
 * @export
 * @interface RequestsManagerGroupsGroupIdTemplatesPost
 */
export interface RequestsManagerGroupsGroupIdTemplatesPost {
    /**
     * テンプレート名
     * @type {string}
     * @memberof RequestsManagerGroupsGroupIdTemplatesPost
     */
    name: string;
    /**
     * テンプレートの説明
     * @type {string}
     * @memberof RequestsManagerGroupsGroupIdTemplatesPost
     */
    description: string;
    /**
     * 
     * @type {TemplateOptions}
     * @memberof RequestsManagerGroupsGroupIdTemplatesPost
     */
    options?: TemplateOptions;
    /**
     * パネル一覧
     * @type {Array<Panel>}
     * @memberof RequestsManagerGroupsGroupIdTemplatesPost
     */
    panels?: Array<Panel>;
}
/**
 * テンプレート
 * @export
 * @interface RequestsManagerGroupsGroupIdTemplatesTemplateIdPut
 */
export interface RequestsManagerGroupsGroupIdTemplatesTemplateIdPut {
    /**
     * テンプレート名
     * @type {string}
     * @memberof RequestsManagerGroupsGroupIdTemplatesTemplateIdPut
     */
    name: string;
    /**
     * テンプレートの説明
     * @type {string}
     * @memberof RequestsManagerGroupsGroupIdTemplatesTemplateIdPut
     */
    description: string;
    /**
     * 
     * @type {TemplateOptions}
     * @memberof RequestsManagerGroupsGroupIdTemplatesTemplateIdPut
     */
    options?: TemplateOptions;
    /**
     * パネル一覧
     * @type {Array<Panel>}
     * @memberof RequestsManagerGroupsGroupIdTemplatesTemplateIdPut
     */
    panels?: Array<Panel>;
}
/**
 * グループ作成
 * @export
 * @interface RequestsManagerGroupsPost
 */
export interface RequestsManagerGroupsPost {
    /**
     * グループ名
     * @type {string}
     * @memberof RequestsManagerGroupsPost
     */
    name: string;
    /**
     * グループの説明
     * @type {string}
     * @memberof RequestsManagerGroupsPost
     */
    description?: string;
    /**
     * カテゴリ一覧
     * @type {Array<Category>}
     * @memberof RequestsManagerGroupsPost
     */
    categories?: Array<Category>;
    /**
     * テンプレート一覧
     * @type {Array<GroupTemplate>}
     * @memberof RequestsManagerGroupsPost
     */
    templates?: Array<GroupTemplate>;
    /**
     * 
     * @type {GroupOptions}
     * @memberof RequestsManagerGroupsPost
     */
    options?: GroupOptions;
}
/**
 * 
 * @export
 * @interface RequestsManagerLogin
 */
export interface RequestsManagerLogin {
    /**
     * ログインID
     * @type {string}
     * @memberof RequestsManagerLogin
     */
    login_id: string;
    /**
     * ログインパスワード
     * @type {string}
     * @memberof RequestsManagerLogin
     */
    password: string;
}
/**
 * 
 * @export
 * @interface RequestsManagerLoginMfaPost
 */
export interface RequestsManagerLoginMfaPost {
    /**
     * 認証コード
     * @type {string}
     * @memberof RequestsManagerLoginMfaPost
     */
    code: string;
}
/**
 * グループのリリース
 * @export
 * @interface RequestsManagerReleasesReleaseIdPut
 */
export interface RequestsManagerReleasesReleaseIdPut {
    /**
     * リリースタイトル
     * @type {string}
     * @memberof RequestsManagerReleasesReleaseIdPut
     */
    title?: string;
    /**
     * リリース説明
     * @type {string}
     * @memberof RequestsManagerReleasesReleaseIdPut
     */
    description?: string;
    /**
     * リリース日時 (YYYY/MM/DD HH:mmの形) 空の場合は即時
     * @type {string}
     * @memberof RequestsManagerReleasesReleaseIdPut
     */
    start_date?: string;
    /**
     * リリース終了日 (YYYY/MM/DD HH:mmの形) 空の場合は終了日なし
     * @type {string}
     * @memberof RequestsManagerReleasesReleaseIdPut
     */
    end_date?: string;
}
/**
 * 
 * @export
 * @interface RequestsManagerUsersPost
 */
export interface RequestsManagerUsersPost {
    /**
     * メールアドレス
     * @type {string}
     * @memberof RequestsManagerUsersPost
     */
    email: string;
    /**
     * ユーザー名
     * @type {string}
     * @memberof RequestsManagerUsersPost
     */
    name: string;
    /**
     * ログインパスワード
     * @type {string}
     * @memberof RequestsManagerUsersPost
     */
    password: string;
}
/**
 * メールの送信情報
 * @export
 * @interface RequestsManagerUsersUserIdMailPost
 */
export interface RequestsManagerUsersUserIdMailPost {
    /**
     * メールのサブジェクト
     * @type {string}
     * @memberof RequestsManagerUsersUserIdMailPost
     */
    subject: string;
    /**
     * メールの本文
     * @type {string}
     * @memberof RequestsManagerUsersUserIdMailPost
     */
    body: string;
}
/**
 * 結果
 * @export
 * @interface ResultAnswers
 */
export interface ResultAnswers {
    /**
     * グループID
     * @type {string}
     * @memberof ResultAnswers
     */
    group_id: string;
    /**
     * テンプレートID
     * @type {string}
     * @memberof ResultAnswers
     */
    template_id: string;
    /**
     * ユーザー定義のID
     * @type {string}
     * @memberof ResultAnswers
     */
    custom_id: string;
    /**
     * 
     * @type {AnswerProgress}
     * @memberof ResultAnswers
     */
    progress: AnswerProgress;
    /**
     * 回答のためのリクエスト
     * @type {Array<FormAnswer>}
     * @memberof ResultAnswers
     */
    answers: Array<FormAnswer>;
    /**
     * 作成日
     * @type {string}
     * @memberof ResultAnswers
     */
    created_at: string;
    /**
     * 更新日
     * @type {string}
     * @memberof ResultAnswers
     */
    updated_at: string;
}
/**
 * ログインの結果情報
 * @export
 * @interface ResultLogin
 */
export interface ResultLogin {
    /**
     * ログインされたかどうか
     * @type {boolean}
     * @memberof ResultLogin
     */
    logined: boolean;
    /**
     * 
     * @type {LoginParameters}
     * @memberof ResultLogin
     */
    parameters: LoginParameters;
}
/**
 * OK
 * @export
 * @interface ResultOk
 */
export interface ResultOk {
    /**
     * 成功結果
     * @type {boolean}
     * @memberof ResultOk
     */
    succeed: boolean;
}
/**
 * 設定情報
 * @export
 * @interface ResultSetting
 */
export interface ResultSetting {
    /**
     * 
     * @type {Template}
     * @memberof ResultSetting
     */
    template: Template;
    /**
     * 
     * @type {ResultAnswers}
     * @memberof ResultSetting
     */
    answers: ResultAnswers;
}
/**
 * OK
 * @export
 * @interface ResultVersion
 */
export interface ResultVersion {
    /**
     * 成功結果
     * @type {boolean}
     * @memberof ResultVersion
     */
    succeed: boolean;
    /**
     * サンプルIDのバージョン情報
     * @type {string}
     * @memberof ResultVersion
     */
    version: string;
}
/**
 * コピー成功
 * @export
 * @interface ResultsClientAnswersCopy
 */
export interface ResultsClientAnswersCopy {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientAnswersCopy
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsClientAnswersCopy
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsClientCustomIdPost
 */
export interface ResultsClientCustomIdPost {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientCustomIdPost
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsClientCustomIdPost
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsClientEmail
 */
export interface ResultsClientEmail {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientEmail
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsClientEmail
     */
    data: ResultOk;
}
/**
 * メールアドレスの認証コードの確認成功
 * @export
 * @interface ResultsClientEmailConfirm
 */
export interface ResultsClientEmailConfirm {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientEmailConfirm
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsClientEmailConfirm
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsClientGroups
 */
export interface ResultsClientGroups {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientGroups
     */
    links: Links;
    /**
     * グループ一覧
     * @type {Array<Group>}
     * @memberof ResultsClientGroups
     */
    data: Array<Group>;
}
/**
 * グループ内の回答一覧
 * @export
 * @interface ResultsClientGroupsGroupIdAnswers
 */
export interface ResultsClientGroupsGroupIdAnswers {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientGroupsGroupIdAnswers
     */
    links: Links;
    /**
     * 結果データ
     * @type {Array<ResultAnswers>}
     * @memberof ResultsClientGroupsGroupIdAnswers
     */
    data: Array<ResultAnswers>;
}
/**
 * 回答の確定に成功
 * @export
 * @interface ResultsClientGroupsGroupIdAnswersConfirm
 */
export interface ResultsClientGroupsGroupIdAnswersConfirm {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientGroupsGroupIdAnswersConfirm
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsClientGroupsGroupIdAnswersConfirm
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsClientGroupsGroupIdTemplatesTemplateId
 */
export interface ResultsClientGroupsGroupIdTemplatesTemplateId {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientGroupsGroupIdTemplatesTemplateId
     */
    links: Links;
    /**
     * 
     * @type {Template}
     * @memberof ResultsClientGroupsGroupIdTemplatesTemplateId
     */
    data: Template;
}
/**
 * 
 * @export
 * @interface ResultsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId
 */
export interface ResultsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId
     */
    links: Links;
    /**
     * 
     * @type {ResultAnswers}
     * @memberof ResultsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId
     */
    data: ResultAnswers;
}
/**
 * ログインの結果
 * @export
 * @interface ResultsClientLogin
 */
export interface ResultsClientLogin {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientLogin
     */
    links: Links;
    /**
     * 
     * @type {ResultLogin}
     * @memberof ResultsClientLogin
     */
    data: ResultLogin;
}
/**
 * 
 * @export
 * @interface ResultsClientLoginMfaPost
 */
export interface ResultsClientLoginMfaPost {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientLoginMfaPost
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsClientLoginMfaPost
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsClientPassword
 */
export interface ResultsClientPassword {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientPassword
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsClientPassword
     */
    data: ResultOk;
}
/**
 * パスワード忘れのためのメール送信成功
 * @export
 * @interface ResultsClientRecoveryPassword
 */
export interface ResultsClientRecoveryPassword {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientRecoveryPassword
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsClientRecoveryPassword
     */
    data: ResultOk;
}
/**
 * パスワード忘れの認証コードの確認成功
 * @export
 * @interface ResultsClientRecoveryPasswordConfirm
 */
export interface ResultsClientRecoveryPasswordConfirm {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientRecoveryPasswordConfirm
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsClientRecoveryPasswordConfirm
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsClientRegister
 */
export interface ResultsClientRegister {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientRegister
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsClientRegister
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsClientRegisterConfirm
 */
export interface ResultsClientRegisterConfirm {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientRegisterConfirm
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsClientRegisterConfirm
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsClientSelf
 */
export interface ResultsClientSelf {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientSelf
     */
    links: Links;
    /**
     * 
     * @type {Self}
     * @memberof ResultsClientSelf
     */
    data: Self;
}
/**
 * 
 * @export
 * @interface ResultsClientUserMfaPost
 */
export interface ResultsClientUserMfaPost {
    /**
     * 
     * @type {Links}
     * @memberof ResultsClientUserMfaPost
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsClientUserMfaPost
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsContentsSettingGet
 */
export interface ResultsContentsSettingGet {
    /**
     * 
     * @type {Links}
     * @memberof ResultsContentsSettingGet
     */
    links: Links;
    /**
     * 
     * @type {ResultSetting}
     * @memberof ResultsContentsSettingGet
     */
    data: ResultSetting;
}
/**
 * 
 * @export
 * @interface ResultsCustomApiClientCheckSampleIdKitId
 */
export interface ResultsCustomApiClientCheckSampleIdKitId {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiClientCheckSampleIdKitId
     */
    links: Links;
    /**
     * 
     * @type {ResultVersion}
     * @memberof ResultsCustomApiClientCheckSampleIdKitId
     */
    data: ResultVersion;
}
/**
 * サンプルIDが確定している
 * @export
 * @interface ResultsCustomApiClientConfirmedMysampleid
 */
export interface ResultsCustomApiClientConfirmedMysampleid {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiClientConfirmedMysampleid
     */
    links: Links;
    /**
     * 
     * @type {ResultsCustomApiClientConfirmedMysampleidData}
     * @memberof ResultsCustomApiClientConfirmedMysampleid
     */
    data: ResultsCustomApiClientConfirmedMysampleidData;
}
/**
 * 
 * @export
 * @interface ResultsCustomApiClientConfirmedMysampleidData
 */
export interface ResultsCustomApiClientConfirmedMysampleidData {
    /**
     * 存在するかどうか
     * @type {boolean}
     * @memberof ResultsCustomApiClientConfirmedMysampleidData
     */
    does_exist: boolean;
    /**
     * 自分の回答かどうか
     * @type {boolean}
     * @memberof ResultsCustomApiClientConfirmedMysampleidData
     */
    is_mine: boolean;
}
/**
 * 回答の確定に成功
 * @export
 * @interface ResultsCustomApiClientGroupsGroupIdAnswersConfirm
 */
export interface ResultsCustomApiClientGroupsGroupIdAnswersConfirm {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiClientGroupsGroupIdAnswersConfirm
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsCustomApiClientGroupsGroupIdAnswersConfirm
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsCustomApiClientMembers
 */
export interface ResultsCustomApiClientMembers {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiClientMembers
     */
    links: Links;
    /**
     * メンバー一覧
     * @type {Array<CustomMember>}
     * @memberof ResultsCustomApiClientMembers
     */
    data: Array<CustomMember>;
}
/**
 * 
 * @export
 * @interface ResultsCustomApiClientMembersMember
 */
export interface ResultsCustomApiClientMembersMember {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiClientMembersMember
     */
    links: Links;
    /**
     * 
     * @type {CustomMemberDetail}
     * @memberof ResultsCustomApiClientMembersMember
     */
    data: CustomMemberDetail;
}
/**
 * 
 * @export
 * @interface ResultsCustomApiClientMembersMemberId
 */
export interface ResultsCustomApiClientMembersMemberId {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiClientMembersMemberId
     */
    links: Links;
    /**
     * 
     * @type {CustomMemberDetail}
     * @memberof ResultsCustomApiClientMembersMemberId
     */
    data: CustomMemberDetail;
}
/**
 * 削除処理に成功
 * @export
 * @interface ResultsCustomApiClientMembersMemberIdDelete
 */
export interface ResultsCustomApiClientMembersMemberIdDelete {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiClientMembersMemberIdDelete
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsCustomApiClientMembersMemberIdDelete
     */
    data: ResultOk;
}
/**
 * 更新処理に成功
 * @export
 * @interface ResultsCustomApiClientMembersMemberIdPut
 */
export interface ResultsCustomApiClientMembersMemberIdPut {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiClientMembersMemberIdPut
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsCustomApiClientMembersMemberIdPut
     */
    data: ResultOk;
}
/**
 * サンプルID追加成功
 * @export
 * @interface ResultsCustomApiClientMembersMemberIdSampleIds
 */
export interface ResultsCustomApiClientMembersMemberIdSampleIds {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiClientMembersMemberIdSampleIds
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsCustomApiClientMembersMemberIdSampleIds
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsCustomApiClientMembersMemberIdSelection
 */
export interface ResultsCustomApiClientMembersMemberIdSelection {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiClientMembersMemberIdSelection
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsCustomApiClientMembersMemberIdSelection
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsCustomApiClientRegister
 */
export interface ResultsCustomApiClientRegister {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiClientRegister
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsCustomApiClientRegister
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsCustomApiClientSelectedMember
 */
export interface ResultsCustomApiClientSelectedMember {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiClientSelectedMember
     */
    links: Links;
    /**
     * 
     * @type {CustomMemberDetail}
     * @memberof ResultsCustomApiClientSelectedMember
     */
    data: CustomMemberDetail;
}
/**
 * 
 * @export
 * @interface ResultsCustomApiClientSelectedMemberDelete
 */
export interface ResultsCustomApiClientSelectedMemberDelete {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiClientSelectedMemberDelete
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsCustomApiClientSelectedMemberDelete
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsCustomApiClientSelf
 */
export interface ResultsCustomApiClientSelf {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiClientSelf
     */
    links: Links;
    /**
     * 
     * @type {CustomMemberDetail}
     * @memberof ResultsCustomApiClientSelf
     */
    data: CustomMemberDetail;
}
/**
 * 
 * @export
 * @interface ResultsCustomApiClientSelfPut
 */
export interface ResultsCustomApiClientSelfPut {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiClientSelfPut
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsCustomApiClientSelfPut
     */
    data: ResultOk;
}
/**
 * メンバー,サンプルIDおよび回答状況情報一覧
 * @export
 * @interface ResultsCustomApiManagerMembersAnswersGet
 */
export interface ResultsCustomApiManagerMembersAnswersGet {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiManagerMembersAnswersGet
     */
    links: Links;
    /**
     * 
     * @type {CustomMembersAnswers}
     * @memberof ResultsCustomApiManagerMembersAnswersGet
     */
    data: CustomMembersAnswers;
}
/**
 * 
 * @export
 * @interface ResultsCustomApiManagerMembersMemberIdGet
 */
export interface ResultsCustomApiManagerMembersMemberIdGet {
    /**
     * 
     * @type {Links}
     * @memberof ResultsCustomApiManagerMembersMemberIdGet
     */
    links: Links;
    /**
     * 
     * @type {CustomMemberDetail}
     * @memberof ResultsCustomApiManagerMembersMemberIdGet
     */
    data: CustomMemberDetail;
}
/**
 * 
 * @export
 * @interface ResultsHealthcheck
 */
export interface ResultsHealthcheck {
    /**
     * 
     * @type {Links}
     * @memberof ResultsHealthcheck
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsHealthcheck
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsLogout
 */
export interface ResultsLogout {
    /**
     * 
     * @type {Links}
     * @memberof ResultsLogout
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsLogout
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsManagerAuthorityGet
 */
export interface ResultsManagerAuthorityGet {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerAuthorityGet
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsManagerAuthorityGet
     */
    data: ResultOk;
}
/**
 * イメージファイル
 * @export
 * @interface ResultsManagerClientImage
 */
export interface ResultsManagerClientImage {
    /**
     * UUID
     * @type {string}
     * @memberof ResultsManagerClientImage
     */
    id: string;
    /**
     * ファイルパス
     * @type {string}
     * @memberof ResultsManagerClientImage
     */
    path: string;
}
/**
 * 
 * @export
 * @interface ResultsManagerContentsSettingGet
 */
export interface ResultsManagerContentsSettingGet {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerContentsSettingGet
     */
    links: Links;
    /**
     * 
     * @type {ResultSetting}
     * @memberof ResultsManagerContentsSettingGet
     */
    data: ResultSetting;
}
/**
 * 
 * @export
 * @interface ResultsManagerContentsSettingMailGet
 */
export interface ResultsManagerContentsSettingMailGet {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerContentsSettingMailGet
     */
    links: Links;
    /**
     * 
     * @type {ResultSetting}
     * @memberof ResultsManagerContentsSettingMailGet
     */
    data: ResultSetting;
}
/**
 * 
 * @export
 * @interface ResultsManagerContentsSettingMailPut
 */
export interface ResultsManagerContentsSettingMailPut {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerContentsSettingMailPut
     */
    links: Links;
    /**
     * 
     * @type {ResultAnswers}
     * @memberof ResultsManagerContentsSettingMailPut
     */
    data: ResultAnswers;
}
/**
 * 
 * @export
 * @interface ResultsManagerContentsSettingPut
 */
export interface ResultsManagerContentsSettingPut {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerContentsSettingPut
     */
    links: Links;
    /**
     * 
     * @type {ResultAnswers}
     * @memberof ResultsManagerContentsSettingPut
     */
    data: ResultAnswers;
}
/**
 * 
 * @export
 * @interface ResultsManagerGroups
 */
export interface ResultsManagerGroups {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerGroups
     */
    links: Links;
    /**
     * グループ一覧
     * @type {Array<Group>}
     * @memberof ResultsManagerGroups
     */
    data: Array<Group>;
}
/**
 * 
 * @export
 * @interface ResultsManagerGroupsGroupIdAnswersGet
 */
export interface ResultsManagerGroupsGroupIdAnswersGet {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerGroupsGroupIdAnswersGet
     */
    links: Links;
    /**
     * 
     * @type {ResultsManagerGroupsGroupIdAnswersGetData}
     * @memberof ResultsManagerGroupsGroupIdAnswersGet
     */
    data: ResultsManagerGroupsGroupIdAnswersGetData;
}
/**
 * 回答一覧
 * @export
 * @interface ResultsManagerGroupsGroupIdAnswersGetData
 */
export interface ResultsManagerGroupsGroupIdAnswersGetData {
    /**
     * DynamoDBのLastEvaluatedKey、次が存在する場合は空文字
     * @type {string}
     * @memberof ResultsManagerGroupsGroupIdAnswersGetData
     */
    next_id?: string;
    /**
     * 回答一覧
     * @type {Array<ResultAnswers>}
     * @memberof ResultsManagerGroupsGroupIdAnswersGetData
     */
    results?: Array<ResultAnswers>;
}
/**
 * 
 * @export
 * @interface ResultsManagerGroupsGroupIdDelete
 */
export interface ResultsManagerGroupsGroupIdDelete {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerGroupsGroupIdDelete
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsManagerGroupsGroupIdDelete
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsManagerGroupsGroupIdPut
 */
export interface ResultsManagerGroupsGroupIdPut {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerGroupsGroupIdPut
     */
    links: Links;
    /**
     * 
     * @type {Group}
     * @memberof ResultsManagerGroupsGroupIdPut
     */
    data: Group;
}
/**
 * 
 * @export
 * @interface ResultsManagerGroupsGroupIdReleasesGet
 */
export interface ResultsManagerGroupsGroupIdReleasesGet {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerGroupsGroupIdReleasesGet
     */
    links: Links;
    /**
     * リリース情報
     * @type {Array<Release>}
     * @memberof ResultsManagerGroupsGroupIdReleasesGet
     */
    data: Array<Release>;
}
/**
 * 
 * @export
 * @interface ResultsManagerGroupsGroupIdReleasesPost
 */
export interface ResultsManagerGroupsGroupIdReleasesPost {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerGroupsGroupIdReleasesPost
     */
    links: Links;
    /**
     * 
     * @type {Release}
     * @memberof ResultsManagerGroupsGroupIdReleasesPost
     */
    data: Release;
}
/**
 * 
 * @export
 * @interface ResultsManagerGroupsGroupIdTemplatesGet
 */
export interface ResultsManagerGroupsGroupIdTemplatesGet {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerGroupsGroupIdTemplatesGet
     */
    links: Links;
    /**
     * 
     * @type {Array<Template>}
     * @memberof ResultsManagerGroupsGroupIdTemplatesGet
     */
    data: Array<Template>;
}
/**
 * 
 * @export
 * @interface ResultsManagerGroupsGroupIdTemplatesPost
 */
export interface ResultsManagerGroupsGroupIdTemplatesPost {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerGroupsGroupIdTemplatesPost
     */
    links: Links;
    /**
     * 
     * @type {Template}
     * @memberof ResultsManagerGroupsGroupIdTemplatesPost
     */
    data: Template;
}
/**
 * 
 * @export
 * @interface ResultsManagerGroupsGroupIdTemplatesTemplateIdDelete
 */
export interface ResultsManagerGroupsGroupIdTemplatesTemplateIdDelete {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerGroupsGroupIdTemplatesTemplateIdDelete
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsManagerGroupsGroupIdTemplatesTemplateIdDelete
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsManagerGroupsGroupIdTemplatesTemplateIdPut
 */
export interface ResultsManagerGroupsGroupIdTemplatesTemplateIdPut {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerGroupsGroupIdTemplatesTemplateIdPut
     */
    links: Links;
    /**
     * 
     * @type {Template}
     * @memberof ResultsManagerGroupsGroupIdTemplatesTemplateIdPut
     */
    data: Template;
}
/**
 * 
 * @export
 * @interface ResultsManagerGroupsPost
 */
export interface ResultsManagerGroupsPost {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerGroupsPost
     */
    links: Links;
    /**
     * 
     * @type {Group}
     * @memberof ResultsManagerGroupsPost
     */
    data: Group;
}
/**
 * 
 * @export
 * @interface ResultsManagerLogin
 */
export interface ResultsManagerLogin {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerLogin
     */
    links: Links;
    /**
     * 
     * @type {ResultLogin}
     * @memberof ResultsManagerLogin
     */
    data: ResultLogin;
}
/**
 * 
 * @export
 * @interface ResultsManagerLoginMfaPost
 */
export interface ResultsManagerLoginMfaPost {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerLoginMfaPost
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsManagerLoginMfaPost
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsManagerReleasesReleaseIdDelete
 */
export interface ResultsManagerReleasesReleaseIdDelete {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerReleasesReleaseIdDelete
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsManagerReleasesReleaseIdDelete
     */
    data: ResultOk;
}
/**
 * 
 * @export
 * @interface ResultsManagerReleasesReleaseIdPut
 */
export interface ResultsManagerReleasesReleaseIdPut {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerReleasesReleaseIdPut
     */
    links: Links;
    /**
     * 
     * @type {Release}
     * @memberof ResultsManagerReleasesReleaseIdPut
     */
    data: Release;
}
/**
 * 
 * @export
 * @interface ResultsManagerUsersGet
 */
export interface ResultsManagerUsersGet {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerUsersGet
     */
    links?: Links;
    /**
     * 
     * @type {Array<User>}
     * @memberof ResultsManagerUsersGet
     */
    data?: Array<User>;
}
/**
 * 
 * @export
 * @interface ResultsManagerUsersPost
 */
export interface ResultsManagerUsersPost {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerUsersPost
     */
    links?: Links;
    /**
     * 
     * @type {User}
     * @memberof ResultsManagerUsersPost
     */
    data?: User;
}
/**
 * 
 * @export
 * @interface ResultsManagerUsersUserIdMailPost
 */
export interface ResultsManagerUsersUserIdMailPost {
    /**
     * 
     * @type {Links}
     * @memberof ResultsManagerUsersUserIdMailPost
     */
    links: Links;
    /**
     * 
     * @type {ResultOk}
     * @memberof ResultsManagerUsersUserIdMailPost
     */
    data: ResultOk;
}
/**
 * select用のフォーム
 * @export
 * @interface SelectForm
 */
export interface SelectForm {
    /**
     * 回答のリスト
     * @type {Array<FormValue>}
     * @memberof SelectForm
     */
    list: Array<FormValue>;
    /**
     * デフォルト値
     * @type {string}
     * @memberof SelectForm
     */
    default_value?: string;
    /**
     * プレフィックス
     * @type {string}
     * @memberof SelectForm
     */
    prefix?: string;
    /**
     * サフィックス
     * @type {string}
     * @memberof SelectForm
     */
    suffix?: string;
    /**
     * 
     * @type {Validations}
     * @memberof SelectForm
     */
    validations: Validations;
}
/**
 * 利用者情報
 * @export
 * @interface Self
 */
export interface Self {
    /**
     * 名
     * @type {string}
     * @memberof Self
     */
    first_name: string;
    /**
     * 姓
     * @type {string}
     * @memberof Self
     */
    last_name: string;
    /**
     * 名（カナ）
     * @type {string}
     * @memberof Self
     */
    first_name_kana: string;
    /**
     * 姓（カナ）
     * @type {string}
     * @memberof Self
     */
    last_name_kana: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof Self
     */
    zip: string;
    /**
     * 性別
     * @type {string}
     * @memberof Self
     */
    gender: string;
    /**
     * 住所
     * @type {string}
     * @memberof Self
     */
    address: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof Self
     */
    phone_number: string;
    /**
     * 誕生日
     * @type {string}
     * @memberof Self
     */
    birthdate: string;
}
/**
 * スライダーフォーム
 * @export
 * @interface SliderForm
 */
export interface SliderForm {
    /**
     * 最大値
     * @type {number}
     * @memberof SliderForm
     */
    max: number;
    /**
     * 最小値
     * @type {number}
     * @memberof SliderForm
     */
    min: number;
    /**
     * 粒度
     * @type {number}
     * @memberof SliderForm
     */
    step: number;
    /**
     * デフォルト値
     * @type {string}
     * @memberof SliderForm
     */
    default_value?: string;
    /**
     * 
     * @type {Validations}
     * @memberof SliderForm
     */
    validations: Validations;
}
/**
 * スライダーリストフォーム
 * @export
 * @interface SliderListForm
 */
export interface SliderListForm {
    /**
     * スライダー一覧
     * @type {Array<FormOnlySlider>}
     * @memberof SliderListForm
     */
    forms: Array<FormOnlySlider>;
    /**
     * 全体としてのトータル
     * @type {number}
     * @memberof SliderListForm
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface Template
 */
export interface Template {
    /**
     * グループID
     * @type {string}
     * @memberof Template
     */
    group_id: string;
    /**
     * テンプレートID
     * @type {string}
     * @memberof Template
     */
    id: string;
    /**
     * テンプレート名
     * @type {string}
     * @memberof Template
     */
    name: string;
    /**
     * テンプレートの説明
     * @type {string}
     * @memberof Template
     */
    description: string;
    /**
     * 
     * @type {TemplateOptions}
     * @memberof Template
     */
    options?: TemplateOptions;
    /**
     * パネル一覧
     * @type {Array<Panel>}
     * @memberof Template
     */
    panels: Array<Panel>;
    /**
     * 作成日
     * @type {string}
     * @memberof Template
     */
    created_at: string;
    /**
     * 更新日
     * @type {string}
     * @memberof Template
     */
    updated_at: string;
}
/**
 * テンプレートに対するオプション設定
 * @export
 * @interface TemplateOptions
 */
export interface TemplateOptions {
    /**
     * 表示条件一覧
     * @type {Array<VisibleCondition>}
     * @memberof TemplateOptions
     */
    visible_conditions?: Array<VisibleCondition>;
}
/**
 * テキストフィールドのフォームに対する設定
 * @export
 * @interface TextForm
 */
export interface TextForm {
    /**
     * テキストフィールドのタイプ
     * @type {string}
     * @memberof TextForm
     */
    type: TextFormTypeEnum;
    /**
     * プレースホルダー
     * @type {string}
     * @memberof TextForm
     */
    placeholder?: string;
    /**
     * 
     * @type {Validations}
     * @memberof TextForm
     */
    validations: Validations;
    /**
     * デフォルト値
     * @type {string}
     * @memberof TextForm
     */
    default_value?: string;
    /**
     * プレフィックス
     * @type {string}
     * @memberof TextForm
     */
    prefix?: string;
    /**
     * サフィックス
     * @type {string}
     * @memberof TextForm
     */
    suffix?: string;
    /**
     * 文字数最大
     * @type {number}
     * @memberof TextForm
     */
    max_length?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TextFormTypeEnum {
    Text = 'text',
    Number = 'number',
    Password = 'password',
    PasswordWithConfirm = 'password_with_confirm',
    PostalWithAddress = 'postal_with_address'
}

/**
 * 
 * @export
 * @interface Timestamp
 */
export interface Timestamp {
    /**
     * 作成日
     * @type {string}
     * @memberof Timestamp
     */
    created_at: string;
    /**
     * 最終更新日
     * @type {string}
     * @memberof Timestamp
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * ユーザーID
     * @type {string}
     * @memberof User
     */
    user_id?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * ユーザー名
     * @type {string}
     * @memberof User
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface UsersData
 */
export interface UsersData {
    /**
     * 
     * @type {Array<User>}
     * @memberof UsersData
     */
    data?: Array<User>;
}
/**
 * 
 * @export
 * @interface Validations
 */
export interface Validations {
    /**
     * バリデーション一覧
     * @type {Array<string>}
     * @memberof Validations
     */
    values: Array<string>;
    /**
     * バリデーションエラーが発生した際のメッセージ
     * @type {{ [key: string]: string; }}
     * @memberof Validations
     */
    messages: { [key: string]: string; };
}
/**
 * 表示条件
 * @export
 * @interface VisibleCondition
 */
export interface VisibleCondition {
    /**
     * AND/OR
     * @type {string}
     * @memberof VisibleCondition
     */
    and_or: VisibleConditionAndOrEnum;
    /**
     * 条件タイプ
     * @type {string}
     * @memberof VisibleCondition
     */
    type: VisibleConditionTypeEnum;
    /**
     * 
     * @type {ConditionValue1}
     * @memberof VisibleCondition
     */
    value1: ConditionValue1;
    /**
     * 選択・入力値
     * @type {Array<string>}
     * @memberof VisibleCondition
     */
    value2: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum VisibleConditionAndOrEnum {
    Empty = '',
    AND = 'AND',
    OR = 'OR'
}
/**
    * @export
    * @enum {string}
    */
export enum VisibleConditionTypeEnum {
    Equal = '=',
    NotEqual = '!='
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 回答をコピーする
         * @param {RequestsClientAnswersCopy} [requestsClientAnswersCopy] 回答をコピーする
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientAnswersCopyPost: async (requestsClientAnswersCopy?: RequestsClientAnswersCopy, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/answers/copy`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientAnswersCopy !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientAnswersCopy !== undefined ? requestsClientAnswersCopy : {}) : (requestsClientAnswersCopy || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary カスタムIDをセットする
         * @param {RequestsClientCustomIdPost} [requestsClientCustomIdPost] カスタムID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientCustomidPost: async (requestsClientCustomIdPost?: RequestsClientCustomIdPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/customid`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientCustomIdPost !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientCustomIdPost !== undefined ? requestsClientCustomIdPost : {}) : (requestsClientCustomIdPost || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メールアドレス変更時の確認コード
         * @param {RequestsClientEmailConfirm} [requestsClientEmailConfirm] 変更メールアドレス確認コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientEmailConfirmPost: async (requestsClientEmailConfirm?: RequestsClientEmailConfirm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/email/confirm`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientEmailConfirm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientEmailConfirm !== undefined ? requestsClientEmailConfirm : {}) : (requestsClientEmailConfirm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メールアドレスを変更する
         * @param {RequestsClientEmail} [requestsClientEmail] 変更後メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientEmailPut: async (requestsClientEmail?: RequestsClientEmail, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/email`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientEmail !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientEmail !== undefined ? requestsClientEmail : {}) : (requestsClientEmail || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーが回答可能なアンケートのグループ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGroupsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/groups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 全ての回答を確定する
         * @param {string} groupId グループID
         * @param {RequestsClientGroupsGroupIdAnswersConfirm} [requestsClientGroupsGroupIdAnswersConfirm] 全ての回答を確定する（一時保存は削除する）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGroupsGroupIdAnswersConfirmPost: async (groupId: string, requestsClientGroupsGroupIdAnswersConfirm?: RequestsClientGroupsGroupIdAnswersConfirm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiClientGroupsGroupIdAnswersConfirmPost.');
            }
            const localVarPath = `/api/client/groups/{group_id}/answers/confirm`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientGroupsGroupIdAnswersConfirm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientGroupsGroupIdAnswersConfirm !== undefined ? requestsClientGroupsGroupIdAnswersConfirm : {}) : (requestsClientGroupsGroupIdAnswersConfirm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary グループ内の全てのテンプレートに対する回答一覧を取得する
         * @param {string} groupId グループID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGroupsGroupIdAnswersGet: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiClientGroupsGroupIdAnswersGet.');
            }
            const localVarPath = `/api/client/groups/{group_id}/answers`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary テンプレートに指定した回答を取得する
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {string} answerId 回答ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdGet: async (groupId: string, templateId: string, answerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdGet.');
            }
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdGet.');
            }
            // verify required parameter 'answerId' is not null or undefined
            if (answerId === null || answerId === undefined) {
                throw new RequiredError('answerId','Required parameter answerId was null or undefined when calling apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdGet.');
            }
            const localVarPath = `/api/client/groups/{group_id}/templates/{template_id}/answers/{answer_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)))
                .replace(`{${"answer_id"}}`, encodeURIComponent(String(answerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary テンプレートに指定したIDに対して回答を作成する
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {string} answerId 回答ID
         * @param {RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId} [requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId] テンプレートに対する回答情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPost: async (groupId: string, templateId: string, answerId: string, requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId?: RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPost.');
            }
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPost.');
            }
            // verify required parameter 'answerId' is not null or undefined
            if (answerId === null || answerId === undefined) {
                throw new RequiredError('answerId','Required parameter answerId was null or undefined when calling apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPost.');
            }
            const localVarPath = `/api/client/groups/{group_id}/templates/{template_id}/answers/{answer_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)))
                .replace(`{${"answer_id"}}`, encodeURIComponent(String(answerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId !== undefined ? requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId : {}) : (requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary テンプレートに指定したIDに対して回答を上書き更新・新規作成する
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {string} answerId 回答ID
         * @param {RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId} [requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId] テンプレートに対する回答情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPut: async (groupId: string, templateId: string, answerId: string, requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId?: RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPut.');
            }
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPut.');
            }
            // verify required parameter 'answerId' is not null or undefined
            if (answerId === null || answerId === undefined) {
                throw new RequiredError('answerId','Required parameter answerId was null or undefined when calling apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPut.');
            }
            const localVarPath = `/api/client/groups/{group_id}/templates/{template_id}/answers/{answer_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)))
                .replace(`{${"answer_id"}}`, encodeURIComponent(String(answerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId !== undefined ? requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId : {}) : (requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定のテンプレート情報を取得する
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGroupsGroupIdTemplatesTemplateIdGet: async (groupId: string, templateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiClientGroupsGroupIdTemplatesTemplateIdGet.');
            }
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling apiClientGroupsGroupIdTemplatesTemplateIdGet.');
            }
            const localVarPath = `/api/client/groups/{group_id}/templates/{template_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary イメージファイルをダウンロードする
         * @param {string} path ファイルパス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientImageGet: async (path: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling apiClientImageGet.');
            }
            const localVarPath = `/api/client/image`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインにMFAコードを確認する
         * @summary ログインにMFAコードを確認する
         * @param {RequestsClientLoginMfaPost} [requestsClientLoginMfaPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientLoginMfaPost: async (requestsClientLoginMfaPost?: RequestsClientLoginMfaPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/login/mfa`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientLoginMfaPost !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientLoginMfaPost !== undefined ? requestsClientLoginMfaPost : {}) : (requestsClientLoginMfaPost || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アンケート回答者ログイン
         * @param {RequestsClientLogin} [requestsClientLogin] ログイン情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientLoginPost: async (requestsClientLogin?: RequestsClientLogin, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientLogin !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientLogin !== undefined ? requestsClientLogin : {}) : (requestsClientLogin || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アンケート回答者ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientLogoutGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/logout`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary パスワードを変更する
         * @param {RequestsClientPassword} [requestsClientPassword] 変更後パスワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientPasswordPut: async (requestsClientPassword?: RequestsClientPassword, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/password`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientPassword !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientPassword !== undefined ? requestsClientPassword : {}) : (requestsClientPassword || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メールアドレスを元にパスワード変更コードを確認
         * @param {RequestsClientRecoveryPasswordConfirm} [requestsClientRecoveryPasswordConfirm] パスワード変更用のメールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientRecoveryPasswordConfirmPost: async (requestsClientRecoveryPasswordConfirm?: RequestsClientRecoveryPasswordConfirm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/recovery/password/confirm`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientRecoveryPasswordConfirm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientRecoveryPasswordConfirm !== undefined ? requestsClientRecoveryPasswordConfirm : {}) : (requestsClientRecoveryPasswordConfirm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メールアドレスを元にパスワード変更のためのコードを送信する
         * @param {RequestsClientRecoveryPassword} [requestsClientRecoveryPassword] パスワード変更用のメールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientRecoveryPasswordPost: async (requestsClientRecoveryPassword?: RequestsClientRecoveryPassword, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/recovery/password`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientRecoveryPassword !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientRecoveryPassword !== undefined ? requestsClientRecoveryPassword : {}) : (requestsClientRecoveryPassword || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー登録の確認
         * @param {RequestsClientRegisterConfirm} [requestsClientRegisterConfirm] ユーザー登録の確認コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientRegisterConfirmPost: async (requestsClientRegisterConfirm?: RequestsClientRegisterConfirm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/register/confirm`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientRegisterConfirm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientRegisterConfirm !== undefined ? requestsClientRegisterConfirm : {}) : (requestsClientRegisterConfirm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー作成
         * @param {RequestsClientRegister} [requestsClientRegister] ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientRegisterPost: async (requestsClientRegister?: RequestsClientRegister, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/register`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientRegister !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientRegister !== undefined ? requestsClientRegister : {}) : (requestsClientRegister || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ログインしているユーザー情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientSelfGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/self`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー情報を更新する
         * @param {RequestsClientSelf} [requestsClientSelf] 更新するユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientSelfPut: async (requestsClientSelf?: RequestsClientSelf, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/self`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientSelf !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientSelf !== undefined ? requestsClientSelf : {}) : (requestsClientSelf || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーのMFAステータスの有効・無効設定
         * @summary ユーザーのMFAステータスの有効・無効設定
         * @param {RequestsClientUserMfaPost} [requestsClientUserMfaPost] MFAの有効・無効設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientUserMfaPost: async (requestsClientUserMfaPost?: RequestsClientUserMfaPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/user/mfa`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsClientUserMfaPost !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsClientUserMfaPost !== undefined ? requestsClientUserMfaPost : {}) : (requestsClientUserMfaPost || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary イメージファイルをダウンロードする
         * @param {string} path ファイルパス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentsImageGet: async (path: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling apiContentsImageGet.');
            }
            const localVarPath = `/api/contents/image`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * イメージファイルをダウンロードする
         * @summary イメージファイルをダウンロードする
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentsImagePathGet: async (path: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling apiContentsImagePathGet.');
            }
            const localVarPath = `/api/contents/image/{path}`
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 設定情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentsSettingGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contents/setting`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ヘルスチェック
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthcheckGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/healthcheck`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 権限が存在するかどうかを確認する
         * @summary 権限が存在するかどうかを確認する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerAuthorityGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager/authority`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary イメージファイル一覧を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerClientImagesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager/client/images`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary イメージファイルをアップロードする
         * @param {any} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerClientImagesPost: async (image?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager/client/images`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 設定情報一覧
         * @summary 設定情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerContentsSettingGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager/contents/setting`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メール設定情報一覧
         * @summary メール設定情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerContentsSettingMailGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager/contents/setting/mail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メール設定の更新
         * @summary メール設定更新
         * @param {RequestsManagerContentsSettingMailPut} [requestsManagerContentsSettingMailPut] メール設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerContentsSettingMailPut: async (requestsManagerContentsSettingMailPut?: RequestsManagerContentsSettingMailPut, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager/contents/setting/mail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsManagerContentsSettingMailPut !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsManagerContentsSettingMailPut !== undefined ? requestsManagerContentsSettingMailPut : {}) : (requestsManagerContentsSettingMailPut || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 設定の更新
         * @summary 設定更新
         * @param {RequestsManagerContentsSettingPut} [requestsManagerContentsSettingPut] 設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerContentsSettingPut: async (requestsManagerContentsSettingPut?: RequestsManagerContentsSettingPut, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager/contents/setting`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsManagerContentsSettingPut !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsManagerContentsSettingPut !== undefined ? requestsManagerContentsSettingPut : {}) : (requestsManagerContentsSettingPut || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * グループ一覧取得
         * @summary グループ一覧取得用
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager/groups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象のグループに対する回答一覧を取得する(カスタムIDごと)
         * @summary 対象のグループの対象回答を取得
         * @param {string} groupId グループID
         * @param {string} answerId 回答ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdAnswersAnswerIdGet: async (groupId: string, answerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiManagerGroupsGroupIdAnswersAnswerIdGet.');
            }
            // verify required parameter 'answerId' is not null or undefined
            if (answerId === null || answerId === undefined) {
                throw new RequiredError('answerId','Required parameter answerId was null or undefined when calling apiManagerGroupsGroupIdAnswersAnswerIdGet.');
            }
            const localVarPath = `/api/manager/groups/{group_id}/answers/{answer_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"answer_id"}}`, encodeURIComponent(String(answerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象のグループに対する回答一覧を取得する、またqueryパラメータにprogressがある場合はその進捗状況の一覧を取得。ない場合はCONFIRMED
         * @summary 対象のグループに対する回答一覧を取得
         * @param {string} groupId グループID
         * @param {AnswerProgress} [progress] 進捗状況
         * @param {string} [nextId] 次のデータID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdAnswersGet: async (groupId: string, progress?: AnswerProgress, nextId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiManagerGroupsGroupIdAnswersGet.');
            }
            const localVarPath = `/api/manager/groups/{group_id}/answers`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (progress !== undefined) {
                localVarQueryParameter['progress'] = progress;
            }

            if (nextId !== undefined) {
                localVarQueryParameter['next_id'] = nextId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象のグループを削除する
         * @summary 対象のグループ削除用
         * @param {string} groupId グループID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdDelete: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiManagerGroupsGroupIdDelete.');
            }
            const localVarPath = `/api/manager/groups/{group_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象のグループを更新する
         * @summary 対象のグループ更新用
         * @param {string} groupId グループID
         * @param {RequestsManagerGroupsGroupIdPut} [requestsManagerGroupsGroupIdPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdPut: async (groupId: string, requestsManagerGroupsGroupIdPut?: RequestsManagerGroupsGroupIdPut, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiManagerGroupsGroupIdPut.');
            }
            const localVarPath = `/api/manager/groups/{group_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsManagerGroupsGroupIdPut !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsManagerGroupsGroupIdPut !== undefined ? requestsManagerGroupsGroupIdPut : {}) : (requestsManagerGroupsGroupIdPut || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象グループのリリース一覧を取得する
         * @summary 対象のグループのリリース一覧を取得
         * @param {string} groupId グループID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdReleasesGet: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiManagerGroupsGroupIdReleasesGet.');
            }
            const localVarPath = `/api/manager/groups/{group_id}/releases`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象グループのリリース一覧を取得する
         * @summary 対象のグループをリリースする
         * @param {string} groupId グループID
         * @param {RequestsManagerGroupsGroupIdReleasesPost} [requestsManagerGroupsGroupIdReleasesPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdReleasesPost: async (groupId: string, requestsManagerGroupsGroupIdReleasesPost?: RequestsManagerGroupsGroupIdReleasesPost, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiManagerGroupsGroupIdReleasesPost.');
            }
            const localVarPath = `/api/manager/groups/{group_id}/releases`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsManagerGroupsGroupIdReleasesPost !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsManagerGroupsGroupIdReleasesPost !== undefined ? requestsManagerGroupsGroupIdReleasesPost : {}) : (requestsManagerGroupsGroupIdReleasesPost || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象のグループをテンプレート一覧を取得する
         * @summary 対象のグループのテンプレート一覧取得用
         * @param {string} groupId グループID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdTemplatesGet: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiManagerGroupsGroupIdTemplatesGet.');
            }
            const localVarPath = `/api/manager/groups/{group_id}/templates`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * テンプレートを作成する
         * @summary テンプレートを作成
         * @param {string} groupId グループID
         * @param {RequestsManagerGroupsGroupIdTemplatesPost} [requestsManagerGroupsGroupIdTemplatesPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdTemplatesPost: async (groupId: string, requestsManagerGroupsGroupIdTemplatesPost?: RequestsManagerGroupsGroupIdTemplatesPost, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiManagerGroupsGroupIdTemplatesPost.');
            }
            const localVarPath = `/api/manager/groups/{group_id}/templates`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsManagerGroupsGroupIdTemplatesPost !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsManagerGroupsGroupIdTemplatesPost !== undefined ? requestsManagerGroupsGroupIdTemplatesPost : {}) : (requestsManagerGroupsGroupIdTemplatesPost || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象のテンプレート削除
         * @summary 対象のテンプレートを削除
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdTemplatesTemplateIdDelete: async (groupId: string, templateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiManagerGroupsGroupIdTemplatesTemplateIdDelete.');
            }
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling apiManagerGroupsGroupIdTemplatesTemplateIdDelete.');
            }
            const localVarPath = `/api/manager/groups/{group_id}/templates/{template_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象のテンプレートを更新する
         * @summary 対象のテンプレート更新
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {RequestsManagerGroupsGroupIdTemplatesTemplateIdPut} [requestsManagerGroupsGroupIdTemplatesTemplateIdPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdTemplatesTemplateIdPut: async (groupId: string, templateId: string, requestsManagerGroupsGroupIdTemplatesTemplateIdPut?: RequestsManagerGroupsGroupIdTemplatesTemplateIdPut, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiManagerGroupsGroupIdTemplatesTemplateIdPut.');
            }
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling apiManagerGroupsGroupIdTemplatesTemplateIdPut.');
            }
            const localVarPath = `/api/manager/groups/{group_id}/templates/{template_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsManagerGroupsGroupIdTemplatesTemplateIdPut !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsManagerGroupsGroupIdTemplatesTemplateIdPut !== undefined ? requestsManagerGroupsGroupIdTemplatesTemplateIdPut : {}) : (requestsManagerGroupsGroupIdTemplatesTemplateIdPut || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * グループの作成を行う
         * @summary グループ作成用
         * @param {RequestsManagerGroupsPost} [requestsManagerGroupsPost] グループ情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsPost: async (requestsManagerGroupsPost?: RequestsManagerGroupsPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager/groups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsManagerGroupsPost !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsManagerGroupsPost !== undefined ? requestsManagerGroupsPost : {}) : (requestsManagerGroupsPost || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインにMFAコードを確認する
         * @summary ログインにMFAコードを確認する
         * @param {RequestsManagerLoginMfaPost} [requestsManagerLoginMfaPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerLoginMfaPost: async (requestsManagerLoginMfaPost?: RequestsManagerLoginMfaPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager/login/mfa`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsManagerLoginMfaPost !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsManagerLoginMfaPost !== undefined ? requestsManagerLoginMfaPost : {}) : (requestsManagerLoginMfaPost || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 企業サーバーログイン
         * @param {RequestsManagerLogin} [requestsManagerLogin] ログイン情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerLoginPost: async (requestsManagerLogin?: RequestsManagerLogin, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsManagerLogin !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsManagerLogin !== undefined ? requestsManagerLogin : {}) : (requestsManagerLogin || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 企業サーバーからログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerLogoutGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager/logout`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象リリースの削除を行う
         * @summary 対象リリースの削除
         * @param {string} releaseId リリースID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReleasesReleaseIdDelete: async (releaseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            if (releaseId === null || releaseId === undefined) {
                throw new RequiredError('releaseId','Required parameter releaseId was null or undefined when calling apiManagerReleasesReleaseIdDelete.');
            }
            const localVarPath = `/api/manager/releases/{release_id}`
                .replace(`{${"release_id"}}`, encodeURIComponent(String(releaseId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象リリースの更新を行う
         * @summary 対象リリースの更新
         * @param {string} releaseId リリースID
         * @param {RequestsManagerReleasesReleaseIdPut} [requestsManagerReleasesReleaseIdPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReleasesReleaseIdPut: async (releaseId: string, requestsManagerReleasesReleaseIdPut?: RequestsManagerReleasesReleaseIdPut, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            if (releaseId === null || releaseId === undefined) {
                throw new RequiredError('releaseId','Required parameter releaseId was null or undefined when calling apiManagerReleasesReleaseIdPut.');
            }
            const localVarPath = `/api/manager/releases/{release_id}`
                .replace(`{${"release_id"}}`, encodeURIComponent(String(releaseId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsManagerReleasesReleaseIdPut !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsManagerReleasesReleaseIdPut !== undefined ? requestsManagerReleasesReleaseIdPut : {}) : (requestsManagerReleasesReleaseIdPut || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ファイルをアップロードする
         * @summary ファイルをアップロード
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerUploadPost: async (inlineObject1?: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager/upload`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof inlineObject1 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject1 !== undefined ? inlineObject1 : {}) : (inlineObject1 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理ユーザー一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerUsersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーを作成する
         * @param {RequestsManagerUsersPost} [requestsManagerUsersPost] ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerUsersPost: async (requestsManagerUsersPost?: RequestsManagerUsersPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsManagerUsersPost !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsManagerUsersPost !== undefined ? requestsManagerUsersPost : {}) : (requestsManagerUsersPost || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象のユーザーを削除する
         * @summary 対象のユーザーを削除
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerUsersUserIdDelete: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling apiManagerUsersUserIdDelete.');
            }
            const localVarPath = `/api/manager/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メールを送信する
         * @summary メールを送信する
         * @param {string} userId ユーザーID
         * @param {RequestsManagerUsersUserIdMailPost} [requestsManagerUsersUserIdMailPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerUsersUserIdMailPost: async (userId: string, requestsManagerUsersUserIdMailPost?: RequestsManagerUsersUserIdMailPost, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling apiManagerUsersUserIdMailPost.');
            }
            const localVarPath = `/api/manager/users/{user_id}/mail`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsManagerUsersUserIdMailPost !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsManagerUsersUserIdMailPost !== undefined ? requestsManagerUsersUserIdMailPost : {}) : (requestsManagerUsersUserIdMailPost || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象のユーザーのパスワードを変更する
         * @summary 対象のユーザーのパスワード変更
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerUsersUserIdPasswordPatch: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling apiManagerUsersUserIdPasswordPatch.');
            }
            const localVarPath = `/api/manager/users/{user_id}/password`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary サンプルIDとキットIDが正しいかを判定する
         * @param {string} sampleId サンプルID
         * @param {string} kitId キットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientCheckSampleIdKitIdGet: async (sampleId: string, kitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleId' is not null or undefined
            if (sampleId === null || sampleId === undefined) {
                throw new RequiredError('sampleId','Required parameter sampleId was null or undefined when calling customApiClientCheckSampleIdKitIdGet.');
            }
            // verify required parameter 'kitId' is not null or undefined
            if (kitId === null || kitId === undefined) {
                throw new RequiredError('kitId','Required parameter kitId was null or undefined when calling customApiClientCheckSampleIdKitIdGet.');
            }
            const localVarPath = `/custom/api/client/check/{sample_id}/{kit_id}`
                .replace(`{${"sample_id"}}`, encodeURIComponent(String(sampleId)))
                .replace(`{${"kit_id"}}`, encodeURIComponent(String(kitId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary サンプルIDに対する回答がされているかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientConfirmedMysampleidGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom/api/client/confirmed/mysampleid`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 全ての回答を確定する
         * @param {string} groupId グループID
         * @param {RequestsCustomApiClientGroupsGroupIdAnswersConfirm} [requestsCustomApiClientGroupsGroupIdAnswersConfirm] 全ての回答を確定する（一時保存は削除する）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientGroupsGroupIdAnswersConfirmPost: async (groupId: string, requestsCustomApiClientGroupsGroupIdAnswersConfirm?: RequestsCustomApiClientGroupsGroupIdAnswersConfirm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling customApiClientGroupsGroupIdAnswersConfirmPost.');
            }
            const localVarPath = `/custom/api/client/groups/{group_id}/answers/confirm`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsCustomApiClientGroupsGroupIdAnswersConfirm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsCustomApiClientGroupsGroupIdAnswersConfirm !== undefined ? requestsCustomApiClientGroupsGroupIdAnswersConfirm : {}) : (requestsCustomApiClientGroupsGroupIdAnswersConfirm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 自身の配下にいるメンバー一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientMembersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom/api/client/members`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メンバー情報の更新
         * @param {string} memberId 被検者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientMembersMemberIdDelete: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling customApiClientMembersMemberIdDelete.');
            }
            const localVarPath = `/custom/api/client/members/{member_id}`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メンバーIDの詳細情報
         * @param {string} memberId 被検者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientMembersMemberIdGet: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling customApiClientMembersMemberIdGet.');
            }
            const localVarPath = `/custom/api/client/members/{member_id}`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メンバー情報の更新
         * @param {string} memberId 被検者ID
         * @param {RequestsCustomApiClientMembersMemberId} [requestsCustomApiClientMembersMemberId] 更新するメンバー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientMembersMemberIdPut: async (memberId: string, requestsCustomApiClientMembersMemberId?: RequestsCustomApiClientMembersMemberId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling customApiClientMembersMemberIdPut.');
            }
            const localVarPath = `/custom/api/client/members/{member_id}`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsCustomApiClientMembersMemberId !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsCustomApiClientMembersMemberId !== undefined ? requestsCustomApiClientMembersMemberId : {}) : (requestsCustomApiClientMembersMemberId || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary サンプルIDを追加する
         * @param {string} memberId 被検者ID
         * @param {RequestsCustomApiClientMembersMemberIdSampleIds} [requestsCustomApiClientMembersMemberIdSampleIds] メンバーにサンプルIDを追加する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientMembersMemberIdSampleidsPost: async (memberId: string, requestsCustomApiClientMembersMemberIdSampleIds?: RequestsCustomApiClientMembersMemberIdSampleIds, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling customApiClientMembersMemberIdSampleidsPost.');
            }
            const localVarPath = `/custom/api/client/members/{member_id}/sampleids`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsCustomApiClientMembersMemberIdSampleIds !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsCustomApiClientMembersMemberIdSampleIds !== undefined ? requestsCustomApiClientMembersMemberIdSampleIds : {}) : (requestsCustomApiClientMembersMemberIdSampleIds || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メンバーを選択
         * @param {string} memberId 被検者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientMembersMemberIdSelectionPost: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling customApiClientMembersMemberIdSelectionPost.');
            }
            const localVarPath = `/custom/api/client/members/{member_id}/selection`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 自身の配下にメンバーを追加する
         * @param {RequestsCustomApiClientMembers} [requestsCustomApiClientMembers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientMembersPost: async (requestsCustomApiClientMembers?: RequestsCustomApiClientMembers, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom/api/client/members`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsCustomApiClientMembers !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsCustomApiClientMembers !== undefined ? requestsCustomApiClientMembers : {}) : (requestsCustomApiClientMembers || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 登録
         * @param {RequestsCustomApiClientRegister} [requestsCustomApiClientRegister] 登録情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientRegisterPost: async (requestsCustomApiClientRegister?: RequestsCustomApiClientRegister, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom/api/client/register`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsCustomApiClientRegister !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsCustomApiClientRegister !== undefined ? requestsCustomApiClientRegister : {}) : (requestsCustomApiClientRegister || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 選択中のメンバー情報を削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientSelectedMemberDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom/api/client/selected/member`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 選択中のメンバーを取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientSelectedMemberGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom/api/client/selected/member`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 自身の詳細情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientSelfGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom/api/client/self`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 自身の詳細情報を更新
         * @param {RequestsCustomApiClientSelf} [requestsCustomApiClientSelf] 更新する情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientSelfPut: async (requestsCustomApiClientSelf?: RequestsCustomApiClientSelf, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom/api/client/self`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestsCustomApiClientSelf !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestsCustomApiClientSelf !== undefined ? requestsCustomApiClientSelf : {}) : (requestsCustomApiClientSelf || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メンバー,サンプルIDおよび回答状況情報一覧を取得
         * @summary メンバー,サンプルIDおよび回答状況情報一覧を取得
         * @param {string} [nextId] 次のデータID
         * @param {string} [sampleId] サンプルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiManagerMembersAnswersGet: async (nextId?: string, sampleId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom/api/manager/members/answers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nextId !== undefined) {
                localVarQueryParameter['next_id'] = nextId;
            }

            if (sampleId !== undefined) {
                localVarQueryParameter['sample_id'] = sampleId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 被検者情報を取得する
         * @summary 被検者情報を取得する
         * @param {string} memberId 被検者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiManagerMembersMemberIdGet: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling customApiManagerMembersMemberIdGet.');
            }
            const localVarPath = `/custom/api/manager/members/{member_id}`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 回答をコピーする
         * @param {RequestsClientAnswersCopy} [requestsClientAnswersCopy] 回答をコピーする
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientAnswersCopyPost(requestsClientAnswersCopy?: RequestsClientAnswersCopy, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientAnswersCopy>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientAnswersCopyPost(requestsClientAnswersCopy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary カスタムIDをセットする
         * @param {RequestsClientCustomIdPost} [requestsClientCustomIdPost] カスタムID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientCustomidPost(requestsClientCustomIdPost?: RequestsClientCustomIdPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientCustomIdPost>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientCustomidPost(requestsClientCustomIdPost, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary メールアドレス変更時の確認コード
         * @param {RequestsClientEmailConfirm} [requestsClientEmailConfirm] 変更メールアドレス確認コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientEmailConfirmPost(requestsClientEmailConfirm?: RequestsClientEmailConfirm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientEmailConfirm>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientEmailConfirmPost(requestsClientEmailConfirm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary メールアドレスを変更する
         * @param {RequestsClientEmail} [requestsClientEmail] 変更後メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientEmailPut(requestsClientEmail?: RequestsClientEmail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientEmail>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientEmailPut(requestsClientEmail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ユーザーが回答可能なアンケートのグループ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientGroupsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientGroups>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientGroupsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 全ての回答を確定する
         * @param {string} groupId グループID
         * @param {RequestsClientGroupsGroupIdAnswersConfirm} [requestsClientGroupsGroupIdAnswersConfirm] 全ての回答を確定する（一時保存は削除する）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientGroupsGroupIdAnswersConfirmPost(groupId: string, requestsClientGroupsGroupIdAnswersConfirm?: RequestsClientGroupsGroupIdAnswersConfirm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientGroupsGroupIdAnswersConfirm>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientGroupsGroupIdAnswersConfirmPost(groupId, requestsClientGroupsGroupIdAnswersConfirm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary グループ内の全てのテンプレートに対する回答一覧を取得する
         * @param {string} groupId グループID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientGroupsGroupIdAnswersGet(groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientGroupsGroupIdAnswers>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientGroupsGroupIdAnswersGet(groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary テンプレートに指定した回答を取得する
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {string} answerId 回答ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdGet(groupId: string, templateId: string, answerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdGet(groupId, templateId, answerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary テンプレートに指定したIDに対して回答を作成する
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {string} answerId 回答ID
         * @param {RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId} [requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId] テンプレートに対する回答情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPost(groupId: string, templateId: string, answerId: string, requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId?: RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPost(groupId, templateId, answerId, requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary テンプレートに指定したIDに対して回答を上書き更新・新規作成する
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {string} answerId 回答ID
         * @param {RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId} [requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId] テンプレートに対する回答情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPut(groupId: string, templateId: string, answerId: string, requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId?: RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPut(groupId, templateId, answerId, requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 指定のテンプレート情報を取得する
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientGroupsGroupIdTemplatesTemplateIdGet(groupId: string, templateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientGroupsGroupIdTemplatesTemplateId>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientGroupsGroupIdTemplatesTemplateIdGet(groupId, templateId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary イメージファイルをダウンロードする
         * @param {string} path ファイルパス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientImageGet(path: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientImageGet(path, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ログインにMFAコードを確認する
         * @summary ログインにMFAコードを確認する
         * @param {RequestsClientLoginMfaPost} [requestsClientLoginMfaPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientLoginMfaPost(requestsClientLoginMfaPost?: RequestsClientLoginMfaPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientLoginMfaPost>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientLoginMfaPost(requestsClientLoginMfaPost, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アンケート回答者ログイン
         * @param {RequestsClientLogin} [requestsClientLogin] ログイン情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientLoginPost(requestsClientLogin?: RequestsClientLogin, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientLogin>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientLoginPost(requestsClientLogin, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アンケート回答者ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientLogoutGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientLogoutGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary パスワードを変更する
         * @param {RequestsClientPassword} [requestsClientPassword] 変更後パスワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientPasswordPut(requestsClientPassword?: RequestsClientPassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientPassword>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientPasswordPut(requestsClientPassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary メールアドレスを元にパスワード変更コードを確認
         * @param {RequestsClientRecoveryPasswordConfirm} [requestsClientRecoveryPasswordConfirm] パスワード変更用のメールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientRecoveryPasswordConfirmPost(requestsClientRecoveryPasswordConfirm?: RequestsClientRecoveryPasswordConfirm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientRecoveryPasswordConfirm>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientRecoveryPasswordConfirmPost(requestsClientRecoveryPasswordConfirm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary メールアドレスを元にパスワード変更のためのコードを送信する
         * @param {RequestsClientRecoveryPassword} [requestsClientRecoveryPassword] パスワード変更用のメールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientRecoveryPasswordPost(requestsClientRecoveryPassword?: RequestsClientRecoveryPassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientRecoveryPassword>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientRecoveryPasswordPost(requestsClientRecoveryPassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ユーザー登録の確認
         * @param {RequestsClientRegisterConfirm} [requestsClientRegisterConfirm] ユーザー登録の確認コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientRegisterConfirmPost(requestsClientRegisterConfirm?: RequestsClientRegisterConfirm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientRegisterConfirm>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientRegisterConfirmPost(requestsClientRegisterConfirm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ユーザー作成
         * @param {RequestsClientRegister} [requestsClientRegister] ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientRegisterPost(requestsClientRegister?: RequestsClientRegister, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientRegister>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientRegisterPost(requestsClientRegister, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ログインしているユーザー情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientSelfGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientSelf>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientSelfGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ユーザー情報を更新する
         * @param {RequestsClientSelf} [requestsClientSelf] 更新するユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientSelfPut(requestsClientSelf?: RequestsClientSelf, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOk>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientSelfPut(requestsClientSelf, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ユーザーのMFAステータスの有効・無効設定
         * @summary ユーザーのMFAステータスの有効・無効設定
         * @param {RequestsClientUserMfaPost} [requestsClientUserMfaPost] MFAの有効・無効設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientUserMfaPost(requestsClientUserMfaPost?: RequestsClientUserMfaPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsClientUserMfaPost>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiClientUserMfaPost(requestsClientUserMfaPost, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary イメージファイルをダウンロードする
         * @param {string} path ファイルパス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentsImageGet(path: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiContentsImageGet(path, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * イメージファイルをダウンロードする
         * @summary イメージファイルをダウンロードする
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentsImagePathGet(path: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiContentsImagePathGet(path, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 設定情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentsSettingGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsContentsSettingGet>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiContentsSettingGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ヘルスチェック
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHealthcheckGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsHealthcheck>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiHealthcheckGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 権限が存在するかどうかを確認する
         * @summary 権限が存在するかどうかを確認する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerAuthorityGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerAuthorityGet>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerAuthorityGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary イメージファイル一覧を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerClientImagesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResultsManagerClientImage>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerClientImagesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary イメージファイルをアップロードする
         * @param {any} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerClientImagesPost(image?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerClientImage>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerClientImagesPost(image, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 設定情報一覧
         * @summary 設定情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerContentsSettingGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerContentsSettingGet>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerContentsSettingGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * メール設定情報一覧
         * @summary メール設定情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerContentsSettingMailGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerContentsSettingMailGet>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerContentsSettingMailGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * メール設定の更新
         * @summary メール設定更新
         * @param {RequestsManagerContentsSettingMailPut} [requestsManagerContentsSettingMailPut] メール設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerContentsSettingMailPut(requestsManagerContentsSettingMailPut?: RequestsManagerContentsSettingMailPut, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerContentsSettingPut>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerContentsSettingMailPut(requestsManagerContentsSettingMailPut, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 設定の更新
         * @summary 設定更新
         * @param {RequestsManagerContentsSettingPut} [requestsManagerContentsSettingPut] 設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerContentsSettingPut(requestsManagerContentsSettingPut?: RequestsManagerContentsSettingPut, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerContentsSettingPut>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerContentsSettingPut(requestsManagerContentsSettingPut, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * グループ一覧取得
         * @summary グループ一覧取得用
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerGroupsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerGroups>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerGroupsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 対象のグループに対する回答一覧を取得する(カスタムIDごと)
         * @summary 対象のグループの対象回答を取得
         * @param {string} groupId グループID
         * @param {string} answerId 回答ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerGroupsGroupIdAnswersAnswerIdGet(groupId: string, answerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerGroupsGroupIdAnswersGet>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerGroupsGroupIdAnswersAnswerIdGet(groupId, answerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 対象のグループに対する回答一覧を取得する、またqueryパラメータにprogressがある場合はその進捗状況の一覧を取得。ない場合はCONFIRMED
         * @summary 対象のグループに対する回答一覧を取得
         * @param {string} groupId グループID
         * @param {AnswerProgress} [progress] 進捗状況
         * @param {string} [nextId] 次のデータID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerGroupsGroupIdAnswersGet(groupId: string, progress?: AnswerProgress, nextId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerGroupsGroupIdAnswersGet>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerGroupsGroupIdAnswersGet(groupId, progress, nextId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 対象のグループを削除する
         * @summary 対象のグループ削除用
         * @param {string} groupId グループID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerGroupsGroupIdDelete(groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerGroupsGroupIdDelete>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerGroupsGroupIdDelete(groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 対象のグループを更新する
         * @summary 対象のグループ更新用
         * @param {string} groupId グループID
         * @param {RequestsManagerGroupsGroupIdPut} [requestsManagerGroupsGroupIdPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerGroupsGroupIdPut(groupId: string, requestsManagerGroupsGroupIdPut?: RequestsManagerGroupsGroupIdPut, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerGroupsGroupIdPut>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerGroupsGroupIdPut(groupId, requestsManagerGroupsGroupIdPut, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 対象グループのリリース一覧を取得する
         * @summary 対象のグループのリリース一覧を取得
         * @param {string} groupId グループID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerGroupsGroupIdReleasesGet(groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerGroupsGroupIdReleasesGet>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerGroupsGroupIdReleasesGet(groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 対象グループのリリース一覧を取得する
         * @summary 対象のグループをリリースする
         * @param {string} groupId グループID
         * @param {RequestsManagerGroupsGroupIdReleasesPost} [requestsManagerGroupsGroupIdReleasesPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerGroupsGroupIdReleasesPost(groupId: string, requestsManagerGroupsGroupIdReleasesPost?: RequestsManagerGroupsGroupIdReleasesPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerGroupsGroupIdReleasesPost>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerGroupsGroupIdReleasesPost(groupId, requestsManagerGroupsGroupIdReleasesPost, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 対象のグループをテンプレート一覧を取得する
         * @summary 対象のグループのテンプレート一覧取得用
         * @param {string} groupId グループID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerGroupsGroupIdTemplatesGet(groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerGroupsGroupIdTemplatesGet>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerGroupsGroupIdTemplatesGet(groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * テンプレートを作成する
         * @summary テンプレートを作成
         * @param {string} groupId グループID
         * @param {RequestsManagerGroupsGroupIdTemplatesPost} [requestsManagerGroupsGroupIdTemplatesPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerGroupsGroupIdTemplatesPost(groupId: string, requestsManagerGroupsGroupIdTemplatesPost?: RequestsManagerGroupsGroupIdTemplatesPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerGroupsGroupIdTemplatesPost>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerGroupsGroupIdTemplatesPost(groupId, requestsManagerGroupsGroupIdTemplatesPost, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 対象のテンプレート削除
         * @summary 対象のテンプレートを削除
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerGroupsGroupIdTemplatesTemplateIdDelete(groupId: string, templateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerGroupsGroupIdTemplatesTemplateIdDelete>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerGroupsGroupIdTemplatesTemplateIdDelete(groupId, templateId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 対象のテンプレートを更新する
         * @summary 対象のテンプレート更新
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {RequestsManagerGroupsGroupIdTemplatesTemplateIdPut} [requestsManagerGroupsGroupIdTemplatesTemplateIdPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerGroupsGroupIdTemplatesTemplateIdPut(groupId: string, templateId: string, requestsManagerGroupsGroupIdTemplatesTemplateIdPut?: RequestsManagerGroupsGroupIdTemplatesTemplateIdPut, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerGroupsGroupIdTemplatesTemplateIdPut>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerGroupsGroupIdTemplatesTemplateIdPut(groupId, templateId, requestsManagerGroupsGroupIdTemplatesTemplateIdPut, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * グループの作成を行う
         * @summary グループ作成用
         * @param {RequestsManagerGroupsPost} [requestsManagerGroupsPost] グループ情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerGroupsPost(requestsManagerGroupsPost?: RequestsManagerGroupsPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerGroupsPost>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerGroupsPost(requestsManagerGroupsPost, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ログインにMFAコードを確認する
         * @summary ログインにMFAコードを確認する
         * @param {RequestsManagerLoginMfaPost} [requestsManagerLoginMfaPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerLoginMfaPost(requestsManagerLoginMfaPost?: RequestsManagerLoginMfaPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerLoginMfaPost>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerLoginMfaPost(requestsManagerLoginMfaPost, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 企業サーバーログイン
         * @param {RequestsManagerLogin} [requestsManagerLogin] ログイン情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerLoginPost(requestsManagerLogin?: RequestsManagerLogin, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerLogin>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerLoginPost(requestsManagerLogin, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 企業サーバーからログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerLogoutGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsLogout>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerLogoutGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 対象リリースの削除を行う
         * @summary 対象リリースの削除
         * @param {string} releaseId リリースID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerReleasesReleaseIdDelete(releaseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerReleasesReleaseIdDelete>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerReleasesReleaseIdDelete(releaseId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 対象リリースの更新を行う
         * @summary 対象リリースの更新
         * @param {string} releaseId リリースID
         * @param {RequestsManagerReleasesReleaseIdPut} [requestsManagerReleasesReleaseIdPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerReleasesReleaseIdPut(releaseId: string, requestsManagerReleasesReleaseIdPut?: RequestsManagerReleasesReleaseIdPut, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerReleasesReleaseIdPut>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerReleasesReleaseIdPut(releaseId, requestsManagerReleasesReleaseIdPut, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ファイルをアップロードする
         * @summary ファイルをアップロード
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerUploadPost(inlineObject1?: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerUploadPost(inlineObject1, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 管理ユーザー一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerUsersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerUsersGet>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerUsersGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ユーザーを作成する
         * @param {RequestsManagerUsersPost} [requestsManagerUsersPost] ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerUsersPost(requestsManagerUsersPost?: RequestsManagerUsersPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerUsersPost>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerUsersPost(requestsManagerUsersPost, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 対象のユーザーを削除する
         * @summary 対象のユーザーを削除
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerUsersUserIdDelete(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Links & ResultOk>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerUsersUserIdDelete(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * メールを送信する
         * @summary メールを送信する
         * @param {string} userId ユーザーID
         * @param {RequestsManagerUsersUserIdMailPost} [requestsManagerUsersUserIdMailPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerUsersUserIdMailPost(userId: string, requestsManagerUsersUserIdMailPost?: RequestsManagerUsersUserIdMailPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsManagerUsersUserIdMailPost>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerUsersUserIdMailPost(userId, requestsManagerUsersUserIdMailPost, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 対象のユーザーのパスワードを変更する
         * @summary 対象のユーザーのパスワード変更
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerUsersUserIdPasswordPatch(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Links & ResultOk>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiManagerUsersUserIdPasswordPatch(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary サンプルIDとキットIDが正しいかを判定する
         * @param {string} sampleId サンプルID
         * @param {string} kitId キットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiClientCheckSampleIdKitIdGet(sampleId: string, kitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiClientCheckSampleIdKitId>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiClientCheckSampleIdKitIdGet(sampleId, kitId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary サンプルIDに対する回答がされているかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiClientConfirmedMysampleidGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiClientConfirmedMysampleid>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiClientConfirmedMysampleidGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 全ての回答を確定する
         * @param {string} groupId グループID
         * @param {RequestsCustomApiClientGroupsGroupIdAnswersConfirm} [requestsCustomApiClientGroupsGroupIdAnswersConfirm] 全ての回答を確定する（一時保存は削除する）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiClientGroupsGroupIdAnswersConfirmPost(groupId: string, requestsCustomApiClientGroupsGroupIdAnswersConfirm?: RequestsCustomApiClientGroupsGroupIdAnswersConfirm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiClientGroupsGroupIdAnswersConfirm>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiClientGroupsGroupIdAnswersConfirmPost(groupId, requestsCustomApiClientGroupsGroupIdAnswersConfirm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 自身の配下にいるメンバー一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiClientMembersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiClientMembers>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiClientMembersGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary メンバー情報の更新
         * @param {string} memberId 被検者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiClientMembersMemberIdDelete(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiClientMembersMemberIdDelete>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiClientMembersMemberIdDelete(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary メンバーIDの詳細情報
         * @param {string} memberId 被検者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiClientMembersMemberIdGet(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiClientMembersMemberId>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiClientMembersMemberIdGet(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary メンバー情報の更新
         * @param {string} memberId 被検者ID
         * @param {RequestsCustomApiClientMembersMemberId} [requestsCustomApiClientMembersMemberId] 更新するメンバー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiClientMembersMemberIdPut(memberId: string, requestsCustomApiClientMembersMemberId?: RequestsCustomApiClientMembersMemberId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiClientMembersMemberIdPut>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiClientMembersMemberIdPut(memberId, requestsCustomApiClientMembersMemberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary サンプルIDを追加する
         * @param {string} memberId 被検者ID
         * @param {RequestsCustomApiClientMembersMemberIdSampleIds} [requestsCustomApiClientMembersMemberIdSampleIds] メンバーにサンプルIDを追加する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiClientMembersMemberIdSampleidsPost(memberId: string, requestsCustomApiClientMembersMemberIdSampleIds?: RequestsCustomApiClientMembersMemberIdSampleIds, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiClientMembersMemberIdSampleIds>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiClientMembersMemberIdSampleidsPost(memberId, requestsCustomApiClientMembersMemberIdSampleIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary メンバーを選択
         * @param {string} memberId 被検者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiClientMembersMemberIdSelectionPost(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiClientMembersMemberIdSelection>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiClientMembersMemberIdSelectionPost(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 自身の配下にメンバーを追加する
         * @param {RequestsCustomApiClientMembers} [requestsCustomApiClientMembers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiClientMembersPost(requestsCustomApiClientMembers?: RequestsCustomApiClientMembers, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiClientMembersMember>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiClientMembersPost(requestsCustomApiClientMembers, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 登録
         * @param {RequestsCustomApiClientRegister} [requestsCustomApiClientRegister] 登録情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiClientRegisterPost(requestsCustomApiClientRegister?: RequestsCustomApiClientRegister, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiClientRegister>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiClientRegisterPost(requestsCustomApiClientRegister, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 選択中のメンバー情報を削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiClientSelectedMemberDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiClientSelectedMemberDelete>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiClientSelectedMemberDelete(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 選択中のメンバーを取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiClientSelectedMemberGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiClientSelectedMember>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiClientSelectedMemberGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 自身の詳細情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiClientSelfGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiClientSelf>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiClientSelfGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 自身の詳細情報を更新
         * @param {RequestsCustomApiClientSelf} [requestsCustomApiClientSelf] 更新する情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiClientSelfPut(requestsCustomApiClientSelf?: RequestsCustomApiClientSelf, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiClientSelfPut>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiClientSelfPut(requestsCustomApiClientSelf, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * メンバー,サンプルIDおよび回答状況情報一覧を取得
         * @summary メンバー,サンプルIDおよび回答状況情報一覧を取得
         * @param {string} [nextId] 次のデータID
         * @param {string} [sampleId] サンプルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiManagerMembersAnswersGet(nextId?: string, sampleId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiManagerMembersAnswersGet>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiManagerMembersAnswersGet(nextId, sampleId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 被検者情報を取得する
         * @summary 被検者情報を取得する
         * @param {string} memberId 被検者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customApiManagerMembersMemberIdGet(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsCustomApiManagerMembersMemberIdGet>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).customApiManagerMembersMemberIdGet(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 回答をコピーする
         * @param {RequestsClientAnswersCopy} [requestsClientAnswersCopy] 回答をコピーする
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientAnswersCopyPost(requestsClientAnswersCopy?: RequestsClientAnswersCopy, options?: any): AxiosPromise<ResultsClientAnswersCopy> {
            return DefaultApiFp(configuration).apiClientAnswersCopyPost(requestsClientAnswersCopy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary カスタムIDをセットする
         * @param {RequestsClientCustomIdPost} [requestsClientCustomIdPost] カスタムID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientCustomidPost(requestsClientCustomIdPost?: RequestsClientCustomIdPost, options?: any): AxiosPromise<ResultsClientCustomIdPost> {
            return DefaultApiFp(configuration).apiClientCustomidPost(requestsClientCustomIdPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メールアドレス変更時の確認コード
         * @param {RequestsClientEmailConfirm} [requestsClientEmailConfirm] 変更メールアドレス確認コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientEmailConfirmPost(requestsClientEmailConfirm?: RequestsClientEmailConfirm, options?: any): AxiosPromise<ResultsClientEmailConfirm> {
            return DefaultApiFp(configuration).apiClientEmailConfirmPost(requestsClientEmailConfirm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メールアドレスを変更する
         * @param {RequestsClientEmail} [requestsClientEmail] 変更後メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientEmailPut(requestsClientEmail?: RequestsClientEmail, options?: any): AxiosPromise<ResultsClientEmail> {
            return DefaultApiFp(configuration).apiClientEmailPut(requestsClientEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーが回答可能なアンケートのグループ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGroupsGet(options?: any): AxiosPromise<ResultsClientGroups> {
            return DefaultApiFp(configuration).apiClientGroupsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 全ての回答を確定する
         * @param {string} groupId グループID
         * @param {RequestsClientGroupsGroupIdAnswersConfirm} [requestsClientGroupsGroupIdAnswersConfirm] 全ての回答を確定する（一時保存は削除する）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGroupsGroupIdAnswersConfirmPost(groupId: string, requestsClientGroupsGroupIdAnswersConfirm?: RequestsClientGroupsGroupIdAnswersConfirm, options?: any): AxiosPromise<ResultsClientGroupsGroupIdAnswersConfirm> {
            return DefaultApiFp(configuration).apiClientGroupsGroupIdAnswersConfirmPost(groupId, requestsClientGroupsGroupIdAnswersConfirm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary グループ内の全てのテンプレートに対する回答一覧を取得する
         * @param {string} groupId グループID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGroupsGroupIdAnswersGet(groupId: string, options?: any): AxiosPromise<ResultsClientGroupsGroupIdAnswers> {
            return DefaultApiFp(configuration).apiClientGroupsGroupIdAnswersGet(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary テンプレートに指定した回答を取得する
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {string} answerId 回答ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdGet(groupId: string, templateId: string, answerId: string, options?: any): AxiosPromise<ResultsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId> {
            return DefaultApiFp(configuration).apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdGet(groupId, templateId, answerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary テンプレートに指定したIDに対して回答を作成する
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {string} answerId 回答ID
         * @param {RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId} [requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId] テンプレートに対する回答情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPost(groupId: string, templateId: string, answerId: string, requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId?: RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId, options?: any): AxiosPromise<ResultsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId> {
            return DefaultApiFp(configuration).apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPost(groupId, templateId, answerId, requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary テンプレートに指定したIDに対して回答を上書き更新・新規作成する
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {string} answerId 回答ID
         * @param {RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId} [requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId] テンプレートに対する回答情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPut(groupId: string, templateId: string, answerId: string, requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId?: RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId, options?: any): AxiosPromise<ResultsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId> {
            return DefaultApiFp(configuration).apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPut(groupId, templateId, answerId, requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定のテンプレート情報を取得する
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGroupsGroupIdTemplatesTemplateIdGet(groupId: string, templateId: string, options?: any): AxiosPromise<ResultsClientGroupsGroupIdTemplatesTemplateId> {
            return DefaultApiFp(configuration).apiClientGroupsGroupIdTemplatesTemplateIdGet(groupId, templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary イメージファイルをダウンロードする
         * @param {string} path ファイルパス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientImageGet(path: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).apiClientImageGet(path, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインにMFAコードを確認する
         * @summary ログインにMFAコードを確認する
         * @param {RequestsClientLoginMfaPost} [requestsClientLoginMfaPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientLoginMfaPost(requestsClientLoginMfaPost?: RequestsClientLoginMfaPost, options?: any): AxiosPromise<ResultsClientLoginMfaPost> {
            return DefaultApiFp(configuration).apiClientLoginMfaPost(requestsClientLoginMfaPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary アンケート回答者ログイン
         * @param {RequestsClientLogin} [requestsClientLogin] ログイン情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientLoginPost(requestsClientLogin?: RequestsClientLogin, options?: any): AxiosPromise<ResultsClientLogin> {
            return DefaultApiFp(configuration).apiClientLoginPost(requestsClientLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary アンケート回答者ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientLogoutGet(options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).apiClientLogoutGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary パスワードを変更する
         * @param {RequestsClientPassword} [requestsClientPassword] 変更後パスワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientPasswordPut(requestsClientPassword?: RequestsClientPassword, options?: any): AxiosPromise<ResultsClientPassword> {
            return DefaultApiFp(configuration).apiClientPasswordPut(requestsClientPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メールアドレスを元にパスワード変更コードを確認
         * @param {RequestsClientRecoveryPasswordConfirm} [requestsClientRecoveryPasswordConfirm] パスワード変更用のメールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientRecoveryPasswordConfirmPost(requestsClientRecoveryPasswordConfirm?: RequestsClientRecoveryPasswordConfirm, options?: any): AxiosPromise<ResultsClientRecoveryPasswordConfirm> {
            return DefaultApiFp(configuration).apiClientRecoveryPasswordConfirmPost(requestsClientRecoveryPasswordConfirm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メールアドレスを元にパスワード変更のためのコードを送信する
         * @param {RequestsClientRecoveryPassword} [requestsClientRecoveryPassword] パスワード変更用のメールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientRecoveryPasswordPost(requestsClientRecoveryPassword?: RequestsClientRecoveryPassword, options?: any): AxiosPromise<ResultsClientRecoveryPassword> {
            return DefaultApiFp(configuration).apiClientRecoveryPasswordPost(requestsClientRecoveryPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー登録の確認
         * @param {RequestsClientRegisterConfirm} [requestsClientRegisterConfirm] ユーザー登録の確認コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientRegisterConfirmPost(requestsClientRegisterConfirm?: RequestsClientRegisterConfirm, options?: any): AxiosPromise<ResultsClientRegisterConfirm> {
            return DefaultApiFp(configuration).apiClientRegisterConfirmPost(requestsClientRegisterConfirm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー作成
         * @param {RequestsClientRegister} [requestsClientRegister] ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientRegisterPost(requestsClientRegister?: RequestsClientRegister, options?: any): AxiosPromise<ResultsClientRegister> {
            return DefaultApiFp(configuration).apiClientRegisterPost(requestsClientRegister, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ログインしているユーザー情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientSelfGet(options?: any): AxiosPromise<ResultsClientSelf> {
            return DefaultApiFp(configuration).apiClientSelfGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー情報を更新する
         * @param {RequestsClientSelf} [requestsClientSelf] 更新するユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientSelfPut(requestsClientSelf?: RequestsClientSelf, options?: any): AxiosPromise<ResultOk> {
            return DefaultApiFp(configuration).apiClientSelfPut(requestsClientSelf, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーのMFAステータスの有効・無効設定
         * @summary ユーザーのMFAステータスの有効・無効設定
         * @param {RequestsClientUserMfaPost} [requestsClientUserMfaPost] MFAの有効・無効設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientUserMfaPost(requestsClientUserMfaPost?: RequestsClientUserMfaPost, options?: any): AxiosPromise<ResultsClientUserMfaPost> {
            return DefaultApiFp(configuration).apiClientUserMfaPost(requestsClientUserMfaPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary イメージファイルをダウンロードする
         * @param {string} path ファイルパス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentsImageGet(path: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).apiContentsImageGet(path, options).then((request) => request(axios, basePath));
        },
        /**
         * イメージファイルをダウンロードする
         * @summary イメージファイルをダウンロードする
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentsImagePathGet(path: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).apiContentsImagePathGet(path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 設定情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentsSettingGet(options?: any): AxiosPromise<ResultsContentsSettingGet> {
            return DefaultApiFp(configuration).apiContentsSettingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ヘルスチェック
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthcheckGet(options?: any): AxiosPromise<ResultsHealthcheck> {
            return DefaultApiFp(configuration).apiHealthcheckGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 権限が存在するかどうかを確認する
         * @summary 権限が存在するかどうかを確認する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerAuthorityGet(options?: any): AxiosPromise<ResultsManagerAuthorityGet> {
            return DefaultApiFp(configuration).apiManagerAuthorityGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary イメージファイル一覧を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerClientImagesGet(options?: any): AxiosPromise<Array<ResultsManagerClientImage>> {
            return DefaultApiFp(configuration).apiManagerClientImagesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary イメージファイルをアップロードする
         * @param {any} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerClientImagesPost(image?: any, options?: any): AxiosPromise<ResultsManagerClientImage> {
            return DefaultApiFp(configuration).apiManagerClientImagesPost(image, options).then((request) => request(axios, basePath));
        },
        /**
         * 設定情報一覧
         * @summary 設定情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerContentsSettingGet(options?: any): AxiosPromise<ResultsManagerContentsSettingGet> {
            return DefaultApiFp(configuration).apiManagerContentsSettingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * メール設定情報一覧
         * @summary メール設定情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerContentsSettingMailGet(options?: any): AxiosPromise<ResultsManagerContentsSettingMailGet> {
            return DefaultApiFp(configuration).apiManagerContentsSettingMailGet(options).then((request) => request(axios, basePath));
        },
        /**
         * メール設定の更新
         * @summary メール設定更新
         * @param {RequestsManagerContentsSettingMailPut} [requestsManagerContentsSettingMailPut] メール設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerContentsSettingMailPut(requestsManagerContentsSettingMailPut?: RequestsManagerContentsSettingMailPut, options?: any): AxiosPromise<ResultsManagerContentsSettingPut> {
            return DefaultApiFp(configuration).apiManagerContentsSettingMailPut(requestsManagerContentsSettingMailPut, options).then((request) => request(axios, basePath));
        },
        /**
         * 設定の更新
         * @summary 設定更新
         * @param {RequestsManagerContentsSettingPut} [requestsManagerContentsSettingPut] 設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerContentsSettingPut(requestsManagerContentsSettingPut?: RequestsManagerContentsSettingPut, options?: any): AxiosPromise<ResultsManagerContentsSettingPut> {
            return DefaultApiFp(configuration).apiManagerContentsSettingPut(requestsManagerContentsSettingPut, options).then((request) => request(axios, basePath));
        },
        /**
         * グループ一覧取得
         * @summary グループ一覧取得用
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGet(options?: any): AxiosPromise<ResultsManagerGroups> {
            return DefaultApiFp(configuration).apiManagerGroupsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 対象のグループに対する回答一覧を取得する(カスタムIDごと)
         * @summary 対象のグループの対象回答を取得
         * @param {string} groupId グループID
         * @param {string} answerId 回答ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdAnswersAnswerIdGet(groupId: string, answerId: string, options?: any): AxiosPromise<ResultsManagerGroupsGroupIdAnswersGet> {
            return DefaultApiFp(configuration).apiManagerGroupsGroupIdAnswersAnswerIdGet(groupId, answerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象のグループに対する回答一覧を取得する、またqueryパラメータにprogressがある場合はその進捗状況の一覧を取得。ない場合はCONFIRMED
         * @summary 対象のグループに対する回答一覧を取得
         * @param {string} groupId グループID
         * @param {AnswerProgress} [progress] 進捗状況
         * @param {string} [nextId] 次のデータID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdAnswersGet(groupId: string, progress?: AnswerProgress, nextId?: string, options?: any): AxiosPromise<ResultsManagerGroupsGroupIdAnswersGet> {
            return DefaultApiFp(configuration).apiManagerGroupsGroupIdAnswersGet(groupId, progress, nextId, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象のグループを削除する
         * @summary 対象のグループ削除用
         * @param {string} groupId グループID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdDelete(groupId: string, options?: any): AxiosPromise<ResultsManagerGroupsGroupIdDelete> {
            return DefaultApiFp(configuration).apiManagerGroupsGroupIdDelete(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象のグループを更新する
         * @summary 対象のグループ更新用
         * @param {string} groupId グループID
         * @param {RequestsManagerGroupsGroupIdPut} [requestsManagerGroupsGroupIdPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdPut(groupId: string, requestsManagerGroupsGroupIdPut?: RequestsManagerGroupsGroupIdPut, options?: any): AxiosPromise<ResultsManagerGroupsGroupIdPut> {
            return DefaultApiFp(configuration).apiManagerGroupsGroupIdPut(groupId, requestsManagerGroupsGroupIdPut, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象グループのリリース一覧を取得する
         * @summary 対象のグループのリリース一覧を取得
         * @param {string} groupId グループID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdReleasesGet(groupId: string, options?: any): AxiosPromise<ResultsManagerGroupsGroupIdReleasesGet> {
            return DefaultApiFp(configuration).apiManagerGroupsGroupIdReleasesGet(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象グループのリリース一覧を取得する
         * @summary 対象のグループをリリースする
         * @param {string} groupId グループID
         * @param {RequestsManagerGroupsGroupIdReleasesPost} [requestsManagerGroupsGroupIdReleasesPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdReleasesPost(groupId: string, requestsManagerGroupsGroupIdReleasesPost?: RequestsManagerGroupsGroupIdReleasesPost, options?: any): AxiosPromise<ResultsManagerGroupsGroupIdReleasesPost> {
            return DefaultApiFp(configuration).apiManagerGroupsGroupIdReleasesPost(groupId, requestsManagerGroupsGroupIdReleasesPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象のグループをテンプレート一覧を取得する
         * @summary 対象のグループのテンプレート一覧取得用
         * @param {string} groupId グループID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdTemplatesGet(groupId: string, options?: any): AxiosPromise<ResultsManagerGroupsGroupIdTemplatesGet> {
            return DefaultApiFp(configuration).apiManagerGroupsGroupIdTemplatesGet(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * テンプレートを作成する
         * @summary テンプレートを作成
         * @param {string} groupId グループID
         * @param {RequestsManagerGroupsGroupIdTemplatesPost} [requestsManagerGroupsGroupIdTemplatesPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdTemplatesPost(groupId: string, requestsManagerGroupsGroupIdTemplatesPost?: RequestsManagerGroupsGroupIdTemplatesPost, options?: any): AxiosPromise<ResultsManagerGroupsGroupIdTemplatesPost> {
            return DefaultApiFp(configuration).apiManagerGroupsGroupIdTemplatesPost(groupId, requestsManagerGroupsGroupIdTemplatesPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象のテンプレート削除
         * @summary 対象のテンプレートを削除
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdTemplatesTemplateIdDelete(groupId: string, templateId: string, options?: any): AxiosPromise<ResultsManagerGroupsGroupIdTemplatesTemplateIdDelete> {
            return DefaultApiFp(configuration).apiManagerGroupsGroupIdTemplatesTemplateIdDelete(groupId, templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象のテンプレートを更新する
         * @summary 対象のテンプレート更新
         * @param {string} groupId グループID
         * @param {string} templateId テンプレートID
         * @param {RequestsManagerGroupsGroupIdTemplatesTemplateIdPut} [requestsManagerGroupsGroupIdTemplatesTemplateIdPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsGroupIdTemplatesTemplateIdPut(groupId: string, templateId: string, requestsManagerGroupsGroupIdTemplatesTemplateIdPut?: RequestsManagerGroupsGroupIdTemplatesTemplateIdPut, options?: any): AxiosPromise<ResultsManagerGroupsGroupIdTemplatesTemplateIdPut> {
            return DefaultApiFp(configuration).apiManagerGroupsGroupIdTemplatesTemplateIdPut(groupId, templateId, requestsManagerGroupsGroupIdTemplatesTemplateIdPut, options).then((request) => request(axios, basePath));
        },
        /**
         * グループの作成を行う
         * @summary グループ作成用
         * @param {RequestsManagerGroupsPost} [requestsManagerGroupsPost] グループ情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGroupsPost(requestsManagerGroupsPost?: RequestsManagerGroupsPost, options?: any): AxiosPromise<ResultsManagerGroupsPost> {
            return DefaultApiFp(configuration).apiManagerGroupsPost(requestsManagerGroupsPost, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインにMFAコードを確認する
         * @summary ログインにMFAコードを確認する
         * @param {RequestsManagerLoginMfaPost} [requestsManagerLoginMfaPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerLoginMfaPost(requestsManagerLoginMfaPost?: RequestsManagerLoginMfaPost, options?: any): AxiosPromise<ResultsManagerLoginMfaPost> {
            return DefaultApiFp(configuration).apiManagerLoginMfaPost(requestsManagerLoginMfaPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 企業サーバーログイン
         * @param {RequestsManagerLogin} [requestsManagerLogin] ログイン情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerLoginPost(requestsManagerLogin?: RequestsManagerLogin, options?: any): AxiosPromise<ResultsManagerLogin> {
            return DefaultApiFp(configuration).apiManagerLoginPost(requestsManagerLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 企業サーバーからログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerLogoutGet(options?: any): AxiosPromise<ResultsLogout> {
            return DefaultApiFp(configuration).apiManagerLogoutGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 対象リリースの削除を行う
         * @summary 対象リリースの削除
         * @param {string} releaseId リリースID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReleasesReleaseIdDelete(releaseId: string, options?: any): AxiosPromise<ResultsManagerReleasesReleaseIdDelete> {
            return DefaultApiFp(configuration).apiManagerReleasesReleaseIdDelete(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象リリースの更新を行う
         * @summary 対象リリースの更新
         * @param {string} releaseId リリースID
         * @param {RequestsManagerReleasesReleaseIdPut} [requestsManagerReleasesReleaseIdPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReleasesReleaseIdPut(releaseId: string, requestsManagerReleasesReleaseIdPut?: RequestsManagerReleasesReleaseIdPut, options?: any): AxiosPromise<ResultsManagerReleasesReleaseIdPut> {
            return DefaultApiFp(configuration).apiManagerReleasesReleaseIdPut(releaseId, requestsManagerReleasesReleaseIdPut, options).then((request) => request(axios, basePath));
        },
        /**
         * ファイルをアップロードする
         * @summary ファイルをアップロード
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerUploadPost(inlineObject1?: InlineObject1, options?: any): AxiosPromise<InlineResponse200> {
            return DefaultApiFp(configuration).apiManagerUploadPost(inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理ユーザー一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerUsersGet(options?: any): AxiosPromise<ResultsManagerUsersGet> {
            return DefaultApiFp(configuration).apiManagerUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーを作成する
         * @param {RequestsManagerUsersPost} [requestsManagerUsersPost] ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerUsersPost(requestsManagerUsersPost?: RequestsManagerUsersPost, options?: any): AxiosPromise<ResultsManagerUsersPost> {
            return DefaultApiFp(configuration).apiManagerUsersPost(requestsManagerUsersPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象のユーザーを削除する
         * @summary 対象のユーザーを削除
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerUsersUserIdDelete(userId: string, options?: any): AxiosPromise<Links & ResultOk> {
            return DefaultApiFp(configuration).apiManagerUsersUserIdDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * メールを送信する
         * @summary メールを送信する
         * @param {string} userId ユーザーID
         * @param {RequestsManagerUsersUserIdMailPost} [requestsManagerUsersUserIdMailPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerUsersUserIdMailPost(userId: string, requestsManagerUsersUserIdMailPost?: RequestsManagerUsersUserIdMailPost, options?: any): AxiosPromise<ResultsManagerUsersUserIdMailPost> {
            return DefaultApiFp(configuration).apiManagerUsersUserIdMailPost(userId, requestsManagerUsersUserIdMailPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象のユーザーのパスワードを変更する
         * @summary 対象のユーザーのパスワード変更
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerUsersUserIdPasswordPatch(userId: string, options?: any): AxiosPromise<Links & ResultOk> {
            return DefaultApiFp(configuration).apiManagerUsersUserIdPasswordPatch(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary サンプルIDとキットIDが正しいかを判定する
         * @param {string} sampleId サンプルID
         * @param {string} kitId キットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientCheckSampleIdKitIdGet(sampleId: string, kitId: string, options?: any): AxiosPromise<ResultsCustomApiClientCheckSampleIdKitId> {
            return DefaultApiFp(configuration).customApiClientCheckSampleIdKitIdGet(sampleId, kitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary サンプルIDに対する回答がされているかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientConfirmedMysampleidGet(options?: any): AxiosPromise<ResultsCustomApiClientConfirmedMysampleid> {
            return DefaultApiFp(configuration).customApiClientConfirmedMysampleidGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 全ての回答を確定する
         * @param {string} groupId グループID
         * @param {RequestsCustomApiClientGroupsGroupIdAnswersConfirm} [requestsCustomApiClientGroupsGroupIdAnswersConfirm] 全ての回答を確定する（一時保存は削除する）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientGroupsGroupIdAnswersConfirmPost(groupId: string, requestsCustomApiClientGroupsGroupIdAnswersConfirm?: RequestsCustomApiClientGroupsGroupIdAnswersConfirm, options?: any): AxiosPromise<ResultsCustomApiClientGroupsGroupIdAnswersConfirm> {
            return DefaultApiFp(configuration).customApiClientGroupsGroupIdAnswersConfirmPost(groupId, requestsCustomApiClientGroupsGroupIdAnswersConfirm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 自身の配下にいるメンバー一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientMembersGet(options?: any): AxiosPromise<ResultsCustomApiClientMembers> {
            return DefaultApiFp(configuration).customApiClientMembersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メンバー情報の更新
         * @param {string} memberId 被検者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientMembersMemberIdDelete(memberId: string, options?: any): AxiosPromise<ResultsCustomApiClientMembersMemberIdDelete> {
            return DefaultApiFp(configuration).customApiClientMembersMemberIdDelete(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メンバーIDの詳細情報
         * @param {string} memberId 被検者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientMembersMemberIdGet(memberId: string, options?: any): AxiosPromise<ResultsCustomApiClientMembersMemberId> {
            return DefaultApiFp(configuration).customApiClientMembersMemberIdGet(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メンバー情報の更新
         * @param {string} memberId 被検者ID
         * @param {RequestsCustomApiClientMembersMemberId} [requestsCustomApiClientMembersMemberId] 更新するメンバー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientMembersMemberIdPut(memberId: string, requestsCustomApiClientMembersMemberId?: RequestsCustomApiClientMembersMemberId, options?: any): AxiosPromise<ResultsCustomApiClientMembersMemberIdPut> {
            return DefaultApiFp(configuration).customApiClientMembersMemberIdPut(memberId, requestsCustomApiClientMembersMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary サンプルIDを追加する
         * @param {string} memberId 被検者ID
         * @param {RequestsCustomApiClientMembersMemberIdSampleIds} [requestsCustomApiClientMembersMemberIdSampleIds] メンバーにサンプルIDを追加する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientMembersMemberIdSampleidsPost(memberId: string, requestsCustomApiClientMembersMemberIdSampleIds?: RequestsCustomApiClientMembersMemberIdSampleIds, options?: any): AxiosPromise<ResultsCustomApiClientMembersMemberIdSampleIds> {
            return DefaultApiFp(configuration).customApiClientMembersMemberIdSampleidsPost(memberId, requestsCustomApiClientMembersMemberIdSampleIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メンバーを選択
         * @param {string} memberId 被検者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientMembersMemberIdSelectionPost(memberId: string, options?: any): AxiosPromise<ResultsCustomApiClientMembersMemberIdSelection> {
            return DefaultApiFp(configuration).customApiClientMembersMemberIdSelectionPost(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 自身の配下にメンバーを追加する
         * @param {RequestsCustomApiClientMembers} [requestsCustomApiClientMembers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientMembersPost(requestsCustomApiClientMembers?: RequestsCustomApiClientMembers, options?: any): AxiosPromise<ResultsCustomApiClientMembersMember> {
            return DefaultApiFp(configuration).customApiClientMembersPost(requestsCustomApiClientMembers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 登録
         * @param {RequestsCustomApiClientRegister} [requestsCustomApiClientRegister] 登録情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientRegisterPost(requestsCustomApiClientRegister?: RequestsCustomApiClientRegister, options?: any): AxiosPromise<ResultsCustomApiClientRegister> {
            return DefaultApiFp(configuration).customApiClientRegisterPost(requestsCustomApiClientRegister, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 選択中のメンバー情報を削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientSelectedMemberDelete(options?: any): AxiosPromise<ResultsCustomApiClientSelectedMemberDelete> {
            return DefaultApiFp(configuration).customApiClientSelectedMemberDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 選択中のメンバーを取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientSelectedMemberGet(options?: any): AxiosPromise<ResultsCustomApiClientSelectedMember> {
            return DefaultApiFp(configuration).customApiClientSelectedMemberGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 自身の詳細情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientSelfGet(options?: any): AxiosPromise<ResultsCustomApiClientSelf> {
            return DefaultApiFp(configuration).customApiClientSelfGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 自身の詳細情報を更新
         * @param {RequestsCustomApiClientSelf} [requestsCustomApiClientSelf] 更新する情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiClientSelfPut(requestsCustomApiClientSelf?: RequestsCustomApiClientSelf, options?: any): AxiosPromise<ResultsCustomApiClientSelfPut> {
            return DefaultApiFp(configuration).customApiClientSelfPut(requestsCustomApiClientSelf, options).then((request) => request(axios, basePath));
        },
        /**
         * メンバー,サンプルIDおよび回答状況情報一覧を取得
         * @summary メンバー,サンプルIDおよび回答状況情報一覧を取得
         * @param {string} [nextId] 次のデータID
         * @param {string} [sampleId] サンプルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiManagerMembersAnswersGet(nextId?: string, sampleId?: string, options?: any): AxiosPromise<ResultsCustomApiManagerMembersAnswersGet> {
            return DefaultApiFp(configuration).customApiManagerMembersAnswersGet(nextId, sampleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 被検者情報を取得する
         * @summary 被検者情報を取得する
         * @param {string} memberId 被検者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customApiManagerMembersMemberIdGet(memberId: string, options?: any): AxiosPromise<ResultsCustomApiManagerMembersMemberIdGet> {
            return DefaultApiFp(configuration).customApiManagerMembersMemberIdGet(memberId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary 回答をコピーする
     * @param {RequestsClientAnswersCopy} [requestsClientAnswersCopy] 回答をコピーする
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientAnswersCopyPost(requestsClientAnswersCopy?: RequestsClientAnswersCopy, options?: any) {
        return DefaultApiFp(this.configuration).apiClientAnswersCopyPost(requestsClientAnswersCopy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary カスタムIDをセットする
     * @param {RequestsClientCustomIdPost} [requestsClientCustomIdPost] カスタムID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientCustomidPost(requestsClientCustomIdPost?: RequestsClientCustomIdPost, options?: any) {
        return DefaultApiFp(this.configuration).apiClientCustomidPost(requestsClientCustomIdPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メールアドレス変更時の確認コード
     * @param {RequestsClientEmailConfirm} [requestsClientEmailConfirm] 変更メールアドレス確認コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientEmailConfirmPost(requestsClientEmailConfirm?: RequestsClientEmailConfirm, options?: any) {
        return DefaultApiFp(this.configuration).apiClientEmailConfirmPost(requestsClientEmailConfirm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メールアドレスを変更する
     * @param {RequestsClientEmail} [requestsClientEmail] 変更後メールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientEmailPut(requestsClientEmail?: RequestsClientEmail, options?: any) {
        return DefaultApiFp(this.configuration).apiClientEmailPut(requestsClientEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーが回答可能なアンケートのグループ一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientGroupsGet(options?: any) {
        return DefaultApiFp(this.configuration).apiClientGroupsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 全ての回答を確定する
     * @param {string} groupId グループID
     * @param {RequestsClientGroupsGroupIdAnswersConfirm} [requestsClientGroupsGroupIdAnswersConfirm] 全ての回答を確定する（一時保存は削除する）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientGroupsGroupIdAnswersConfirmPost(groupId: string, requestsClientGroupsGroupIdAnswersConfirm?: RequestsClientGroupsGroupIdAnswersConfirm, options?: any) {
        return DefaultApiFp(this.configuration).apiClientGroupsGroupIdAnswersConfirmPost(groupId, requestsClientGroupsGroupIdAnswersConfirm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary グループ内の全てのテンプレートに対する回答一覧を取得する
     * @param {string} groupId グループID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientGroupsGroupIdAnswersGet(groupId: string, options?: any) {
        return DefaultApiFp(this.configuration).apiClientGroupsGroupIdAnswersGet(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary テンプレートに指定した回答を取得する
     * @param {string} groupId グループID
     * @param {string} templateId テンプレートID
     * @param {string} answerId 回答ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdGet(groupId: string, templateId: string, answerId: string, options?: any) {
        return DefaultApiFp(this.configuration).apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdGet(groupId, templateId, answerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary テンプレートに指定したIDに対して回答を作成する
     * @param {string} groupId グループID
     * @param {string} templateId テンプレートID
     * @param {string} answerId 回答ID
     * @param {RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId} [requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId] テンプレートに対する回答情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPost(groupId: string, templateId: string, answerId: string, requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId?: RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId, options?: any) {
        return DefaultApiFp(this.configuration).apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPost(groupId, templateId, answerId, requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary テンプレートに指定したIDに対して回答を上書き更新・新規作成する
     * @param {string} groupId グループID
     * @param {string} templateId テンプレートID
     * @param {string} answerId 回答ID
     * @param {RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId} [requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId] テンプレートに対する回答情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPut(groupId: string, templateId: string, answerId: string, requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId?: RequestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId, options?: any) {
        return DefaultApiFp(this.configuration).apiClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerIdPut(groupId, templateId, answerId, requestsClientGroupsGroupIdTemplatesTemplateIdAnswersAnswerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定のテンプレート情報を取得する
     * @param {string} groupId グループID
     * @param {string} templateId テンプレートID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientGroupsGroupIdTemplatesTemplateIdGet(groupId: string, templateId: string, options?: any) {
        return DefaultApiFp(this.configuration).apiClientGroupsGroupIdTemplatesTemplateIdGet(groupId, templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary イメージファイルをダウンロードする
     * @param {string} path ファイルパス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientImageGet(path: string, options?: any) {
        return DefaultApiFp(this.configuration).apiClientImageGet(path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインにMFAコードを確認する
     * @summary ログインにMFAコードを確認する
     * @param {RequestsClientLoginMfaPost} [requestsClientLoginMfaPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientLoginMfaPost(requestsClientLoginMfaPost?: RequestsClientLoginMfaPost, options?: any) {
        return DefaultApiFp(this.configuration).apiClientLoginMfaPost(requestsClientLoginMfaPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary アンケート回答者ログイン
     * @param {RequestsClientLogin} [requestsClientLogin] ログイン情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientLoginPost(requestsClientLogin?: RequestsClientLogin, options?: any) {
        return DefaultApiFp(this.configuration).apiClientLoginPost(requestsClientLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary アンケート回答者ログアウト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientLogoutGet(options?: any) {
        return DefaultApiFp(this.configuration).apiClientLogoutGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary パスワードを変更する
     * @param {RequestsClientPassword} [requestsClientPassword] 変更後パスワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientPasswordPut(requestsClientPassword?: RequestsClientPassword, options?: any) {
        return DefaultApiFp(this.configuration).apiClientPasswordPut(requestsClientPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メールアドレスを元にパスワード変更コードを確認
     * @param {RequestsClientRecoveryPasswordConfirm} [requestsClientRecoveryPasswordConfirm] パスワード変更用のメールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientRecoveryPasswordConfirmPost(requestsClientRecoveryPasswordConfirm?: RequestsClientRecoveryPasswordConfirm, options?: any) {
        return DefaultApiFp(this.configuration).apiClientRecoveryPasswordConfirmPost(requestsClientRecoveryPasswordConfirm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メールアドレスを元にパスワード変更のためのコードを送信する
     * @param {RequestsClientRecoveryPassword} [requestsClientRecoveryPassword] パスワード変更用のメールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientRecoveryPasswordPost(requestsClientRecoveryPassword?: RequestsClientRecoveryPassword, options?: any) {
        return DefaultApiFp(this.configuration).apiClientRecoveryPasswordPost(requestsClientRecoveryPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー登録の確認
     * @param {RequestsClientRegisterConfirm} [requestsClientRegisterConfirm] ユーザー登録の確認コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientRegisterConfirmPost(requestsClientRegisterConfirm?: RequestsClientRegisterConfirm, options?: any) {
        return DefaultApiFp(this.configuration).apiClientRegisterConfirmPost(requestsClientRegisterConfirm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー作成
     * @param {RequestsClientRegister} [requestsClientRegister] ユーザー情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientRegisterPost(requestsClientRegister?: RequestsClientRegister, options?: any) {
        return DefaultApiFp(this.configuration).apiClientRegisterPost(requestsClientRegister, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ログインしているユーザー情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientSelfGet(options?: any) {
        return DefaultApiFp(this.configuration).apiClientSelfGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー情報を更新する
     * @param {RequestsClientSelf} [requestsClientSelf] 更新するユーザー情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientSelfPut(requestsClientSelf?: RequestsClientSelf, options?: any) {
        return DefaultApiFp(this.configuration).apiClientSelfPut(requestsClientSelf, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーのMFAステータスの有効・無効設定
     * @summary ユーザーのMFAステータスの有効・無効設定
     * @param {RequestsClientUserMfaPost} [requestsClientUserMfaPost] MFAの有効・無効設定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientUserMfaPost(requestsClientUserMfaPost?: RequestsClientUserMfaPost, options?: any) {
        return DefaultApiFp(this.configuration).apiClientUserMfaPost(requestsClientUserMfaPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary イメージファイルをダウンロードする
     * @param {string} path ファイルパス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiContentsImageGet(path: string, options?: any) {
        return DefaultApiFp(this.configuration).apiContentsImageGet(path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * イメージファイルをダウンロードする
     * @summary イメージファイルをダウンロードする
     * @param {string} path 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiContentsImagePathGet(path: string, options?: any) {
        return DefaultApiFp(this.configuration).apiContentsImagePathGet(path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 設定情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiContentsSettingGet(options?: any) {
        return DefaultApiFp(this.configuration).apiContentsSettingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ヘルスチェック
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiHealthcheckGet(options?: any) {
        return DefaultApiFp(this.configuration).apiHealthcheckGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 権限が存在するかどうかを確認する
     * @summary 権限が存在するかどうかを確認する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerAuthorityGet(options?: any) {
        return DefaultApiFp(this.configuration).apiManagerAuthorityGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary イメージファイル一覧を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerClientImagesGet(options?: any) {
        return DefaultApiFp(this.configuration).apiManagerClientImagesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary イメージファイルをアップロードする
     * @param {any} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerClientImagesPost(image?: any, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerClientImagesPost(image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 設定情報一覧
     * @summary 設定情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerContentsSettingGet(options?: any) {
        return DefaultApiFp(this.configuration).apiManagerContentsSettingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メール設定情報一覧
     * @summary メール設定情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerContentsSettingMailGet(options?: any) {
        return DefaultApiFp(this.configuration).apiManagerContentsSettingMailGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メール設定の更新
     * @summary メール設定更新
     * @param {RequestsManagerContentsSettingMailPut} [requestsManagerContentsSettingMailPut] メール設定情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerContentsSettingMailPut(requestsManagerContentsSettingMailPut?: RequestsManagerContentsSettingMailPut, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerContentsSettingMailPut(requestsManagerContentsSettingMailPut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 設定の更新
     * @summary 設定更新
     * @param {RequestsManagerContentsSettingPut} [requestsManagerContentsSettingPut] 設定情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerContentsSettingPut(requestsManagerContentsSettingPut?: RequestsManagerContentsSettingPut, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerContentsSettingPut(requestsManagerContentsSettingPut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * グループ一覧取得
     * @summary グループ一覧取得用
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerGroupsGet(options?: any) {
        return DefaultApiFp(this.configuration).apiManagerGroupsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象のグループに対する回答一覧を取得する(カスタムIDごと)
     * @summary 対象のグループの対象回答を取得
     * @param {string} groupId グループID
     * @param {string} answerId 回答ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerGroupsGroupIdAnswersAnswerIdGet(groupId: string, answerId: string, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerGroupsGroupIdAnswersAnswerIdGet(groupId, answerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象のグループに対する回答一覧を取得する、またqueryパラメータにprogressがある場合はその進捗状況の一覧を取得。ない場合はCONFIRMED
     * @summary 対象のグループに対する回答一覧を取得
     * @param {string} groupId グループID
     * @param {AnswerProgress} [progress] 進捗状況
     * @param {string} [nextId] 次のデータID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerGroupsGroupIdAnswersGet(groupId: string, progress?: AnswerProgress, nextId?: string, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerGroupsGroupIdAnswersGet(groupId, progress, nextId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象のグループを削除する
     * @summary 対象のグループ削除用
     * @param {string} groupId グループID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerGroupsGroupIdDelete(groupId: string, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerGroupsGroupIdDelete(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象のグループを更新する
     * @summary 対象のグループ更新用
     * @param {string} groupId グループID
     * @param {RequestsManagerGroupsGroupIdPut} [requestsManagerGroupsGroupIdPut] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerGroupsGroupIdPut(groupId: string, requestsManagerGroupsGroupIdPut?: RequestsManagerGroupsGroupIdPut, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerGroupsGroupIdPut(groupId, requestsManagerGroupsGroupIdPut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象グループのリリース一覧を取得する
     * @summary 対象のグループのリリース一覧を取得
     * @param {string} groupId グループID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerGroupsGroupIdReleasesGet(groupId: string, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerGroupsGroupIdReleasesGet(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象グループのリリース一覧を取得する
     * @summary 対象のグループをリリースする
     * @param {string} groupId グループID
     * @param {RequestsManagerGroupsGroupIdReleasesPost} [requestsManagerGroupsGroupIdReleasesPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerGroupsGroupIdReleasesPost(groupId: string, requestsManagerGroupsGroupIdReleasesPost?: RequestsManagerGroupsGroupIdReleasesPost, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerGroupsGroupIdReleasesPost(groupId, requestsManagerGroupsGroupIdReleasesPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象のグループをテンプレート一覧を取得する
     * @summary 対象のグループのテンプレート一覧取得用
     * @param {string} groupId グループID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerGroupsGroupIdTemplatesGet(groupId: string, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerGroupsGroupIdTemplatesGet(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * テンプレートを作成する
     * @summary テンプレートを作成
     * @param {string} groupId グループID
     * @param {RequestsManagerGroupsGroupIdTemplatesPost} [requestsManagerGroupsGroupIdTemplatesPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerGroupsGroupIdTemplatesPost(groupId: string, requestsManagerGroupsGroupIdTemplatesPost?: RequestsManagerGroupsGroupIdTemplatesPost, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerGroupsGroupIdTemplatesPost(groupId, requestsManagerGroupsGroupIdTemplatesPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象のテンプレート削除
     * @summary 対象のテンプレートを削除
     * @param {string} groupId グループID
     * @param {string} templateId テンプレートID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerGroupsGroupIdTemplatesTemplateIdDelete(groupId: string, templateId: string, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerGroupsGroupIdTemplatesTemplateIdDelete(groupId, templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象のテンプレートを更新する
     * @summary 対象のテンプレート更新
     * @param {string} groupId グループID
     * @param {string} templateId テンプレートID
     * @param {RequestsManagerGroupsGroupIdTemplatesTemplateIdPut} [requestsManagerGroupsGroupIdTemplatesTemplateIdPut] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerGroupsGroupIdTemplatesTemplateIdPut(groupId: string, templateId: string, requestsManagerGroupsGroupIdTemplatesTemplateIdPut?: RequestsManagerGroupsGroupIdTemplatesTemplateIdPut, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerGroupsGroupIdTemplatesTemplateIdPut(groupId, templateId, requestsManagerGroupsGroupIdTemplatesTemplateIdPut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * グループの作成を行う
     * @summary グループ作成用
     * @param {RequestsManagerGroupsPost} [requestsManagerGroupsPost] グループ情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerGroupsPost(requestsManagerGroupsPost?: RequestsManagerGroupsPost, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerGroupsPost(requestsManagerGroupsPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインにMFAコードを確認する
     * @summary ログインにMFAコードを確認する
     * @param {RequestsManagerLoginMfaPost} [requestsManagerLoginMfaPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerLoginMfaPost(requestsManagerLoginMfaPost?: RequestsManagerLoginMfaPost, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerLoginMfaPost(requestsManagerLoginMfaPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 企業サーバーログイン
     * @param {RequestsManagerLogin} [requestsManagerLogin] ログイン情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerLoginPost(requestsManagerLogin?: RequestsManagerLogin, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerLoginPost(requestsManagerLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 企業サーバーからログアウト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerLogoutGet(options?: any) {
        return DefaultApiFp(this.configuration).apiManagerLogoutGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象リリースの削除を行う
     * @summary 対象リリースの削除
     * @param {string} releaseId リリースID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerReleasesReleaseIdDelete(releaseId: string, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerReleasesReleaseIdDelete(releaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象リリースの更新を行う
     * @summary 対象リリースの更新
     * @param {string} releaseId リリースID
     * @param {RequestsManagerReleasesReleaseIdPut} [requestsManagerReleasesReleaseIdPut] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerReleasesReleaseIdPut(releaseId: string, requestsManagerReleasesReleaseIdPut?: RequestsManagerReleasesReleaseIdPut, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerReleasesReleaseIdPut(releaseId, requestsManagerReleasesReleaseIdPut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ファイルをアップロードする
     * @summary ファイルをアップロード
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerUploadPost(inlineObject1?: InlineObject1, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerUploadPost(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理ユーザー一覧を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerUsersGet(options?: any) {
        return DefaultApiFp(this.configuration).apiManagerUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーを作成する
     * @param {RequestsManagerUsersPost} [requestsManagerUsersPost] ユーザー情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerUsersPost(requestsManagerUsersPost?: RequestsManagerUsersPost, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerUsersPost(requestsManagerUsersPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象のユーザーを削除する
     * @summary 対象のユーザーを削除
     * @param {string} userId ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerUsersUserIdDelete(userId: string, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerUsersUserIdDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メールを送信する
     * @summary メールを送信する
     * @param {string} userId ユーザーID
     * @param {RequestsManagerUsersUserIdMailPost} [requestsManagerUsersUserIdMailPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerUsersUserIdMailPost(userId: string, requestsManagerUsersUserIdMailPost?: RequestsManagerUsersUserIdMailPost, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerUsersUserIdMailPost(userId, requestsManagerUsersUserIdMailPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象のユーザーのパスワードを変更する
     * @summary 対象のユーザーのパスワード変更
     * @param {string} userId ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiManagerUsersUserIdPasswordPatch(userId: string, options?: any) {
        return DefaultApiFp(this.configuration).apiManagerUsersUserIdPasswordPatch(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary サンプルIDとキットIDが正しいかを判定する
     * @param {string} sampleId サンプルID
     * @param {string} kitId キットID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiClientCheckSampleIdKitIdGet(sampleId: string, kitId: string, options?: any) {
        return DefaultApiFp(this.configuration).customApiClientCheckSampleIdKitIdGet(sampleId, kitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary サンプルIDに対する回答がされているかどうか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiClientConfirmedMysampleidGet(options?: any) {
        return DefaultApiFp(this.configuration).customApiClientConfirmedMysampleidGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 全ての回答を確定する
     * @param {string} groupId グループID
     * @param {RequestsCustomApiClientGroupsGroupIdAnswersConfirm} [requestsCustomApiClientGroupsGroupIdAnswersConfirm] 全ての回答を確定する（一時保存は削除する）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiClientGroupsGroupIdAnswersConfirmPost(groupId: string, requestsCustomApiClientGroupsGroupIdAnswersConfirm?: RequestsCustomApiClientGroupsGroupIdAnswersConfirm, options?: any) {
        return DefaultApiFp(this.configuration).customApiClientGroupsGroupIdAnswersConfirmPost(groupId, requestsCustomApiClientGroupsGroupIdAnswersConfirm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 自身の配下にいるメンバー一覧を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiClientMembersGet(options?: any) {
        return DefaultApiFp(this.configuration).customApiClientMembersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メンバー情報の更新
     * @param {string} memberId 被検者ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiClientMembersMemberIdDelete(memberId: string, options?: any) {
        return DefaultApiFp(this.configuration).customApiClientMembersMemberIdDelete(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メンバーIDの詳細情報
     * @param {string} memberId 被検者ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiClientMembersMemberIdGet(memberId: string, options?: any) {
        return DefaultApiFp(this.configuration).customApiClientMembersMemberIdGet(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メンバー情報の更新
     * @param {string} memberId 被検者ID
     * @param {RequestsCustomApiClientMembersMemberId} [requestsCustomApiClientMembersMemberId] 更新するメンバー情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiClientMembersMemberIdPut(memberId: string, requestsCustomApiClientMembersMemberId?: RequestsCustomApiClientMembersMemberId, options?: any) {
        return DefaultApiFp(this.configuration).customApiClientMembersMemberIdPut(memberId, requestsCustomApiClientMembersMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary サンプルIDを追加する
     * @param {string} memberId 被検者ID
     * @param {RequestsCustomApiClientMembersMemberIdSampleIds} [requestsCustomApiClientMembersMemberIdSampleIds] メンバーにサンプルIDを追加する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiClientMembersMemberIdSampleidsPost(memberId: string, requestsCustomApiClientMembersMemberIdSampleIds?: RequestsCustomApiClientMembersMemberIdSampleIds, options?: any) {
        return DefaultApiFp(this.configuration).customApiClientMembersMemberIdSampleidsPost(memberId, requestsCustomApiClientMembersMemberIdSampleIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メンバーを選択
     * @param {string} memberId 被検者ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiClientMembersMemberIdSelectionPost(memberId: string, options?: any) {
        return DefaultApiFp(this.configuration).customApiClientMembersMemberIdSelectionPost(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 自身の配下にメンバーを追加する
     * @param {RequestsCustomApiClientMembers} [requestsCustomApiClientMembers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiClientMembersPost(requestsCustomApiClientMembers?: RequestsCustomApiClientMembers, options?: any) {
        return DefaultApiFp(this.configuration).customApiClientMembersPost(requestsCustomApiClientMembers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 登録
     * @param {RequestsCustomApiClientRegister} [requestsCustomApiClientRegister] 登録情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiClientRegisterPost(requestsCustomApiClientRegister?: RequestsCustomApiClientRegister, options?: any) {
        return DefaultApiFp(this.configuration).customApiClientRegisterPost(requestsCustomApiClientRegister, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 選択中のメンバー情報を削除
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiClientSelectedMemberDelete(options?: any) {
        return DefaultApiFp(this.configuration).customApiClientSelectedMemberDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 選択中のメンバーを取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiClientSelectedMemberGet(options?: any) {
        return DefaultApiFp(this.configuration).customApiClientSelectedMemberGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 自身の詳細情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiClientSelfGet(options?: any) {
        return DefaultApiFp(this.configuration).customApiClientSelfGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 自身の詳細情報を更新
     * @param {RequestsCustomApiClientSelf} [requestsCustomApiClientSelf] 更新する情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiClientSelfPut(requestsCustomApiClientSelf?: RequestsCustomApiClientSelf, options?: any) {
        return DefaultApiFp(this.configuration).customApiClientSelfPut(requestsCustomApiClientSelf, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メンバー,サンプルIDおよび回答状況情報一覧を取得
     * @summary メンバー,サンプルIDおよび回答状況情報一覧を取得
     * @param {string} [nextId] 次のデータID
     * @param {string} [sampleId] サンプルID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiManagerMembersAnswersGet(nextId?: string, sampleId?: string, options?: any) {
        return DefaultApiFp(this.configuration).customApiManagerMembersAnswersGet(nextId, sampleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 被検者情報を取得する
     * @summary 被検者情報を取得する
     * @param {string} memberId 被検者ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customApiManagerMembersMemberIdGet(memberId: string, options?: any) {
        return DefaultApiFp(this.configuration).customApiManagerMembersMemberIdGet(memberId, options).then((request) => request(this.axios, this.basePath));
    }

}


