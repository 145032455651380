import { Dispatch } from 'redux';
import actionCreatorFactory from 'typescript-fsa';
import { RequestsClientGroupsGroupIdAnswersConfirm } from 'oapi';
import APIWrapper, { DefaultApiFp, APIError } from 'common/APIWrapper';
import { actions as commonActions } from 'duck/common/Actions';

const { customApiClientGroupsGroupIdAnswersConfirmPost } = DefaultApiFp();
const actionCreator = actionCreatorFactory();

export const actions = {
  load: actionCreator.async<{}, void, APIError>('LOAD_CUSTOM_GROUP'),
};

/**
 * 回答を確定する
 */
export const confirmAnswers = (groupId: string, customId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.load.started({}));
      const param: RequestsClientGroupsGroupIdAnswersConfirm = {
        custom_id: customId,
      };
      await APIWrapper(
        await customApiClientGroupsGroupIdAnswersConfirmPost(groupId, param),
      );
      dispatch(actions.load.done({ params: {} }));
      return true;
    } catch (e) {
      if (e instanceof APIError && e.status === 401) {
        dispatch(commonActions.revokePermission());
        dispatch(actions.load.done({ params: {} }));
      } else {
        dispatch(actions.load.failed({ params: {}, error: e }));
      }
      return false;
    }
  };
};

export default { confirmAnswers };
