import { useState, ChangeEvent } from 'react';
import { ErrorProps, validateChecks } from 'hooks/Validator';
import { VALIDATE_ERROR_MESSAGE } from 'common/Messages';
import {
  CheckboxDataProps,
  CheckboxDataListProps,
} from 'presentational/general/atoms/basicInput/CheckboxGroup';

type CheckboxFormListProps = {
  label: string;
  value: string;
};

type CheckboxGroupUpdateProps = {
  values: string[];
  error: ErrorProps;
  hasInput: boolean;
};

export type CheckboxGroupHooksProps = {
  checkboxDataList: CheckboxDataListProps;
  error: ErrorProps;
  hasInput: boolean;
  setHasInputTrue: () => void;
  onChange: (
    e: ChangeEvent<HTMLInputElement>,
    key: number,
  ) => CheckboxGroupUpdateProps;
  clear: () => CheckboxGroupUpdateProps;
};

/**
 * チェックボックスリストを生成する関数
 * @param {CheckboxFormListProps[]} list - チェックボックス情報
 * @param {string[]} values - チェック済みの値リスト
 * @returns {CheckboxDataListProps[]} 生成されたチェックボックスリスト
 */
export const generateCheckboxDataList = (
  list: CheckboxFormListProps[],
  values: string[],
): CheckboxDataListProps => {
  return list.map((data: CheckboxFormListProps) => {
    const { value } = data;
    const { label } = data;
    const isChecked: boolean = values.indexOf(data.value) !== -1;
    return { value, label, checked: isChecked };
  });
};

/**
 * チェック済み値の配列を生成する関数
 * @param {CheckboxDataListProps} checkboxDataList - チェックボックスリスト
 * @returns {string[]} - 生成された値配列
 */
export const generateCheckboxValues = (
  checkboxDataList: CheckboxDataListProps,
): string[] => {
  const values: string[] = [];
  for (let i = 0; i < checkboxDataList.length; i += 1) {
    if (checkboxDataList[i].checked) {
      values.push(checkboxDataList[i].value);
    }
  }
  return values;
};

const CHECKBOX_GROUP_DEFAULT_RULES = ['numeric'];
const VALIDATOR_DEFAULT_MESSAGE = {
  MAX: VALIDATE_ERROR_MESSAGE.CHECKBOX_GROUP_MAX,
  MIN: VALIDATE_ERROR_MESSAGE.CHECKBOX_GROUP_MIN,
};

/**
 * チェックボックスグループ<CheckboxGroup>を使う場合の部品生成
 * ※ バリデーションは選択数(文字)に実施(0の時は空文字)
 *
 * @param {CheckboxDataListProps} initialValue - 作成するチェックボックスリスト
 * @param {boolean} initialHasInput - 入力済フラグ初期値
 * @param {{ checked: string[] }} validatorRules - バリデーションルール
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ
 * @returns {CheckboxHooksProps} - 生成された部品
 */
const useCheckboxGroup = (
  initialValue: CheckboxDataListProps,
  hasInitialInput: boolean,
  validatorRules?: string[],
  validatorMessage?: { [key: string]: string },
): CheckboxGroupHooksProps => {
  const customValidatorRules = validatorRules
    ? [...CHECKBOX_GROUP_DEFAULT_RULES, ...validatorRules]
    : CHECKBOX_GROUP_DEFAULT_RULES;
  const customValidatorMessage = validatorMessage
    ? { ...VALIDATOR_DEFAULT_MESSAGE, ...validatorMessage }
    : VALIDATOR_DEFAULT_MESSAGE;

  const [checkboxDataList, setCheckboxDataList] = useState<
    CheckboxDataListProps
  >(initialValue);
  const [error, setError] = useState<ErrorProps>(
    validateChecks({
      checks: initialValue.map(
        (CheckboxData: CheckboxDataProps): boolean => CheckboxData.checked,
      ),
      validatorRules: customValidatorRules,
      validatorMessage: customValidatorMessage,
    }),
  );
  const [hasInput, setHasInput] = useState(hasInitialInput);

  const setHasInputTrue = (): void => {
    setHasInput(true);
  };

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: number,
  ): CheckboxGroupUpdateProps => {
    const checkboxDataListCache = [...checkboxDataList];
    checkboxDataListCache[key].checked = e.target.checked;
    const validateResult: ErrorProps = validateChecks({
      checks: checkboxDataListCache.map(
        (CheckboxData: CheckboxDataProps): boolean => CheckboxData.checked,
      ),
      validatorRules: customValidatorRules,
      validatorMessage: customValidatorMessage,
    });
    const update: CheckboxGroupUpdateProps = {
      values: generateCheckboxValues(checkboxDataListCache),
      error: validateResult,
      hasInput: true,
    };
    setCheckboxDataList(checkboxDataListCache);
    setError(validateResult);
    setHasInput(update.hasInput);
    return update;
  };

  const clear = (): CheckboxGroupUpdateProps => {
    const checkboxDataListCache = checkboxDataList.map(
      (checkboxData: CheckboxDataProps): CheckboxDataProps => {
        const checkboxDataCache = { ...checkboxData };
        checkboxDataCache.checked = false;
        return checkboxDataCache;
      },
    );
    const validateResult: ErrorProps = validateChecks({
      checks: checkboxDataListCache.map(
        (CheckboxData: CheckboxDataProps): boolean => CheckboxData.checked,
      ),
      validatorRules: customValidatorRules,
      validatorMessage: customValidatorMessage,
    });
    const update: CheckboxGroupUpdateProps = {
      values: [],
      error: validateResult,
      hasInput: false,
    };
    setCheckboxDataList(checkboxDataListCache);
    setError(validateResult);
    setHasInput(update.hasInput);
    return update;
  };

  return {
    checkboxDataList,
    error,
    hasInput,
    setHasInputTrue,
    onChange,
    clear,
  };
};

export default useCheckboxGroup;
