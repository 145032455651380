import React, { useLayoutEffect } from 'react';
import * as api from 'oapi/api';
import * as TYPES from 'common/Types';
import { generateTsxToReplacedBr } from 'commontsx/Utility';
import usePasswordField from 'hooks/usePasswordField';
import { HandleSetAnswerProps } from 'presentational/general/templates/template/InputTemplate';
import Form from 'presentational/general/molecules/form/Form';
import InputArea from 'presentational/general/molecules/form/InputArea';
import PasswordField from 'presentational/general/atoms/basicInput/PasswordField';
import FormDescription from 'presentational/general/molecules/other/FormDescription';

export type TextStringFieldFormProps = {
  device: TYPES.DEVICE_TYPES;
  form: api.Form;
  disabled: boolean;
  visible: boolean;
  handleSetAnswer: (answer: HandleSetAnswerProps) => void;
};

/**
 * 【部品】【有機体】【テンプレート入力フォーム】パスワードフィールド
 * @param {TYPES.DEVICE_TYPES} device - 表示デバイスの種類
 * @param {api.Form} form - フォーム情報
 * @param {boolean} disabled - 非活性フラグ
 * @param {boolean} visible - 表示フラグ
 * @param {(answer: HandleSetAnswerProps) => void} handleSetAnswer - InputTemplateに入力状態を渡す関数
 */
const TextStringFieldForm = ({
  device,
  form,
  disabled,
  visible,
  handleSetAnswer,
}: TextStringFieldFormProps) => {
  const textForm = form.text_form as api.TextForm;
  const hooks = usePasswordField('', false, textForm.validations.values);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const { value, error, hasInput } = hooks.onChange(e);
    handleSetAnswer({
      formId: form.id,
      values: [value],
      hasInput,
      isError: error.isError,
      isInitial: false,
      setHasInputTrue: hooks.setHasInputTrue,
    });
  };

  const handleBlur = (): void => {
    const { value, error, hasInput } = hooks.onBlur();
    handleSetAnswer({
      formId: form.id,
      values: [value],
      hasInput,
      isError: error.isError,
      isInitial: false,
      setHasInputTrue: hooks.setHasInputTrue,
    });
  };

  useLayoutEffect((): void => {
    if (disabled && hooks.hasInput) {
      const { value, error, hasInput } = hooks.clear();
      handleSetAnswer({
        formId: form.id,
        values: [value],
        hasInput,
        isError: error.isError,
        isInitial: true,
        setHasInputTrue: hooks.setHasInputTrue,
      });
    }
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useLayoutEffect((): void => {
    handleSetAnswer({
      formId: form.id,
      values: [hooks.value],
      hasInput: hooks.hasInput,
      isError: hooks.error.isError,
      isInitial: true,
      setHasInputTrue: hooks.setHasInputTrue,
    });
    // 初回のみ呼び出したいため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {visible && (
        <Form
          device={device}
          templateInOut={TYPES.TEMPLATE_IN_OUT_TYPES.INPUT}
          question={generateTsxToReplacedBr(form.subscribe)}
          complement={<FormDescription form={form} />}
          required={disabled ? false : form.is_required}
          visible={visible}
        >
          <InputArea
            error={hooks.hasInput && hooks.error.isError}
            errorMessage={hooks.hasInput ? hooks.error.errorMessage : ''}
          >
            <PasswordField
              id={form.id}
              value={hooks.value}
              onChange={handleChange}
              onBlur={handleBlur}
              error={hooks.hasInput && hooks.error.isError}
              disabled={disabled}
              inputProps={{
                maxLength: textForm.max_length || 50,
              }}
            />
          </InputArea>
        </Form>
      )}
    </>
  );
};

export default TextStringFieldForm;
