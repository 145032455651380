import React from 'react';
import styled from 'styled-components';
import Text from 'presentational/general/atoms/text/Text';

/**
 * 【部品】【分子】【その他】Symgram利用規約 <SymgramTermsOfUseContent>タグ
 */
const SymgramTermsOfUseContent = () => {
  const TextWrap = styled.div`
    margin: 10px 0;
  `;

  const BlockText = styled(Text)`
    display: block;
  `;

  return (
    <React.Fragment>
      <TextWrap>
        <BlockText fontWeight="bold">Web情報登録システム利用規約</BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">第１条 摘要</BlockText>
        <BlockText>
          Web情報登録システム利用規約（以下、「本規約」といいます。）とは、
          シンバイオシス・ソリューションズ株式会社（以下「当社」といいます。）が運営する医療機関向け腸内細菌叢の検査・分析サービス「SYMGRAM」での
          Web情報登録システムを利用するための条件を、利用者と当社との間において定めるものです。
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">第２条 本規約の変更・改定</BlockText>
        <BlockText>
          当社は、本規約の変更が、本規約に基づく契約の目的に反せず、
          かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるときは、
          民法548条の4の規定により、本規約を変更することができるものとします。
          本規約を変更した場合、変更後の本規約が適用されます。
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">第３条 利用者および被検者登録</BlockText>
        <BlockText>
          1.
          利用者とは本規約を承認の上、当社が運営するWeb情報登録システムの利用のために利用者として登録した方のことを言います。
          利用者は、本規約に定める条件に従い、本サービスを利用することができます。
          <br />
          2.
          利用者登録をする方は、当社の定める情報を当社に登録する必要があります。
          個人情報の取り扱いについて登録時の確認事項に同意いただくものとします。
          <br />
          3.
          利用者は、本サービスのご利用にあたり、検査キット毎に決められた認証コードおよびサンプルIDを、
          当社が定める方法により登録するものとします。
          <br />
          4.
          被検者とは、本サービス内で必要となる大便検体およびアンケート回答内容の本人を指します。
          利用者本人が被検者として登録する場合もありますが、利用者が被検者の代諾者となる場合もあります。
          <br />
          5.
          1人の利用者について、登録することのできる被検者の人数は3人までとします。
          <br />
          6. Web情報登録システムのアカウントは利用者のアカウントとなります。
          利用者は登録した被検者を選択し、サービスに必要な情報を登録することができます。
          <br />
          7.
          利用者が第三者に登録情報を貸与、譲渡、売買、担保提供などすることは禁止します。
          <br />
          8. 日本国外に在住の方は利用者登録することができません。
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">
          第４条 メールアドレス、パスワードの管理
        </BlockText>
        <BlockText>
          1.
          Web情報登録システムを利用するためのメールアドレスおよびパスワードは、
          他人に知られることがないよう、利用者本人が責任をもって管理するものとします。
          <br />
          2.
          当社は、入力または利用されたメールアドレスおよびパスワードの組合せが利用者の登録したものと一致することを所定の方法により確認した場合、
          利用者による利用があったものとしてサービスを提供するものとします。
          <br />
          3.
          当社は、盗用、不正利用その他の事情により利用者のアカウントを当該利用者以外の第三者が利用している場合であっても、
          当社の責めに帰すべき事由がある場合を除き、それにより生じた損害について責任を負わないものとします。
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">
          第５条 利用者規約の違反などについて
        </BlockText>
        <BlockText>
          利用者が以下の各号のいずれかに該当した場合、当社は、当社の定める期間、本サービスおよび当社が提供するその他のサービスの全部もしくは一部の利用を認めないこと、
          または、利用者登録を取り消すことができます。
          <br />
          ・利用者登録申込みの際の個人情報登録、および利用者となった後の個人情報変更において、その内容に虚偽や不正があった場合
          <br />
          ・本規約に違反した場合
          <br />
          ・利用者が真に本サービスを申し込む意思のない申込みを行った場合
          <br />
          ・他の利用者に不当に迷惑をかけた場合
          <br />
          ・利用者と連絡が取れない場合
          <br />
          ・本サービスが不正利用されている疑いがある場合、その他不適当な場合
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">第６条 利用者の禁止事項</BlockText>
        <BlockText>
          利用者は、以下の行為を行ってはなりません。
          <br />
          ・利用者が被検者の代諾者となることが不適当であるにも関わらず、他人を被検者として登録すること
          <br />
          ・他人の個人情報、分析結果または利用者記述情報を違法、不適切に収集、開示その他不正な目的で利用すること
          <br />
          ・他の個人または団体になりすまし、または他の個人または団体と関係があるように不当に見せかけること
          <br />
          ・他の利用者の認証コード、サンプルID、メールアドレスおよびパスワードを入手しようとすること
          <br />
          ・当社が提供するアプリケーション、当社が保有するサーバーおよびこれらが生成する情報、通信内容などの解読、
          解析、逆コンパイル、逆アセンブルまたはリバースエンジニアリングをすること
          <br />
          ・迷惑メール、チェーンメール、コンピューターウィルスなどの不適切なデータを送信すること
          <br />
          ・本システムを変更または妨げることを目的に利用すること
          <br />
          ・本システムのバグ、誤動作を利用すること
          <br />
          ・詐欺的行為をすること
          <br />
          ・本システムの不正利用、その他不適当な行為
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">第７条 個人情報の取り扱い</BlockText>
        <BlockText>
          情報登録時に提供いただいた個人情報は、当社が定める「個人情報保護方針」および「個人情報の取り扱いについて」に則って適切に取り扱います。
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">第８条 免責事項</BlockText>
        <BlockText>
          1.
          当社は、本サービスの内容、および利用者が本サービスを通じて入手した情報などについて、その完全性、正確性、確実性などにつき、責任を負いません。
          <br />
          2.
          当社は、本規約に記載の事項、検査キット内に記載の事項、情報登録時の同意事項において、当社が責任を負わないと記載した内容について責任を負いません。
          <br />
          3.
          利用者は法律の範囲内で本システムを利用するものとします。本システムの利用に関連して利用者が日本および外国の法律に触れた場合でも、当社は責任を負いません。
          <br />
          4.
          当社は、当社の故意または重大な過失により利用者に損害を与えた場合には、その損害（現実に生じた直接かつ通常の損害に限り、逸失利益を含まない）を賠償します。
          <br />
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">第９条 登録事項の変更</BlockText>
        <BlockText>
          利用者は、登録事項に変更のあった場合、すみやかに当社の定める手続きにより当社に届け出るものとします。
          この届出のない場合、当社は、登録事項の変更のないものとして取り扱うことができます。
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">第１０条 登録情報の削除</BlockText>
        <BlockText>
          利用者は、当社の定める手続きにより利用者情報を削除することができます。
          <br />
          利用者情報を削除した場合、利用者は、アンケート回答内容の閲覧など、利用者登録が必要なものについて以後利用することができません。
        </BlockText>
      </TextWrap>
      <TextWrap>
        <BlockText fontWeight="bold">第１１条 準拠法、管轄裁判所</BlockText>
        <BlockText>
          本規約に関する準拠法は日本法とし、本システムに関し、利用者と当社との間で訴訟が生じた場合、東京地方裁判所を第一審の専属的管轄裁判所とします。
        </BlockText>
      </TextWrap>
    </React.Fragment>
  );
};

export default SymgramTermsOfUseContent;
